<template>
  <div class="no-scroll">
    <div class="app-container">

      <a-layout>
        <a-layout-sider v-if="show_panels" :trigger="null" collapsible v-model:collapsed="collapsed">
          <SideNav
            @register_toggleCollapsed="register_toggleCollapsed"
          />
        </a-layout-sider>
        <a-layout>
          <div class="main-container no-scroll" style="width: 100%">
            <div class="no-scroll" style="display: flex;flex-direction: column;overflow-y: scroll;">
              <nav v-if="show_panels" class="app-nav dark-background no-scroll">
                <div style="color: white;font-size: 16px;margin: 10px">
                    <MenuUnfoldOutlined @click="collapsed = !collapsed" v-if="collapsed" />
                    <MenuFoldOutlined @click="collapsed = !collapsed" v-else />
                </div>
                <div class="profile">
                  <a-dropdown :trigger="['click']">
                    <a class="ant-dropdown-link" @click.prevent>
                      <img style="height: 24px;width: 24px;border-radius: 300%;background-color: white;" :src="company_data.logo? company_data.logo:default_vars.avatar" />
                      <span style="color: white;font-size: 14px;margin-left: 8px">{{company_data[role]?.username}}</span>
                    </a>
                    
                    <template #overlay>
                      <a-menu>
                        <a-menu-item key="0" disabled>
                          <span class="material-symbols-outlined" style="font-size: 16px;margin-right: 4px">Settings</span>
                          <a href="http://www.taobao.com/">Settings</a>
                        </a-menu-item>
                        <a-menu-item key="1">
                          <span class="material-symbols-outlined" style="font-size: 16px;margin-right: 4px">Lock</span>
                          <a @click="toChangePassword()">เปลี่ยนรหัสผ่าน</a>
                        </a-menu-item>
                        <a-menu-divider />

                        <a-menu-item key="2">
                          <span class="material-symbols-outlined" style="font-size: 16px;margin-right: 4px">power_rounded</span>
                          <a @click="logout()" >ออกจากระบบ</a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
              </nav>
              <router-view 
                class="no-scroll"
                style="background-color: #f5f5f5;overflow-y: scroll;"
                :user_name="company_data[role]?.username"
                :permission="user_profile?.permission"
                :model_data="model_data"
                @register_value="register_company"
                @apply_model_data="apply_model_data"
                @request_fullscreen="apply_fullscreen"
              />
            </div>

            <div v-if="show_panels" class="no-srcoll" style="display: flex;width: 100%;position: relative">
              <footer>© 2022 VECABO MANAGEMENT</footer>
            </div>
          </div>
        </a-layout>
      </a-layout>

    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import SystemService from './api/SystemService';
import SideNav from './components/side_nav.vue'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons-vue';
import VecaboService from './api/VecaboService';
import AdminService from './api/AdminService';

export default {
  components: { SideNav, MenuUnfoldOutlined, MenuFoldOutlined },
  name: 'App',
  computed: {
    show_panels() {
      return this.is_logged_in && !this.fullscreen
    }
  },
  data () {
    return {
      is_logged_in: localStorage.getItem('token'),
      company_data: localStorage.getItem('companyData')? JSON.parse(localStorage.getItem('companyData')):{},
      user_profile: localStorage.getItem('user_profile')? JSON.parse(localStorage.getItem('user_profile')):{},
      role: localStorage.getItem('role'),
      default_vars: {
        avatar: require('@/assets/images/fleetex.png')
      },
      toggleCollapsed: null,
      collapsed: false,
      fullscreen: false,

      /* Tray */
      model_data: {},

      company_id : null,
    }
  },
  methods: {
    register_toggleCollapsed(e) {
      this.toggleCollapsed = e
    },
    register_company(e) {
      const { 
        company_name,
        company_address,
        company_billing_address,
        company_phone_no,
        company_tax_id,
        company_logo,
        company_bank,
        company_bank_acc_name,
        company_bank_acc_no,
        company_bank_branch,
        company_email,
        admin_username,
        admin_password,
        admin_email,
        admin_firstname,
        admin_lastname,
        admin_phone_no,
        business_type_id,
        tax_id,
      } = e
      const company = {
        fullname: company_name,
        address: company_address,
        billing_address: company_billing_address,
        phone_no: company_phone_no,
        tax_id: company_tax_id,
        logo: company_logo,
        bank_name: company_bank,
        bank_acc_no: company_bank_acc_no,
        bank_branch: company_bank_branch,
        business_type_id: business_type_id,
        company_type_id: tax_id,
        email: admin_email,
      }
      const admin = {
        username: admin_username,
        password: admin_password,
        email: admin_email,
        firstname: admin_firstname,
        lastname: admin_lastname,
        phone_no: admin_phone_no,
      }
      const fileLogo = this.base64ToFile(company_logo);
      let carCompany = {
        fullname: company_name,
        address: company_address,
        billing_address: company_billing_address,
        phone_no: company_phone_no,
        tax_id: company_tax_id,
        logo: company_logo ?? null,
        logo_file_name: company_logo ? fileLogo.name : null,
        bank_account_name: company_bank_acc_name,
        bank_id : company_bank,
        bank_account_no: company_bank_acc_no,
        bank_branch: company_bank_branch,
        company_id: this.company_id,
        email: admin_email,
      }
      Swal.fire({
        title: 'กำลังดำเนินการ...',
        text: 'กรุณารอสักครู่',
        icon: 'info',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading()
        }
      });

      AdminService.createCompanyAdmin({data: [company]})
        .then(res => {
          if (res) {
            admin.company_id = res.data.id
            AdminService.createAdmin({data: [admin]})
              .then(res => {
                if (res) {
                  carCompany.company_id = res.data.company_id
                  const permission = {
                    role: 'admin',
                    role_id: res.data.id,
                    is_active: true,
                    dashboard: 2,
                    calendar: 2,
                    schedule: 2,
                    quotation: 2,
                    booking: 2,
                    billing: 2,
                    manage_booking: 2,
                    manage_tour: 2,
                    company: 2,
                    manage_cars: 2,
                    manage_car_parts: 2,
                    manage_sales: 2,
                    manage_repair: 2,
                    gas_station: 2,
                    manage_users: 2,
                    manage_accident: 2,
                    roll_call: 2,
                    cr_chat: 2,
                  }

                  AdminService.createPermissionAdmin({data: [permission]})
                    .then(async res2 => {
                      if (res2) {

                        // API to add car company
                        await SystemService.create_all("CarCompany", { data: [carCompany] })
                          .then(res => {
                              if (res) {
                                  console.log('CarCompany Crreated', res)
                              }
                          })

                        // i need if user click ok give reload page
                        Swal.fire({
                          title: 'สร้างบริษัท และผู้ดูแลเรียบร้อยแล้ว',
                          text: 'ระบบได้ส่งข้อมูลบัญญชีไปยังอีเมลแล้ว กรุณาตรวจสอบ',
                          icon: 'success',
                          confirmButtonText: 'OK'
                        }).then((result) => {
                          if (result.isConfirmed) {
                            this.$router.push(`/app/admin`)
                          }
                        })
                        
                      }
                    })
                }
              })
          }
        })
    },
    base64ToFile(base64) {
      const [header, base64Data] = base64.split(',');
      const mimeType = header.match(/data:([^;]+);base64/)[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });
      const extension = mimeType.split('/')[1]; // e.g., "png" for "image/png"
      const fileName = `logo.${extension}`;
      const file = new File([blob], fileName, { type: mimeType });
      return file;
    },
    logout () {
      localStorage.clear()
      window.location.href = '/'
    },
    apply_fullscreen(e) {
      this.fullscreen = e
    },
    apply_model_data(e) {
      this.model_data = e
    },
    toChangePassword() {
      this. user_profile = localStorage.getItem('user_profile')? JSON.parse(localStorage.getItem('user_profile')):{}
      this.$router.push(`/app/manageUsers/${this.role}/`+this.user_profile?.username)
    }
  }
}
</script>

<style lang="scss">
//'Noto Sans Thai', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap');

$blue: #1890FF;
$dark: #343A40;
$green: #46b04b;
$orange: #ee582f;
$yellow: #fcab10;

* {
  font-family: 'Noto Sans Thai', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-upload-list-item-actions {
  display: flex;
  align-items: center;
}

.ant-upload-list-item-actions .download-icon {
  font-size: 16px;
  color: #1890ff; 
  margin-left: 8px;
  cursor: pointer;
}

.ant-upload-list-picture-container {
  margin-right: 10px;
}
.ant-upload-list {
  display: flex;
  overflow-x: scroll;
}
span.ant-upload-list-item-name {
  display: none !important;
}
.ant-layout-sider-children {
  background-color: $dark;
  overflow-y: scroll;
}
.ant-input-number {
  width: 100% !important;
}
.ant-divider-inner-text {
  color: #1d39c4;
}
.ant-layout-sider-children::-webkit-scrollbar {
  opacity: 1;
}
.ant-btn {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.material-symbols-outlined {
  font-size: 14px;
  margin: auto 3px
}
// html::-webkit-scrollbar, .no-scroll::-webkit-scrollbar {
//   display: none;
// }

footer {
  position: absolute;
  bottom: 0;
  padding:11px 30px 12px;
  background-color: #f0f2f5;
  width: 100%;
  border-top:1px solid #0000000d;
  font-size: 11px;
}

html {
  padding: 0;
  width: 100vw;

  body {
    margin: 0;
  }
}

.google-icon {
  display: flex;
  align-items: center;
  span {
    padding-left: 4px;
  }
}
.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.collapse-button {
  background-color: $dark;
  border: none;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 240px;
  overflow: hidden;
  justify-content: space-between;
}

.menu-class {
  span, .menu-list {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 8px;
    font-size: 14px;
    color: #ffffffa6 !important;
  }
  a {
    text-decoration: none;
  }
}
.app-container {
  height: 100vh;
  display: flex;
}
.app-content {
  background-color: white;
  height: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 0.375rem;
  padding: 2rem;
  overflow-y: scroll;
}
.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.panel-container {
  width: 240px;
  text-align: center;

  @media (-width: 240px) {
    opacity: 1;
  }
}

.primary-button {
  cursor: pointer;
  background-color: $blue;
  color: white;
  margin: 0px 8px 12px 0px;
  padding: 7.2px 42px;
  width: 100%;
  border: none;
  border-radius: 6px;
}
.bg-blue {
  background-color: $blue !important;
}
.bg-green {
  background-color: $green !important;
}
.bg-orange {
  background-color: $orange !important;
}
.bg-yellow {
  background-color: $yellow !important;
}

.ant-upload {
  margin: 0 !important;
  height: fit-content !important;
  width: fit-content !important;
}
.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-row {
  display: flex;
  align-items: center;
}
.dark-background {
  background-color: $dark;
}
.app-nav {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 14px;
}
.btn-disabled,
.btn-disabled[disabled] {
  opacity: .9;
  cursor: default !important;
  pointer-events: none;
}
</style>
