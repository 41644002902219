<template>
    <div style="padding: 15px;height: 100vh">
        <div class="app-content">
            <a-card :title="title">
                <template v-if="!sjinda" #extra>
                    <a-space v-if="editable">
                        <a-button v-if="button_title" style="display: flex" type="primary" size="large"
                            @click="go_create(render_type)">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            {{ button_title }}
                        </a-button>
                    </a-space>
                </template>
                <a-row v-if="sjinda" style="display: flex; justify-content: flex-end;">
                    <a-space v-if="editable">
                        <a-button v-if="button_title" style="display: flex" type="primary" size="large"
                            @click="go_create(render_type)">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            {{ button_title }}
                        </a-button>
                    </a-space>
                </a-row>
                <br>
                <a-row>
                    <a-table
                        :columns="columns.filter((c) => !editable >= 1 ? !c.key.includes('id') : true)"
                        :data-source="show_table_list" bordered size="small"
                        :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'id'">
                                <div>
                                    <a @click="go_edit(record)"><span style="font-size: 14px"
                                            class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                    <a-divider type="vertical"></a-divider>
                                    <a @click="go_remove(record.id, record.modelname)"><span
                                            style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
                                </div>
                            </template>
                            <template v-else-if="column.key == 'isAir'">
                                <a-switch v-model:checked="record.isAir" />
                            </template>
                        </template>

                    </a-table>
                </a-row>
            </a-card>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import SystemService from '../../api/SystemService'
import Utility from '../../utility'
import UserService from '../../api/UserService';
import CompanyService from '../../api/CompanyService';
export default {
    name: 'repair-type',
    data() {
        return {
            sjinda: false,
            company_list: [],
            editable: Utility.get_permission_editable_with_key('manage_repair'),
            show_table_list: [],
            title: 'จัดการประเภทการซ่อม',
            button_title: 'เพิ่มประเภทการซ่อม',
            input: 'ชื่อคนขาย',
            columns: [
                {
                    title: 'ชื่อประเภทการซ่อม',
                    dataIndex: 'name',
                    key: 'name',
                    sorter: true,
                    width: 200
                }, {
                    title: 'ตัวเลือก',
                    dataIndex: 'id',
                    key: 'id',
                    width: 100,
                    fixed: 'right'
                }
            ],
        }
    },
    methods: {
        async go_remove(id, type) {
            Swal.fire({
                title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่? [${type}ID: ${id}]`,
                text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all(type, id)
                    this.init_repair_types()
                    Swal.fire(
                        'ลบข้อมูลเรียบร้อย!',
                        'ข้อมูลของคุณถูกลบแล้ว',
                        'success'
                    )
                }
            })
        },
        go_edit(model_data) {
            this.$emit('apply_model_data', model_data)
            this.$router.push(`/app/CarRepairType/${model_data.id}`)
        },
        go_create() {
            this.$router.push('/app/CarRepairType/create')
        },
        async init_repair_types() {
            this.show_table_list = await SystemService.get_all('CarRepairType')
        },
        async init_company_default() {
            this.company_list = await UserService.get_user_profile()
            const businessType = await CompanyService.getBusinessId(this.company_list.company_id)
            if (this.company_list.company_id === 39 || businessType.data === 2) {
                this.sjinda = true
            }
        }
    },
    mounted() {
        this.init_company_default()
        this.init_repair_types()
    }
}
</script>