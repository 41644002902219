
import httpClient from './httpClient';
const prefix = 'client'

export default {
  async getClientAll() {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getClientAll`)

    return data
  
  },

  async getClientByCompany() {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getClientByCompany`)

    return data
  },

  async getClientByCompanyAndId(id) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getClientByCompanyAndId/${id}`)

    return data
  },

  async updatePhoneNo(id, phone_no) {
    const { data } = await httpClient({ requiresAuth: true }).post(`${prefix}/updatePhoneNo`, 
      { 
        id: id,
        phone_no: phone_no
      })

    return data
  },
}