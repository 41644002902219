<template>
  <div class="quotation-container">
    <a-spin :spinning="loading">
      <div v-if="render_type == 'all'" class="app-content">
        <div v-if="!sjinda" class="app-header">
          <h2>จัดการใบเสนอราคา</h2>
          <router-link v-if="editable" :to="`/app/quotation/add`">
            <a-button
              @click="init_create_booking_options"
              style="display: flex"
              type="primary"
              size="large"
            >
              <template #icon
                ><span class="material-symbols-outlined">Add</span></template
              >
              สร้างใบเสนอราคา
            </a-button>
          </router-link>
        </div>
        <div  v-if="sjinda" class="app-header">
          <h2>จัดการใบเสนอราคา</h2>
        </div>
        <a-divider />
        <a-space v-if="editable,sjinda" style="width: 100%; justify-content: end;  margin-bottom: 16px">
          <router-link v-if="editable" :to="`/app/quotation/add`">
            <a-button
              @click="init_create_booking_options"
              style="display: flex"
              type="primary"
              size="large"
            >
              <template #icon
                ><span class="material-symbols-outlined">Add</span></template
              >
              สร้างใบเสนอราคา
            </a-button>
          </router-link>
          <!-- <a-button v-if="!sjinda"
            style="display: flex"
            type="primary"
            size="large"
            @click="open_create_invoice(selected_quotation, 'ออกสัญญาการเช่า')"
          >
            ออกสัญญาการเช่า
          </a-button> -->
          <a-button
            style="display: flex"
            type="primary"
            size="large"
            @click="open_create_invoice(selected_quotation)"
          >
            ออกใบเสนอราคา
          </a-button>
          <a-button
            @click="booking_progress()"
            style="display: flex"
            type="primary"
            size="large"
          >
            <template #icon><span class="material-symbols-outlined">Add</span></template>
            Confirm งาน
          </a-button>
        </a-space> 
        <a-space v-if="!sjinda" style="width: 100%; margin-bottom: 16px">
          <a-input-search
            v-model:value="search_input"
            placeholder="ค้นหาใบเสนอราคา ชื่อลูกค้า ชื่อรถ"
            enter-button="Search"
            @search="handleTableChange"
          />

          <a-select
            :dropdownMatchSelectWidth="false"
            style="width: 160px"
            v-model:value="search_booking_type_id"
            @change="handleTableChange"
            placeholder="ประเภทการเดินทาง"
          >
            <a-select-option v-for="{ name, id } in booking_types" :key="id" :value="id">
              {{ name }}
            </a-select-option>
          </a-select>

          <a-select
            v-model:value="search_progress"
            style="width: 160px"
            placeholder="สถานะของใบเสนอราคา"
            @change="handleTableChange"
          >
            <a-select-option :value="3">ยกเลิก</a-select-option>
            <a-select-option :value="0">ใหม่</a-select-option>
            <a-select-option :value="1">กำลังดำเนินการ</a-select-option>
            <a-select-option :value="2">เสร็จสิ้น</a-select-option>
          </a-select>

          <a-range-picker
            v-model:value="datetime"
            @change="handleTableChange"
          />

          <a-button @click="clear_search()" type="dashed">CLEAR</a-button>
        </a-space>
        <a-space v-if="sjinda" style="width: 100%;  margin-bottom: 16px">
          <a-input-search
            v-model:value="search_input"
            placeholder="ค้นหาใบเสนอราคา ชื่อลูกค้า ชื่อรถ"
            enter-button="Search"
            @search="handleTableChange"
          />

          <a-select
            :dropdownMatchSelectWidth="false"
            style="width: 160px"
            v-model:value="search_booking_type_id"
            @change="handleTableChange"
            placeholder="ประเภทการเดินทาง"
          >
            <a-select-option v-for="{ name, id } in booking_types" :key="id" :value="id">
              {{ name }}
            </a-select-option>
          </a-select>

          <a-select
            v-model:value="search_progress"
            style="width: 160px"
            placeholder="สถานะของใบเสนอราคา"
            @change="handleTableChange"
          >
            <a-select-option :value="3">ยกเลิก</a-select-option>
            <a-select-option :value="0">ใหม่</a-select-option>
            <a-select-option :value="1">กำลังดำเนินการ</a-select-option>
            <a-select-option :value="2">เสร็จสิ้น</a-select-option>
          </a-select>
          <a-range-picker
            v-model:value="datetime"
            @change="handleTableChange"
          />
          <a-button @click="clear_search()" type="dashed">CLEAR</a-button>
        </a-space>
        <a-space  v-if="editable,!sjinda" style="width: 100%; justify-content: end; margin: 4px">
          <a-button
            style="display: flex"
            type="primary"
            size="large"
            @click="open_create_invoice(selected_quotation, 'ออกสัญญาการเช่า')"
          >
            ออกสัญญาการเช่า
          </a-button>
          <a-button
            style="display: flex"
            type="primary"
            size="large"
            @click="open_create_invoice(selected_quotation)"
          >
            ออกใบเสนอราคา
          </a-button>
          <a-button
            @click="booking_progress()"
            style="display: flex"
            type="primary"
            size="large"
          >
            <template #icon><span class="material-symbols-outlined">Add</span></template>
            Confirm งาน
          </a-button>
        </a-space>
        <a-table
          rowKey="id"
          :columns="[
            {
              title: 'QTC',
              dataIndex: 'booking_no',
              key: 'booking_no',
              width: 150,
            },
            {
              title: 'สถานะรับงาน',
              dataIndex: 'car_job_status',
              key: 'car_job_status',
              width: 100,
              align: 'center',
            },
            {
              title: 'แก้ไขล่าสุด',
              dataIndex: 'qt_updated_at',
              key: 'qt_updated_at',
              width: 175,
              align: 'center',
            },
            {
              title: 'สถานะ',
              dataIndex: 'progress',
              key: 'progress',
              width: 118,
              align: 'center',
            },
            {
              title: 'บริษัท',
              dataIndex: 'client_name',
              key: 'client_name',
              width: 216,
            },
            {
              title: 'วันที่ - เวลา รับ',
              dataIndex: 'time_start',
              key: 'time_start',
              width: 150,
            },
            {
              title: 'วันที่ - เวลา ส่ง',
              dataIndex: 'time_end',
              key: 'time_end',
              width: 150,
            },
            {
              title: 'จุดรับ',
              dataIndex: 'start_location_name',
              key: 'start_location_name',
              width: 150,
              align: 'center',
              background: 'lightblue',
            },
            {
              title: 'จุดส่ง',
              dataIndex: 'end_location_name',
              key: 'end_location_name',
              width: 150,
              align: 'center',
            },
            {
              title: 'ราคาสุทธิ',
              dataIndex: 'net_price',
              key: 'net_price',
              width: 100,
            },
            {
              title: 'ชื่อ sale',
              dataIndex: 'sale_name',
              key: 'sale_name',
              width: 150,
            },
            ...(this.thanawit ? [] : [
            {
              title: 'จำนวนวัน',
              dataIndex: 'total_days',
              key: 'total_days',
              width: 100,
            },
            {
              title: 'ผู้โดยสาร',
              dataIndex: 'person_qty',
              key: 'person_qty',
              width: 100,
            },
            ]),
            {
              title: 'ประเภท',
              dataIndex: 'booking_type_id',
              key: 'booking_type_id',
              width: 200,
              align: 'center',
            },
            {
              title: 'เหตุผลในการยกเลิก',
              dataIndex: 'booking_cancel_text',
              key: 'booking_cancel_text',
              width: 200,
            },
            {
              title: 'ตัวเลือก',
              dataIndex: 'id',
              key: 'id',
              width: 160,
              align: 'center',
              fixed: 'right',
            },
          ]"
          :data-source="filtered_booking_list(booking_list)"
          bordered
          size="small"
          :scroll="{ x: 'calc(100px + 50%)', y: '500px' }"
          :row-selection="{
            type: 'radio',
            onSelect: select_row,
          }"
          :pagination="{
            total: pagination.total,
            current: pagination.onpage,
            pageSize: pagination.perpage,
          }"
          @change="handleTableChange"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'booking_type_id' && record[column.key]">
              <span
                ><a-tag
                  v-if="tag_option.booking_type_id[record.booking_type_id]"
                  :color="tag_option.booking_type_id[record.booking_type_id].color"
                >
                  {{ tag_option.booking_type_id[record.booking_type_id].txt }}
                </a-tag></span
              >
            </template>
            <template v-if="column.key === 'car_job_status'">
              <a-tag
                :color="
                  record.booking_cars?.length == record.booking_cars.filter(car => car.status == 8).length
                    ? 'green'
                    : 'red'
                "
                >
                {{
                  record.booking_cars.reduce((count, car) => {
                    return count + car.booking_car_statuses.filter(status => status.booking_status == 8).length;
                  }, 0)
                }} / {{ record.booking_cars.filter(car => car.active_car == 1).length}}              
              </a-tag>
            </template>
            <template v-else-if="column.key === 'progress' && record[column.key] != null">
              <span>
                <a-tag v-if="progressText(record)" :color="progressText(record).color">
                  {{ progressText(record).txt }}
                </a-tag>
              </span>
              <!-- <span v-if="booking_sjinda()">
                <a-tag v-if="progressText(record)" :color="progressText(record).color">
                  {{ progressText(record).txt }}
                </a-tag>
              </span> -->
            </template>
            <template v-else-if="column.key === 'net_price' && record[column.key]">
              <b>{{ Number(record.net_price).toLocaleString() }}</b>
            </template>
            <template v-else-if="column.key === 'time_start' && record[column.key]">
              {{ render_date(record.time_start) }}
            </template>
            <template v-else-if="column.key === 'time_end' && record[column.key]">
              {{ render_date(record.time_end) }}
            </template>
            <template v-else-if="column.key === 'total_days'">
              {{ record.total_days + 1 }}
            </template>
            <template v-else-if="column.key === 'id' && record[column.key]">
              <div>
              <a @click="duplicate_booking(record.id)"
                  ><span style="font-size: 18px" class="material-symbols-outlined"
                    >difference</span
                  ></a
                >
                <a-divider type="vertical"></a-divider>
                <a @click="edit_quotation(record.id, record)"
                  ><span style="font-size: 18px" class="material-symbols-outlined"
                    >drive_file_rename_outline</span
                  ></a
                >
                <a-divider type="vertical"></a-divider>
                <a @click="remove_booking(record.id, record.booking_no)"
                  ><span style="font-size: 18px" class="material-symbols-outlined"
                    >delete</span
                  ></a
                >
              </div>
            </template>
            <template v-if="column.key == 'qt_updated_at'">
              <!-- {{ record.qt_updated_at? record.qt_updated_at:record.updated_at }} <a-tag v-if="compare_timeGreater(record.update_at, record.billings)" :bordered="false" color="error">!</a-tag> -->
              {{
                record.qt_updated_at
                  ? render_time(record.qt_updated_at)
                  : render_time(record.updated_at)
              }}
              <a-tag v-if="record.qt_updated_at" :bordered="false" color="error">!</a-tag>
            </template>
            <!-- <template v-else>
                            <span>{{record[column.key]}}</span>
                        </template> -->
          </template>
        </a-table>
      </div>

      <div v-else class="app-content">
        <a-card
          :title="
            render_type == 'add'
              ? from_booking
                ? 'สร้าง ใบจองจัดรถ'
                : 'สร้างใบเสนอราคา'
              : on_booking
              ? `แก้ไข Booking: ${create_booking.booking_no}`
              : `แก้ไขใบเสนอราคา: ${create_booking.booking_no}`
          "
        >
          <template #extra>
            <a-button style="display: flex" @click="$router.go(-1)" size="large">
              <template #icon>
                <span class="material-symbols-outlined">arrow_back_ios</span>
              </template>
              ย้อนกลับ
            </a-button>
          </template>
        </a-card>
        <div style="margin: 8px 0">
          <a-form
            :model="create_booking"
            name="create_booking"
            autocomplete="off"
            ref="bookingForm"
            layout="vertical"
            @finish="submit_create_booking"
          >
            <a-collapse class="create_booking" style="border-radius: 4px" v-model:activeKey="activeKey">
              <a-collapse-panel
                key="1"
                ref="clientItem"
                :header="`ลูกค้าบริษัท/กรุ๊ปทัวร์/ไกด์/ผู้จองรถ`"
              >
                <template #extra>
                  <p
                    v-if="[create_booking.client_id]?.filter((e) => e == null).length"
                    style="margin: 0; color: grey"
                  >
                    {{
                      `(ต้องระบุอีก ${
                        [create_booking.client_id].filter((e) => e == null).length
                      })`
                    }}
                  </p>
                </template>
                <a-divider orientation="left">บริษัทลูกค้า</a-divider>

                <a-row>
                  <a-button
                    v-if="render_type == 'add'"
                    type="primary"
                    style="margin-bottom: 5px; height: 32px"
                    @click="add_company()"
                    size="large"
                  >
                    <template #icon>
                      <span class="material-symbols-outlined">Add</span>
                    </template>
                    เพิ่มบริษัท
                  </a-button>
                </a-row>

                <a-row :gutter="16">
                  <a-col :span="24">
                    <a-form-item
                      label="บริษัท"
                      name="client_id"
                      :rules="[{ required: true, message: 'โปรดเลือกบริษัท' }]"
                    >
                      <!-- <a-select v-model:value="create_booking.client_id" placeholder="เลือกบริษัท" @change="select_client">
                                                    <a-select-option v-for="{ id, fullname }, i in client_company_list" :key="i" :value="id">{{ fullname }}</a-select-option>
                                                </a-select> -->
                      <a-select
                        v-model:value="create_booking.client_id"
                        show-search
                        placeholder="เลือกบริษัท"
                        :options="
                          client_company_list.map(({ id, fullname }) => ({
                            value: id,
                            label: fullname,
                          }))
                        "
                        :filter-option="filterOption"
                        @change="select_client(); resetBranch()"
                      ></a-select>
                    </a-form-item>
                  </a-col>

                  <a-col :span="8">
                    <a-form-item label="สาขา" name="company_branch">
                      <a-select
                        v-model:value="create_booking.client_branch_id"
                        @change="select_client"
                        placeholder="เลือกสาขา"
                      >
                        <a-select-option
                          v-for="{ id, name } in client_branch_list"
                          :key="id"
                          :value="id"
                        >
                          {{ name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>

                  <a-col :span="8">
                    <a-form-item label="อีเมล" name="email">
                      <a-input
                        disabled
                        placeholder="อีเมล"
                        v-model:value="create_booking.email"
                      ></a-input>
                    </a-form-item>
                  </a-col>

                  <a-col :span="8">
                    <a-form-item label="ประเภทกลุ่มลูกค้า" name="tour_type">
                      <a-select
                        disabled
                        placeholder="ประเภทกลุ่มลูกค้า"
                        v-model:value="create_booking.tour_type_id"
                      >
                        <a-select-option
                          v-for="{ id, name } in tour_types_list"
                          :key="id"
                          :value="id"
                        >
                          {{ name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>

                  <a-col :span="12">
                    <a-form-item label="เบอร์โทรศัพท์" name="phone_no">
                      <a-input
                        placeholder="เบอร์โทรศัพท์"
                        v-model:value="create_booking.phone_no"
                      ></a-input>
                    </a-form-item>
                  </a-col>

                  <a-col :span="12">
                    <a-form-item label="เลขผู้เสียภาษี" name="tax_id">
                      <a-input
                        disabled
                        placeholder="เลขผู้เสียภาษี"
                        v-model:value="create_booking.tax_id"
                      ></a-input>
                    </a-form-item>
                  </a-col>

                  <a-col :span="24">
                    <a-form-item label="ที่อยู่" name="address">
                      <a-textarea
                        disabled
                        placeholder="ที่อยู่"
                        v-model:value="create_booking.address"
                      ></a-textarea>
                    </a-form-item>
                  </a-col>
                </a-row>

                <a-divider orientation="left">ผู้จองรถ</a-divider>
                <a-row>
                  <a-button
                    v-if="render_type == 'add'"
                    type="primary"
                    style="margin-bottom: 5px; height: 32px"
                    @click="add_client(create_booking.client_id)"
                    size="large"
                    :disabled="!create_booking.client_id"
                  >
                    <template #icon>
                      <span class="material-symbols-outlined">Add</span>
                    </template>
                    เพิ่มผู้จองรถ
                  </a-button>
                </a-row>
                <a-row>
                  <a-col :span="12">
                    <a-form-item label="ผู้จองรถ" name="client_contact_id">
                      <a-select
                        v-if="create_booking.client_branch_id"
                        placeholder="ผู้จองรถ"
                        v-model:value="create_booking.client_contact_id"
                        @change="selectClientContact"
                      >
                        <a-select-option
                          v-for="{ id, firstname, lastname } in client_contact_list"
                          :key="id"
                          :value="id"
                          selected
                        >
                          {{ firstname }} {{ lastname }}
                        </a-select-option>
                      </a-select>
                      <a-select v-else disabled placeholder="โปรดเลือกบริษัทและสาขา"> </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="6">
                    <a-form-item label="ชื่อผู้จองรถ" name="firstname">
                      <a-input
                        disabled
                        placeholder="ชื่อผู้จองรถ"
                        v-model:value="create_booking.client_contact.firstname"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="6">
                    <a-form-item label="นามสกุลผู้จองรถ" name="lastname">
                      <a-input
                        disabled
                        placeholder="นามสกุลผู้จองรถ"
                        v-model:value="create_booking.client_contact.lastname"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>

                  <a-col :span="12">
                    <a-form-item label="เบอร์โทรผู้จองรถ" name="phone_no">
                      <a-input
                        placeholder="เบอร์โทรผู้จองรถ"
                        v-model:value="create_booking.client_contact.phone_no"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                 
                  <a-col :span="12">
                    <a-form-item label="อีเมลผู้จองรถ" name="email">
                      <a-input
                        disabled
                        placeholder="อีเมลผู้จองรถ"
                        v-model:value="create_booking.client_contact.email"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12">
                    <a-form-item label="ผู้ประสานงานหน้างาน" name="temporary_coordinator">
                      <a-input
                        placeholder="ผู้ประสานงานหน้างาน"
                        v-model:value="create_booking.temporary_coordinator"
                      ></a-input>
                    </a-form-item>
                  </a-col>

              

                  <a-col :span="12">
                    <a-form-item label="เบอร์โทรผู้ประสานงานหน้างาน" name="temporary_coordinator_phone_no">
                      <a-input
                        placeholder="เบอร์โทรผู้ประสานงานหน้างาน"
                        v-model:value="create_booking.temporary_coordinator_phone_no"
                      ></a-input>
                    </a-form-item>
                  </a-col>


                  <!-- <a-col :span="12">
                    <a-form-item label="อีเมล" name="email">
                      <a-input
                        disabled
                        placeholder="อีเมล"
                        v-model:value="create_booking.client_contact.email"
                      ></a-input>
                    </a-form-item>
                  </a-col> -->
                </a-row>
                <a-row>
                  <!-- <a-col :span="12">
										<a-form-item
											label="ประเภทกลุ่มลูกค้า"
											name="group_name">
											<a-select
												placeholder="ประเภทกลุ่มลูกค้า"
												v-model:value="create_booking.tour_id">
												<a-select-option
													v-for="{ id, name } in tour_list"
													:key="id"
													:value="id">
													{{ name }}
												</a-select-option>
											</a-select>
										</a-form-item>
									</a-col> -->

                  <a-col :span="12">
                    <a-form-item label="เลขอ้างอิง" name="booking_no">
                      <a-input
                        placeholder="เลขอ้างอิง"
                        v-model:value="create_booking.booking_no"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-collapse-panel>

              <a-collapse-panel key="2" :header="`การจอง`" ref="typeBooking">
                <template #extra>
                  <p
                    v-if="
                      [create_booking.booking_type_id, create_booking.datetime].filter(
                        (e) => e == null
                      ).length
                    "
                    style="margin: 0; color: grey"
                  >
                    {{
                      `(ต้องระบุอีก ${
                        [create_booking.booking_type_id, create_booking.datetime].filter(
                          (e) => e == null
                        ).length
                      })`
                    }}
                  </p>
                </template>
                <a-row  >
                  <a-col :span="12">
                    <a-form-item
                      label="ประเภทการจอง"
                      name="booking_type_id"
                      :rules="[{ required: true, message: 'โปรดเลือกประเภทการจอง' }]"
                    >
                      <a-select
                        v-model:value="create_booking.booking_type_id"
                        :style="{ marginBottom: '8px' }"
                      >
                        <a-select-option
                          v-for="{ id, name } in booking_types"
                          :key="id"
                          :value="id"
                          >{{ name }}</a-select-option
                        >
                      </a-select>
                    </a-form-item>
                  </a-col>

                  <a-col v-if="!booking_sjinda" :span="4">
                    <a-form-item label="จำนวนคน" name="person_qty">
                      <a-input v-model:value="create_booking.person_qty" />
                    </a-form-item>
                  </a-col>

                  <a-col v-if="!booking_sjinda"  :span="4">
                    <a-form-item label="ไฟท์รับ" name="flight_pick">
                      <a-input
                        v-model:value="create_booking.flight_pick"
                        placeholder="ระบุไฟท์รับ"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col v-if="!booking_sjinda"  :span="4">
                    <a-form-item label="ไฟท์ส่ง" name="flight_drop">
                      <a-input
                        v-model:value="create_booking.flight_drop"
                        placeholder="ระบุไฟท์ส่ง"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12">
                    <a-form-item
                      label="วันเวลารับ - ส่ง"
                      name="datetime"
                      :rules="[{ required: true, message: 'โปรดระบุวัน-เวลา รับส่ง' }]"
                    >
                      <a-range-picker
                        v-model:value="create_booking.datetime"
                        @change="auto_fill_days"
                        :show-time="{ format: 'HH:mm' }"
                        format="DD/MM/YYYY HH:mm"
                        :placeholder="['Start Time', 'End Time']"
                        :minuteStep="1"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col :span="4">
                    <a-form-item label="จำนวนวัน" name="total_days">
                      <a-input
                        disabled
                        v-model:value="create_booking.total_days"
                        placeholder="จำนวนวัน"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col :span="4">
                    <a-form-item label="จำนวนวันที่ค้างคืน" name="full_day_amount">
                      <a-input-number
                        v-model:value="create_booking.full_day_amount"
                        placeholder="จำนวนวันที่ค้างคืน"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col :span="4">
                    <a-form-item label="จุดประสงค์การเดินทาง" name="trip">
                      <a-select v-model:value="create_booking.trip_id">
                        <a-select-option
                          v-for="{ id, name } in company_trip_list"
                          :key="id"
                          >{{ name }}</a-select-option
                        >
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12">
                    <a-form-item label="ชื่อจุดรับ" name="start_location_name">
                      <vue-google-autocomplete
                        id="start_location_name"
                        classname="form-control"
                        placeholder="ค้นหาสถานที่"
                        v-on:input="
                          create_booking.start_location_name = $event.target.value
                        "
                        v-on:placechanged="
                          select_stop_name($event, 'start_location_name')
                        "
                        types="establishment"
                        country="th"
                      >
                      </vue-google-autocomplete>
                    </a-form-item>
                  </a-col>

                  <a-col :span="12">
                    <a-form-item label="ชื่อจุดส่ง" name="end_location_name">
                      <vue-google-autocomplete
                        id="end_location_name"
                        classname="form-control"
                        placeholder="ค้นหาสถานที่"
                        v-on:input="
                          create_booking.end_location_name = $event.target.value
                        "
                        v-on:placechanged="select_stop_name($event, 'end_location_name')"
                        types="establishment"
                        country="th"
                      >
                      </vue-google-autocomplete>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="24">
                    <a-form-item label="หมายเหตุ (ภายใน)" name="remark">
                      <a-textarea
                        v-model:value="create_booking.remark"
                        placeholder="ระบุหมายเหตุ"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <!-- <a-divider orientation="left">เงื่อนไขการชำระเงิน</a-divider>
                <a-row>
                  <a-col v-if="form.pay_type == 3">
                    <a-form-item label="เปอร์เซ็นต์จ่าย">
                      <a-input
                        type="number"
                        v-model:value="price_percent"
                        :min="1"
                        :max="100"
                        @change="cal_price(form.pay_type)"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col>
                    <a-form-item label="จำนวนเงิน">
                      <a-input
                        type="number"
                        v-model:value="form.total_price"
                        :disabled="form.pay_type == 1"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col>
                    <a-form-item label="ประเภทการจ่ายเงิน">
                      <a-select v-model:value="form.pay_type" @change="cal_price">
                        <a-select-option :value="1">ราคาเต็ม</a-select-option>
                        <a-select-option :value="2">จำนวน</a-select-option>
                        <a-select-option :value="3">เปอร์เซ็น</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item label="รายละเอียดชำระเงิน">
                      <a-input v-model:value="form.pay_extend"> </a-input>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-divider orientation="left">ตั้งค่าภาษี</a-divider>
                  <a-col :span="24">
                    <a-space>
                      <span>ภาษีมูลค่าเพิ่ม: </span>
                      <a-switch v-model:checked="create_booking.inc_vat" />
                      <span>หัก ณ ที่จ่าย: </span>
                      <a-switch v-model:checked="create_booking.withholding" />
                      <span>บริษัทรถ: </span>
                      <a-select
                        style="width: 500px"
                        placeholder="บริษัทรถ"
                        v-model:value="create_booking.car_company_id"
                        @change="
                          create_booking.car_company = car_company_list.find(
                            ({ id }) => $event == id
                          )
                        "
                      >
                        <a-select-option
                          v-for="({ id, fullname, withholding }, i) in car_company_list"
                          :key="i"
                          :value="id"
                        >
                          {{ fullname }} {{ `(${withholding?.name})` }}
                        </a-select-option>
                      </a-select>
                    </a-space>
                  </a-col>
                </a-row> -->
              </a-collapse-panel>

              <a-collapse-panel key="3" header="รถที่เลือกใช้งาน" ref="carType">
                <template #extra>
                  <div v-if ="this.on_booking">
                    <p
                      v-if="create_booking.booking_cars.length < 1"
                      style="margin: 0; color: grey"
                    >
                      {{
                        `(ต้องระบุอีก ${
                          [create_booking.booking_cars.length].filter(
                            (e) => e == null
                          ).length + 1
                        })`
                      }}
                    </p>
                  </div>
                  <div v-if ="!on_booking">
                    <p
                      v-if="create_booking.booking_car_types.length < 1"
                      style="margin: 0; color: grey"
                    >
                      {{
                        `(ต้องระบุอีก ${
                          [booking_car_types.booking_type_id].filter(
                            (e) => e == null
                          ).length
                        })`
                      }}
                    </p>
                  </div>
                </template>
                <a-row v-if="on_booking">
                  <a-col v-if="create_booking.booking_no">
                    <h1 style="color: red">ข้อมูลอ้างอิงมาจากใบเสนอราคา</h1>
                    <div
                      v-for="({
                          car_type, car_group, quantity, time_start, time_end, cost, expenses, price, start_point, end_point, remark, total_days, booking_type_id, flight_pick, flight_drop, full_day_amount, trip_id, created_at
                        },i) in create_booking.booking_car_types"
                      :key="i"
                    >
                    <!-- <div v-if ="create_booking.confirm_date >= created_at"> -->
                      <h4>{{ i + 1 }}. ข้อมูลการจอง</h4>
                      <div>
                        <span style="font-weight: bold; font-size: 18px; margin-right:5px">ประเภทการเดินทาง</span>
                        <span style="font-size: 18px; margin-right:5px">{{ get_booking_type_name(booking_type_id) }}</span>
                        <span style="font-weight: bold; font-size: 18px; margin-right:5px">วันที่</span> 
                        <span style="font-size: 18px; margin-right:5px">{{ render_date(time_start) }} - {{ render_date(time_end) }}</span> 
                        <span style="font-weight: bold; font-size: 18px; margin-right:5px">จำนวน</span>
                        <span style="font-size: 18px; margin-right:5px">{{ total_days }} </span>
                        <span style="font-weight: bold; font-size: 18px; margin-right:5px">วัน</span>
                      </div>
                      <div>
                        <span v-if="!booking_sjinda" style="font-weight: bold; font-size: 18px; margin-right:5px">จำนวน</span>
                        <span v-if="!booking_sjinda" style="font-size: 18px; margin-right:5px">{{ quantity }}</span>
                        <span v-if="!booking_sjinda" style="font-weight: bold; font-size: 18px; margin-right:5px">คัน</span>
                        <span v-if="!booking_sjinda" style="font-weight: bold; font-size: 18px; margin-right:5px">ไฟท์รับ</span> 
                        <span v-if="!booking_sjinda" style="font-size: 18px; margin-right:5px">{{ format_time(flight_pick) }}</span> 
                        <span v-if="!booking_sjinda" style="font-weight: bold; font-size: 18px; margin-right:5px">ไฟท์ส่ง</span> 
                        <span v-if="!booking_sjinda" style="font-size: 18px; margin-right:5px">{{ format_time(flight_drop) }}</span> 
                        <span style="font-weight: bold; font-size: 18px; margin-right:5px">จำนวนวันที่ค้างคืน</span> 
                        <span style="font-size: 18px; margin-right:5px">{{ full_day_amount }}</span> 
                        <span style="font-weight: bold; font-size: 18px; margin-right:5px">วัน</span>
                        <span style="font-weight: bold; font-size: 18px; margin-right:5px">จุดประสงค์การเดินทาง</span> 
                        <span style="font-size: 18px; margin-right:5px">{{ get_trip_name(trip_id) }}</span>
                      </div>
                      <div>
                        <span style="font-weight: bold; font-size: 18px; margin-right:5px">สถานที่จุดรับ</span> 
                        <span style="font-size: 18px; margin-right:5px">{{ start_point }}</span>
                      </div>
                      <div style="margin-bottom: 30px;">
                        <span style="font-weight: bold; font-size: 18px; margin-right:5px">สถานที่จุดส่ง</span> 
                        <span style="font-size: 18px; margin-right:5px">{{ end_point }}</span>
                      </div>
                      <h4>
                        ชนิดรถ {{ car_type?.name }}
                        {{ car_group?.name }} {{ car_group?.seats }} ที่นั่ง จำนวน
                        {{ quantity }} คัน {{ Number(price)?.toLocaleString() }} บาท/คัน
                      </h4>
                      <h4>
                        ( {{ render_time(time_start) }} - {{ render_time(time_end) }} )
                      </h4>
                      <h4 v-if="start_point && end_point">
                        {{ start_point }} - {{ end_point }}
                      </h4>
                      <p>หมายเหตุ: {{ remark }}</p>
                      <ul>
                        <li>
                          <h4>ราคารถ {{ Number(price * quantity)?.toLocaleString() }} บาท</h4>
                        </li>
                        <li v-for="{ expense, amount, price } in expenses">
                          <h4>
                            {{ expense.name }} ({{ price }} บาท) จำนวน
                            {{ amount }} ราคารวม {{ (amount * price).toLocaleString() }}
                          </h4>
                        </li>
                      </ul>
                      <div style="text-align: end">
                        <a-popover title="รายละเอียดราคารถ">
                          <template #content>
                            <p>
                              ภาษีมูลค่าเพิ่ม (VAT):
                              {{
                                sum_car_price(
                                  {
                                    cost: price * quantity,
                                    expenses,
                                    inc_vat: create_booking.inc_vat,
                                    withholding: create_booking.withholding,
                                  },
                                  "vat"
                                ).toLocaleString()
                              }}
                              บาท
                            </p>
                            <p>
                              หัก ณ ที่จ่าย
                              {{ withholding_type(create_booking.car_company, "text") }}:
                              {{
                                sum_car_price(
                                  {
                                    cost: price * quantity,
                                    expenses,
                                    inc_vat: create_booking.inc_vat,
                                    withholding: create_booking.withholding,
                                  },
                                  "withholding"
                                )?.toLocaleString()
                              }}
                              บาท
                            </p>
                          </template>
                          <a style="font-size: 20px"
                            >รวมราคา
                            {{
                              sum_car_price(
                                {
                                  cost: price * quantity,
                                  expenses,
                                  inc_vat: create_booking.inc_vat,
                                  withholding: create_booking.withholding,
                                },
                                "total"
                              ).toLocaleString()
                            }}
                            บาท</a
                          >
                        </a-popover>
                      </div>
                      <a-divider />
                      <!-- </div> -->
                    </div>
                    <br />
                    <h2>--- การบริการที่ผูกกับใบเสนอราคา ---</h2>
                    <ul>
                      <li v-for="extra in create_booking.extras">
                        <h4>{{ extra.extra.name }} จำนวน {{ extra.amount?.toLocaleString() }} หน่วย</h4>
                      </li>
                    </ul>
                    <h4 style="text-align: end">
                      รวมราคา
                      {{
                        Number(
                          create_booking.extras
                            .map(({ amount, price }) => amount * price)
                            .reduce((a, b) => a + b, 0)
                        ).toLocaleString()
                      }}
                      บาท
                    </h4>
                    <a-divider />
                    <br />
                    <div>
                    <h2 style="text-align: end">ราคารวม {{ price_formula().revenue }}</h2>
                    <h2 style="text-align: end">ส่วนลด {{ price_formula().discount }}</h2>
                    <h2 style="text-align: end">
                      ราคารวมหลังหักส่วนลด {{ price_formula().total_discounted }}
                    </h2>
                    <h2 style="text-align: end">
                      หัก ณ ที่จ่าย
                      {{ withholding_type(create_booking.car_company, "text") }}:
                      {{ price_formula().withholding }}
                    </h2>
                    <h2 style="text-align: end">
                      ภาษีมูลค่าเพิ่ม {{ price_formula().vat }}
                    </h2>
                    <h2 style="text-align: end">
                      ราคาสุทธิ {{ price_formula().revenue_net }}
                    </h2>
                    </div>
                    <a-divider />
                    <h3 style="text-align: end">
                      ต้นทุนค่าเช่ารถ {{ price_formula().cost_sum }}
                    </h3>
                    <h3 style="text-align: end">
                      กำไรขั้นต้น {{ price_formula().profit }}
                    </h3>
                  </a-col>
                </a-row>
                <a-row v-else>
                  <a-col>
                    <a-table
                      :columns="[
                        {
                          title: 'ชนิดรถ',
                          dataIndex: 'car_type_id',
                          key: 'car_type_id',
                          width: 150,
                        },
                        {
                          title: 'ประเภทที่นั่งรถ',
                          dataIndex: 'car_group_id',
                          key: 'car_group_id',
                          width: 150,
                        },
                        {
                          title: 'จำนวนคัน',
                          dataIndex: 'quantity',
                          key: 'quantity',
                          width: 150,
                        },
                        {
                          title: 'วันที่/เวลา รับลูกค้า',
                          dataIndex: 'time_start',
                          key: 'time_start',
                          width: 150,
                        },
                        {
                          title: 'วันที่/เวลา ส่งลูกค้า',
                          dataIndex: 'time_end',
                          key: 'time_end',
                          width: 150,
                        },
                        {
                          title: 'หมายเหตุ',
                          dataIndex: 'remark',
                          key: 'remark',
                          width: 150,
                        },
                        {
                          title: 'ราคารวม',
                          dataIndex: 'cost',
                          key: 'cost',
                          width: 150,
                        },
                        {
                          title: 'ตัวเลือก',
                          dataIndex: 'id',
                          key: 'id',
                          align: 'center',
                          width: 100,
                          fixed: 'right',
                        },
                      ]"
                      :data-source="create_booking.booking_car_types"
                      bordered
                      size="small"
                      :scroll="{ x: 400, y: 200 }"
                      :pagination="false"
                    >
                      <template #bodyCell="{ column, record, index }">
                        <template v-if="column.key === 'id'">
                          <a-tooltip placement="topLeft" title="ใช้วันเวลาใบเสนอราคา">
                            <a @click="sync_booking_car_with_quotation(record)">
                              <span
                                style="font-size: 18px"
                                class="material-symbols-outlined"
                                >sync</span
                              >
                            </a>
                          </a-tooltip>
                          <a-divider type="vertical" />
                          <a @click="add_booking_car_types(index)">
                            <span
                              style="font-size: 18px"
                              class="material-symbols-outlined"
                              >edit</span
                            >
                          </a>
                          <a-divider type="vertical" />
                          <a @click="remove_booking_car_types(index)">
                            <span
                              style="font-size: 18px"
                              class="material-symbols-outlined"
                              >delete</span
                            >
                          </a>
                        </template>
                        <template
                          v-if="column.key === 'time_start' || column.key === 'time_end'"
                        >
                          {{ render_date(record[column.key]) }}
                        </template>
                        <template v-if="column.key === 'cost' && record[column.key]">
                          {{ Number(record.cost).toLocaleString() }}
                        </template>
                        <template v-if="column.key === 'car_group_id'">
                          {{
                            car_group_list.find(({ id }) => id == record.car_group_id)
                              ?.name
                          }}
                        </template>
                        <template v-if="column.key === 'car_type_id'">
                          {{
                            car_type_list.find(({ id }) => id == record.car_type_id)?.name
                          }}
                        </template>
                      </template>
                    </a-table>
                  </a-col>
                </a-row>
                <a-row v-if="on_booking">
                  <a-col>
                    <a-table
                      rowKey="id"
                      :columns="[
                        {
                          title: 'ลำดับ',
                          dataIndex: 'order_pos',
                          key: 'order_pos',
                          align: 'center',
                          width: 70,
                        },
                        {
                          title: 'Booking Car',
                          dataIndex: 'booking_car_no',
                          key: 'booking_car_no',
                          align: 'center',
                          width: 110,
                        },
                        {
                          title: 'เบอร์รถ',
                          dataIndex: 'car_no',
                          key: 'car_no',
                          width: 100,
                          align: 'center',
                        },
                        {
                          title: 'ทะเบียนรถ',
                          dataIndex: 'plate_no',
                          key: 'plate_no',
                          width: 150,
                          align: 'start',
                        },
                        {
                          title: 'คนขับ',
                          dataIndex: 'driver',
                          key: 'driver',
                          width: 200,
                        },
                        {
                          title: 'คนขับชั่วคราว',
                          dataIndex: 'temporary_driver',
                          key: 'temporary_driver',
                          width: 200,
                        },
                        {
                          title: 'ชนิดรถ',
                          dataIndex: 'car_type',
                          key: 'car_type',
                          width: 150,
                          align: 'center',
                        },
                        {
                          title: 'วันเวลารับ-ส่ง',
                          dataIndex: 'start_end',
                          key: 'start_end',
                          width: 300,
                          align: 'center',
                          sorter: {
                            compare: (a, b) => new Date(b.time_start) - new Date(a.time_start),
                            multiple: 2,
                          },
                        },
                        {
                          title: 'RollCall',
                          dataIndex: 'rollcall',
                          key: 'rollcall',
                          width: 150,
                          align: 'center',
                        },
                        {
                            title: 'สถานะแจ้งงาน',
                            dataIndex: 'active_car',
                            key: 'active_car',
                            width: 130,
                            align: 'center',
                          },
                        {
                          title: 'สถานะของยานพาหนะ',
                          dataIndex: 'car_status',
                          key: 'car_status',
                          width: 200,
                          align: 'center',
                        },
                        {
                          title: 'สถานะรับงาน',
                          dataIndex: 'last_booking_status',
                          key: 'last_booking_status',
                          width: 200,
                        },
                        {
                          title: 'อัพเดท',
                          dataIndex: 'last_update',
                          key: 'last_update',
                          align: 'center',
                          width: 100,
                        },
                        {
                          title: `จำนวนรถ (${
                            create_booking.booking_cars.length
                          }/${create_booking.booking_car_types
                            .map(({ quantity }) => (quantity ? quantity : 0))
                            .reduce((a, b) => a + b, 0)})`,
                          dataIndex: 'total_car',
                          key: 'total_car',
                          align: 'right',
                          width: 150,
                        },
                        {
                          title: `จำนวนผู้โดยสาร (${create_booking.booking_cars
                            .map(({ passengers }) => passengers)
                            .reduce(
                              (a, b) => a + b, 0
                            )}/${create_booking.booking_car_types
                            .map(({ car_group }) => (car_group ? car_group.seats : 0))
                            .reduce((a, b) => a + b, 0)}) `,
                          dataIndex: 'total_seats',
                          key: 'total_seats',
                          align: 'right',
                          width: 150,
                        },
                        {
                          title: 'ราคาเช่า',
                          dataIndex: 'rental_price',
                          key: 'rental_price',
                          align: 'right',
                          width: 150,
                        },
                        {
                          title: 'ค่าเบี้ยเลี้ยง',
                          dataIndex: 'driver_cost',
                          key: 'driver_cost',
                          align: 'right',
                          width: 150,
                        },
                        {
                          title: 'รายจ่าย',
                          dataIndex: 'total_cars_expense',
                          key: 'total_cars_expense',
                          align: 'right',
                          width: 150,
                        },
                        {
                          title: 'ตัวเลือก',
                          dataIndex: 'id',
                          align: 'center',
                          width: 220,
                          key: 'id',
                          fixed: 'right',
                        },
                      ]"
                      :data-source="create_booking.booking_cars"
                      bordered
                      size="small"
                      :pagination="false"
                      :scroll="{ x: 'calc(100px + 50%)', y: '100%' }"
                    >
                      <template #bodyCell="{ column, record, index }">
                        <template v-if="column.key === 'active_car'">
                            <a-button
                              type="danger"
                              @click="activate_car(record)"
                              :disabled="record.active_car == 1"
                              >{{ record.active_car ? "แจ้งงานแล้ว" : "แจ้งงาน" }}</a-button
                            >
                          </template>
                          <template v-if="column.key === 'rental_price'" >
                             {{ Number(record.rental_price)?.toLocaleString() }}
                          </template>
                          <template v-if="column.key === 'driver_cost'" >
                             {{ Number(record.driver_cost)?.toLocaleString() }}
                          </template>
                        <template v-if="column.key === 'id'">
                          <a-tooltip
                            v-if="record.order_pos > 1"
                            placement="topLeft"
                            title="เลื่อนรถขึ้น"
                          >
                            <a
                              @click="adjust_car_pos(record.order_pos, record.id, 'up')"
                              stlye="width: 1rem"
                            >
                              <span
                                style="font-size: 18px"
                                class="material-symbols-outlined"
                                >arrow_drop_up</span
                              >
                            </a>
                          </a-tooltip>
                          <a-tooltip
                            v-if="record.order_pos == 1"
                            placement="topLeft"
                            title="เลื่อนรถลง"
                          >
                            <a
                              @click="adjust_car_pos(record.order_pos, record.id, 'down')"
                              stlye="width: 1rem"
                            >
                              <span
                                style="font-size: 18px"
                                class="material-symbols-outlined"
                                >arrow_drop_down</span
                              >
                            </a>
                          </a-tooltip>
                          <a-divider type="vertical" />
                          <a @click="duplicate(record)">
                            <span
                              style="font-size: 18px"
                              class="material-symbols-outlined"
                              >difference</span
                            >
                          </a>
                          <a-divider type="vertical" />
                          <a-tooltip placement="topLeft" title="ใช้วันเวลาจากการจอง">
                            <a @click="sync_booking_datetime(record, record.id)">
                              <span
                                style="font-size: 18px"
                                class="material-symbols-outlined"
                                >sync</span
                              >
                            </a>
                          </a-tooltip>
                          <a-divider type="vertical" />
                          <a @click="edit_booking_car(record, record.id)">
                            <span
                              style="font-size: 18px"
                              class="material-symbols-outlined"
                              >edit</span
                            >
                          </a>
                          <a-divider type="vertical" />
                          <a
                            @click="
                              remove_booking_car(record.id, record,{
                                is_last:
                                  create_booking.booking_cars.length ==
                                  create_booking.booking_car_types.length,
                              })
                            "
                          >
                            <span
                              style="font-size: 18px"
                              class="material-symbols-outlined"
                              >delete</span
                            >
                          </a>
                        </template>
                        <template v-else-if="column.key === 'rollcall'">
                          <a-space
                            ><a-tag
                              v-if="record.roll_call?.has_roll_call"
                              :color="record.roll_call?.allow ? 'green' : 'red'"
                              >{{ record.roll_call.allow ? "ผ่าน" : "ไม่ผ่าน" }}</a-tag
                            >
                            <a-tag
                              :color="
                                record.roll_call?.has_roll_call ? 'green' : 'yellow'
                              "
                              >{{
                                record.roll_call?.has_roll_call ? "เช็คแล้ว" : "รอเช็ค"
                              }}</a-tag
                            ></a-space
                          >
                        </template>
                        <template v-else-if="column.key === 'order_pos'">
                          <a-select
                            v-model:value="record.order_pos"
                            @change="select_order_pos(record.order_pos, record.id)"
                            style="width: 100%"
                          >
                            <a-select-option
                              v-for="i in create_booking.booking_cars.length"
                              :value="i"
                              >{{ i }}</a-select-option
                            >
                          </a-select>
                        </template>
                        <template v-if="column.key === 'car_no'">
                          {{
                            record.car
                              ? record.car[column.key]
                                ? record.car[column.key]
                                : ""
                              : ""
                          }}
                        </template>
                        <template v-else-if="column.key === 'plate_no'">
                          <a-badge-ribbon
                            v-if="
                              compare_time(
                                create_booking.created_at,
                                record.created_at,
                                30,
                                'seconds'
                              )
                            "
                            text="เปลี่ยน"
                            color="red"
                          >
                            <a-card
                              :title="
                                record.car
                                  ? record.car.plate_no
                                    ? record.car.plate_no
                                    : ''
                                  : ''
                              "
                              size="small"
                            ></a-card>
                          </a-badge-ribbon>
                          <a-card
                            v-else
                            :title="
                              record.car
                                ? record.car.plate_no
                                  ? record.car.plate_no
                                  : ''
                                : ''
                            "
                            size="small"
                          ></a-card>
                        </template>
                        <template v-else-if="column.key === 'car_type'">
                          {{ record.car?.car_group?.car_type?.name }}
                        </template>
                        <template v-else-if="column.key === 'car_status'">
                          <a-space
                            ><span>{{ record.car ? record.car.car_status : "" }}</span>
                            <a-button
                              type="primary"
                              :disabled="record.car.car_status_id == 1"
                              @click="confirm_booking_car(record)"
                              >ยืนยัน</a-button
                            ></a-space
                          >
                        </template>
                        <template v-else-if="column.key === 'total_car'">
                          {{ get_total_car(index) }}
                        </template>
                        <template
                          v-else-if="column.key === 'driver' && driver_list.length">
                          <span v-if="get_driver_fullname_with_car(record.driver_id)">
                            {{ get_driver_fullname_with_car(record.driver_id) }}
                          </span>
                          <span v-else-if="get_backup_driver_fullname_with_car(record.driver_id)">
                            {{ get_backup_driver_fullname_with_car(record.driver_id) }}
                          </span>
                        </template>
                        <template
                          v-else-if="column.key === 'temporary_driver'">
                          <span >
                          <p v-if="!record.driver_id" style="padding-top: 13px">
                            {{ record?.temporary_driver }}
                          </p>
                          </span>
                        </template>
                        <template v-else-if="column.key === 'last_update'">
                          <a-badge :count="record.logs?.length - record.last_update">
                            <a-popover trigger="hover">
                              <template #title>
                                ประวัติการอัพเดท
                                <a @click="upto_log_date(record.id, record.logs.length)"
                                  >อ่านแล้ว</a
                                >
                              </template>
                              <template #content>
                                <div style="height: 450px; overflow-y: scroll">
                                  <p v-for="data in render_log(record.logs)">
                                    {{ data }}
                                  </p>
                                </div>
                              </template>
                              <a-button>ดูอัพเดท</a-button>
                            </a-popover>
                          </a-badge>
                        </template>
                        <template v-else-if="column.key === 'last_booking_status'">
                          <a-tag
                            v-if="record.booking_car_statuses.filter(status => status.booking_status === 8).length"
                            color="green"
                          >
                            {{
                              render_date(
                                record.booking_car_statuses[0].created_at,
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            }}
                          </a-tag>
                          <a-tag color="red" v-else> ยังไม่ได้รับงาน </a-tag>
                        </template>
                        <template v-else-if="column.key === 'total_seats'">
                          {{ record.passengers }}
                        </template>
                        <template v-else-if="column.key === 'booking_car_no'">
                          {{ record.booking_car_no }}
                        </template>
                        <template v-else-if="column.key === 'start_end'">
                          {{ render_time(record.time_start) }} -
                          {{ render_time(record.time_end) }}
                        </template>
                        <template v-else-if="column.key === 'total_cars_expense'">
                          <span style="font-size: 16px">
                            {{ sum_car_expense(record).toLocaleString() }}
                          </span>
                          <a
                            @click="show_total_cars_expense(record,record.id)"
                            :style="`height: 16px; color: ${
                              allIsApproved(record)
                                ? 'greenyellow'
                                : 'red'
                            }`"
                          >
                            <span
                              style="font-size: 16px; line-height: 0"
                              class="material-symbols-outlined"
                              >pie_chart</span
                            >
                          </a>
                        </template>
                      </template>
                    </a-table>
                  </a-col>
                </a-row>
                <a-row v-if="on_booking">
                  <a-space>
                    <a-button
                      type="primary"
                      style="display: flex"
                      @click="add_car_to_booking()"
                      size="large"
                    >
                      <template #icon>
                        <span class="material-symbols-outlined">Add</span>
                      </template>
                      เพิ่มรถ
                    </a-button>
                    <!-- <a-button
                      type="primary"
                      style="display: flex"
                      @click="booking_car_re_order(this.create_booking.booking_cars)"
                      size="large"
                    >
                      <template #icon>
                        <span class="material-symbols-outlined">manage_history</span>
                      </template>
                      จัดรถตามเวลาเริ่ม
                    </a-button> -->
                  </a-space>
                </a-row>
                <a-row justify="end" v-else>
                  <a-button
                    type="primary"
                    style="display: flex"
                    @click="add_booking_car_types()"
                    size="large"
                  >
                    <template #icon>
                      <span class="material-symbols-outlined">Add</span>
                    </template>
                    เพิ่มรถ
                  </a-button>
                </a-row>
                <!-- BookingCar Drawer -->
                <a-drawer
                  v-model:visible="add_car_drawer"
                  class="custom-class"
                  style="color: red"
                  title="รถที่รับงานได้"
                  placement="right"
                >
                  <a-row>
                    <a-col style="width: 20px; height: 20px; background-color: #1890ff; border-radius: 50%; margin-right: 2px;"></a-col>
                    <p style="color: black; margin-right: 10px">พร้อมใช้งาน</p>
                    <a-col style="width: 20px; height: 20px; background-color: #a2a2a2; border-radius: 50%;margin-right: 2px"></a-col>
                    <p style="color: black">ไม่พร้อมใช้งาน</p>                
                  </a-row>
                  <a-row>
                    <a-col>
                    <p style="margin-bottom: 0px; color:black">ชนิดรถ</p>
                      <a-select v-model:value="preselect_car_type_id">
                        <a-select-option
                          v-for="car_type in car_type_list"
                          :key="car_type.id"
                          :value="car_type.id"
                        >
                          {{ car_type.name }}
                        </a-select-option>
                      </a-select>
                    </a-col>
                  </a-row>
                  <a-space>
                    <a-input-search
                      v-model:value="search_car"
                      enter-button="ค้นหา"
                      placeholder="ค้นหารถ"
                      style="margin-bottom: 10px"
                    />
                    <a-button @click="clear_search()" type="dashed">CLEAR</a-button>
                  </a-space>
                  <a-card
                  @click="
                      car_status_id == 1 || car_status_id == 7
                        ? assign_car(filtered_cars[i], { car_status_id })
                        : show_message('รถไม่ว่าง', 'error')
                    "
                    v-for="(
                      {
                        car_no,
                        driver,
                        plate_no,
                        parking,
                        id,
                        car_status_id,
                        car_group,
                        stops,
                        created_at,
                        time_start,
                        time_end,
                        createAt,
                      },
                      i
                    ) in filtered_cars"
                    size="small"
                    hoverable
                    :key="id"
                    :class="
                    createAt
                      ? 'gray-card'
                      : car_status_id === 1 || car_status_id === 7
                      ? car_group?.car_type_id === preselect_car_type_id
                        ? 'blue-card'
                        : 'yellow-card'
                      : 'gray-card'
                    "
                    :bordered="false"
                  >
                    <p>
                      {{
                        `เลขรถ ${car_no} | ทะเบียน ${plate_no} | สาขา ${
                          parking ? parking.name : ""
                        }`
                      }}
                    </p>
                    <p>ประเภทรถ: {{ car_group?.car_type?.name }}</p>
                    <p v-if="driver">
                      คนขับ: {{ driver.firstname }} {{ driver.lastname }}
                    </p>
                    <p v-else>คนขับ: ไม่มี</p>
                    <p style="max-height: 10rem; overflow-y: scroll">
                      มีการจองรถในช่วงเวลา:
                      <a-tag
                        style="
                          max-width: 20rem;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                        v-for="{ id, name, expected_timestamp } in stops
                          .filter(
                            ({ name, expected_timestamp }) => name && expected_timestamp
                          )
                          .sort(
                            (a, b) =>
                              new Date(a.expected_timestamp) -
                              new Date(b.expected_timestamp)
                          )"
                        :key="id"
                        color="red"
                      >
                        {{ render_time(expected_timestamp) }} : {{ name }}
                      </a-tag>
                    </p>
                    <div v-if="time_end" style="display: flex; align-items: center">
                        <span
                          class="material-symbols-outlined"
                          style="
                            font-size: 26px;
                            color: red;
                            transform: scaleX(-1);
                            display: inline-block;
                            pointer-events: auto;
                          "
                          >Build</span
                        >
                        <p v-if="createAt" style="margin-top: 17px">แจ้งซ่อม</p>
                      </div>
                      <p v-if="createAt">{{ `วันที่แจ้งซ่อม : ${createAt}` }}</p>
                      <p v-if="createAt">{{ `วันที่ซ่อมเสร็จ : ${time_end}` }}</p>
                  </a-card>
                </a-drawer>

                    <!-- Create Booking Expense Drawer -->
                    <a-drawer
                      v-model:visible="show_create_booking_car_expense"
                      placement="right"
                      title="เพิ่มค่าใช้จ่ายเพิ่มเติม"
                    >
                      <a-form
                        :model="create_booking_car_expense"
                        layout="vertical"
                        @finish="
                          submit_drawer(
                            'create_booking_car_expense',
                            'expenses',
                            'show_create_booking_car_expense',
                            'BookingCarExpense'
                          )
                        "
                      >
                        <a-row>
                          <a-col :span="12">
                            <a-form-item
                              label="ผู้ชำระ"
                              name="driver_paid"
                              :rules="[{ required: true, message: 'กรุณาเลือกผู้ชำระ' }]"
                            >
                              <a-radio-group
                                v-model:value="create_booking_car_expense.driver_paid"
                              >
                                <a-radio-button value="1">คนขับ</a-radio-button>
                                <a-radio-button value="0">บริษัท</a-radio-button>
                              </a-radio-group>
                            </a-form-item>
                          </a-col>

                          <a-col :span="12">
                            <a-form-item
                              label="ประเภทการจ่ายเงิน"
                              name="pay_method_id"
                              :rules="[
                                {
                                  required: true,
                                  message: 'เลือกประเภทการจ่ายเงิน',
                                },
                              ]"
                            >
                              <a-select
                                v-model:value="create_booking_car_expense.pay_method_id"
                                placeholder="เลือกประเภทการจ่ายเงิน"
                              >
                                <a-select-option
                                  v-for="{ id, name } in pay_method_list"
                                  :key="id"
                                  :value="id"
                                >
                                  {{ name }}
                                </a-select-option>
                              </a-select>
                            </a-form-item>
                          </a-col>
                        </a-row>

                        <a-form-item
                          label="รายการ"
                          name="expense_id"
                          :rules="[{ required: true, message: 'กรุณากรอกรายการ' }]"
                        >
                          <a-select v-model:value="create_booking_car_expense.expense_id">
                            <a-select-option
                              v-for="expense in expense_list"
                              :key="expense.id"
                              :value="expense.id"
                            >
                              {{ expense.name }}
                            </a-select-option>
                          </a-select>
                        </a-form-item>

                        <a-form-item
                          label="จำนวนเงิน"
                          name="amount"
                          :rules="[{ required: true, message: 'กรุณากรอกจำนวนเงิน' }]"
                        >
                          <a-input-number
                            v-model:value="create_booking_car_expense.amount"
                          />
                        </a-form-item>

                        <!-- <a-form-item label="หมายเหตุ" name="remark">
                          <a-textarea v-model:value="create_booking_car_expense.remark" />
                        </a-form-item> -->

                        <a-form-item label="รูปหลักฐานการโอน" name="remark">
                          <a-upload
                            v-model:file-list="create_booking_car_expense.fileList"
                            list-type="picture-card"
                            class="avatar-uploader"
                            :show-upload-list="false"
                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                            @change="handleUpload($event, 'create_booking_car_expense')"
                            method="GET"
                          >
                            <img
                              v-if="create_booking_car_expense.photo"
                              :src="create_booking_car_expense.photo"
                              :style="`width: 100%;object-fit: cover`"
                              alt="avatar"
                            />
                            <div v-else>
                              <p class="ant-upload-drag-icon">
                                <span
                                  class="material-symbols-outlined"
                                  style="font-size: 5rem"
                                  >upload_file</span
                                >
                              </p>
                              คลิกเพื่ออัพโหลดรูปภาพ
                            </div>
                          </a-upload>
                        </a-form-item>

                        <a-row justify="end">
                          <a-button
                            html-type="submit"
                            type="primary"
                            size="large"
                            style="margin: 4px; text-align: right"
                          >
                            <span
                              class="material-symbols-outlined"
                              style="font-size: 16px; margin-right: 4px"
                              >Edit</span
                            >
                            บันทึกข้อมูล
                          </a-button>
                        </a-row>
                      </a-form>
                    </a-drawer>

                    <!-- Create toll way Drawer -->
                    <a-drawer
                      v-model:visible="show_create_toll_way"
                      placement="right"
                      title="เพิ่มรายการค่าทางด่วน"
                    >
                      <a-form
                        :model="create_toll_way"
                        layout="vertical"
                        @finish="submit_create_toll_way"
                      >
                        <a-form-item
                          label="ผู้ชำระ"
                          name="driver_paid"
                          :rules="[{ required: true, message: 'กรุณากรอกผู้ชำระ' }]"
                        >
                          <a-radio-group v-model:value="create_toll_way.driver_paid">
                            <a-radio-button value="1">คนขับ</a-radio-button>
                            <a-radio-button value="0">บริษัท</a-radio-button>
                          </a-radio-group>
                        </a-form-item>

                        <a-form-item
                          label="สถานะงาน"
                          name="booking_status"
                          :rules="[{ required: true, message: 'เลือกสถานะงาน' }]"
                        >
                          <a-select
                            v-model:value="create_toll_way.booking_status"
                            placeholder="เลือกสถานะงาน"
                          >
                            <a-select-option :value="8">ก่อนเริ่มงาน</a-select-option>
                            <a-select-option :value="7">ระหว่างงาน</a-select-option>
                            <a-select-option :value="11">หลังจบงาน</a-select-option>
                          </a-select>
                        </a-form-item>

                        <a-form-item
                          label="จำนวนเงิน"
                          name="amount"
                          :rules="[{ required: true, message: 'กรุณากรอกจำนวนเงิน' }]"
                        >
                          <a-input-number
                            v-model:value="create_toll_way.amount"
                            :min="0"
                            :max="1000000"
                          />
                        </a-form-item>

                        <a-form-item
                          label="วันเวลา"
                          name="datetime"
                          :rules="[{ required: true, message: 'กรุณากรอกวันเวลา' }]"
                        >
                          <a-date-picker
                            v-model:value="create_toll_way.datetime"
                            :show-time="{ format: 'HH:mm' }"
                            format="DD/MM/YYYY HH:mm"
                            placeholder="เลือกเวลา"
                            :minuteStep="1"
                          />
                        </a-form-item>

                        <a-form-item label="ใบรูปหลักฐานการโอน" name="photo_1">
                          <div class="upload-section">
                            <a-upload
                              v-model:file-list="tollWay.photo_1_show"
                              list-type="picture-card"
                              class="avatar-uploader"
                              accept="image/*"
                              :show-upload-list="false"
                              action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                              @change="upload_files($event, 'photo_1_show' , 'toll_ways', create_toll_way.index)"
                              method="GET"
                            >
                              <img
                                v-if="create_toll_way.photo_1"
                                :src="create_toll_way.photo_1"
                                :style="`width: 100%;object-fit: cover`"
                                alt="avatar"
                              />
                              <div v-else>
                                <p class="ant-upload-drag-icon">
                                  <span
                                    class="material-symbols-outlined"
                                    style="font-size: 5rem"
                                    >upload_file</span
                                  >
                                </p>
                                คลิกเพื่ออัปโหลดรูปภาพ
                              </div>
                            </a-upload>
                          </div>
                        </a-form-item>

                        <a-row justify="end">
                          <a-button
                            html-type="submit"
                            type="primary"
                            size="large"
                            style="margin: 4px; text-align: right"
                          >
                            <span
                              class="material-symbols-outlined"
                              style="font-size: 16px; margin-right: 4px"
                              >Edit</span
                            >
                            บันทึกข้อมูล
                          </a-button>
                        </a-row>
                      </a-form>
                    </a-drawer>

                    <a-modal
                      v-model:visible="total_cars_expense_modal"
                      width="80%"
                      :footer="null"
                      @cancel="refreshPage"
                    >
                      <template #title>
                        <div style="margin-bottom: 5px;">ต้นทุนค่าใช้จ่าย ใบจองจัดรถ ID : {{ create_booking.booking_no}}</div>
                        <div>Booking Car : {{selected_booking[0].booking_car_no}}</div>
                      </template>
                      <p style="color: black; margin-left: 10px;">ทะเบียนรถ : {{ selected_booking[0].car.plate_no }} </p>
                      <div v-if="total_car_expenses">
                      <a-collapse
                        v-model:activeKey="total_car_expenses_collapse"
                        expand-icon-position="right"
                        style="background-color: white; font-size: 18px; font-weight: 500"
                      >
                        <a-collapse-panel key="1">
                          <template #header>
                            <div>
                              1. ค่าทางด่วน - 
                              {{ total_car_expenses.toll_ways?.filter((i) => i.granted)?.length }} /
                              {{ total_car_expenses.toll_ways?.length }}
                              <!-- <a-tag v-if="showWarningTollWays" bordered="false" color="error">!</a-tag> -->
                            </div>
                          </template>
                          <template #extra>
                            {{ totaTollWays }}บาท
                          </template>
                          
                          <a-button
                            @click="init_toll_way()"
                            type="primary"
                            style="margin-bottom: 10px"
                            >เพิ่มค่าทางด่วน</a-button
                          >
                          <a-table
                            :dataSource="filteredTollWays"
                            :pagination="false"
                            :columns="[
                              {
                                title: 'ทะเบียนรถ',
                                dataIndex: 'plate_no',
                                key: 'plate_no',
                              },
                              {
                                title: 'ผู้ชำระ',
                                dataIndex: 'driver_paid',
                                key: 'driver_paid',
                                align: 'center',
                                width: 100,
                              },
                              {
                                title: 'จำนวนเงิน',
                                dataIndex: 'amount',
                                key: 'amount',
                                align: 'center',
                                width: 150,
                              },
                              {
                                title: 'วัน-เวลา',
                                dataIndex: 'created_at',
                                key: 'created_at',
                                align: 'center',
                                width: 180,
                              },
                              {
                                title: 'หมายเหตุกรณีไม่อนุมัติ',
                                dataIndex: 'approve_remark',
                                key: 'approve_remark',
                              },
                              {
                                title: 'สถานะ',
                                dataIndex: 'granted',
                                key: 'granted',
                                align: 'center',
                                width: 150,
                              },
                              {
                                title: 'Action',
                                dataIndex: 'id',
                                key: 'id',
                                align: 'center',
                                width: 100,
                              },
                            ]"
                          >
                            <template #bodyCell="{ column, record }">
                              <template v-if="column.key === 'plate_no'">
                                {{ this.selected_booking[0].car.plate_no }}
                              </template>
                              <template v-if="column.key === 'driver_paid'">
                                {{ record.driver_paid ? "คนขับรถ" : "บริษัท" }}
                              </template>
                              <template v-if="column.key === 'created_at'">
                                {{ render_date(record.created_at) }}
                              </template>
                              <template v-if="column.key === 'granted'">
                                <a-select
                                  v-model:value="record.granted"
                                  @change="
                                    update_granted(
                                      record,
                                      'toll_ways',
                                      $event,
                                      total_car_expenses.toll_ways
                                    )
                                  "
                                  style="width: 120px"
                                  placeholder="เลือกสถานะ"
                                >
                                  <a-select-option :value="null">รออนุมัต</a-select-option>
                                  <a-select-option
                                    v-for="{ id, name } in expense_logs"
                                    :key="id"
                                    :value="id"
                                  >
                                    {{ name }}
                                  </a-select-option>
                                </a-select>
                              </template>
                              <template v-if="column.key === 'id' && record[column.key]">
                                <div>
                                  <a-tooltip>
                                    <template #title>แก้ไข</template>
                                    <a @click="edit_expense_log(record, 'toll_ways')"
                                      ><span style="font-size: 14px" class="material-symbols-outlined"
                                        >drive_file_rename_outline</span
                                      ></a
                                    >
                                  </a-tooltip>
                                  <a-divider type="vertical"></a-divider>
                                  <a-tooltip>
                                    <template #title>ยกเลิก</template>
                                    <a @click="remove_expense_log(record.id, 'toll_ways')"
                                      ><span style="font-size: 18px" class="material-symbols-outlined"
                                        >delete</span
                                      ></a
                                    >
                                  </a-tooltip>
                                </div>
                              </template>
                            </template>
                          </a-table>
                        </a-collapse-panel>

                        <a-collapse-panel key="2">
                            <template #header>
                              <div>
                                2. ค่าน้ำมัน - 
                                {{ total_car_expenses.gas_refills?.filter((i) => i.granted)?.length }} /
                                {{ total_car_expenses.gas_refills?.length }}
                                <a-tag v-if="showWarningGasRefills" bordered="false" color="error">!</a-tag>
                              </div>
                            </template>
                            <template #extra>
                              {{ totalGasRefills }}บาท
                            </template>

                            <a-button
                              @click="init_gas_refill()"
                              type="primary"
                              style="margin-bottom: 10px"
                              >เพิ่มค่าน้ำมัน</a-button
                            >
                            <a-table
                              :dataSource="filteredGasRefills"
                              :pagination="false"
                              :columns="[
                                {
                                  title: 'ทะเบียนรถ',
                                  dataIndex: 'plate_no',
                                  key: 'plate_no',
                                },
                                {
                                  title: 'การชำระเงิน',
                                  dataIndex: 'pay_method_id',
                                  key: 'pay_method_id',
                                  align: 'center',
                                  width: 120,
                                },
                                {
                                  title: 'จำนวนเงิน',
                                  dataIndex: 'amount',
                                  key: 'amount',
                                  align: 'center',
                                  width: 150,
                                },
                                {
                                  title: 'วัน-เวลา',
                                  dataIndex: 'created_at',
                                  key: 'created_at',
                                  align: 'center',
                                  width: 180,
                                },
                                {
                                  title: 'หมายเหตุกรณีไม่อนุมัติ',
                                  dataIndex: 'approve_remark',
                                  key: 'approve_remark',
                                },
                                {
                                  title: 'สถานะ',
                                  dataIndex: 'granted',
                                  key: 'granted',
                                  align: 'center',
                                  width: 150,
                                },
                                {
                                  title: 'Action',
                                  dataIndex: 'id',
                                  key: 'id',
                                  align: 'center',
                                  width: 100,
                                },
                              ]"
                            >
                              <template #bodyCell="{ column, record }">
                                <template v-if="column.key === 'pay_method_id'">
                                  {{ pay_method_list.find((e) => record.pay_method_id == e.id)?.name }}
                                </template>
                                <template v-if="column.key === 'created_at'">
                                  {{ render_date(record.created_at) }}
                                </template>
                                <template v-if="column.key === 'granted'">
                                  <a-select
                                    v-model:value="record.granted"
                                    @change="
                                      update_granted(
                                        record,
                                        'gas_refills',
                                        $event,
                                        total_car_expenses.gas_refills
                                      )
                                    "
                                    style="width: 120px"
                                    placeholder="เลือกสถานะ"
                                  >
                                    <a-select-option :value="null">รออนุมัต</a-select-option>
                                    <a-select-option
                                      v-for="{ id, name } in expense_logs"
                                      :key="id"
                                      :value="id"
                                    >
                                      {{ name }}
                                    </a-select-option>
                                  </a-select>
                                </template>
                                <template v-if="column.key === 'id' && record[column.key]">
                                  <div>
                                    <a-tooltip>
                                      <template #title>แก้ไข</template>
                                      <a @click="edit_expense_log(record, 'gas_refills')"
                                        ><span style="font-size: 14px" class="material-symbols-outlined"
                                          >drive_file_rename_outline</span
                                        ></a
                                      >
                                    </a-tooltip>
                                    <a-divider type="vertical"></a-divider>
                                    <a-tooltip>
                                      <template #title>ยกเลิก</template>
                                      <a @click="remove_expense_log(record.id, 'gas_refills')"
                                        ><span style="font-size: 18px" class="material-symbols-outlined"
                                          >delete</span
                                        ></a
                                      >
                                    </a-tooltip>
                                  </div>
                                </template>
                              </template>
                            </a-table>
                          </a-collapse-panel>

                          <a-collapse-panel key="3">
                            <template #header>
                              <div>
                                3. ค่าใช้จ่ายอื่นๆ - 
                                {{ total_car_expenses.expenses?.filter((i) => i.granted)?.length }} /
                                {{ total_car_expenses.expenses?.length }}
                                <a-tag v-if="showWarningExpenses" bordered="false" color="error">!</a-tag>
                              </div>
                            </template>
                            <template #extra>
                              {{ totalExpense }}บาท
                            </template>
                            <a-button
                              @click="handleAddBookingExpense()"
                              type="primary"
                              style="margin-bottom: 10px"
                              >เพิ่มค่าใช้จ่ายเพิ่มเติม</a-button
                            >
                            <a-table
                              :dataSource="filteredExpense"
                              :pagination="false"
                              :columns="[
                                {
                                  title: 'ทะเบียนรถ',
                                  dataIndex: 'plate_no',
                                  key: 'plate_no',
                                },
                                {
                                  title: 'ผู้ชำระ',
                                  dataIndex: 'driver_paid',
                                  key: 'driver_paid',
                                  align: 'center',
                                  width: 100,
                                },
                                {
                                  title: 'จำนวนเงิน',
                                  dataIndex: 'amount',
                                  key: 'amount',
                                  align: 'center',
                                  width: 150,
                                },
                                {
                                  title: 'รายการ',
                                  dataIndex: 'expense_id',
                                  key: 'expense_id',
                                  align: 'center',
                                },
                                {
                                  title: 'หมายเหตุกรณีไม่อนุมัติ',
                                  dataIndex: 'approve_remark',
                                  key: 'approve_remark',
                                },
                                {
                                  title: 'สถานะ',
                                  dataIndex: 'granted',
                                  key: 'granted',
                                  align: 'center',
                                  width: 150,
                                },
                                {
                                  title: 'Action',
                                  dataIndex: 'id',
                                  key: 'id',
                                  align: 'center',
                                  width: 100,
                                },
                              ]"
                            >
                              <template #bodyCell="{ column, record }">
                                <template v-if="column.key === 'driver_paid'">
                                  {{ record.driver_paid ? "คนขับรถ" : "บริษัท" }}
                                </template>
                                <template v-else-if="column.key === 'expense_id'">
                                  {{
                                    expense_list.find(
                                      (expense) => expense.id === record.expense_id
                                    )?.name
                                  }}
                                </template>
                                <template v-if="column.key === 'granted'">
                                  <a-select
                                    v-model:value="record.granted"
                                    @change="
                                      update_granted(
                                        record,
                                        'expenses',
                                        $event,
                                        total_car_expenses.expenses
                                      )
                                    "
                                    style="width: 120px"
                                    placeholder="เลือกสถานะ"
                                  >
                                    <a-select-option :value="null">รออนุมัต</a-select-option>
                                    <a-select-option
                                      v-for="{ id, name } in expense_logs"
                                      :key="id"
                                      :value="id"
                                    >
                                      {{ name }}
                                    </a-select-option>
                                  </a-select>
                                </template>
                                <template v-if="column.key === 'id' && record[column.key]">
                                  <div>
                                    <a-tooltip>
                                      <template #title>แก้ไข</template>
                                      <a @click="edit_expense_log(record, 'expenses')"
                                        ><span style="font-size: 14px" class="material-symbols-outlined"
                                          >drive_file_rename_outline</span
                                        ></a
                                      >
                                    </a-tooltip>
                                    <a-divider type="vertical"></a-divider>
                                    <a-tooltip>
                                      <template #title>ยกเลิก</template>
                                      <a @click="remove_expense_log(record.id, 'expenses')"
                                        ><span style="font-size: 18px" class="material-symbols-outlined"
                                          >delete</span
                                        ></a
                                      >
                                    </a-tooltip>
                                  </div>
                                </template>
                              </template>
                            </a-table>
                          </a-collapse-panel>
                          
                          <a-collapse-panel key="4" :header="`4. ค่าเช่ารถ`">
                            <template #extra>
                              {{ totalRentalCost }} บาท</template>
                              <span v-for="{ car, driver_cost, rental_price } in filteredBookingCars" :key="car.plate_no">
                                ทะเบียนรถ: {{ car?.plate_no }} :
                                {{ (Number(rental_price)).toLocaleString() }} บาท<br />
                              </span>
                          </a-collapse-panel>

                          <a-collapse-panel key="5" :header="`5. ค่าเบี้ยเลี้ยง`">
                            <template #extra>
                              {{ totalDriverCost }} บาท</template>
                              <span v-for="{ car, driver_cost, rental_price } in filteredBookingCars" :key="car.plate_no">
                                ทะเบียนรถ: {{ car?.plate_no }} :
                                {{ (Number(driver_cost)).toLocaleString() }} บาท<br />
                              </span>
                          </a-collapse-panel>
                      </a-collapse>
                      </div>
                    </a-modal> 

                    <!-- Gas Refill Drawer -->
                    <a-drawer
                      v-model:visible="add_gas_refill_drawer"
                      placement="right"
                      title="บันทึกค่าน้ำมัน"
                      @close="create_gas_refill = {}"
                    >
                      <a-form
                        name="create_gas_refill"
                        autocomplete="off"
                        layout="vertical"
                        :model="create_gas_refill"
                        @finish="submit_create_gas_refill"
                      >
                        <a-switch
                          v-model:checked="create_gas_refill.use_gas_station"
                          checkedChildren="ปั้มที่ร่วมกับบริษัท"
                          unCheckedChildren="ปั้มนอก"
                        />
                        <a-form-item
                          v-if="create_gas_refill.use_gas_station"
                          label="เลือกปั้มน้ำมัน"
                          name="gas_station_id"
                          :rules="[{ required: true, message: 'เลือกปั้มน้ำมัน' }]"
                        >
                          <a-select
                            v-model:value="create_gas_refill.gas_station_id"
                            placeholder="เลือกปั้มน้ำมัน"
                          >
                            <a-select-option
                              v-for="{ id, name } in gas_station_list"
                              :key="id"
                              :value="id"
                            >
                              {{ name }}
                            </a-select-option>
                          </a-select>
                        </a-form-item>
                        <a-form-item
                          v-else
                          label="ระบุชื่อปั้ม"
                          name="station_name"
                          :rules="[{ required: true, message: 'เลือกปั้มน้ำมัน' }]"
                        >
                          <a-textarea v-model:value="create_gas_refill.station_name" />
                        </a-form-item>
                        <a-form-item
                          label="ชนิดน้ำมัน"
                          name="gas_type"
                          :rules="[
                            { required: true, message: 'เลือกชนิดน้ำมัน' },
                          ]"
                        >
                          <a-select
                            v-model:value="create_gas_refill.gas_type"
                            placeholder="เลือกชนิดน้ำมัน"
                          >
                            <a-select-option :value="'Diesel'"
                              >Diesel</a-select-option
                            >
                            <a-select-option :value="'Diesel B7'"
                              >Diesel B7</a-select-option
                            >
                            <a-select-option :value="'Vepower Diesel'"
                              >Vepower Diesel</a-select-option
                            >
                            <a-select-option :value="'EV'"
                              >EV</a-select-option
                            >
                          </a-select>
                        </a-form-item>
                        <a-row>
                          <a-col :span="12">
                            <a-form-item
                              label="จำนวนลิตร"
                              name="refill_amount"
                              :rules="[
                                {
                                  required: true,
                                  message: 'กรุณากรอกจำนวนลิตร',
                                },
                              ]"
                            >
                              <a-input-number
                                v-model:value="create_gas_refill.refill_amount"
                                :min="0"
                                :max="1000"
                              />
                            </a-form-item>
                          </a-col>
                          <a-col :span="12">
                            <a-form-item
                              label="จำนวนเงิน"
                              name="amount"
                              :rules="[{ required: true, message: 'กรุณาจำนวนเงิน' }]"
                            >
                              <a-input-number
                                v-model:value="create_gas_refill.amount"
                                :min="0"
                                :max="100000"
                              />
                            </a-form-item>
                          </a-col>
                        </a-row>

                        <a-form-item
                          label="ประเภทการจ่ายเงิน"
                          name="pay_method_id"
                          :rules="[
                            {
                              required: true,
                              message: 'เลือกประเภทการจ่ายเงิน',
                            },
                          ]"
                        >
                          <a-select
                            v-model:value="create_gas_refill.pay_method_id"
                            placeholder="เลือกประเภทการจ่ายเงิน"
                          >
                            <a-select-option
                              v-for="{ id, name } in pay_method_list"
                              :key="id"
                              :value="id"
                            >
                              {{ name }}
                            </a-select-option>
                          </a-select>
                        </a-form-item>

                        <a-form-item
                          label="วันเวลา"
                          name="datetime"
                          :rules="[{ required: true, message: 'กรุณากรอกวันเวลา' }]"
                        >
                          <a-date-picker
                            v-model:value="create_gas_refill.datetime"
                            :show-time="{ format: 'HH:mm' }"
                            format="DD/MM/YYYY HH:mm"
                            placeholder="เลือกเวลา"
                            :minuteStep="1"
                          />
                        </a-form-item>
                        <a-form-item name="bill_photo" label="รูปหลักฐานการโอน">
                          <a-upload
                            v-model:file-list="gas.bill_photo_show"
                            list-type="picture-card"
                            class="avatar-uploader"
                            accept="image/*"
                            :show-upload-list="false"
                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                            @change="upload_files($event, 'bill_photo_show','gas_refills',gas.orderPos,gas.indexGas)"
                            method="GET"
                            @remove="remove_uploaded_file"
                          >
                            <div v-if="bill_photo_show_source != ''" class="upload-container-gas">
                              <img
                                :src="bill_photo_show_source"
                                class="uploaded-image"
                                alt="uploaded image"
                              />
                            </div>
                            <div v-else style="display: flex; flex-direction: column; align-items: center;">
                              <p class="ant-upload-drag-icon">
                                <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                              </p>
                              คลิกเพื่ออัพโหลดรูปภาพ
                            </div>
                          </a-upload>
                        </a-form-item>

                        <a-row justify="end">
                          <a-button
                            html-type="submit"
                            type="primary"
                            size="large"
                            style="margin: 4px; text-align: right"
                          >
                            <span
                              class="material-symbols-outlined"
                              style="font-size: 16px; margin-right: 4px"
                              >Edit</span
                            >
                            บันทึกข้อมูล
                          </a-button>
                        </a-row>
                      </a-form>
                    </a-drawer>

                <!-- Assign Car Drawer -->
                <a-drawer
                  v-model:visible="assign_car_drawer"
                  @close="handleClose"
                  lass="custom-class"
                  style="color: red"
                  title="ข้อมูลรถ"
                  placement="right"
                  width="650"
                >
                  <a-form
                    name="assign_car"
                    autocomplete="off"
                    layout="vertical"
                    :model="assign_car_form"
                    @finish="submit_assign_car"
                  >
                  <a-divider orientation="left" orientation-margin="0px">
                    <a-space>ข้อมูลการจองรถ</a-space>
                  </a-divider>
                  
                  <a-col>
                      <a-form-item
                      label="ประเภทการจอง"
                      name="booking_type_id"
                      :rules="[{ required: true, message: 'โปรดเลือกประเภทการจอง' }]"
                      >
                          <a-select
                            v-model:value="assign_car_form.booking_type_id"
                            :style="{ marginBottom: '8px' }"
                          >
                            <a-select-option
                              v-for="{id, name} in booking_types"
                              :key="id"
                              :value="id"
                            >
                              {{ name }}
                            </a-select-option>
                          </a-select>
                        </a-form-item>
                      </a-col>

                      <a-row :gutter="16">
                        <a-col  v-if="!booking_sjinda" :span="8">
                          <a-form-item label="จำนวนคน" name="person_qty">
                            <a-input-number v-model:value="assign_car_form.person_qty" />
                          </a-form-item>
                        </a-col>
                        <a-col v-if="!booking_sjinda" :span="8">
                          <a-form-item label="ไฟท์รับ" name="flight_pick">
                            <a-date-picker 
                              v-model:value="assign_car_form.flight_pick" 
                              placeholder="ระบุไฟท์รับ" 
                              format="YYYY-MM-DD HH:mm"
                              :show-time="{ format: 'HH:mm' }">
                            </a-date-picker>
                          </a-form-item>
                        </a-col>
                        <a-col v-if="!booking_sjinda" :span="8">
                          <a-form-item label="ไฟท์ส่ง" name="flight_drop">
                            <a-date-picker 
                              v-model:value="assign_car_form.flight_drop" 
                              placeholder="ระบุไฟท์ส่ง" 
                              format="YYYY-MM-DD HH:mm"
                              :show-time="{ format: 'HH:mm' }">
                            </a-date-picker>
                          </a-form-item>
                        </a-col>
                      </a-row>

                      <a-form-item
                        label="วันเวลา รับ - ส่ง"
                        name="datetime"
                        :rules="[{ required: true, message: 'โปรดระบุวันเวลา รับ - ส่ง' }]"
                      >
                        <a-range-picker
                          v-model:value="assign_car_form.datetime"
                          :show-time="{ format: 'HH:mm' }"
                          format="DD/MM/YYYY HH:mm"
                          :placeholder="['เวลาเริ่ม', 'เวลาสิ้นสุด']"
                          :minuteStep="1"
                          @change="getStatusCar"
                        />
                      </a-form-item>

                      <a-row :gutter="16">
                        <a-col :span="8">
                          <a-form-item label="จำนวนวัน" name="total_days">
                            <a-input
                              disabled
                              v-model:value="assign_car_form.total_days"
                              placeholder="จำนวนวัน"
                            />
                          </a-form-item>
                        </a-col>

                        <a-col :span="8">
                          <a-form-item label="จำนวนวันที่ค้างคืน" name="full_day_amount">
                            <a-input-number
                              v-model:value="assign_car_form.full_day_amount"
                              placeholder="จำนวนวันที่ค้างคืน"
                            />
                          </a-form-item>
                        </a-col>

                        <a-col :span="8">
                          <a-form-item label="จุดประสงค์การเดินทาง" name="trip">
                            <a-select v-model:value="assign_car_form.trip_id">
                              <a-select-option
                                v-for="{ id, name } in company_trip_list"
                                :key="id"
                                >{{ name }}</a-select-option
                              >
                            </a-select>
                          </a-form-item>
                        </a-col>
                    </a-row>

                    <a-col>
                      <a-form-item label="ชนิดรถ" name="car_type_id">
                        <a-select
                          v-model:value="preselect_car_type_id"
                          disabled
                          @change="cal_total_seats"
                          placeholder="เลือกชนิดรถ"
                        >
                          <a-select-option
                            v-for="car_type in car_type_list"
                            :key="car_type.id"
                            :value="car_type.id"
                          >
                            {{ car_type.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>

                    <a-form-item label="ทะเบียนรถ" name="plate_no">
                      <a-select
                        v-model:value="assign_car_form.car_id"
                        @change="changeCar"
                        :options="
                        preselect_car_type_id?
                        filtered_cars
                        .map(({ id, plate_no, car_no, time_end }) => ({
                            value: id,
                            label: `${plate_no} (${car_no}) ${time_end ? '(แจ้งซ่อม)' : ''}`,
                            disabled: time_end,
                          }))
                        :
                        filtered_cars
                        .map(({ id, plate_no, car_no, time_end }) => ({
                            value: id,
                            label: `${plate_no} (${car_no}) ${time_end ? '(แจ้งซ่อม)' : ''}`,
                            disabled: time_end,
                          }))
                        "
                      >
                      <template #option="option">
                          <div>
                            <div :style="{ color: option.disabled ? 'red' : 'black' }">{{ option.label }}</div>
                          </div>
                        </template>
                      </a-select>
                    </a-form-item>

                    <a-form-item name="driver_id">
                    <div style="display: flex; align-items: center">
                          <span style="margin-right: 8px; margin-bottom: 8px;">คนขับรถประจำ</span>
                          <a-checkbox
                            style="margin-bottom: 8px;"
                            v-model:checked="assign_car_form.main_driver_enabled"
                          ></a-checkbox>
                          <span style="margin-right: 8px; margin-left: 15px; margin-bottom: 8px;">คนขับรถสำรอง</span>
                          <a-checkbox
                            style="margin-bottom: 8px;"
                            v-model:checked="assign_car_form.second_driver_enabled"
                          ></a-checkbox>
                        </div>

                        <a-input
                          v-if="!assign_car_form.main_driver_enabled && !assign_car_form.second_driver_enabled"
                          :value="getDriverFullName"
                          :disabled="!assign_car_form.default_driver_enabled"
                          placeholder="เลือกคนขับรถ"
                        />

                      <a-select
                        v-if="assign_car_form.main_driver_enabled"
                        v-model:value="assign_car_form.driver_id"
                        show-search
                        :disabled="!assign_car_form.main_driver_enabled"
                        placeholder="เลือกคนขับรถประจำ"
                        :options="
                        filteredDrivers.map(({ id, firstname, lastname, car_no }) => ({
                            value: id,
                            label: `${firstname} ${lastname}`,
                            description: car_no !== null ? `เบอร์รถ: ${car_no}` : 'ไม่มีเบอร์รถ',
                          }))
                        "
                        :filter-option="filterOption"
                      >
                      <template #option="option">
                          <div>
                            <div>{{ option.label }}</div>
                            <div style="color: #888;">{{ option.description }}</div>
                          </div>
                        </template>
                      </a-select>

                      <a-select
                        v-if="assign_car_form.second_driver_enabled"
                        v-model:value="assign_car_form.driver_id"
                        show-search
                        :disabled="!assign_car_form.second_driver_enabled"
                        placeholder="เลือกคนขับรถสำรอง"
                        :options="
                          cars_with_backup_driver.filter(fd => !filteredDrivers.some(bd => bd.id === fd.id))
                          .map(({ id, firstname, lastname, car_no }) => ({
                            value: id,
                            label: `${firstname} ${lastname}`,
                            description: car_no !== null ? `เบอร์รถ: ${car_no}` : 'ไม่มีเบอร์รถ',
                          }))
                        "
                        :filter-option="filterOption"
                        @change="check_driver_is_booked"
                      >
                      <template #option="option">
                          <div>
                            <div>{{ option.label }}</div>
                            <div style="color: #888;">{{ option.description }}</div>
                          </div>
                        </template>
                      </a-select>
                    </a-form-item>

                    <a-form-item name="temporary_driver">
                        <div style="display: flex; align-items: center">
                          <span style="margin-right: 8px; margin-bottom: 8px;">คนขับรถชั่วคราว</span>
                          <a-checkbox
                            style="margin-bottom: 8px;"
                            v-model:checked="assign_car_form.temporary_driver_enabled"
                          ></a-checkbox>
                        </div>
                        <a-input 
                          v-model:value="assign_car_form.temporary_driver"
                          :disabled="!assign_car_form.temporary_driver_enabled"
                          placeholder="ชื่อคนขับรถชั่วคราว"
                          @change="reMoveDriverId"
                        />
                    </a-form-item>

                    <a-form-item
                      label="ราคาเช่า"
                      name="rental_price"
                    >
                      <a-input-number
                        v-model:value="assign_car_form.rental_price"
                        :precision="2"
                      />
                    </a-form-item>

                    <a-divider orientation="left">เบี้ยเลี้ยง</a-divider>
                    <a-form-item
                      label="ค่าเบี้ยเลี้ยง"
                      name="driver_cost"
                    >
                      <a-input-number
                        v-model:value="assign_car_form.driver_cost"
                        :disabled="assign_car_form.driver_cost_as_percent"
                        :precision="2"
                      />
                    </a-form-item>

                    <br />
                    <a-space>
                      <a-checkbox
                        v-model:checked="assign_car_form.driver_cost_as_percent"
                      >
                        คิดราคาเป็น(%)
                      </a-checkbox>
                      <a-input-number
                        style="width: 2rem"
                        v-model:value="assign_car_form.driver_cost_percent"
                        :disabled="!assign_car_form.driver_cost_as_percent"
                      />
                      <a-button type="primary" @click="calculate_driver_cost()"
                        >คำนวณ</a-button
                      >
                    </a-space>
                    <a-divider />

                    <a-row>
                      <a-col :span="12">
                        <a-form-item label="เงินสำรองจ่าย[เริ่มงาน]">
                          <a-input-number
                            v-model:value="assign_car_form.deposit"
                            :disabled="assign_car_form.deposit_photo != null"
                          /> </a-form-item
                        ><br />
                        <img
                          v-if="assign_car_form.deposit_photo"
                          :src="assign_car_form.deposit_photo"
                          :style="`width: 100%;object-fit: cover;height: 10rem`"
                          alt="avatar"
                        />
                      </a-col>
                      <a-col :span="12">
                        <a-form-item label="เงินสำรองจ่าย[รับคืน]">
                          <a-input-number
                            v-model:value="assign_car_form.refund"
                            disabled
                          /> </a-form-item
                        ><br />
                        <img
                          v-if="assign_car_form.refund_photo"
                          :src="assign_car_form.refund_photo"
                          :style="`width: 100%;object-fit: cover;height: 10rem`"
                          alt="avatar"
                        />
                      </a-col>
                    </a-row>

                    <a-row>
                      <a-col>
                        <a-form
                          :model="add_stop"
                          layout="vertical"
                          @finish="add_stop_to_table"
                        >
                          <a-divider></a-divider>
                          <a-row justify="space-between">
                            <a-divider style="margin-bottom: 2px;" orientation="left" orientation-margin="0px">
                              <a-space>จุดแวะ(ระบบนำทาง)</a-space>
                            </a-divider>
                            <a-col>
                              <p style="margin-bottom: 1px;">ใช้ระบุจุดรับ-จุดส่ง หรือจุดที่ต้องการให้แวะระหว่างทาง เพื่อให้พนักงานขับรถทราบรายละเอียดสถานที่ที่ต้องไป</p>
                              <p>โดยข้อมูลนี้จะไปแสดงอยู่ที่ส่วนของใบงาน(Job Order) และแสดงที่ Vecabo Driver Application</p>
                            </a-col>
                            <a-col :span="7">
                              <a-form-item
                                label="ประเภทจุดแวะ"
                                name="stop_type_id"
                                :rules="[{ required: true, message: 'โปรดเลือกจุดแวะ' }]"
                              >
                                <a-select v-model:value="add_stop.stop_type_id">
                                  <a-select-option
                                    v-for="{ name, id } in stop_type_list"
                                    :value="id"
                                    >{{ name }}</a-select-option
                                  >
                                </a-select>
                              </a-form-item>
                            </a-col>
                            <a-col :span="16">
                              <a-form-item
                                label="เวลาไปถึง"
                                name="expected_timestamp"
                                :rules="[
                                  { required: true, message: 'ระบุเวลาถึง' },
                                  { validator: validateTimeRange, message: 'เวลาที่เลือกต้องอยู่ในช่วงเวลาที่กำหนด' }
                                ]"
                              >
                                <a-date-picker
                                  v-model:value="add_stop.expected_timestamp"
                                  :show-time="{ format: 'HH:mm' }"
                                  format="DD/MM/YYYY HH:mm"
                                  placeholder="เลือกเวลา"
                                  :minuteStep="1"
                                />
                              </a-form-item>
                            </a-col>
                          </a-row>
                          <a-row justify="space-between">
                            <a-col :span="16">
                              <a-form-item 
                              label="ชื่อสถานที่" 
                              name="name" 
                              :rules="[{ required: true, message: 'โปรดเลือกชื่อสถานที่' }]">
                                <vue-google-autocomplete
                                  id="map"
                                  classname="form-control"
                                  placeholder="ค้นหาสถานที่"
                                  v-on:placechanged="select_stop_name($event)"
                                  types="establishment"
                                  country="th"
                                >
                                </vue-google-autocomplete>
                              </a-form-item>
                            </a-col>
                            <a-col
                              :span="7"
                              style="display: flex; justify-content: space-evenlya"
                            >
                              <a-space>
                                <a-button
                                  v-if="on_edit_stop == null"
                                  html-type="submit"
                                  type="primary"
                                  >เพิ่ม</a-button
                                >
                                <a-button
                                  v-if="on_edit_stop != null"
                                  type="primary"
                                  @click="save_edit_stop(on_edit_stop)"
                                  >บันทึก</a-button
                                >
                                <a-button @click="cancel_edit_stop()">ยกเลิก</a-button>
                              </a-space>
                            </a-col>
                          </a-row>
                          <p>
                            วัน เวลา สถานที่ ส่วนนี้จะแสดงให้คนขับเห็นผ่านทาง Vecabo
                            Driver Application หรือในใบงาน (Job Order)
                          </p>
                          <a-table
                            :columns="[
                              {
                                title: 'ประเภทจุดแวะ',
                                dataIndex: 'stop_type_id',
                                key: 'stop_type_id',
                                align: 'center',
                                width: 120,
                              },
                              {
                                title: 'ชื่อสถานที่',
                                dataIndex: 'name',
                                key: 'name',
                                align: 'center',
                              },
                              {
                                title: 'เวลา',
                                dataIndex: 'expected_timestamp',
                                key: 'expected_timestamp',
                                align: 'center',
                                width: 150,
                                sorter: {
                                  compare: (a, b) => new Date(b.expected_timestamp) - new Date(a.expected_timestamp),
                                  multiple: 2,
                                },
                              },
                              {
                                title: 'Action',
                                key: 'action',
                                align: 'center',
                                width: 100,
                              },
                            ]"
                            :data-source="
                              assign_car_form.stops?.filter(({ booking_car_id }) =>
                                booking_car_id
                                  ? booking_car_id == assign_car_form.id
                                  : true
                              )
                            "
                            :pagination="false"
                          >
                            <template #bodyCell="{ column, record, index }">
                              <template v-if="column.key === 'stop_type_id'">
                                <a-tag
                                  :color="tag_option.stop_type[record.stop_type_id].color"
                                >
                                  {{ tag_option.stop_type[record.stop_type_id].txt }}
                                </a-tag>
                              </template>
                              <template v-else-if="column.key === 'expected_timestamp'">
                                {{ render_date(record.expected_timestamp) }}
                              </template>
                              <template v-else-if="column.key === 'action'">
                                <a @click="edit_stop_from_table(index)">
                                  <span
                                    style="font-size: 18px"
                                    class="material-symbols-outlined"
                                    >edit</span
                                  >
                                </a>
                                <a @click="remove_stop_from_table(index, record.id)">
                                  <span
                                    style="font-size: 18px"
                                    class="material-symbols-outlined"
                                    >delete</span
                                  >
                                </a>
                              </template>
                            </template>
                          </a-table>
                        </a-form>
                      </a-col>
                    </a-row>
                    <!-- Pickup Item -->
                    <a-divider orientation="left" orientation-margin="0px">
                      <a-space
                        >รายการรับของ
                        <a-button
                          v-if="
                            !(
                              assign_car_form.pickup_items &&
                              assign_car_form.pickup_items.length
                            )
                          "
                          @click="show_create_pickup_item = true"
                          type="primary"
                          >เพิ่มรายการรับของ</a-button
                        ></a-space
                      >
                    </a-divider>

                    <a-row>
                      <a-col>
                        <a-table
                          :columns="[
                            {
                              title: 'รายการ',
                              dataIndex: 'items',
                              key: 'items',
                              align: 'center',
                            },
                            {
                              title: 'สถานะ',
                              dataIndex: 'pickup_status',
                              key: 'pickup_status',
                              align: 'center',
                            },
                            {
                              title: 'เวลารับ',
                              dataIndex: 'pickup_at',
                              key: 'pickup_at',
                              align: 'center',
                            },
                            {
                              title: 'รูปภาพ',
                              dataIndex: 'pickup_photo',
                              key: 'pickup_photo',
                              align: 'center',
                            },
                            {
                              title: 'หมายเหตุ',
                              dataIndex: 'pickup_remark',
                              key: 'pickup_remark',
                              align: 'center',
                            },
                            {
                              title: 'Action',
                              key: 'action',
                              align: 'center',
                              width: 100,
                            },
                          ]"
                          :data-source="assign_car_form.pickup_items"
                          :pagination="false"
                        >
                          <template #bodyCell="{ column, record, index }">
                            <template v-if="column.key === 'action'">
                              <a
                                @click="
                                  edit_list_in_table(
                                    record,
                                    'create_pickup_item',
                                    'show_create_pickup_item',
                                    index
                                  )
                                "
                              >
                                <span
                                  style="font-size: 18px"
                                  class="material-symbols-outlined"
                                  >edit</span
                                >
                              </a>
                              <a-divider type="vertical" />
                              <a
                                @click="
                                  remove_list_from_table(
                                    index,
                                    'pickup_items',
                                    'PickupItem'
                                  )
                                "
                              >
                                <span
                                  style="font-size: 18px"
                                  class="material-symbols-outlined"
                                  >delete</span
                                >
                              </a>
                            </template>
                            <template v-if="column.key === 'pickup_status' && record.id">
                              <a-tag
                                :color="
                                  tag_option.pickup_status[record[column.key]].color
                                "
                              >
                                {{ tag_option.pickup_status[record[column.key]].txt }}
                              </a-tag>
                            </template>
                            <template
                              v-if="column.key === 'pickup_at' && record.pickup_at"
                            >
                              {{ render_date(record[column.key]) }}
                            </template>
                            <template
                              v-if="column.key === 'pickup_photo' && record.pickup_photo"
                            >
                              <a-image :src="record[column.key]" width="100px" />
                            </template>
                          </template>
                        </a-table>
                      </a-col>
                    </a-row>

                    <!-- Pickup Item Drawer -->
                    <a-drawer
                      v-model:visible="show_create_pickup_item"
                      placement="right"
                      title="เพิ่มรายการรับของ"
                      @close="create_pickup_item = {}"
                    >
                      <a-form
                        name="create_pickup_item"
                        autocomplete="off"
                        layout="vertical"
                        :model="create_pickup_item"
                        @finish="submit_create_pickup_item()"
                      >
                        <a-form-item
                          label="รายการ"
                          name="items"
                          :rules="[{ required: true, message: 'รายการ' }]"
                        >
                          <a-textarea v-model:value="create_pickup_item.items" />
                        </a-form-item>

                        <a-row justify="end">
                          <a-button
                            html-type="submit"
                            type="primary"
                            size="large"
                            style="margin: 4px; text-align: right"
                          >
                            <span
                              class="material-symbols-outlined"
                              style="font-size: 16px; margin-right: 4px"
                              >Edit</span
                            >
                            บันทึก
                          </a-button>
                        </a-row>
                      </a-form>
                    </a-drawer>

                    <!-- Guide Section -->
                    <div v-if="(this.create_booking.company_id != 39 && this.businessTypeId != 2) && this.create_booking.company_id != null">
                      <a-divider orientation="left" orientation-margin="0px">
                        <a-space>ไกด์/คนนำเที่ยว
                          <a-button @click="add_guide_drawer = true" type="primary">เพิ่มไกด์</a-button>
                        </a-space>
                      </a-divider>
                    </div>

                    <div v-if="this.create_booking.company_id != 39 && this.businessTypeId != 2">
                      <a-row>
                        <a-col>
                          <a-table
                            :columns="[
                              {
                                title: 'ชื่อ-นามสกุล',
                                dataIndex: 'name',
                                key: 'name',
                                align: 'center',
                              },
                              {
                                title: 'เบอร์โทร',
                                dataIndex: 'phone_no',
                                key: 'phone_no',
                                align: 'center',
                              },
                              {
                                title: 'ราคาบริการ',
                                dataIndex: 'price',
                                key: 'price',
                                align: 'center',
                              },
                              {
                                title: 'หมายเหตุ',
                                dataIndex: 'remark',
                                key: 'remark',
                                align: 'center',
                              },
                              {
                                title: 'Action',
                                key: 'action',
                                align: 'center',
                                width: 100,
                              },
                            ]"
                            :data-source="assign_car_form.guides"
                            :pagination="false"
                          >
                            <template #bodyCell="{ column, record, index }">
                              <template v-if="column.key === 'action'">
                                <a
                                  @click="
                                    edit_list_in_table(
                                      record,
                                      'create_guide',
                                      'add_guide_drawer',
                                      index
                                    )
                                  "
                                >
                                  <span
                                    style="font-size: 18px"
                                    class="material-symbols-outlined"
                                    >edit</span
                                  >
                                </a>
                                <a-divider type="vertical" />
                                <a
                                  @click="
                                    remove_list_from_table(
                                      index,
                                      'guides',
                                      'BookingCarGuide'
                                    )
                                  "
                                >
                                  <span
                                    style="font-size: 18px"
                                    class="material-symbols-outlined"
                                    >delete</span
                                  >
                                </a>
                              </template>
                              <template v-if="column.key === 'name'">
                                {{
                                  guide_list.find((guide) => guide.id === record.guide_id)
                                    ?.firstname
                                }}
                                {{
                                  guide_list.find((guide) => guide.id === record.guide_id)
                                    ?.lastname
                                }}
                              </template>
                              <template v-if="column.key === 'phone_no'">
                                {{
                                  guide_list.find((guide) => guide.id === record.guide_id)
                                    ?.phone_no
                                }}
                              </template>
                              <template v-if="column.key === 'price'">
                                {{ Number(record.price).toLocaleString() }}
                              </template>
                            </template>
                          </a-table>
                        </a-col>
                      </a-row>
                      </div>

                    <!-- Guide Drawer -->
                    <a-drawer
                      v-model:visible="add_guide_drawer"
                      placement="right"
                      title="เพิ่มไกด์"
                      @close="create_guide = {}"
                    >
                      <a-form
                        name="create_guide"
                        autocomplete="off"
                        layout="vertical"
                        :model="create_guide"
                        @finish="
                          submit_drawer(
                            'create_guide',
                            'guides',
                            'add_guide_drawer',
                            'BookingCarGuide'
                          )
                        "
                      >
                        <a-form-item
                          label="เลือกไกด์"
                          name="guide_id"
                          :rules="[{ required: true, message: 'กรุณาเลือกไกด์' }]"
                        >
                          <a-select
                            v-model:value="create_guide.guide_id"
                            placeholder="เลือกไกด์"
                          >
                            <a-select-option
                              v-for="{ id, firstname, lastname } in guide_list"
                              :key="id"
                              :value="id"
                            >
                              {{ firstname }} {{ lastname }}
                            </a-select-option>
                          </a-select>
                        </a-form-item>

                        <a-form-item
                          label="ราคาบริการ"
                          name="price"
                          :rules="[{ required: true, message: 'ระบุราคา' }]"
                        >
                          <a-input-number
                            v-model:value="create_guide.price"
                            :min="0"
                            :max="999999"
                          />
                        </a-form-item>

                        <a-form-item label="หมายเหตุ" name="remark">
                          <a-textarea v-model:value="create_guide.remark" />
                        </a-form-item>

                        <a-row justify="end">
                          <a-button
                            html-type="submit"
                            type="primary"
                            size="large"
                            style="margin: 4px; text-align: right"
                          >
                            <span
                              class="material-symbols-outlined"
                              style="font-size: 16px; margin-right: 4px"
                              >Edit</span
                            >
                            บันทึกข้อมูล
                          </a-button>
                        </a-row>
                      </a-form>
                    </a-drawer>

                    <!-- Gas Refill Section -->
                    <a-divider orientation="left" orientation-margin="0px">
                      <a-space>
                        รายการใช้น้ำมัน
                        <a-button @click="init_gas_refill()" type="primary"
                          >เพิ่มรายการใช้น้ำมัน</a-button
                        >
                      </a-space>
                    </a-divider>

                    <a-row>
                      <a-col>
                        <a-table
                          :columns="[
                            {
                              title: 'วันเวลา',
                              dataIndex: 'created_at',
                              key: 'created_at',
                              align: 'center',
                            },
                            {
                              title: 'จำนวนลิตร(กำกับ)',
                              dataIndex: 'refill_amount',
                              key: 'refill_amount',
                              align: 'center',
                            },
                            {
                              title: 'ยอดจ่าย',
                              dataIndex: 'total_amount',
                              key: 'total_amount',
                              align: 'center',
                            },
                            {
                              title: 'จำนวนลิตรที่เติม',
                              dataIndex: 'total_refill_amount',
                              key: 'total_refill_amount',
                              align: 'center',
                            },
                            {
                              title: 'ประเภทการจ่ายเงิน',
                              dataIndex: 'pay_method_id',
                              key: 'pay_method_id',
                              align: 'center',
                            },
                            {
                              title: 'รูปหลักฐานการโอน',
                              dataIndex: 'bill_photo',
                              key: 'bill_photo',
                              align: 'center',
                            },
                            {
                              title: 'หมายเหตุ',
                              dataIndex: 'remark',
                              key: 'remark',
                              align: 'center',
                            },
                            {
                              title: 'Action',
                              key: 'action',
                              align: 'center',
                              width: 100,
                              fixed: 'right',
                            },
                          ]"
                          :data-source="assign_car_form.gas_refills"
                          :scroll="{ x: 1200 , y: 500 }"
                          :pagination="false"
                        >
                          <template #bodyCell="{ record, index, column }">
                            <template v-if="column.key === 'created_at'">
                              {{ render_time(record.created_at) }}
                            </template>
                            <template v-if="column.key === 'bill_photo'" >
                              <div v-if="record.bill_photo != null&&record.bill_photo !=''">
                                <img
                                  :src="record.bill_photo"
                                  class="uploaded-image-table"
                                  alt="uploaded image"
                                  @click="showPreview($event , record.bill_photo)"
                                />
                                <div class="icon-container-gas">
                                  <span
                                    class="material-symbols-outlined preview-icon"
                                    style="font-size: 15px;"
                                    @click="handleIconClick('preview', record.bill_photo, $event)"
                                  >
                                    visibility
                                  </span>
                                  <div @click="getBase64Image($event ,record.bill_photo)">
                                      <span class="material-symbols-outlined download-icon" 
                                      style="color: black;font-size: 15px;">
                                        download
                                      </span>
                                  </div>
                                </div>
                              </div>  
                            </template>
                            <template v-if="column.key === 'action'">
                              <a
                                @click="
                                  edit_list_in_table(
                                    record,
                                    'create_gas_refill',
                                    'add_gas_refill_drawer',
                                    index
                                  ),
                                    init_gas_refill_edit()
                                "
                              >
                                <span
                                  style="font-size: 18px"
                                  class="material-symbols-outlined"
                                  >edit</span
                                >
                              </a>
                              <a-divider type="vertical" />
                              <a
                                @click="
                                  remove_list_from_table(
                                    index,
                                    'gas_refills',
                                    'GasRefill'
                                  )
                                "
                              >
                                <span
                                  style="font-size: 18px"
                                  class="material-symbols-outlined"
                                  >delete</span
                                >
                              </a>
                            </template>
                            <template v-else-if="column.key === 'pay_method_id'">
                              {{
                                pay_method_list.find(
                                  (pay_method) => pay_method.id === record.pay_method_id
                                )?.name
                              }}
                            </template>
                            <template v-else-if="column.key === 'refill_amount'">
                              {{ Number(record.refill_amount).toLocaleString() }}
                            </template>
                            <template v-else-if="column.key === 'amount'">
                              {{ Number(record.amount).toLocaleString() }}
                            </template>
                          </template>
                        </a-table>
                      </a-col>
                    </a-row>

                    <a-divider orientation="left" orientation-margin="0px">
                      <a-space>
                        รายการค่าทางด่วน
                        <a-button @click="init_toll_way()" type="primary"
                          >เพิ่มรายการค่าทางด่วน</a-button
                        >
                      </a-space>
                    </a-divider>

                    <a-row>
                      <a-col>
                        <a-table
                          :columns="[
                            {
                              title: 'วันเวลา',
                              dataIndex: 'created_at',
                              key: 'created_at',
                              align: 'center',
                              width: 150,
                            },
                            {
                              title: 'ผู้ชำระ',
                              dataIndex: 'driver_paid',
                              key: 'driver_paid',
                              align: 'center',
                            },
                            {
                              title: 'ยอดจ่าย',
                              dataIndex: 'paid_amount',
                              key: 'amount',
                              align: 'center',
                            },
                            {
                              title: 'สถานะงาน',
                              key: 'booking_status',
                              dataIndex: 'booking_status',
                              align: 'center',
                            },
                            {
                              title: 'รูปใบเสร็จ/สลิป',
                              key: 'pay_slip_photo',
                              dataIndex: 'pay_slip_photo',
                              width: 120,
                              align: 'center',
                            },
                            {
                              title: 'หมายเหตุ',
                              key: 'remark',
                              dataIndex: 'remark',
                              align: 'center',
                            },
                            {
                              title: 'Action',
                              key: 'action',
                              width: 120,
                              align: 'center',
                              fixed: 'right',
                            },
                          ]"
                          :data-source="assign_car_form.toll_ways"
                          :pagination="false"
                          :scroll="{ x: 1200 , y: 500 }"
                        >
                          <template #bodyCell="{ column, record, index }">
                            <template v-if="column.key === 'created_at'">
                              {{ render_time(record.created_at) }}
                            </template>
                            <template v-if="column.key === 'action'">
                              <a
                                @click="
                                  edit_list_in_table(
                                    record,
                                    'create_toll_way',
                                    'show_create_toll_way',
                                    index
                                  )
                                "
                              >
                                <span
                                  style="font-size: 18px"
                                  class="material-symbols-outlined"
                                  >edit</span
                                >
                              </a>
                              <a-divider type="vertical" />
                              <a
                                @click="
                                  remove_list_from_table(index, 'toll_ways', 'TollWay')
                                "
                              >
                                <span
                                  style="font-size: 18px"
                                  class="material-symbols-outlined"
                                  >delete</span
                                >
                              </a>
                            </template>
                            <template v-else-if="column.key === 'datetime'">
                              {{ render_time(record.datetime) }}
                            </template>
                            <template v-else-if="column.key === 'paid_amount'">
                              {{ Number(record.paid_amount).toLocaleString() }}
                            </template>
                            <template v-else-if="column.key == 'booking_status'">
                              <a-tag
                                v-if="
                                  this.assign_car_form.logs?.filter(
                                    ({ model_name }) => model_name == 'BookingCarStatus'
                                  ).length
                                "
                                color="green"
                              >
                                {{
                                  render_date(
                                    this.assign_car_form.logs?.filter(
                                      ({ model_name }) => model_name == "BookingCarStatus"
                                    )[0].created_at,
                                    "DD/MM/YYYY HH:mm:ss"
                                  )
                                }}
                              </a-tag>
                              <a-tag color="red" v-else> ยังไม่ได้รับงาน </a-tag>
                            </template>
                            <template v-else-if="column.key === 'driver_paid'">
                              {{ record.driver_paid == "1" ? "คนขับ" : "บริษัท" }}
                            </template>
                            <template v-if="column.key === 'pay_slip_photo'">
                              <div v-if="record.pay_slip_photo" class="upload-container">
                                <img
                                  :src="record.pay_slip_photo"
                                  style="max-width: 50px; max-height: 50px;"
                                  alt="uploaded image"
                                  @click="showPreview($event , record.pay_slip_photo)"
                                />
                                <div class="icon-container">
                                  <span
                                    class="material-symbols-outlined preview-icon"
                                    @click="handleIconClick('preview', record.pay_slip_photo, $event)"
                                  >
                                    visibility
                                  </span>
                                  <div @click="getBase64Image($event , { url: record.pay_slip_photo})">
                                  <span class="material-symbols-outlined download-icon" style="color: black;">
                                    download
                                  </span>
                                  </div>
                                </div>
                              </div>
                              
                            </template>
                          </template>
                        </a-table>
                      </a-col>
                    </a-row>

                    <a-divider orientation="left" orientation-margin="0px">
                      <a-space
                        >ค่าใช้จ่ายเพิ่มเติม
                        <a-button
                          @click="handleAddBookingExpense()"
                          type="primary"
                          >เพิ่มค่าใช้จ่ายเพิ่มเติม</a-button
                        ></a-space
                      >
                    </a-divider>

                    <a-row>
                      <a-col>
                        <a-table
                          :columns="[
                            {
                              title: 'ผู้ชำระ',
                              key: 'driver_paid',
                              dataIndex: 'driver_paid',
                              align: 'center',
                            },
                            {
                              title: 'ประเภทการจ่ายเงิน',
                              dataIndex: 'pay_method_id',
                              key: 'pay_method_id',
                              align: 'center',
                            },
                            {
                              title: 'รายการ',
                              dataIndex: 'expense_id',
                              key: 'expense_id',
                              align: 'center',
                            },
                            // {
                            //   title: 'จำนวนเงิน',
                            //   dataIndex: 'amount',
                            //   key: 'amount',
                            //   align: 'center',
                            // },
                            {
                             title: 'ยอดจ่าย',
                             dataIndex: 'total_payment',
                             key: 'total_payment',
                             align: 'center',
                            },
                            {
                              title: 'รูปใบเสร็จ/สลิป',
                              dataIndex: 'photo_slip',
                              key: 'photo_slip',
                              align: 'center',
                            },
                            {
                              title: 'หมายเหตุ',
                              key: 'remark',
                              dataIndex: 'remark',
                              align: 'center',
                            },
                            {
                              title: 'Action',
                              key: 'action',
                              width: 100,
                              align: 'center',
                              fixed: 'right',
                            },
                          ]"
                          :data-source="assign_car_form.expenses"
                          :pagination="false"
                          :scroll="{ x: 1200 , y: 500 }"
                        >
                          <template #bodyCell="{ index, column, record}">
                            <template v-if="column.key === 'action'">
                              <a
                                @click="
                                  edit_list_in_table(
                                    record,
                                    'create_booking_car_expense',
                                    'show_create_booking_car_expense',
                                    index
                                  )
                                "
                              >
                                <span
                                  style="font-size: 18px"
                                  class="material-symbols-outlined"
                                  >edit</span
                                >
                              </a>
                              <a-divider type="vertical" />
                              <a
                                @click="remove_list_from_table(
                                    index,
                                    'expenses',
                                    'BookingCarExpense'
                                  )
                                "
                              >
                                <span
                                  style="font-size: 18px"
                                  class="material-symbols-outlined"
                                  >delete</span
                                >
                              </a>
                            </template>
                            <template v-else-if="column.key === 'pay_method_id'">
                              {{
                                pay_method_list.find(
                                  (pay_method) => pay_method.id === record.pay_method_id
                                )?.name
                              }}
                            </template>
                            <template v-else-if="column.key === 'expense_id'">
                              {{
                                expense_list.find(
                                  (expense) => expense.id === record.expense_id
                                )?.name
                              }}
                            </template>
                            <template v-else-if="column.key === 'amount'">
                              {{ record.amount ? Number(record.amount).toLocaleString() : "" }}
                            </template>
                            <template v-else-if="column.key === 'driver_paid'">
                              {{ record.driver_paid == 1 ? "คนขับ" : "บริษัท" }}
                            </template>
                            <template v-else-if="column.key === 'photo_slip'">
                              <div v-if="record.photo_slip" class="upload-container">
                                <img
                                  :src="record.photo_slip"
                                  style="max-width: 50px; max-height: 50px;"
                                  alt="uploaded image"
                                  @click="showPreview($event , record.photo_slip)"
                                />
                                <div class="icon-container">
                                  <span
                                    class="material-symbols-outlined preview-icon"
                                    @click="handleIconClick('preview', record.photo_slip, $event)"
                                  >
                                    visibility
                                  </span>
                                  <div @click="getBase64Image($event , { url: record.photo_slip})">
                                  <span class="material-symbols-outlined download-icon" style="color: black;">
                                    download
                                  </span>
                                  </div>
                                </div>
                              </div>
                            </template>
                            <template v-else-if="column.key === 'total_payment'">
                              {{ record.total_payment ? Number(Number(record.total_payment).toFixed(0)).toLocaleString() : "" }}
                            </template>
                          </template>
                        </a-table>
                      </a-col>
                    </a-row>

                    <a-divider orientation="left" orientation-margin="0px">
                      <a-space>เลขไมล์</a-space>
                    </a-divider>
                      <div style="display: flex; align-items: center; justify-content: center; gap:50px">
                        <a-form-item>
                          <p style = "font-weight: 500;">เลขไมล์ก่อนเริ่มงาน</p>
                          <span style="font-size:medium">{{ get_start_distance }} km</span>
                        </a-form-item>
                          <div style="border-right: 1px solid #D3D3D3; padding: 10px; height: 70px;"/>
                        <a-form-item>
                          <p style = "font-weight: 500">เลขไมล์หลังเริ่มงาน</p>
                          <span style="font-size:medium">{{ get_end_distance }} km</span>
                        </a-form-item>
                          <div style="border-right: 1.5px solid #D3D3D3; padding: 10px; height: 70px;"/>
                        <a-form-item>
                          <p style = "font-weight: 500">รวมระยะทาง</p>
                          <span style="font-size:medium; font-weight: bold;">{{ Number(get_end_distance - get_start_distance).toLocaleString() }} km</span>                        </a-form-item>
                      </div>
                  
                    <a-row>
                      <a-col>
                        <a-form-item label="หมายเหตุ" name="remark">
                          <a-textarea v-model:value="assign_car_form.remark" />
                        </a-form-item>
                      </a-col>
                    </a-row>

                    <!-- <a-row justify="end">
                                            <a-col :span="12">
                                                <a-form-item label="สถานะยานพาหนะ" name="car_status_id">
                                                    <a-select v-model:value="assign_car_form.car_status_id" @change="update_car_status_id($event, assign_car_form.car_id)">
                                                        <a-select-option v-for="{ name, id } in car_status_list" :value="id">{{ name }}</a-select-option>
                                                    </a-select>
                                                </a-form-item>
                                            </a-col>
                                        </a-row> -->
                    <a-row justify="end">
                      <a-button
                        html-type="submit"
                        :disabled="loading"
                        type="primary"
                        size="large"
                        style="margin: 4px; text-align: right"
                      >
                        <span
                          class="material-symbols-outlined"
                          style="font-size: 16px; margin-right: 4px"
                          >Edit</span
                        >
                        บันทึกข้อมูล
                      </a-button>
                    </a-row>
                  </a-form>
                </a-drawer>

                <!-- BookingCarType Drawer -->
                <a-drawer
                  v-model:visible="show_add_booking_car_types"
                  class="custom-class"
                  style="color: red"
                  title="รถที่รับงานได้"
                  placement="right"
                >
                  <a-form
                    name="booking_car_types"
                    autocomplete="off"
                    layout="vertical"
                    :model="booking_car_types"
                    @finish="submit_add_booking_car_types"
                  >
                  <span>ทั้งหมด: {{ statusCarTypes.total }} | จองแล้ว: {{ statusCarTypes.has_event }} | ว่าง: {{ statusCarTypes.no_event }}</span>
                  <a-row>
                    <a-col>
                      <a-form-item
                      label="ประเภทการจอง"
                      name="booking_type_id"
                      :rules="[{ required: true, message: 'โปรดเลือกประเภทการจอง' }]"
                      >
                          <a-select
                            v-model:value="booking_car_types.booking_type_id"
                            :style="{ marginBottom: '8px' }"
                          >
                            <a-select-option
                              v-for="{id, name} in booking_types"
                              :key="id"
                              :value="id"
                            >
                              {{ name }}
                            </a-select-option>
                          </a-select>
                        </a-form-item>
                      </a-col>

                      <a-col v-if="!booking_sjinda">
                        <a-form-item label="จำนวนคน" name="person_qty">
                          <a-input-number v-model:value="booking_car_types.person_qty" />
                        </a-form-item>
                      </a-col>

                      <a-col v-if="!booking_sjinda" :span="12">
                        <a-form-item label="ไฟท์รับ" name="flight_pick">
                          <a-date-picker 
                            v-model:value="booking_car_types.flight_pick" 
                            placeholder="ระบุไฟท์รับ" 
                            format="YYYY-MM-DD HH:mm"
                            :show-time="{ format: 'HH:mm' }">
                          </a-date-picker>
                        </a-form-item>
                      </a-col>
                      <a-col v-if="!booking_sjinda" :span="12">
                        <a-form-item label="ไฟท์ส่ง" name="flight_drop">
                          <a-date-picker 
                            v-model:value="booking_car_types.flight_drop" 
                            placeholder="ระบุไฟท์ส่ง" 
                            format="YYYY-MM-DD HH:mm" 
                            :show-time="{ format: 'HH:mm' }">
                          </a-date-picker>
                        </a-form-item>
                      </a-col>
                    <a-col>
                      <a-form-item
                      label="ชนิดรถ"
                      name="car_type_id"
                      :rules="[{ required: true, message: 'โปรดเลือกประเภทรถ' }]"
                      >
                          <a-select
                            v-model:value="booking_car_types.car_type_id"
                            @change="cal_total_seats"
                            placeholder="เลือกชนิดรถ"
                          >
                            <a-select-option
                              v-for="car_type in car_type_list"
                              :key="car_type.id"
                              :value="car_type.id"
                            >
                              {{ car_type.name }}
                            </a-select-option>
                          </a-select>
                        </a-form-item>
                      </a-col>
                      <a-col>
                        <a-form-item
                          label="ประเภทที่นั่งรถ"
                          name="car_group_id"
                          :rules="[
                            {
                              required: true,
                              message: 'โปรดเลือกประเภทที่นั่งรถ',
                            },
                          ]"
                        >
                          <a-select
                            v-model:value="booking_car_types.car_group_id"
                            @change="cal_total_seats"
                            placeholder="เลือกประเภทที่นั่งรถ"
                          >
                            <a-select-option
                              v-for="car_group in car_group_list.filter(
                                ({ car_type_id }) =>
                                  car_type_id == booking_car_types.car_type_id
                              )"
                              :key="car_group.id"
                              :value="car_group.id"
                            >
                              {{ car_group.name }}
                            </a-select-option>
                          </a-select>
                        </a-form-item>
                      </a-col>
                      <a-col>
                        <a-form-item
                          label="ระบุราคารถ"
                          name="price"
                          :rules="[{ required: true, message: 'โปรดระบุราคารถ' }]"
                        >
                          <a-input-number
                            v-model:value="booking_car_types.price"
                            placeholder="ระบุราคารถ"
                          />
                        </a-form-item>
                      </a-col>
                      <a-col :span="12">
                        <a-form-item
                          label="ระบุจำนวนรถ"
                          name="quantity"
                          :rules="[{ required: true, message: 'โปรดระบุจำนวนรถ' }]"
                        >
                          <a-input-number
                            v-model:value="booking_car_types.quantity"
                            @change="cal_total_seats"
                            placeholder="ระบุจำนวนรถ"
                          />
                        </a-form-item>
                      </a-col>
                      <a-col :span="12">
                        <a-form-item label="จำนวนที่นั่ง" name="seats">
                          <a-input-number
                            :disabled="true"
                            v-model:value="get_car_seats"
                            placeholder="ระบุจำนวนรถ"
                          />
                        </a-form-item>
                      </a-col>
                      <a-col>
                        <a-form-item
                          label="วันเวลารับ - ส่ง"
                          name="datetime"
                          :rules="[
                            {
                              required: true,
                              message: 'โปรดระบุวันเวลารับ - ส่ง',
                            },
                          ]"
                        >
                          <a-range-picker
                            v-model:value="booking_car_types.datetime"
                            :show-time="{ format: 'HH:mm' }"
                            format="DD/MM/YYYY HH:mm"
                            :placeholder="['Start Time', 'End Time']"
                            :minuteStep="1"
                            @change="getStatusCarTypes"
                          />
                        </a-form-item>
                      </a-col>
                      <a-col>
                        <a-form-item label="จำนวนวัน" name="total_days">
                          <a-input
                            disabled
                            v-model:value="booking_car_types.total_days"
                            placeholder="จำนวนวัน"
                          />
                        </a-form-item>
                      </a-col>

                  <a-col>
                    <a-form-item label="จำนวนวันที่ค้างคืน" name="full_day_amount">
                      <a-input-number
                        v-model:value="booking_car_types.full_day_amount"
                        placeholder="จำนวนวันที่ค้างคืน"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col>
                    <a-form-item label="จุดประสงค์การเดินทาง" name="trip">
                      <a-select v-model:value="booking_car_types.trip_id">
                        <a-select-option
                          v-for="{ id, name } in company_trip_list"
                          :key="id"
                          >{{ name }}</a-select-option
                        >
                      </a-select>
                    </a-form-item>
                  </a-col>
                      <a-col>
                        <a-form-item label="ชื่อจุดรับ" name="start_point">
                          <vue-google-autocomplete
                            ref="start_point"
                            id="start_point"
                            classname="form-control"
                            placeholder="ค้นหาสถานที่"
                            v-model="booking_car_types.start_point"
                            v-on:placechanged="select_stop_name($event, 'start_point', true)"
                            types="establishment"
                            country="th"
                          >
                          </vue-google-autocomplete>
                        </a-form-item>
                        <a-form-item label="ชื่อจุดส่ง" name="end_point">
                          <vue-google-autocomplete
                            ref="end_point"
                            id="end_point"
                            classname="form-control"
                            placeholder="ค้นหาสถานที่"
                            v-model="booking_car_types.end_point"
                            v-on:placechanged="select_stop_name($event, 'end_point', true)"
                            types="establishment"
                            country="th"
                          >
                          </vue-google-autocomplete>
                        </a-form-item>
                      </a-col>
                      <a-col>
                        <a-form-item label="หมายเหตุ" name="destination">
                          <a-textarea
                            v-model:value="booking_car_types.remark"
                            placeholder="ระบุหมายเหตุ"
                          />
                        </a-form-item>
                      </a-col>

                      <a-col>
                        <a-form-item label="ค่าใช้จ่ายรถ" name="car_expense">
                          <a-table
                            :columns="[
                              {
                                title: 'ชื่อ',
                                dataIndex: 'name',
                                key: 'name',
                              },
                              {
                                title: 'ราคาต่อหน่วย',
                                dataIndex: 'price',
                                key: 'price',
                              },
                              {
                                title: 'จำนวน',
                                dataIndex: 'amount',
                                key: 'amount',
                              },
                              {
                                title: 'ตัวเลือก',
                                dataIndex: 'id',
                                align: 'center',
                                width: 100,
                                key: 'id',
                                fixed: 'right',
                              },
                            ]"
                            :data-source="booking_car_types.expenses"
                            bordered
                            size="small"
                            :pagination="false"
                          >
                            <template #bodyCell="{ column, record, index }">
                              <template v-if="column.key === 'id'">
                                <a @click="edit_expense_table(record,
                                    'edit_expense',
                                    'show_edit_expense',
                                    index)">
                                  <span style="font-size: 14px" class="material-symbols-outlined"
                                >edit</span
                                ></a
                              >
                                <a
                                  @click="
                                    remove_booking_car_types_expense(index, record.id)
                                  "
                                  ><span
                                    style="font-size: 18px"
                                    class="material-symbols-outlined"
                                    >delete</span
                                  ></a
                                >
                              </template>
                              <template v-if="column.key === 'name'">
                                {{
                                  record.name
                                    ? record.name
                                    : record.expense
                                    ? record.expense.name
                                    : ""
                                }}
                              </template>
                            </template>
                          </a-table>
                        </a-form-item>

                        <a-col>
                          <a-row justify="end">
                            <a-button
                              @click="show_add_expense = true"
                              type="primary"
                              size="small"
                            >
                              <span
                                class="material-symbols-outlined"
                                style="
                                  font-size: 14px;
                                  margin-right: 4px;
                                  margin-bottom: 4px;
                                "
                                >Add</span
                              >
                              เพิ่มค่าใช้จ่ายรถ
                            </a-button>
                          </a-row>
                        </a-col>

                        <a-drawer
                          v-model:visible="show_edit_expense"
                          title="แก้ไขค่าใช้จ่ายรถ"
                          width="320"
                          :closable="true"
                        >
                          <a-form
                            :model="edit_expense"
                            name="edit_expense"
                            autocomplete="off"
                            layout="vertical"
                            @finish="submit_edit_expense"
                          >
                            <a-row>
                              <a-col>
                                <a-form-item
                                  label="ค่าใช้จ่ายรถ"
                                  name="expense_id"
                                  :rules="[{ required: true, message: 'โปรดใส่ข้อมูล' }]"
                                >
                                  <a-select
                                    v-model:value="edit_expense.expense_id"
                                    placeholder="เลือกรายการค่าใช้จ่าย"
                                  >
                                    <a-select-option
                                      v-for="{ id, name } in expense_list"
                                      :key="id"
                                      :value="id"
                                    >
                                      {{ name }}
                                    </a-select-option>
                                  </a-select>
                                </a-form-item>
                              </a-col>
                              <a-col>
                                <a-form-item
                                  label="จำนวน"
                                  name="amount"
                                  :rules="[{ required: true, message: 'โปรดใส่ข้อมูล' }]"
                                >
                                  <a-input
                                    v-model:value="edit_expense.amount"
                                    placeholder="ระบุจำนวน"
                                  />
                                </a-form-item>
                              </a-col>
                              <a-col>
                                <a-form-item
                                  label="ราคาต่อหน่วย"
                                  name="price"
                                  :rules="[{ required: true, message: 'โปรดใส่ข้อมูล' }]"
                                >
                                  <a-input
                                    v-model:value="edit_expense.price"
                                    placeholder="ระบุราคาต่อหน่วย"
                                  />
                                </a-form-item>
                              </a-col>
                              <a-col>
                                <a-row justify="end">
                                  <a-col>
                                    <a-button
                                      type="primary"
                                      html-type="submit"
                                      size="small"
                                    >
                                      บันทึก
                                    </a-button>
                                  </a-col>
                                </a-row>
                              </a-col>
                            </a-row>
                          </a-form>
                        </a-drawer>

                        <a-drawer
                          v-model:visible="show_add_expense"
                          title="เพิ่มค่าใช้จ่ายรถ"
                          width="320"
                          :closable="true"
                        >
                          <a-form
                            :model="add_expense"
                            name="add_expense"
                            autocomplete="off"
                            layout="vertical"
                            @finish="submit_add_expense"
                            @finish-failed="submit_add_expense"
                          >
                            <a-row>
                              <a-col>
                                <a-form-item
                                  label="ค่าใช้จ่ายรถ"
                                  name="expense_id"
                                  :rules="[{ required: true, message: 'โปรดใส่ข้อมูล' }]"
                                >
                                  <a-select
                                    v-model:value="add_expense.expense_id"
                                    placeholder="เลือกรายการค่าใช้จ่าย"
                                  >
                                    <a-select-option
                                      v-for="{ id, name } in expense_list"
                                      :key="id"
                                      :value="id"
                                    >
                                      {{ name }}
                                    </a-select-option>
                                  </a-select>
                                </a-form-item>
                              </a-col>
                              <a-col>
                                <a-form-item
                                  label="จำนวน"
                                  name="amount"
                                  :rules="[{ required: true, message: 'โปรดใส่ข้อมูล' }]"
                                >
                                  <a-input
                                    v-model:value="add_expense.amount"
                                    placeholder="ระบุจำนวน"
                                  />
                                </a-form-item>
                              </a-col>
                              <a-col>
                                <a-form-item
                                  label="ราคาต่อหน่วย"
                                  name="price"
                                  :rules="[{ required: true, message: 'โปรดใส่ข้อมูล' }]"
                                >
                                  <a-input
                                    v-model:value="add_expense.price"
                                    placeholder="ระบุราคาต่อหน่วย"
                                  />
                                </a-form-item>
                              </a-col>
                              <a-col>
                                <a-row justify="end">
                                  <a-col>
                                    <a-button
                                      type="primary"
                                      html-type="submit"
                                      size="small"
                                    >
                                      <span
                                        class="material-symbols-outlined"
                                        style="
                                          font-size: 14px;
                                          margin-right: 4px;
                                          margin-bottom: 4px;
                                        "
                                        >Add</span
                                      >
                                      เพิ่มค่าใช้จ่ายรถ
                                    </a-button>
                                  </a-col>
                                </a-row>
                              </a-col>
                            </a-row>
                          </a-form>
                        </a-drawer>
                      </a-col>
                    </a-row>
                    <a-col>
                      <a-row justify="end">
                        <a-button type="primary" html-type="submit" size="large">
                          <span
                            class="material-symbols-outlined"
                            style="font-size: 16px; margin-right: 4px"
                            >Add</span
                          >
                          บันทึก
                        </a-button>
                      </a-row>
                    </a-col>
                  </a-form>
                </a-drawer>
              </a-collapse-panel>

              <a-collapse-panel key="4" :header="`ค่าใช้จ่าย`">
                <a-divider orientation="left">ค่าใช้จ่ายเพิ่มเติม</a-divider>
                <a-drawer
                  v-model:visible="show_add_booking_extra"
                  class="custom-class"
                  style="color: red"
                  title="บันทึกรายการค่าใช้จ่ายเพิ่มเติม"
                  placement="right"
                >
                  <a-form
                    name="extra"
                    autocomplete="off"
                    layout="vertical"
                    :model="extra"
                    @finish="submit_extra"
                  >
                    <a-row>
                      <a-col>
                        <a-form-item
                          label="ค่าใช้จ่ายเพิ่มเติม"
                          name="extra_id"
                          :rules="[
                            {
                              required: true,
                              message: `โปรดเลือกรายการค่าใช้จ่าย`,
                            },
                          ]"
                        >
                          <a-select
                            v-model:value="extra.extra_id"
                            placeholder="เลือกรายการค่าใช้จ่าย"
                          >
                            <a-select-option
                              v-for="{ id, name } in extra_list"
                              :key="id"
                              :value="id"
                            >
                              {{ name }}
                            </a-select-option>
                          </a-select>
                        </a-form-item>
                      </a-col>
                      <a-col>
                        <a-form-item
                          label="ราคาต่อหน่วย"
                          name="price"
                          :rules="[{ required: true, message: `โปรดระบุราคาต่อหน่วย` }]"
                        >
                          <a-input-number
                            v-model:value="extra.price"
                            placeholder="ระบุราคาต่อหน่วย"
                          />
                        </a-form-item>
                      </a-col>
                      <a-col>
                        <a-form-item
                          label="จำนวน"
                          name="amount"
                          :rules="[{ required: true, message: `โปรดระบุจำนวน` }]"
                        >
                          <a-input-number
                            v-model:value="extra.amount"
                            placeholder="ระบุจำนวน"
                          />
                        </a-form-item>
                      </a-col>
                      <a-col>
                        <a-row justify="end">
                          <a-button type="primary" html-type="submit" size="large">
                            บันทึก
                          </a-button>
                        </a-row>
                      </a-col>
                    </a-row>
                  </a-form>
                </a-drawer>
                <a-row>
                  <a-col>
                    <a-table
                        :columns="createBookingExtrasColumns()"
                        :data-source="create_booking.extras"
                        bordered
                        size="small"
                        :scroll="{ x: 400, y: 200 }"
                        :pagination="false"
                      >
                      <template #bodyCell="{ column, record, index }">
                        <template v-if="column.key === 'action'">
                          <a @click="edit_booking_expense(index, record.id)"
                            ><span
                              style="font-size: 18px"
                              class="material-symbols-outlined"
                              >edit</span
                            ></a
                          >
                          <a-divider type="vertical" />
                          <a @click="remove_booking_expense(index, record.id)"
                            ><span
                              style="font-size: 18px"
                              class="material-symbols-outlined"
                              >delete</span
                            ></a
                          >
                        </template>
                        <template v-if="column.key === 'name'">
                          {{ record.id ? record.extra.name : record.name }}
                        </template>
                        <template v-if="column.key === 'amount' && record[column.key]">
                          {{ Number(record.amount).toLocaleString() }}
                        </template>
                        <template v-if="column.key === 'price' && record[column.key]">
                          {{ Number(record.price).toLocaleString() }}
                        </template>
                        <template v-if="column.key === 'total'">
                          {{ Number(record.price * record.amount).toLocaleString() }}
                        </template>
                      </template>
                    </a-table>
                  </a-col>
                </a-row>
                <a-row v-if="from_booking == false && on_booking == false">
                  <a-button
                    type="primary"
                    style="display: flex; margin: 1.25rem 1rem"
                    @click="add_booking_expense()"
                    size="large"
                  >
                    <template #icon>
                      <span class="material-symbols-outlined">Add</span>
                    </template>
                    เพิ่มค่าใช้จ่ายเพิ่ม
                  </a-button>
                </a-row>
                <a-divider orientation="left">เงื่อนไขการชำระเงิน</a-divider>
                <a-row>
                  <!-- <a-col v-if="form.pay_type == 3">
                    <a-form-item label="เปอร์เซ็นต์จ่าย">
                      <a-input
                        type="number"
                        v-model:value="price_percent"
                        :min="1"
                        :max="100"
                        @change="cal_price(form.pay_type)"
                        />
                      </a-form-item>
                    </a-col> -->
                    <a-col>
                      <a-form-item>
                        <a-col style="display: flex; align-items: center">
                          <label>จำนวนเงิน</label>
                          <a-button
                            type="primary"
                            style="margin: 1.25rem"
                            @click="cal_price(form.pay_type, create_booking.inc_vat, create_booking.withholding, form.discount)"
                            size="large"
                            >
                            คำนวน
                          </a-button>
                        </a-col>
                          <b style="flex: 1; display: flex; align-items: center; background-color: #f0f0f0">
                            {{ (form.total_price || 0).toLocaleString() }}
                          </b>
                      </a-form-item>
                    </a-col>
                    <a-col>
                      <a-form-item label="ประเภทการจ่ายเงิน" name="pay_type">
                      <!-- :rules="[{ required: true, validator: (rule, value) => validate_required(rule, form.pay_type, 'กรุณาเลือกประเภทการจ่ายเงิน'), trigger: 'submit', }]"> -->
                        <a-select v-model:value="form.pay_type" @change="cal_price(form.pay_type)" :disabled="from_booking || on_booking">
                        <!-- <a-select v-model:value="form.pay_type"> -->
                          <a-select-option :value="1">ราคาเต็ม</a-select-option>
                          <a-select-option :value="2">จำนวน</a-select-option>
                          <a-select-option :value="3">เปอร์เซ็น</a-select-option>
                        </a-select>
                      </a-form-item>
                      <!-- <a-form-item v-if="form.pay_type == 1" label="รายละเอียดชำระเงิน">
                        <a-input v-model:value="form.pay_extend"/>
                      </a-form-item> -->
                      <a-form-item v-if="form.pay_type == 2" label="จำนวนเงินที่ต้องการชำระเงิน" name="pay_amount"
                      :rules="[{ required: true, validator: (rule, value) => validate_required(rule, form.pay_amount, 'กรุณากรอกจำนวนเงินที่ต้องการชำระเงิน'), trigger: 'change', }]">
                        <a-input v-model:value="form.pay_amount" type='number' :disabled="from_booking || on_booking"/>
                      </a-form-item>
                      <a-form-item v-else-if="form.pay_type == 3" label="เปอร์เซ็นจ่ายเงิน" name="price_percent"
                      :rules="[{ required: true, validator: (rule, value) => validate_required(rule, form.price_percent, 'กรุณาเลือกเปอร์เซ็นการจ่ายเงิน'), trigger: 'change', },]">
                        <a-select v-model:value="form.price_percent" :disabled="from_booking || on_booking">
                          <a-select-option :value="0.1">10%</a-select-option>
                          <a-select-option :value="0.2">20%</a-select-option>
                          <a-select-option :value="0.3">30%</a-select-option>
                          <a-select-option :value="0.4">40%</a-select-option>
                          <a-select-option :value="0.5">50%</a-select-option>
                          <a-select-option :value="0.6">60%</a-select-option>
                          <a-select-option :value="0.7">70%</a-select-option>
                          <a-select-option :value="0.8">80%</a-select-option>
                          <a-select-option :value="0.9">90%</a-select-option>
                          <!-- <a-select-option :value="10">100%</a-select-option> -->
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col>
                      <a-form-item label="ส่วนลด">
                        <a-input v-model:value="form.discount" :disabled="from_booking || on_booking"/>
                      </a-form-item>
  
                      <a-form-item label="สาเหตุของส่วนลด">
                        <a-input v-model:value="form.reason_discount" :disabled="from_booking || on_booking"/>
                      </a-form-item>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-space>
                      <a-upload
                        v-model:file-list="create_booking.photo_expenses_1"
                        list-type="picture-card"
                        class="avatar-uploader"
                        action="https://mocki.io/v1/a6c8d0ec-4de1-4a61-870c-4e4751fb8fda"
                        @preview="handlePreview"
                        @change="upload_files($event, 'photo_expenses_1')"
                        method="GET"
                        @remove="remove_uploaded_file($event)"
                        :disabled="from_booking || on_booking"
                      >
                        <div
                          v-if="create_booking.photo_expenses_1?.length < 1"
                          style="height: 104px; width: 104px; padding: 2rem"
                        >
                          <span style="font-size: 20px" class="material-symbols-outlined"
                            >add</span
                          >
                          <div style="margin-top: 8px">รูป</div>
                        </div>
                      </a-upload>
                      <a-upload
                        v-model:file-list="create_booking.photo_expenses_2"
                        list-type="picture-card"
                        class="avatar-uploader"
                        action="https://mocki.io/v1/a6c8d0ec-4de1-4a61-870c-4e4751fb8fda"
                        @preview="handlePreview"
                        @change="upload_files($event, 'photo_expenses_2')"
                        method="GET"
                        @remove="remove_uploaded_file($event)"
                        :disabled="from_booking || on_booking"
                      >
                        <div
                          v-if="create_booking.photo_expenses_2?.length < 1"
                          style="height: 104px; width: 104px; padding: 2rem"
                        >
                          <span style="font-size: 20px" class="material-symbols-outlined"
                            >add</span
                          >
                          <div style="margin-top: 8px">รูป</div>
                        </div>
                      </a-upload>
                      <a-upload
                        v-model:file-list="create_booking.photo_expenses_3"
                        list-type="picture-card"
                        class="avatar-uploader"
                        action="https://mocki.io/v1/a6c8d0ec-4de1-4a61-870c-4e4751fb8fda"
                        @preview="handleIconClick"
                        @change="upload_files($event, 'photo_expenses_3')"
                        method="GET"
                        @remove="remove_uploaded_file($event)"
                        :disabled="from_booking || on_booking"
                      >
                        <div
                          v-if="create_booking.photo_expenses_3?.length < 1"
                          style="height: 104px; width: 104px; padding: 2rem"
                        >
                          <span style="font-size: 20px" class="material-symbols-outlined"
                            >add</span
                          >
                          <div style="margin-top: 8px">รูป</div>
                        </div>
                      </a-upload>
                    </a-space>
                  </a-row>
                  <a-row>
                    <a-divider orientation="left">ตั้งค่าภาษี</a-divider>
                    <a-col :span="24">
                      <a-space>
                        <a-form-item
                          name="tax"
                        >
                          <span>ภาษีมูลค่าเพิ่ม: </span>
                          <a-switch v-model:checked="create_booking.inc_vat" @change="create_booking.withholding = false" :disabled="from_booking || on_booking" />
                          <span style="margin-left: 8px">หัก ณ ที่จ่าย: </span>
                          <a-switch v-model:checked="create_booking.withholding" @change="create_booking.inc_vat = false" :disabled="from_booking || on_booking"/>
                          <span style="margin-left: 8px">บริษัทรถ: </span>
                          <a-select
                          style="width: 500px"
                          placeholder="บริษัทรถ"
                          v-model:value="create_booking.car_company_id"
                          @change="
                            create_booking.car_company = car_company_list.find(
                              ({ id }) => $event == id
                            )
                          "
                          :disabled="from_booking || on_booking"
                        >
                          <a-select-option
                            v-for="({ id, fullname, withholding }, i) in car_company_list"
                            :key="i"
                            :value="id"
                          >
                            {{ fullname }} {{ `(${withholding?.name})` }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      </a-space>
                    </a-col>
                  </a-row>
                </a-collapse-panel>
              <!-- 
              <a-collapse-panel v-if="!on_booking" key="6" header="ส่วนลด">
								<a-row>
									<a-col>
										<a-form-item label="ระบุส่วนลด" name="discount">
											<a-input-number v-model:value="create_booking.discount" />
										</a-form-item>
									</a-col>
								</a-row>
							</a-collapse-panel> -->

              <a-collapse-panel key="6" header="เอกสารที่เกี่ยวข้อง">
                <a-space>
                  <!-- <a-upload
                    v-model:file-list="create_booking.program"
                    list-type="picture-card"
                    class="avatar-uploader"
                    action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                    @preview="handlePreview"
                    @change="upload_files($event, 'create_photo')"
                    method="GET"
                    @remove="remove_uploaded_file($event)"
                  >
                    <div
                      v-if="create_booking.program?.length < 1"
                      style="height: 104px; width: 104px; padding: 2rem"
                    >
                      <span style="font-size: 20px" class="material-symbols-outlined"
                        >add</span
                      >
                      <div style="margin-top: 8px">โปรแกรม</div>
                    </div>
                  </a-upload>
                  <a-upload
                    v-model:file-list="create_booking.map"
                    list-type="picture-card"
                    class="avatar-uploader"
                    action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                    @preview="handlePreview"
                    @change="upload_files($event, 'create_photo')"
                    method="GET"
                    @remove="remove_uploaded_file($event)"
                  >
                    <div
                      v-if="create_booking.map?.length < 1"
                      style="height: 104px; width: 104px; padding: 2rem"
                    >
                      <span style="font-size: 20px" class="material-symbols-outlined"
                        >add</span
                      >
                      <div style="margin-top: 8px">แผนที่</div>
                    </div>
                  </a-upload> -->
                  <a-upload
                    v-model:file-list="create_booking.booking_files"
                    list-type="picture-card"
                    class="avatar-uploader"
                    action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                    @preview="handlePreview"
                    @change="upload_files($event, 'create_photo')"
                    method="GET"
                    @remove="remove_uploaded_file($event)"
                  >
                    <div
                      v-if="create_booking.booking_files?.length < 3"
                      style="height: 104px; width: 104px; padding: 1rem"
                    >
                      <span
                        style="font-size: 20px; margin-top: 8px"
                        class="material-symbols-outlined"
                        >add</span
                      >
                      <div>
                        รายละเอียดการใช้รถ ({{
                          create_booking.booking_files.length + 1
                        }}/3)
                      </div>
                    </div>
                  </a-upload>
                </a-space>
              </a-collapse-panel>

              <a-collapse-panel key="7" header="พนักงานบริการลูกค้า CS" ref="clientCS">
                <template #extra>
                  <p
                    v-if="[create_booking.sale_id].filter((e) => e == null).length"
                    style="margin: 0; color: grey"
                  >
                    {{
                      `(ต้องระบุอีก ${
                        [create_booking.sale_id].filter((e) => e == null).length
                      })`
                    }}
                  </p>
                </template>
                <a-form-item
                  name="sale_id"
                  label="พนักงานบริการลูกค้า CS"
                  :rules="[{ required: true, message: 'โปรดเลือกคนขาย' }]"
                >
                  <a-select v-model:value="create_booking.sale_id">
                    <a-select-option
                      v-for="{ id, firstname, lastname, nickname } in sales_list"
                      :key="id"
                      :value="id"
                    >
                      {{ `${firstname} ${lastname} (${nickname})` }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-collapse-panel>
              <a-collapse-panel key="8" header="จัดการหมายเหตุ"  >
                    <a-row>
                    <a-col :span="24" v-for="(item, index) in remark_types_all" :key="index">
                      <remark_group_new 
                        @remove_remark = "remove_remark"
                        @move_up = "move_up"
                        @move_down = "move_down"
                        :remark_type = "item"
                        :remark_detail = "filter_remark(item.id)"
                      />
                    </a-col>
                    <a-col>
                      <a-form-item label="เลือกหมายเหตุ" >
                          <a-select v-model:value="remark_selected" >
                              <a-select-option 
                                  v-for="(item2 , index) in remark_select" 
                                  :value="item2.remark" 
                                  :key="item2.id" 
                                  @click="add_remark(item2.id)"
                              >
                                  {{ item2.remark }}
                              </a-select-option>
                          </a-select>
                      </a-form-item>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="24">
                      <a-form-item label="ระบุหมายเหตุเพิ่มเติม" name="remark_more">
                      <a-textarea
                        :rows="4"
                        placeholder="ระบุหมายเหตุเพิ่มเติม"
                        v-model:value="create_booking.remark_more"
                      ></a-textarea>
                    </a-form-item>
                    </a-col>
                  </a-row>
              </a-collapse-panel>

              <a-collapse-panel v-if="on_booking && booking_sjinda === true" key="10"  header="รายงานสถานะหน้างาน">
                <a-collapse style="border-radius: 4px" v-model:activeKey="activeSubKeys">
                  <a-collapse-panel
                    v-for="(car , index_car) in create_booking.booking_cars"
                    :key="car.id"
                    :header="`ทะเบียนรถ: ${car.car.plate_no} เบอร์รถ : (${car.car.car_no})`"
                  >
                    <div
                      style="display: flex; justify-content: space-between; width: 100%"
                    >
                    <div>
                    <div>
                      <a-form-item name="onsite_photo" label="1.ถ่ายเมื่อถึงหน้างาน">
                        <a-upload
                          v-model:file-list="car.service.photo_1_show"
                          list-type="picture-card"
                          class="avatar-uploader"
                          accept="image/*"
                          :show-upload-list="false"
                          action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                          @change="upload_files($event, 'photo_1_show' , 'services' , index_car)"
                          method="GET"
                          @remove="remove_uploaded_file"
                        >
                          <div v-if="car.service.photo_1_show.length" class="upload-container">
                            <img
                              :src="getUploadedUrl(car.service.photo_1_show[0])"
                              class="uploaded-image"
                              alt="uploaded image"
                              @click="showPreview($event , getUploadedUrl(car.service.photo_1_show[0]))"
                            />
                            <div class="icon-container">
                              <span
                                class="material-symbols-outlined preview-icon"
                                @click="handleIconClick('preview', getUploadedUrl(car.service.photo_1_show[0]), $event)"
                              >
                                visibility
                              </span>
                              <div @click="getBase64Image($event ,car.service.photo_1_show[0])">
                                <span class="material-symbols-outlined download-icon" style="color: black;">
                                  download
                                </span>
                              </div>
                              <span
                                class="material-symbols-outlined delete-icon"
                                @click="handleClickDeletePhoto($event , 'photo_1_show' , 'services' , index_car)"
                              >
                                delete
                              </span>
                            </div>
                          </div>
                          <div v-else style="display: flex; flex-direction: column; align-items: center;">
                            <p class="ant-upload-drag-icon">
                              <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                            </p>
                            แนบรูปภาพ
                          </div>
                        </a-upload>
                      </a-form-item>
                    </div>
                    <div>
                      <a-form-item name="onsite_photo">
                        <a-upload
                          v-model:file-list="car.service.photo_7_show"
                          list-type="picture-card"
                          class="avatar-uploader"
                          accept="image/*"
                          :show-upload-list="false"
                          action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                          @change="upload_files($event, 'photo_7_show' , 'services' , index_car)"
                          method="GET"
                          @remove="remove_uploaded_file"
                        >
                          <div v-if="car.service.photo_7_show.length" class="upload-container">
                            <img
                              :src="getUploadedUrl(car.service.photo_7_show[0])"
                              class="uploaded-image"
                              alt="uploaded image"
                              @click="showPreview($event , getUploadedUrl(car.service.photo_7_show[0]))"
                            />
                            <div class="icon-container">
                              <span
                                class="material-symbols-outlined preview-icon"
                                @click="handleIconClick('preview', getUploadedUrl(car.service.photo_7_show[0]), $event)"
                              >
                                visibility
                              </span>
                              <div @click="getBase64Image($event ,car.service.photo_7_show[0])">
                                <span class="material-symbols-outlined download-icon" style="color: black;">
                                  download
                                </span>
                              </div>
                              <span
                                class="material-symbols-outlined delete-icon"
                                @click="handleClickDeletePhoto($event , 'photo_7_show' , 'services' , index_car)"
                              >
                                delete
                              </span>
                            </div>
                          </div>
                          <div v-else style="display: flex; flex-direction: column; align-items: center;">
                            <p class="ant-upload-drag-icon">
                              <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                            </p>
                            แนบรูปภาพ
                          </div>
                        </a-upload>
                    
                      </a-form-item>
                    </div>
                    </div>
                    <div>
                      <div>
                        <a-form-item name="onsite_photo" label="2.ขึ้นสินค้า">
                        <a-upload
                          v-model:file-list="car.service.photo_2_show"
                          list-type="picture-card"
                          class="avatar-uploader"
                          accept="image/*"
                          :show-upload-list="false"
                          action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                          @change="upload_files($event, 'photo_2_show' , 'services' , index_car)"
                          method="GET"
                          @remove="remove_uploaded_file"
                        >
                          <div v-if="car.service.photo_2_show.length" class="upload-container">
                            <img
                              :src="getUploadedUrl(car.service.photo_2_show[0])"
                              class="uploaded-image"
                              alt="uploaded image"
                              @click="showPreview($event , getUploadedUrl(car.service.photo_2_show[0]))"
                            />
                            <div class="icon-container">
                              <span
                                class="material-symbols-outlined preview-icon"
                                @click="handleIconClick('preview', getUploadedUrl(car.service.photo_2_show[0]), $event)"
                              >
                                visibility
                              </span>
                              <div @click="getBase64Image($event ,car.service.photo_2_show[0])">
                                <span class="material-symbols-outlined download-icon" style="color: black;">
                                  download
                                </span>
                              </div>
                              <span
                                class="material-symbols-outlined delete-icon"
                                @click="handleClickDeletePhoto($event , 'photo_2_show' , 'services' , index_car)"
                              >
                                delete
                              </span>
                            </div>
                          </div>
                          <div v-else style="display: flex; flex-direction: column; align-items: center;">
                            <p class="ant-upload-drag-icon">
                              <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                            </p>
                            แนบรูปภาพ
                          </div>
                        </a-upload>
                        </a-form-item>
                      </div>
                      <div>
                        <a-form-item name="onsite_photo">
                        <a-upload
                          v-model:file-list="car.service.photo_8_show"
                          list-type="picture-card"
                          class="avatar-uploader"
                          accept="image/*"
                          :show-upload-list="false"
                          action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                          @change="upload_files($event, 'photo_8_show' , 'services' , index_car)"
                          method="GET"
                          @remove="remove_uploaded_file"
                        >
                          <div v-if="car.service.photo_8_show.length" class="upload-container">
                            <img
                              :src="getUploadedUrl(car.service.photo_8_show[0])"
                              class="uploaded-image"
                              alt="uploaded image"
                              @click="showPreview($event , getUploadedUrl(car.service.photo_8_show[0]))"
                            />
                            <div class="icon-container">
                              <span
                                class="material-symbols-outlined preview-icon"
                                @click="handleIconClick('preview', getUploadedUrl(car.service.photo_8_show[0]), $event)"
                              >
                                visibility
                              </span>
                              <div @click="getBase64Image($event ,car.service.photo_8_show[0])">
                                <span class="material-symbols-outlined download-icon" style="color: black;">
                                  download
                                </span>
                              </div>
                              <span
                                class="material-symbols-outlined delete-icon"
                                @click="handleClickDeletePhoto($event , 'photo_8_show' , 'services' , index_car)"
                              >
                                delete
                              </span>
                            </div>
                          </div>
                          <div v-else style="display: flex; flex-direction: column; align-items: center;">
                            <p class="ant-upload-drag-icon">
                              <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                            </p>
                            แนบรูปภาพ
                          </div>
                        </a-upload>
                        </a-form-item>
                      </div>
                    </div>
                    <div>
                      <div>
                        <a-form-item name="onsite_photo" label="3.ตรวจสอบสินค้า">
                      <a-upload
                        v-model:file-list="car.service.photo_3_show"
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept="image/*"
                        :show-upload-list="false"
                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                        @change="upload_files($event, 'photo_3_show' , 'services' , index_car)"
                        method="GET"
                        @remove="remove_uploaded_file"
                      >
                        <div v-if="car.service.photo_3_show.length" class="upload-container">
                          <img
                            :src="getUploadedUrl(car.service.photo_3_show[0])"
                            class="uploaded-image"
                            alt="uploaded image"
                            @click="showPreview($event , getUploadedUrl(car.service.photo_3_show[0]))"
                          />
                          <div class="icon-container">
                            <span
                              class="material-symbols-outlined preview-icon"
                              @click="handleIconClick('preview', getUploadedUrl(car.service.photo_3_show[0]), $event)"
                            >
                              visibility
                            </span>
                            <div @click="getBase64Image($event ,car.service.photo_3_show[0])">
                              <span class="material-symbols-outlined download-icon" style="color: black;">
                                download
                              </span>
                            </div>
                            <span
                              class="material-symbols-outlined delete-icon"
                              @click="handleClickDeletePhoto($event , 'photo_3_show' , 'services' , index_car)"
                            >
                              delete
                            </span>
                          </div>
                        </div>
                        <div v-else style="display: flex; flex-direction: column; align-items: center;">
                          <p class="ant-upload-drag-icon">
                            <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                          </p>
                          แนบรูปภาพ
                        </div>
                      </a-upload>
                        </a-form-item>
                     </div>
                     <div>
                        <a-form-item name="onsite_photo">
                        <a-upload
                          v-model:file-list="car.service.photo_9_show"
                          list-type="picture-card"
                          class="avatar-uploader"
                          accept="image/*"
                          :show-upload-list="false"
                          action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                          @change="upload_files($event, 'photo_9_show' , 'services' , index_car)"
                          method="GET"
                          @remove="remove_uploaded_file"
                        >
                          <div v-if="car.service.photo_9_show.length" class="upload-container">
                            <img
                              :src="getUploadedUrl(car.service.photo_9_show[0])"
                              class="uploaded-image"
                              alt="uploaded image"
                              @click="showPreview($event , getUploadedUrl(car.service.photo_9_show[0]))"
                            />
                            <div class="icon-container">
                              <span
                                class="material-symbols-outlined preview-icon"
                                @click="handleIconClick('preview', getUploadedUrl(car.service.photo_9_show[0]), $event)"
                              >
                                visibility
                              </span>
                              <div @click="getBase64Image($event ,car.service.photo_9_show[0])">
                                <span class="material-symbols-outlined download-icon" style="color: black;">
                                  download
                                </span>
                              </div>
                              <span
                                class="material-symbols-outlined delete-icon"
                                @click="handleClickDeletePhoto($event , 'photo_9_show' , 'services' , index_car)"
                              >
                                delete
                              </span>
                            </div>
                          </div>
                          <div v-else style="display: flex; flex-direction: column; align-items: center;">
                            <p class="ant-upload-drag-icon">
                              <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                            </p>
                            แนบรูปภาพ
                          </div>
                        </a-upload>
                        </a-form-item>
                     </div>
                    </div>
                    <div>
                      <div>
                        <a-form-item name="onsite_photo" label="4.สินค้าชำรุด(ถ้ามี)">
                        <a-upload
                          v-model:file-list="car.service.photo_4_show"
                          list-type="picture-card"
                          class="avatar-uploader"
                          accept="image/*"
                          :show-upload-list="false"
                          action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                          @change="upload_files($event, 'photo_4_show' , 'services' , index_car)"
                          method="GET"
                          @remove="remove_uploaded_file"
                        >
                          <div v-if="car.service.photo_4_show.length" class="upload-container">
                            <img
                              :src="getUploadedUrl(car.service.photo_4_show[0])"
                              class="uploaded-image"
                              alt="uploaded image"
                              @click="showPreview($event , getUploadedUrl(car.service.photo_4_show[0]))"
                            />
                            <div class="icon-container">
                              <span
                                class="material-symbols-outlined preview-icon"
                                @click="handleIconClick('preview', getUploadedUrl(car.service.photo_4_show[0]), $event)"
                              >
                                visibility
                              </span>
                              <div @click="getBase64Image($event ,car.service.photo_4_show[0])">
                                <span class="material-symbols-outlined download-icon" style="color: black;">
                                  download
                                </span>
                              </div>
                              <span
                                class="material-symbols-outlined delete-icon"
                                @click="handleClickDeletePhoto($event , 'photo_4_show' , 'services' , index_car)"
                              >
                                delete
                              </span>
                            </div>
                          </div>
                          <div v-else style="display: flex; flex-direction: column; align-items: center;">
                            <p class="ant-upload-drag-icon">
                              <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                            </p>
                            แนบรูปภาพ
                          </div>
                        </a-upload>
                        </a-form-item>
                      </div>
                      <div>
                        <a-form-item name="onsite_photo">
                        <a-upload
                          v-model:file-list="car.service.photo_10_show"
                          list-type="picture-card"
                          class="avatar-uploader"
                          accept="image/*"
                          :show-upload-list="false"
                          action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                          @change="upload_files($event, 'photo_10_show' , 'services' , index_car)"
                          method="GET"
                          @remove="remove_uploaded_file"
                        >
                          <div v-if="car.service.photo_10_show.length" class="upload-container">
                            <img
                              :src="getUploadedUrl(car.service.photo_10_show[0])"
                              class="uploaded-image"
                              alt="uploaded image"
                              @click="showPreview($event , getUploadedUrl(car.service.photo_10_show[0]))"
                            />
                            <div class="icon-container">
                              <span
                                class="material-symbols-outlined preview-icon"
                                @click="handleIconClick('preview', getUploadedUrl(car.service.photo_10_show[0]), $event)"
                              >
                                visibility
                              </span>
                              <div @click="getBase64Image($event ,car.service.photo_10_show[0])">
                                <span class="material-symbols-outlined download-icon" style="color: black;">
                                  download
                                </span>
                              </div>
                              <span
                                class="material-symbols-outlined delete-icon"
                                @click="handleClickDeletePhoto($event , 'photo_10_show' , 'services' , index_car)"
                              >
                                delete
                              </span>
                            </div>
                          </div>
                          <div v-else style="display: flex; flex-direction: column; align-items: center;">
                            <p class="ant-upload-drag-icon">
                              <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                            </p>
                            แนบรูปภาพ
                          </div>
                        </a-upload>
                        </a-form-item>
                      </div>
                    </div>
                    <div>
                      <div>
                        <a-form-item name="onsite_photo" label="5.ส่งสินค้า">                      
                        <a-upload
                          v-model:file-list="car.service.photo_5_show"
                          list-type="picture-card"
                          class="avatar-uploader"
                          accept="image/*"
                          :show-upload-list="false"
                          action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                          @change="upload_files($event, 'photo_5_show' , 'services' , index_car)"
                          method="GET"
                          @remove="remove_uploaded_file"
                        >
                          <div v-if="car.service.photo_5_show.length" class="upload-container">
                            <img
                              :src="getUploadedUrl(car.service.photo_5_show[0])"
                              class="uploaded-image"
                              alt="uploaded image"
                              @click="showPreview($event , getUploadedUrl(car.service.photo_5_show[0]))"
                            />
                            <div class="icon-container">
                              <span
                                class="material-symbols-outlined preview-icon"
                                @click="handleIconClick('preview', getUploadedUrl(car.service.photo_5_show[0]), $event)"
                              >
                                visibility
                              </span>
                              <div @click="getBase64Image($event ,car.service.photo_5_show[0])">
                                <span class="material-symbols-outlined download-icon" style="color: black;">
                                  download
                                </span>
                              </div>
                              <span
                                class="material-symbols-outlined delete-icon"
                                @click="handleClickDeletePhoto($event , 'photo_5_show' , 'services' , index_car)"
                              >
                                delete
                              </span>
                            </div>
                          </div>
                          <div v-else style="display: flex; flex-direction: column; align-items: center;">
                            <p class="ant-upload-drag-icon">
                              <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                            </p>
                            แนบรูปภาพ
                          </div>
                        </a-upload>
                        </a-form-item>
                      </div>
                      <div> 
                        <a-form-item
                          name="onsite_photo"
                        >                      
                        <a-upload
                          v-model:file-list="car.service.photo_11_show"
                          list-type="picture-card"
                          class="avatar-uploader"
                          accept="image/*"
                          :show-upload-list="false"
                          action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                          @change="upload_files($event, 'photo_11_show' , 'services' , index_car)"
                          method="GET"
                          @remove="remove_uploaded_file"
                        >
                          <div v-if="car.service.photo_11_show.length" class="upload-container">
                            <img
                              :src="getUploadedUrl(car.service.photo_11_show[0])"
                              class="uploaded-image"
                              alt="uploaded image"
                              @click="showPreview($event , getUploadedUrl(car.service.photo_11_show[0]))"
                            />
                            <div class="icon-container">
                              <span
                                class="material-symbols-outlined preview-icon"
                                @click="handleIconClick('preview', getUploadedUrl(car.service.photo_11_show[0]), $event)"
                              >
                                visibility
                              </span>
                              <div @click="getBase64Image($event ,car.service.photo_11_show[0])">
                                <span class="material-symbols-outlined download-icon" style="color: black;">
                                  download
                                </span>
                              </div>
                              <span
                                class="material-symbols-outlined delete-icon"
                                @click="handleClickDeletePhoto($event , 'photo_11_show' , 'services' , index_car)"
                              >
                                delete
                              </span>
                            </div>
                          </div>
                          <div v-else style="display: flex; flex-direction: column; align-items: center;">
                            <p class="ant-upload-drag-icon">
                              <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                            </p>
                            แนบรูปภาพ
                          </div>
                        </a-upload>
                        </a-form-item>
                      </div>
                    </div>
                    <div>
                      <div>
                        <a-form-item name="assist_driver_photo" label="6.รูปพนักงานคนขับรถที่ 2">                      
                          <a-upload
                            v-model:file-list="car.service.photo_6_show"
                            list-type="picture-card"
                            class="avatar-uploader"
                            accept="image/*"
                            :show-upload-list="false"
                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                            @change="upload_files($event, 'photo_6_show', 'services', index_car)"
                            method="GET"
                            @remove="remove_uploaded_file"
                          >
                            <div v-if="car.service.photo_6_show.length" class="upload-container">
                              <img
                                :src="getUploadedUrl(car.service.photo_6_show[0])"
                                class="uploaded-image"
                                alt="uploaded image"
                                @click="showPreview($event, getUploadedUrl(car.service.photo_6_show[0]))"
                              />
                              <div class="icon-container">
                                <span
                                  class="material-symbols-outlined preview-icon"
                                  @click="handleIconClick('preview', getUploadedUrl(car.service.photo_6_show[0]), $event)"
                                >
                                  visibility
                                </span>
                                <div @click="getBase64Image($event, car.service.photo_6_show[0])">
                                  <span class="material-symbols-outlined download-icon" style="color: black;">
                                    download
                                  </span>
                                </div>
                                <span
                                  class="material-symbols-outlined delete-icon"
                                  @click="handleClickDeletePhoto($event, 'photo_6_show', 'services', index_car)"
                                >
                                  delete
                                </span>
                              </div>
                            </div>
                            <div v-else style="display: flex; flex-direction: column; align-items: center;">
                              <p class="ant-upload-drag-icon">
                                <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                              </p>
                              แนบรูปภาพ
                            </div>
                          </a-upload>
                        </a-form-item>
                      </div>
                      <div>
                        <a-form-item
                          name="assist_driver_photo"
                        >                 
                          <a-upload
                            v-model:file-list="car.service.photo_12_show"
                            list-type="picture-card"
                            class="avatar-uploader"
                            accept="image/*"
                            :show-upload-list="false"
                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                            @change="upload_files($event, 'photo_12_show', 'services', index_car)"
                            method="GET"
                            @remove="remove_uploaded_file"
                          >
                            <div v-if="car.service.photo_12_show.length" class="upload-container">
                              <img
                                :src="getUploadedUrl(car.service.photo_12_show[0])"
                                class="uploaded-image"
                                alt="uploaded image"
                                @click="showPreview($event, getUploadedUrl(car.service.photo_12_show[0]))"
                              />
                              <div class="icon-container">
                                <span
                                  class="material-symbols-outlined preview-icon"
                                  @click="handleIconClick('preview', getUploadedUrl(car.service.photo_12_show[0]), $event)"
                                >
                                  visibility
                                </span>
                                <div @click="getBase64Image($event, car.service.photo_12_show[0])">
                                  <span class="material-symbols-outlined download-icon" style="color: black;">
                                    download
                                  </span>
                                </div>
                                <span
                                  class="material-symbols-outlined delete-icon"
                                  @click="handleClickDeletePhoto($event, 'photo_12_show', 'services', index_car)"
                                >
                                  delete
                                </span>
                              </div>
                            </div>
                            <div v-else style="display: flex; flex-direction: column; align-items: center;">
                              <p class="ant-upload-drag-icon">
                                <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                              </p>
                              แนบรูปภาพ
                            </div>
                          </a-upload>
                        </a-form-item>
                      </div>
                    </div>
                    </div>
                    <!-- แถวสอง -->
                  </a-collapse-panel>
                </a-collapse>
              </a-collapse-panel>

              <a-collapse-panel v-if="on_booking && booking_sjinda !== true" key="10" header="รายงานสถานะหน้างาน">
                <a-collapse style="border-radius: 4px" v-model:activeKey="activeSubKeys">
                  <a-collapse-panel
                    v-for="(car , index_car) in create_booking.booking_cars"
                    :key="car.id"
                    :header="`ทะเบียนรถ: ${car.car.plate_no} เบอร์รถ : (${car.car.car_no})`"
                  >
                    <div
                      style="display: flex; justify-content: space-between; width: 100%"
                    >
                    <a-form-item name="onsite_photo" label="1. ถ่ายภาพเมื่อถึงหน้างานแล้ว">
                      <a-upload
                        v-model:file-list="car.service.photo_1_show"
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept="image/*"
                        :show-upload-list="false"
                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                        @change="upload_files($event, 'photo_1_show' , 'services' , index_car)"
                        method="GET"
                        @remove="remove_uploaded_file"
                      >
                        <div v-if="car.service.photo_1_show.length" class="upload-container">
                          <img
                            :src="getUploadedUrl(car.service.photo_1_show[0])"
                            class="uploaded-image"
                            alt="uploaded image"
                            @click="showPreview($event , getUploadedUrl(car.service.photo_1_show[0]))"
                          />
                          <div class="icon-container">
                            <span
                              class="material-symbols-outlined preview-icon"
                              @click="handleIconClick('preview', getUploadedUrl(car.service.photo_1_show[0]), $event)"
                            >
                              visibility
                            </span>
                            <div @click="getBase64Image($event ,car.service.photo_1_show[0])">
                              <span class="material-symbols-outlined download-icon" style="color: black;">
                                download
                              </span>
                            </div>
                            <span
                              class="material-symbols-outlined delete-icon"
                              @click="handleClickDeletePhoto($event , 'photo_1_show' , 'services' , index_car)"
                            >
                              delete
                            </span>
                          </div>
                        </div>
                        <div v-else style="display: flex; flex-direction: column; align-items: center;">
                          <p class="ant-upload-drag-icon">
                            <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                          </p>
                          แนบรูปภาพ
                        </div>
                      </a-upload>
                    </a-form-item>

                      <a-form-item name="onsite_photo" label="2. รูปการเปิด VTR ความปลอดภัย">
                      <a-upload
                        v-model:file-list="car.service.photo_2_show"
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept="image/*"
                        :show-upload-list="false"
                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                        @change="upload_files($event, 'photo_2_show' , 'services' , index_car)"
                        method="GET"
                        @remove="remove_uploaded_file"
                      >
                        <div v-if="car.service.photo_2_show.length" class="upload-container">
                          <img
                            :src="getUploadedUrl(car.service.photo_2_show[0])"
                            class="uploaded-image"
                            alt="uploaded image"
                            @click="showPreview($event , getUploadedUrl(car.service.photo_2_show[0]))"
                          />
                          <div class="icon-container">
                            <span
                              class="material-symbols-outlined preview-icon"
                              @click="handleIconClick('preview', getUploadedUrl(car.service.photo_2_show[0]), $event)"
                            >
                              visibility
                            </span>
                            <div @click="getBase64Image($event ,car.service.photo_2_show[0])">
                              <span class="material-symbols-outlined download-icon" style="color: black;">
                                download
                              </span>
                            </div>
                            <span
                              class="material-symbols-outlined delete-icon"
                              @click="handleClickDeletePhoto($event , 'photo_2_show' , 'services' , index_car)"
                            >
                              delete
                            </span>
                          </div>
                        </div>
                        <div v-else style="display: flex; flex-direction: column; align-items: center;">
                          <p class="ant-upload-drag-icon">
                            <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                          </p>
                          แนบรูปภาพ
                        </div>
                      </a-upload>
                    </a-form-item>

                      <a-form-item name="onsite_photo" label="3. การเสิร์ฟน้ำ">
                      <a-upload
                        v-model:file-list="car.service.photo_3_show"
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept="image/*"
                        :show-upload-list="false"
                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                        @change="upload_files($event, 'photo_3_show' , 'services' , index_car)"
                        method="GET"
                        @remove="remove_uploaded_file"
                      >
                        <div v-if="car.service.photo_3_show.length" class="upload-container">
                          <img
                            :src="getUploadedUrl(car.service.photo_3_show[0])"
                            class="uploaded-image"
                            alt="uploaded image"
                            @click="showPreview($event , getUploadedUrl(car.service.photo_3_show[0]))"
                          />
                          <div class="icon-container">
                            <span
                              class="material-symbols-outlined preview-icon"
                              @click="handleIconClick('preview', getUploadedUrl(car.service.photo_3_show[0]), $event)"
                            >
                              visibility
                            </span>
                            <div @click="getBase64Image($event ,car.service.photo_3_show[0])">
                              <span class="material-symbols-outlined download-icon" style="color: black;">
                                download
                              </span>
                            </div>
                            <span
                              class="material-symbols-outlined delete-icon"
                              @click="handleClickDeletePhoto($event , 'photo_3_show' , 'services' , index_car)"
                            >
                              delete
                            </span>
                          </div>
                        </div>
                        <div v-else style="display: flex; flex-direction: column; align-items: center;">
                          <p class="ant-upload-drag-icon">
                            <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                          </p>
                          แนบรูปภาพ
                        </div>
                      </a-upload>
                    </a-form-item>

                      <a-form-item name="onsite_photo" label="4. การกดเจลแอลกอฮอล์">
                      <a-upload
                        v-model:file-list="car.service.photo_4_show"
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept="image/*"
                        :show-upload-list="false"
                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                        @change="upload_files($event, 'photo_4_show' , 'services' , index_car)"
                        method="GET"
                        @remove="remove_uploaded_file"
                      >
                        <div v-if="car.service.photo_4_show.length" class="upload-container">
                          <img
                            :src="getUploadedUrl(car.service.photo_4_show[0])"
                            class="uploaded-image"
                            alt="uploaded image"
                            @click="showPreview($event , getUploadedUrl(car.service.photo_4_show[0]))"
                          />
                          <div class="icon-container">
                            <span
                              class="material-symbols-outlined preview-icon"
                              @click="handleIconClick('preview', getUploadedUrl(car.service.photo_4_show[0]), $event)"
                            >
                              visibility
                            </span>
                            <div @click="getBase64Image($event ,car.service.photo_4_show[0])">
                              <span class="material-symbols-outlined download-icon" style="color: black;">
                                download
                              </span>
                            </div>
                            <span
                              class="material-symbols-outlined delete-icon"
                              @click="handleClickDeletePhoto($event , 'photo_4_show' , 'services' , index_car)"
                            >
                              delete
                            </span>
                          </div>
                        </div>
                        <div v-else style="display: flex; flex-direction: column; align-items: center;">
                          <p class="ant-upload-drag-icon">
                            <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                          </p>
                          แนบรูปภาพ
                        </div>
                      </a-upload>
                    </a-form-item>

                      <a-form-item
                        name="assist_driver_photo"
                        label="5. รูปภาพพนักงานขับรถคนที่ 2"
                      >                      
                      <a-upload
                        v-model:file-list="car.service.photo_5_show"
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept="image/*"
                        :show-upload-list="false"
                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                        @change="upload_files($event, 'photo_5_show' , 'services' , index_car)"
                        method="GET"
                        @remove="remove_uploaded_file"
                      >
                        <div v-if="car.service.photo_5_show.length" class="upload-container">
                          <img
                            :src="getUploadedUrl(car.service.photo_5_show[0])"
                            class="uploaded-image"
                            alt="uploaded image"
                            @click="showPreview($event , getUploadedUrl(car.service.photo_5_show[0]))"
                          />
                          <div class="icon-container">
                            <span
                              class="material-symbols-outlined preview-icon"
                              @click="handleIconClick('preview', getUploadedUrl(car.service.photo_5_show[0]), $event)"
                            >
                              visibility
                            </span>
                            <div @click="getBase64Image($event ,car.service.photo_5_show[0])">
                              <span class="material-symbols-outlined download-icon" style="color: black;">
                                download
                              </span>
                            </div>
                            <span
                              class="material-symbols-outlined delete-icon"
                              @click="handleClickDeletePhoto($event , 'photo_5_show' , 'services' , index_car)"
                            >
                              delete
                            </span>
                          </div>
                        </div>
                        <div v-else style="display: flex; flex-direction: column; align-items: center;">
                          <p class="ant-upload-drag-icon">
                            <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                          </p>
                          แนบรูปภาพ
                        </div>
                      </a-upload>
                    </a-form-item>

                      
                    </div>
                  </a-collapse-panel>
                </a-collapse>
              </a-collapse-panel>

              <a-collapse-panel v-if="on_booking" key="9" header="บันทึกค่าน้ำมัน">
                <a-collapse style="border-radius: 4px" v-model:activeKey="activeSubKeys2">
                  <a-collapse-panel
                    v-for="(car,index_car) in create_booking.booking_cars"
                    :key="car.id"
                    :header="`ทะเบียนรถ: ${car.car.plate_no} เบอร์รถ : (${car.car.car_no})`"
                  >
                    <div v-if="car.gas_refills.length">
                      <a-card
                        v-for="(gas, index_gas_refills) in car.gas_refills"
                        :key="gas.id"
                        :title="`หลักฐานการเติม: #${index_gas_refills + 1} - ${render_date(
                          gas.created_at
                        )}`   "
                      >
                        <div
                          style="
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                          "
                        >
                            <a-form-item name="gas1" label="1. รูปหน้าปัดก่อนเติมน้ำมัน">
                              <!-- First Upload Section -->
                              <div class="upload-section">
                                <a-upload
                                  v-model:file-list="gas.photo_1_show"
                                  list-type="picture-card"
                                  class="avatar-uploader"
                                  :show-upload-list="false"
                                  action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                  @change="upload_files($event, 'photo_1_show' , 'gas_refills' , index_car , index_gas_refills)"
                                  method="GET"
                                >
                                  <div v-if="gas.photo_1_show.length" class="upload-container">
                                    <img
                                      :src="getUploadedUrl(gas.photo_1_show[0])"
                                      class="uploaded-image"
                                      alt="uploaded image"
                                      @click="showPreview($event , getUploadedUrl(gas.photo_1_show[0]))"
                                    />
                                    <div class="icon-container">
                                      <span
                                        class="material-symbols-outlined preview-icon"
                                        @click="handleIconClick('preview', getUploadedUrl(gas.photo_1_show[0]), $event)"
                                      >
                                        visibility
                                      </span>
                                      <div @click="getBase64Image($event ,gas.photo_1_show[0])">
                                        <span class="material-symbols-outlined download-icon" style="color: black;">
                                          download
                                        </span>
                                      </div>
                                      <span
                                        class="material-symbols-outlined delete-icon"
                                        @click="handleClickDeletePhoto($event , 'photo_1_show' , 'gas_refills' , index_car , index_gas_refills)"
                                      >
                                        delete
                                      </span>
                                    </div>
                                  </div>
                                  <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                    <p class="ant-upload-drag-icon">
                                      <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                    </p>
                                    แนบรูปภาพ
                                  </div>
                                </a-upload>
                              </div>

                              <!-- Second Upload Section -->
                              <div class="upload-section">
                                <a-upload
                                  v-model:file-list="gas.photo_9_show"
                                  list-type="picture-card"
                                  class="avatar-uploader"
                                  :show-upload-list="false"
                                  action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                  @change="upload_files($event, 'photo_9_show' , 'gas_refills' , index_car , index_gas_refills)"
                                  method="GET"
                                >
                                  <div v-if="gas.photo_9_show.length" class="upload-container">
                                    <img
                                      :src="getUploadedUrl(gas.photo_9_show[0])"
                                      class="uploaded-image"
                                      alt="uploaded image"
                                      @click="showPreview($event ,getUploadedUrl(gas.photo_9_show[0]))"
                                    />
                                    <div class="icon-container">
                                      <span
                                        class="material-symbols-outlined preview-icon"
                                        @click="handleIconClick('preview', getUploadedUrl(gas.photo_9_show[0]), $event)"
                                      >
                                        visibility
                                      </span>
                                      <div @click="getBase64Image($event ,gas.photo_9_show[0])">
                                        <span class="material-symbols-outlined download-icon" style="color: black;">
                                          download
                                        </span>
                                      </div>
                                      <span
                                        class="material-symbols-outlined delete-icon"
                                        @click="handleClickDeletePhoto($event , 'photo_9_show' , 'gas_refills', index_car , index_gas_refills)"
                                      >
                                        delete
                                      </span>
                                    </div>
                                  </div>
                                  <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                    <p class="ant-upload-drag-icon">
                                      <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                    </p>
                                    แนบรูปภาพ
                                  </div>
                                </a-upload>
                              </div>
                            </a-form-item>

                          <a-form-item name="gas5" label="2. รูปคอน้ำมัน">
                            <a-upload
                              v-model:file-list="gas.photo_2_show"
                              list-type="picture-card"
                              class="avatar-uploader"
                              :show-upload-list="false"
                              action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                              @change="upload_files($event, 'photo_2_show' , 'gas_refills' , index_car , index_gas_refills)"
                              method="GET"
                            >
                              <div v-if="gas.photo_2_show.length" class="upload-container">
                                <img
                                  :src="getUploadedUrl(gas.photo_2_show[0])"
                                  class="uploaded-image"
                                  alt="uploaded image"
                                  @click="showPreview($event ,getUploadedUrl(gas.photo_2_show[0]))"
                                />
                                <div class="icon-container">
                                  <span
                                    class="material-symbols-outlined preview-icon"
                                    @click="handleIconClick('preview', getUploadedUrl(gas.photo_2_show[0]), $event)"
                                  >
                                    visibility
                                  </span>
                                  <div @click="getBase64Image($event ,gas.photo_2_show[0])">
                                    <span class="material-symbols-outlined download-icon" style="color: black;">
                                      download
                                    </span>
                                  </div>
                                  <span
                                      class="material-symbols-outlined delete-icon"
                                      @click="handleClickDeletePhoto($event , 'photo_2_show' , 'gas_refills', index_car , index_gas_refills)"
                                    >
                                      delete
                                    </span>
                                </div>
                              </div>
                              <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                <p class="ant-upload-drag-icon">
                                  <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                </p>
                                แนบรูปภาพ
                              </div>
                            </a-upload>
                          </a-form-item>

                          <a-modal v-model:visible="previewVisible" :footer="null" :mask="false" @cancel="previewVisible = false" class="custom-modal" style="background-color: none !important">
                            <img :src="previewImage" alt="Preview" style="width: 100%;" />
                          </a-modal>

                            <a-form-item name="gas6" label="3. รูปหัวจ่าย">
                            <a-upload
                              v-model:file-list="gas.photo_3_show"
                              list-type="picture-card"
                              class="avatar-uploader"
                              :show-upload-list="false"
                              action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                              @change="upload_files($event, 'photo_3_show' , 'gas_refills' , index_car , index_gas_refills)"
                              method="GET"
                            >
                              <div v-if="gas.photo_3_show.length" class="upload-container">
                                <img
                                  :src="getUploadedUrl(gas.photo_3_show[0])"
                                  class="uploaded-image"
                                  alt="uploaded image"
                                  @click="showPreview($event ,getUploadedUrl(gas.photo_3_show[0]))"
                                />
                                <div class="icon-container">
                                  <span
                                    class="material-symbols-outlined preview-icon"
                                    @click="handleIconClick('preview', getUploadedUrl(gas.photo_3_show[0]), $event)"
                                  >
                                    visibility
                                  </span>
                                  <div @click="getBase64Image($event ,gas.photo_3_show[0])">
                                    <span class="material-symbols-outlined download-icon" style="color: black;">
                                      download
                                    </span>
                                  </div>
                                  <span
                                      class="material-symbols-outlined delete-icon"
                                      @click="handleClickDeletePhoto($event , 'photo_3_show' , 'gas_refills', index_car , index_gas_refills)"
                                    >
                                      delete
                                    </span>
                                </div>
                              </div>
                              <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                <p class="ant-upload-drag-icon">
                                  <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                </p>
                                แนบรูปภาพ
                              </div>
                            </a-upload>
                          </a-form-item>
 
                            <a-form-item name="gas3" label="4. รูปรถคู่กับหัวจ่าย">
                            <a-upload
                              v-model:file-list="gas.photo_4_show"
                              list-type="picture-card"
                              class="avatar-uploader"
                              :show-upload-list="false"
                              action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                              @change="upload_files($event, 'photo_4_show' , 'gas_refills' , index_car , index_gas_refills)"
                              method="GET"
                            >
                              <div v-if="gas.photo_4_show.length" class="upload-container">
                                <img
                                  :src="getUploadedUrl(gas.photo_4_show[0])"
                                  class="uploaded-image"
                                  alt="uploaded image"
                                  @click="showPreview($event ,getUploadedUrl(gas.photo_4_show[0]))"
                                />
                                <div class="icon-container">
                                  <span
                                    class="material-symbols-outlined preview-icon"
                                    @click="handleIconClick('preview', getUploadedUrl(gas.photo_4_show[0]), $event)"
                                  >
                                    visibility
                                  </span>
                                  <div @click="getBase64Image($event ,gas.photo_4_show[0])">
                                    <span class="material-symbols-outlined download-icon" style="color: black;">
                                      download
                                    </span>
                                  </div>
                                  <span
                                      class="material-symbols-outlined delete-icon"
                                      @click="handleClickDeletePhoto($event , 'photo_4_show' , 'gas_refills', index_car , index_gas_refills)"
                                    >
                                      delete
                                    </span>
                                </div>
                              </div>
                              <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                <p class="ant-upload-drag-icon">
                                  <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                </p>
                                แนบรูปภาพ
                              </div>
                            </a-upload>
                          </a-form-item>
                        
                            <a-form-item name="gas4" label="5. รูปเติมน้ำมันครั้งที่ 1">
                            <a-upload
                              v-model:file-list="gas.photo_5_show"
                              list-type="picture-card"
                              class="avatar-uploader"
                              :show-upload-list="false"
                              action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                              @change="upload_files($event, 'photo_5_show' , 'gas_refills' , index_car , index_gas_refills)"
                              method="GET"
                            >
                              <div v-if="gas.photo_5_show.length" class="upload-container">
                                <img
                                  :src="getUploadedUrl(gas.photo_5_show[0])"
                                  class="uploaded-image"
                                  alt="uploaded image"
                                  @click="showPreview($event ,getUploadedUrl(gas.photo_5_show[0]))"
                                />
                                <div class="icon-container">
                                  <span
                                    class="material-symbols-outlined preview-icon"
                                    @click="handleIconClick('preview', getUploadedUrl(gas.photo_5_show[0]), $event)"
                                  >
                                    visibility
                                  </span>
                                  <div @click="getBase64Image($event ,gas.photo_5_show[0])">
                                    <span class="material-symbols-outlined download-icon" style="color: black;">
                                      download
                                    </span>
                                  </div>
                                  <span
                                      class="material-symbols-outlined delete-icon"
                                      @click="handleClickDeletePhoto($event , 'photo_5_show' , 'gas_refills', index_car , index_gas_refills)"
                                    >
                                      delete
                                    </span>
                                </div>
                              </div>
                              <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                <p class="ant-upload-drag-icon">
                                  <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                </p>
                                แนบรูปภาพ
                              </div>
                            </a-upload>
                          </a-form-item>

                            <a-form-item name="gas8" label="6. บิลเชื้อเพลิง">
                            <a-upload
                              v-model:file-list="gas.photo_6_show"
                              list-type="picture-card"
                              class="avatar-uploader"
                              :show-upload-list="false"
                              action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                              @change="upload_files($event, 'photo_6_show' , 'gas_refills' , index_car , index_gas_refills)"
                              method="GET"
                            >
                              <div v-if="gas.photo_6_show.length" class="upload-container">
                                <img
                                  :src="getUploadedUrl(gas.photo_6_show[0])"
                                  class="uploaded-image"
                                  alt="uploaded image"
                                  @click="showPreview($event ,getUploadedUrl(gas.photo_6_show[0]))"
                                />
                                <div class="icon-container">
                                  <span
                                    class="material-symbols-outlined preview-icon"
                                    @click="handleIconClick('preview', getUploadedUrl(gas.photo_6_show[0]), $event)"
                                  >
                                    visibility
                                  </span>
                                  <div @click="getBase64Image($event ,gas.photo_6_show[0])">
                                    <span class="material-symbols-outlined download-icon" style="color: black;">
                                      download
                                    </span>
                                  </div>
                                  <span
                                      class="material-symbols-outlined delete-icon"
                                      @click="handleClickDeletePhoto($event , 'photo_6_show' , 'gas_refills', index_car , index_gas_refills)"
                                    >
                                      delete
                                    </span>
                                </div>
                              </div>
                              <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                <p class="ant-upload-drag-icon">
                                  <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                </p>
                                แนบรูปภาพ
                              </div>
                            </a-upload>
                          </a-form-item>

                            <a-form-item name="gas7" label="7. รูปหน้าปัดหลังเติม">
                              <!-- First Upload Section -->
                              <div class="upload-section">
                                <a-upload
                                  v-model:file-list="gas.photo_7_show"
                                  list-type="picture-card"
                                  class="avatar-uploader"
                                  :show-upload-list="false"
                                  action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                  @change="upload_files($event, 'photo_7_show' , 'gas_refills' , index_car , index_gas_refills)"
                                  method="GET"
                                >
                                  <div v-if="gas.photo_7_show.length" class="upload-container">
                                    <img
                                      :src="getUploadedUrl(gas.photo_7_show[0])"
                                      class="uploaded-image"
                                      alt="uploaded image"
                                      @click="showPreview($event ,getUploadedUrl(gas.photo_7_show[0]))"
                                    />
                                    <div class="icon-container">
                                      <span
                                        class="material-symbols-outlined preview-icon"
                                        @click="handleIconClick('preview', getUploadedUrl(gas.photo_7_show[0]), $event)"
                                      >
                                        visibility
                                      </span>
                                      <div @click="getBase64Image($event ,gas.photo_7_show[0])">
                                        <span class="material-symbols-outlined download-icon" style="color: black;">
                                          download
                                        </span>
                                      </div>
                                      <span
                                        class="material-symbols-outlined delete-icon"
                                        @click="handleClickDeletePhoto($event , 'photo_7_show' , 'gas_refills', index_car , index_gas_refills)"
                                      >
                                        delete
                                      </span>
                                    </div>
                                  </div>
                                  <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                    <p class="ant-upload-drag-icon">
                                      <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                    </p>
                                    แนบรูปภาพ
                                  </div>
                                </a-upload>
                              </div>

                              <!-- Second Upload Section -->
                              <div class="upload-section">
                                <a-upload
                                  v-model:file-list="gas.photo_10_show"
                                  list-type="picture-card"
                                  class="avatar-uploader"
                                  :show-upload-list="false"
                                  action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                  @change="upload_files($event, 'photo_10_show' , 'gas_refills' , index_car , index_gas_refills)"
                                  method="GET"
                                >
                                  <div v-if="gas.photo_10_show.length" class="upload-container">
                                    <img
                                      :src="getUploadedUrl(gas.photo_10_show[0])"
                                      class="uploaded-image"
                                      alt="uploaded image"
                                      @click="showPreview($event ,getUploadedUrl(gas.photo_10_show[0]))"
                                    />
                                    <div class="icon-container">
                                      <span
                                        class="material-symbols-outlined preview-icon"
                                        @click="handleIconClick('preview', getUploadedUrl(gas.photo_10_show[0]), $event)"
                                      >
                                        visibility
                                      </span>
                                      <div @click="getBase64Image($event ,gas.photo_10_show[0])">
                                        <span class="material-symbols-outlined download-icon" style="color: black;">
                                          download
                                        </span>
                                      </div>
                                      <span
                                        class="material-symbols-outlined delete-icon"
                                        @click="handleClickDeletePhoto($event , 'photo_10_show' , 'gas_refills', index_car , index_gas_refills)"
                                      >
                                        delete
                                      </span>
                                    </div>
                                  </div>
                                  <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                    <p class="ant-upload-drag-icon">
                                      <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                    </p>
                                    แนบรูปภาพ
                                  </div>
                                </a-upload>
                              </div>
                            </a-form-item>
                        
                            <a-form-item name="gas1" label="8. ใบเสร็จ">
                            <a-upload
                              v-model:file-list="gas.photo_8_show"
                              list-type="picture-card"
                              class="avatar-uploader"
                              :show-upload-list="false"
                              action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                              @change="upload_files($event, 'photo_8_show' , 'gas_refills' , index_car , index_gas_refills)"
                              method="GET"
                            >
                              <div v-if="gas.photo_8_show.length" class="upload-container">
                                <img
                                  :src="getUploadedUrl(gas.photo_8_show[0])"
                                  class="uploaded-image"
                                  alt="uploaded image"
                                  @click="showPreview($event , getUploadedUrl(gas.photo_8_show[0]))"
                                />
                                <div class="icon-container">
                                  <span
                                    class="material-symbols-outlined preview-icon"
                                    @click="handleIconClick('preview', getUploadedUrl(gas.photo_8_show[0]), $event)"
                                  >
                                    visibility
                                  </span>
                                  <div @click="getBase64Image($event ,gas.photo_8_show[0])">
                                    <span class="material-symbols-outlined download-icon" style="color: black;">
                                      download
                                    </span>
                                  </div>
                                  <span
                                      class="material-symbols-outlined delete-icon"
                                      @click="handleClickDeletePhoto($event , 'photo_8_show' , 'gas_refills', index_car , index_gas_refills)"
                                    >
                                      delete
                                    </span>
                                </div>
                              </div>
                              <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                <p class="ant-upload-drag-icon">
                                  <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                </p>
                                แนบรูปภาพ
                              </div>
                            </a-upload>
                          </a-form-item>
                          </div>
                      </a-card>
                    </div>
                    <a-empty description="ยังไม่มีการเติมน้ำมันจากคนขับ" v-else />
                  </a-collapse-panel>
                </a-collapse>
              </a-collapse-panel>
            </a-collapse>

            <a-row justify="end">
              <a-form-item :span="24" style="text-align: right">
                <a-button
                  type="primary"
                  html-type="submit"
                  size="large"
                  style="margin: 4px"
                >
                  <span
                    class="material-symbols-outlined"
                    style="font-size: 16px; margin-right: 4px"
                    >edit_square</span
                  >
                  บันทึกข้อมูล
                </a-button>
              </a-form-item>
            </a-row>
          </a-form>
        </div>

        <!-- Modals -->
        <CreateCompany
          :show_create_company="show_create_company"
          :tour_types="tour_types_list"
          @onCreated="init_create_booking_options"
          @afterClose="create_company_close"
        />
        <CreateClient
          :selectedClient_id="create_booking.client_id"
          :selectedBranch_id="create_booking.client_branch_id"
          :show_create_client="show_create_client"
          :client_company_list="client_company_list"
          @onCreated="init_create_booking_options"
          @afterClose="create_client_close"
        />
        <a-modal
          v-if="previewVisible"
          :visible="previewVisible"
          footer=""
          :width="800"
          @cancel="preview_close"
        >
          <img :src="previewImage" style="width: 100%" />
        </a-modal>
      </div>

      <ExportPdf
        v-if="show_export_modal"
        :show_export_modal="show_export_modal"
        :booking_detail="booking_detail"
        :user_name="user_name"
        @afterClose="create_export_close"
      />
    </a-spin>

    <a-modal v-model:visible="showModalWarningDriver" @ok="showModalWarningDriver=false" >
      <div style="display: flex; flex-direction: column ;justify-items:center;align-items: center">
          <span class="material-symbols-outlined" style="font-size: 50px;margin-right: 4px;color:red;margin-bottom: 5px">Info</span>
          <p>รถทะเบียนหรือคนขับท่านนี้มีการมอบหมายงานในเวลานี้แล้ว</p>
          <p>โปรดเลือกรถทะเบียนหรือคนขับท่านอื่น</p>
          <a-tooltip color="white" placement="bottom">
            <template #title>
              <div style="color:black">
                <p>พนักงานขับรถ : {{  detailWarningDriver.driver_name }}</p>
                <p>บริษัทลูกค้า : {{  detailWarningDriver.client_name }}</p>
                <p>วันที่เริ่มเดินทาง : {{  detailWarningDriver.time_start }}</p>
                <p>วันที่จบงาน : {{  detailWarningDriver.time_end }}</p>
              </div>
            </template>
            <p style="padding: 5px; background-color: #888;color:white;border-radius: 5px;cursor: pointer">ดูรายละเอียดของคนขับ</p>
          </a-tooltip>
      </div>
      <template #footer>
        <a-button type="primary" @click="showModalWarningDriver = false">
          ยืนยัน
        </a-button>
      </template> 
    </a-modal>

    <a-modal v-model:visible="showModalRequireInput" @ok="showModalRequireInput=false" >
      <div style="display: flex; flex-direction: column ;justify-items:center;align-items: center">
          <span class="material-symbols-outlined" style="font-size: 50px;margin-right: 4px;color:red;margin-bottom: 5px">Info</span>
          <p v-html="errorMessages" style="text-align: center">
          </p>
      </div>
      <template #footer>
        <a-button type="primary" @click="validateAndClose">
          ตกลง
        </a-button>
      </template> 
    </a-modal>

    <a-modal v-model:visible="showModalRequireInput" @ok="showModalRequireInput=false" >
      <div style="display: flex; flex-direction: column ;justify-items:center;align-items: center">
          <span class="material-symbols-outlined" style="font-size: 50px;margin-right: 4px;color:red;margin-bottom: 5px">Info</span>
          <p v-html="errorMessages" style="text-align: center">
          </p>
      </div>
      <template #footer>
        <a-button type="primary" @click="validateAndClose">
          ตกลง
        </a-button>
      </template> 
    </a-modal>
    
    <a-modal v-model:visible="showModalDuplicate" @ok="showModalDuplicate=false" >
      <div style="display: flex; flex-direction: column ;justify-items:center;align-items: center">
          <span class="material-symbols-outlined" style="font-size: 50px;margin-right: 4px;color:red;margin-bottom: 5px">Info</span>
          <p>กรุณาแก้ทะเบียนรถ ชื่อคนขับ และวันเวลารับส่ง ก่อนสร้างใบงานซ้ำ</p>
      </div>
      <template #footer>
        <a-button type="primary" @click="showModalDuplicate=false">
          ตกลง
        </a-button>
      </template> 
    </a-modal> 
  </div>
</template>

<script>
import inputDatePicker from "../../components/input_date_picker.vue";
import CreateCompany from "../../components/modals/create_company.vue";
import CreateClient from "../../components/modals/create_client.vue";
import ExportPdf from "../../components/modals/export_modal.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import SystemService from "../../api/SystemService.js";
import BookingService from "../../api/BookingService.js";

import ClientService from "../../api/ClientService.";
import ClientContactService from "../../api/ClientContactService";
import CarService from "../../api/CarService";
import BillingService from '../../api/BillingService';

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);

import Swal from "sweetalert2";
import { message } from "ant-design-vue";

import Utility from "../../utility";

import remark_group_new from '../../components/remark_group_new.vue'
import { PlusOutlined, EyeOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons-vue';
import { E } from "@fullcalendar/resource/internal-common";
import { f } from "html2pdf.js";
import { ex } from "@fullcalendar/core/internal-common";
import CompanyService from "../../api/CompanyService";
import UserService from "../../api/UserService";


export default {
  props: {
    user_name: String,
  },
  computed: {
    progressText() {
      return (record) => {
        if (this.booking_sjinda && record.progress == 2 && record.status == 11 && record.billing_status == 0) {
          return { txt: "ใหม่", color: "green" };
        }
        if (this.booking_sjinda && record.progress == 1 && record.billing_status == 1) {
          return { txt: "กำลังดำเนินการ", color: "yellow" };
        }
        if (this.booking_sjinda && record.progress == 2 && record.billing_status == 1) {
          return { txt: "เสร็จสิ้น", color: "green" };
        }
        return this.tag_option.progress[record.progress];
      };
    },
    errorMessages() {
      let messages = '';
      if ([this.create_booking.client_id].filter((e) => e == null).length) {
        messages += 'กรุณาระบุบริษัท <br />';
      }
      if ([this.create_booking.booking_type_id].filter((e) => e == null).length) {
        messages += 'กรุณาระบุประเภทการจอง <br />';
      }
      if ([this.create_booking.booking_car_types[0]?.booking_type_id].filter((e) => e == null).length) {
        messages += 'กรุณาระบุรถที่เลือกใช้งาน <br />';
      }
      if ([this.create_booking.datetime].filter((e) => e == null).length) {
        messages += 'กรุณาระบุเวลารับ/ส่ง <br />';
      }
      if ([this.create_booking.sale_id].filter((e) => e == null).length) {
        messages += 'กรุณาระบุพนักงานบริการลูกค้า CS <br />';
      }
      return messages;
    },
    totalDriverCost() {
      return this.filteredBookingCars
        .map(({ driver_cost}) => {
        const driverCostNum = parseFloat(driver_cost);
        if (!isNaN(driverCostNum)) {
          return driverCostNum;
        } else {
          return 0;
        }
      })
      .reduce((total, amount) => total + amount, 0)
      .toLocaleString(undefined, { maximumFractionDigits: 2 });
    },
    totalRentalCost() {
      return this.filteredBookingCars
        .map(({ rental_price }) => {
        const rentalPriceNum = parseFloat(rental_price);
        if (!isNaN(rentalPriceNum)) {
          return rentalPriceNum;
        } else {
          return 0;
        }
      })
      .reduce((total, amount) => total + amount, 0)
      .toLocaleString(undefined, { maximumFractionDigits: 2 });
    },
    filteredBookingCars() {
      const selectedPlateNo = this.selected_booking[0].car.plate_no;
      if (!selectedPlateNo) {
        return [];
      }
      return this.selected_booking.filter(({ car }) => car.plate_no === selectedPlateNo);
    },
    filteredTollWays() {
      const selectedPlateNo = this.selected_booking[0].car.plate_no ;
      if (!selectedPlateNo || !this.total_car_expenses.toll_ways) {
        return []; 
      }
      return this.total_car_expenses.toll_ways
    },
    totaTollWays() {
    return this.filteredTollWays
      .filter(({ granted }) => granted == 1)
      .reduce((a, b) => a + (Number(b.amount) || 0), 0)
      .toLocaleString();
    },
    filteredGasRefills() {
      const selectedPlateNo = this.selected_booking[0].car.plate_no;
      if (!selectedPlateNo || !this.total_car_expenses.gas_refills) {
        return []; 
      }
      return this.total_car_expenses.gas_refills.filter(item => item.plate_no === selectedPlateNo);
    },
    totalGasRefills() {
    return this.filteredGasRefills
      .filter(({ granted }) => granted == 1)
      .reduce((a, b) => a + (Number(b.amount) || 0), 0)
      .toLocaleString();
    },
    filteredExpense() {
      const selectedPlateNo = this.selected_booking[0].car.plate_no;
      if (!selectedPlateNo || !this.total_car_expenses.expenses) {
        return []; 
      }
      return this.total_car_expenses.expenses.filter(item => item.plate_no === selectedPlateNo);
    },
    totalExpense() {
    return this.filteredExpense
      .filter(({ granted }) => granted == 1)
      .reduce((a, b) => a + (Number(b.amount) || 0), 0)
      .toLocaleString();
    },
    total_car_expenses() {
      const cars = this.selected_booking[0];
      const car = this.selected_booking[0];
      const tollways_list = [];
      const gas_refills_list = [];
      const expenses_list = [];

      if (car && typeof car === 'object') {
        const { toll_ways, gas_refills, expenses } = car;

        if (Array.isArray(toll_ways)) {
          toll_ways.forEach((tollway) => {
            tollway.plate_no = car.car.plate_no;
            tollways_list.push(tollway);
          });
        }

        if (Array.isArray(gas_refills)) {
          gas_refills.forEach((gas_refill) => {
            gas_refill.plate_no = car.car.plate_no;
            gas_refills_list.push(gas_refill);
          });
        }

        if (Array.isArray(expenses)) {
          expenses.forEach((expense) => {
            const {
              // expense: { name },
              booking_car_id,
              amount,
              id,
              granted,
              driver_paid,
              expense_id,
              approve_remark,
              edit_expenses_status,
              pay_method_id,
              photo,
            } = expense;
            expenses_list.push({
              id,
              booking_car_id,
              amount,
              plate_no: car.car.plate_no,
              granted,
              approve_remark,
              driver_paid,
              expense_id,
              edit_expenses_status,
              pay_method_id,
              photo,
              photoList: [],
            });
          });
        }
      }

      return {
        toll_ways: tollways_list,
        gas_refills: gas_refills_list,
        expenses: expenses_list,
      };
    },
    getDriverFullName() {
      return this.get_backup_driver_fullname_with_car(this.assign_car_form.driver_id);
    },
    get_car_seats() {
      const selectedCarGroup = this.car_group_list.find(
        (group) => group.id === this.booking_car_types.car_group_id
      );
      return selectedCarGroup ? selectedCarGroup.seats : 0;
    },
    get_start_distance() {
      if (this.assign_car_form && this.assign_car_form.logs) {
        const log = this.assign_car_form.logs.find(log => {
          const body = JSON.parse(log.body);
          return body.is_start === true;
        });
        return log ? JSON.parse(log.body).distance : 0;
      }
      return 0;
    },
      get_end_distance() {
      if (this.assign_car_form && this.assign_car_form.logs) {
        const log = this.assign_car_form.logs.find(log => {
          const body = JSON.parse(log.body);
          return body.is_start === false;
        });
        return log ? JSON.parse(log.body).distance : 0;
      }
      return 0;
    },
    filteredDrivers() {
      if (this.assign_car_form.car_id > 0) {
        const carNo = this.cars_with_repair.find(car => car.id === this.assign_car_form.car_id).car_no;
        return this.cars_with_driver.filter(driver => driver.car_no === carNo);
      }
      return [];
    },
    from_booking() {
      return this.$route.query.booking == "true";
    },
    render_type() {
      return this.$route.params?.type;
    },
    on_booking() {
      return this.$route.query.edit == "true";
    },
    filtered_cars() {
      return this.filtered_car(this.car_list);
    },
  },
  components: {
    inputDatePicker,
    CreateCompany,
    CreateClient,
    ExportPdf,
    VueGoogleAutocomplete,
    remark_group_new,
    PlusOutlined,
    EyeOutlined,
    DeleteOutlined,
    DownloadOutlined
  },
  data() {
    return {
      businessTypeId: null,
      ProgressConfirmed: false,
      edit_start_location_name: false,
      edit_end_location_name: false,
      sjinda: false,
      thanawit: false,   
      bookingUpdated: {
        booking_no: '', 
        total_cars_expense: null, 
      },
      expense_logs: [
        {
          id: 1,
          name: "อนุมัติ",
        },
        {
          id: 2,
          name: "ไม่อนุมัติ",
        },
      ],
      drawerCreate:false,
      total_car_expenses_collapse: null,
      selected_booking: null,
      search_plate_no: null,
      total_cars_expense_modal: false,
      is_edit_expense: false,
      edit_expense_index: null,
      model_form: {
                key: null // Initialize with your appropriate key
            },
      booking_sjinda: false,    
      showModalWarningDriver: false,
      showModalRequireInput:false,
      showModalDuplicate: false,
      detailWarningDriver: {
        driver_name: null,
        client_name: null,
        time_start: null,
        time_end: null,
      },
      fileList: [],


      activatedCount: 0,
      total_cars_expense_modal: false,
      selected_booking: null,

    assign_car_form: {
      default_driver_id: null,
      default_driver_enabled: false,

      driver_id: null,
      main_driver_enabled: false,

      second_driver_id: null,
      second_driver_enabled: false,

      temporary_driver: null,
      temporary_driver_enabled: false,
      toll_ways: []
    },

      editable: Utility.get_permission_editable_with_key("quotation"),
      booking_detail: null,
      show_export_modal: false,
      datetime: null,
      price_percent: 0,

      search_input: null,
      search_booking_type_id: null,
      person_qty: 1,
      flight_pick: null,
      flight_drop: null,
      full_day_amount: 0,
      total_days: 0,
      discount: 0,
      booking_list: [],
      pagination: {
        total: 0,
        onpage: 1,
        perpage: 20,
      },
      create_photo: [],
      search_progress: null,
      selected_quotation: null,
      loading: false,
      tag_option: {
        booking_type_id: {
          1: { txt: "เที่ยวเดียว (ขาเดียว)", color: "pink" },
          2: { txt: "ไป-กลับ", color: "red" },
          3: { txt: "รายวัน (เต็มวัน)", color: "orange" },
          4: { txt: "รายวัน (ครึ่งวัน)", color: "green" },
          5: { txt: "เฉพาะวัน (ส่ง-รับ วันกลางไม่ใช้รถ)", color: "cyan" },
          6: { txt: "เฉพาะวัน (ระบุวัน)", color: "blue" },
          7: { txt: "ใช้รถทุกวัน", color: "purple" },
          8: { txt: "รายเดือน", color: "green" },
        },
        progress: {
          0: { txt: "ใหม่", color: "green" },
          1: { txt: "กำลังดำเนินการ", color: "yellow" },
          2: { txt: "เสร็จสิ้น", color: "green" },
          3: { txt: "ยกเลิก", color: "red" },
        },
        booking_status: {
          0: { txt: "ใหม่", color: "green" },
          1: { txt: "กำลังดำเนินการ", color: "yellow" },
          2: { txt: "เสร็จสิ้น", color: "green" },
          3: { txt: "ยกเลิก", color: "red" },
        },
        stop_type: {
          1: { txt: "จุดรับ", color: "green" },
          2: { txt: "จุดรับย่อย", color: "blue" },
          3: { txt: "จุดแวะ", color: "blue" },
          4: { txt: "จุดแวะขาไป", color: "red" },
          5: { txt: "จุดหมายหลัก", color: "red" },
          6: { txt: "จุดส่ง", color: "green" },
          7: { txt: "จุดส่งย่อย", color: "blue" },
          8: { txt: "จุดแวะขากลับ", color: "blue" },
          9: { txt: "จุดเติมน้ำมัน", color: "red" },
        },
        pickup_status: {
          0: { txt: "รอรับ", color: "yellow" },
          1: { txt: "รับแล้ว", color: "green" },
          2: { txt: "ไม่รับของ", color: "red" },
        },
      },
      on_edit_stop: null,
      create_booking: {
        onsite_photo_show: [],
        security_photo_show: [],
        service_photo_show: [],
        cleaning_photo_show: [],
        assist_driver_photo_show: [],

        gas1_show: [],
        gas2_show: [],
        gas3_show: [],
        gas4_show: [],
        gas5_show: [],
        gas5_show: [],
        gas6_show: [],

        booking_car_types: [],
        program: [],
        map: [],
        booking_cars: [],
        expenses: [],
        booking_files: [],
        map: [],
        booking_services_files: [],
        stops: [],
        client_contact_id: null,
        client_contact: {
          firstname: null,
        },
        client_branch_id: null,
        inc_vat: false,
        withholding: false,
        selected_remarks_list: [],

        temporary_coordinator: null,
        temporary_coordinator_phone_no: null,
        photo_expenses_1:[],
        photo_expenses_2:[],
        photo_expenses_3:[],
      },
      activeKey: [],
      activeKey: [],
      activeSubKeys: null,
      activeSubKeys2: null,
      remarks_list: [],

      /* part 1 */
      tour_types_list: [],
      client_company_list: [],
      client_branches_list: [],
      client_contact_list: [],
      booking_types: [],
      form: {},

      /* part 2 */
      company_trip_list: [],
      tour_list: [],

      /* part 3 */
      show_add_booking_car_types: false,
      booking_car_types: {
        expenses: [],
      },
      car_list: [],

      /* part 4 */
      show_add_booking_extra: false,

      //Modal
      show_create_company: false,
      show_create_client: false,

      /* Drawer */
      car_type_list: [],
      car_group_list: [],
      expense_list: [],
      driver_list: [],
      places: [],
      gas_station_list: [],
      pay_method_list: [],
      extra_list: [],
      drivers_and_cars: [],

      /* Sub Drawer */
      show_add_expense: false,
      add_expense: {
        name: null,
        expense_id: null,
        price: null,
        amount: null,
      },
      show_edit_expense: false,
      edit_expense: {
        name: null,
        expense_id: null,
        price: null,
        amount: null,
        id: null,
        index: null,
      },
      extra: {
        name: null,
        extra_id: null,
        price: null,
        amount: null,
      },
      preselect_car_type_id: null,
      selected_car_type_id: null,
      add_car_drawer: false,

      assign_car_drawer: false,
      booking_car_types: {
        expenses: [],
      },
      car_list: [],

      stop_type_list: [],
      car_status_list: [],
      sales_list: [],
      search_car: null,
      add_stop: {},
      guide_list: [],
      assign_car_form: {},
      add_guide_drawer: false,
      create_guide: {},
      add_gas_refill_drawer: false,
      create_gas_refill: {
        bill_photo:[]
      },
      bill_photo_show_source:'',
      show_create_toll_way: false,
      create_toll_way: {},

      show_create_mileage: false,
      create_mileage: {},

      show_create_booking_car_expense: false,
      create_booking_car_expense: {
        fileList: [],
      },

      previewImage: "",
      previewVisible: false,
      previewTitle: '',
      uploadAction: 'https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6', // Replace with your S3 upload URL

      gas: {
        photo_1_show: [],
        photo_2_show: [],
        photo_3_show: [],
        photo_4_show: [],
        photo_5_show: [],
        photo_6_show: [],
        photo_7_show: [],
        photo_8_show: [],
        photo_9_show: [],
        photo_10_show: [],
        bill_photo_show: [],
        indexGas:null,
        orderPos:null,
      },
      car: {
        service: {
          photo_1_show: [],
          photo_2_show: [],
          photo_3_show: [],
          photo_4_show: [],
          photo_5_show: [],
          photo_6_show: [],
          photo_7_show: [],
          photo_8_show: [],
          photo_9_show: [],
          photo_10_show: [],
          photo_11_show: [],
          photo_12_show: [],
        },
      },
      tollWay: {
        photo_1_show:[]
      },

      create_pickup_item: {},
      show_create_pickup_item: false,

      driver_costs_list: [],
      booking_status_list: [],
      car_company_list: [],
      show_total_seats: 0,
      statusCarTypes : {
        total: 0,
        has_event: 0,
        no_event: 0,
      },
      list_id_remark:[],

      remark_types_all: [],
      remark_types: {},
      remark_detail_all: [],
      remark_detail: [],
      remark_selected: "",
      remark_select: [],

      

      // current data for compare before save
      // to get update time

      currentClientId: null,
      currentBookingTypeId: null, 
      currentTimeStart: null,
      currentTimeEnd: null,
      currentFullDayAmount: null,
      currentStartLocationName: null,
      currentEndLocationName: null,
      currentPersonQty: null,
      currentCarQty: null,
      currentDriverCost: null,
      currentNetIncome: null,

      //json for BookingUpdated
      bookingUpdated: {
        booking_no: '', 
        time_start: null, 
        time_end: null, 
        total_days: null, 
        start_location_name: null, 
        end_location_name: null, 
        booking_type: null, 
        status: null, 
        booking_cars_status: null, 
        booking_car_types: null, 
        car_late_amount: null, 
        billing_status: null, 
        client_name: null, 
        person_qty: null, 
        net_price: null, 
        total_cars_expense: null, 
        profit: null, 
        driverCost: null, 
      },

    
    };
  },
  
  methods: {
    async get_company() {
    try {
        const company = await UserService.get_user_profile();
        const businessType = await CompanyService.getBusinessId(company.company_id);

        if (businessType.data === 1||(company.company_id !== 39 && businessType.data === null)) {
            this.thanawit = true;
        } 
        
        if (company.company_id === 39 || businessType.data === 2) {
            this.sjinda = true;
        }

    } catch (error) {
        console.error("Error fetching company profile or business type:", error);
    }
},
    reMoveDriverId(){
      const booking_car_id = this.assign_car_form.booking_car_id;
      SystemService.update_all("BookingCar", { data: {driver_id: 0} }, booking_car_id)
    },
    editStartLoaction() {
      this.edit_start_location_name = true
    },
    editEndLoaction() {
      this.edit_end_location_name = true
    },
    cancelStartLoaction() {
      this.edit_start_location_name = false
      this.refresh_data();
    },
    cancelEndLoaction() {
      this.edit_end_location_name = false
      this.refresh_data();
    },
    validateTimeRange(rule, value, callback) {
      const time_start = dayjs(this.assign_car_form.datetime[0]).format("YYYY-MM-DD HH:mm:ss")
      const time_end = dayjs(this.assign_car_form.datetime[1]).format("YYYY-MM-DD HH:mm:ss")
      const selectedTime = dayjs(new Date(value)).format("YYYY-MM-DD HH:mm:ss");
      if (selectedTime >= time_start && selectedTime <= time_end) {
        callback();
      } else {
        callback(new Error('เวลาที่เลือกต้องอยู่ในช่วงเวลาที่กำหนด'));
      }
    },
    submit_create_toll_way() {
      const e = this.create_toll_way;
      const { id } = e;
      e.created_at = e.datetime.format("YYYY-MM-DD HH:mm:ss");
      const datetime = e.datetime
      delete e.datetime;
      delete e.plate_no;
      delete e.modelname;
      let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      this.bookingUpdated["total_cars_expense"] = currentTime
      BookingService.booking_update_timestamp(this.bookingUpdated)
      if (id) {
        SystemService.update_all("TollWay", { data: e }, id).then(() => {
          SystemService.update_all("TollWay", { data: {edit_toll_ways_status: 1} }, id).then(() => {
            message.success("อัพเดทข้อมูลสำเร็จ");
            e.datetime = datetime
            this.show_create_toll_way = false;
            this.create_toll_way = {};
            this.model_form.photo_1_show = null
            this.refresh_total_cars_expense_modal();
              if (e.edit_toll_ways_status == 1 ){
              this.newTollWay = true;
              }
            this.showWarningTollWays = true;
          });
        });
      } else {
        if(!this.assign_car_form.toll_ways){
          this.assign_car_form.toll_ways = []
        }
        if(this.drawerCreate){
          e.booking_car_id = this.selected_booking[0].id;
          SystemService.create_all("TollWay", { data: [e] }).then((result) => {
            message.success("เพิ่มข้อมูลสำเร็จ");
            this.create_toll_way = {};
            this.model_form.photo_1_show = null
            this.refresh_total_cars_expense_modal();
            result.data.datetime = datetime
            this.assign_car_form.toll_ways.push(result.data)
          });
        }else if(!e.index && e.index != 0){
          this.model_form.photo_1_show = null
          e.datetime = datetime
          this.assign_car_form.toll_ways.push(e)
        }
        e.datetime = datetime
        this.show_create_toll_way = false;

      }
      if(this.total_cars_expense_modal){
        this.total_cars_expense_modal = false;
        setTimeout(() => {
          this.total_cars_expense_modal = true;
        }, 1000);
      }
    },
    remove_expense_log(model_id, type) {
      const old_active_key = this.total_car_expenses_collapse;
      let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      this.bookingUpdated["total_cars_expense"] = currentTime
      BookingService.booking_update_timestamp(this.bookingUpdated)
      switch (type) {
        case "toll_ways":
          SystemService.delete_all("TollWay", model_id).then(() => {
            message.success("ลบข้อมูลสำเร็จ");
            this.total_car_expenses_collapse = null;
            this.total_car_expenses.toll_ways.splice(
              this.total_car_expenses.toll_ways.findIndex(({ id }) => id == model_id),
              1
            );
            this.selected_booking.forEach((car) => {
              car.toll_ways = car.toll_ways?.filter(({ id }) => id != model_id);
            });
            this.total_car_expenses_collapse = old_active_key;
          });
          break;
        case "gas_refills":
          SystemService.delete_all("GasRefill", model_id).then(() => {
            message.success("ลบข้อมูลสำเร็จ");
            this.total_car_expenses_collapse = null;
            this.total_car_expenses.gas_refills.splice(
              this.total_car_expenses.gas_refills.findIndex(({ id }) => id == model_id),
              1
            );
            this.selected_booking.forEach((car) => {
              car.gas_refills = car.gas_refills?.filter(({ id }) => id != model_id);
            });
            this.total_car_expenses_collapse = old_active_key;
          });
          break;
        case "expenses":
          SystemService.delete_all("BookingCarExpense", model_id).then(() => {
            message.success("ลบข้อมูลสำเร็จ");
            this.total_car_expenses_collapse = null;
            this.total_car_expenses.expenses.splice(
              this.total_car_expenses.expenses.findIndex(({ id }) => id == model_id),
              1
            );
            this.selected_booking.forEach((car) => {
              car.expenses = car.expenses?.filter(({ id }) => id != model_id);
            });
            this.total_car_expenses_collapse = old_active_key;
          });
          break;
      }
      this.refresh_total_cars_expense_modal();
    },
    edit_expense_log(data, type) {
      switch (type) {
        case "toll_ways":
          this.show_create_toll_way = true;
          this.create_toll_way = data;
          this.create_toll_way.datetime = dayjs(data.created_at, "YYYY-MM-DD HH:mm:ss");
          break;
        case "gas_refills":
          for (const [key, value] of Object.entries(data)) {
            if(key == 'bill_photo' && value != null){
              this.bill_photo_show_source = value
            }else if(key == 'bill_photo' && value == null){
              this.bill_photo_show_source = ''
            }
          }
          this.add_gas_refill_drawer = true;
          this.create_gas_refill = data;
          this.create_gas_refill.datetime = dayjs(data.created_at, "YYYY-MM-DD HH:mm:ss");
          this.create_gas_refill.use_gas_station = data.gas_station_id != null;
          break;
        case "expenses":
          this.show_create_booking_car_expense = true;
          this.create_booking_car_expense = data;
          this.create_booking_car_expense.datetime = dayjs(data.created_at, "YYYY-MM-DD HH:mm:ss");
          break;
      }
    },
    refreshPage() {
      window.location.reload();
    },
    async update_granted({ id, approve_remark }, type, value, list) {
      try {
        const result = {
          approve_remark,
          granted: value,
        };
        if (value == 2) {
          await Swal.fire({
            title: "กรุณาระบุเหตุผลที่ไม่อนุมัติ",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            showLoaderOnConfirm: true,
            preConfirm: (value) => {
              return value;
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then(({ isConfirmed, value }) => {
            // console.log({ isConfirmed, value, type });
            if (value && isConfirmed) {
              if (result.approve_remark?.includes("ไม่อนุมัติ")) {
                result.approve_remark = result.approve_remark.split(" (ไม่อนุมัติ")[0];
                result.approve_remark = `${result.approve_remark} (ไม่อนุมัติ: ${value})`;
              } else {
                result.approve_remark = `${
                  result.approve_remark ? result.approve_remark : ""
                } (ไม่อนุมัติ: ${value})`;
              }

              list.forEach((item) => {
                if (item.id === id) {
                  item.approve_remark = result.approve_remark;
                }
              });
            } else {
              list.forEach((item) => {
                if (item.id === id) {
                  item.granted = null;
                }
              });
              throw "ยกเลิกการอัพเดท";
            }
          });
        }

        switch (type) {
          case "toll_ways":
            SystemService.update_all("TollWay", { data: result }, id).then(() => {
              this.refresh_total_cars_expense_modal();
              message.success("อัพเดทข้อมูลสำเร็จ");
            });
            break;
          case "gas_refills":
            SystemService.update_all("GasRefill", { data: result }, id).then(() => {
              this.refresh_total_cars_expense_modal();
              message.success("อัพเดทข้อมูลสำเร็จ");
            });
            break;
          case "expenses":
            SystemService.update_all("BookingCarExpense", { data: result }, id).then(
              () => {
                this.refresh_total_cars_expense_modal();
                message.success("อัพเดทข้อมูลสำเร็จ");
              }
            );
            break;
        }
            let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
            this.bookingUpdated["total_cars_expense"] = currentTime
            BookingService.booking_update_timestamp(this.bookingUpdated)
      } catch (err) {
        console.log(err);
      }
    },
    async refresh_total_cars_expense_modal() {
      message.loading({
        content: "กำลังโหลดข้อมูลใหม่...",
        key: "refresh_total_cars_expense_modal",
      });
      await this.init_all_bookings();
      message.success({
        content: "โหลดข้อมูลเรียบร้อยแล้ว",
        key: "refresh_total_cars_expense_modal",
      });
    },
    async show_total_cars_expense(obj,id) {
      this.drawerCreate = true
      this.assign_car_form = obj;
      this.total_cars_expense_modal = true;
      this.selected_booking = this.create_booking.booking_cars.filter(car => car.id == id);
    },
    allIsApproved(record) {
      if (record.toll_ways.length === 0 && record.gas_refills.length === 0 && record.expenses.length === 0) {
        if(record.driver_cost != "0.00" || record.rental_price != null){
          return true
        }
        return false
      }
      const anyTollwaysWaiting = record.toll_ways.some(item => item.granted === null)
      const anyGasRefillsWaiting = record.gas_refills.some(item => item.granted === null)
      const anyExpensesWaiting = record.expenses.some(item => item.granted === null)

      return !(anyTollwaysWaiting || anyGasRefillsWaiting || anyExpensesWaiting);
    },
    sum_car_expense(record) {
      const tollWaysSum = record.toll_ways.reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
      const gasRefillsSum = record.gas_refills.reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
      const expensesSum = record.expenses.reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
      const driverCost = parseFloat(record.driver_cost || 0);
      const rentalPrice = parseFloat(record.rental_price || 0);

      return tollWaysSum + gasRefillsSum + expensesSum + driverCost + rentalPrice;
    },
    async validate_required(_rule, value, message) {
      return new Promise((resolve, reject) => {
        if (_rule.required && !value) {
          reject(message || 'โปรดระบุข้อมูล');
        } else {
          resolve();
        }
      });
    },
    // async validateTax(rule, value, message) {
    //   if (!this.create_booking.inc_vat && !this.create_booking.withholding) {
    //     return Promise.reject(message);
    //   } else {
    //     return Promise.resolve();
    //   }
    // },
    handleAddBookingExpense(){
      this.show_create_booking_car_expense = true
      this.create_booking_car_expense = {
        fileList: [],
      }

    },
    get_booking_type_name(id) {
      const type = this.booking_types.find(type => type.id === id);
      return type ? type.name : '';
    },
    get_trip_name(id) {
      const trip = this.company_trip_list.find(trip => trip.id === id);
      return trip ? trip.name : '';
    },
    async check_driver_is_booked(){
      const time_start = dayjs(this.assign_car_form.datetime[0]).format("YYYY-MM-DD HH:mm:ss")
      const time_end = dayjs(this.assign_car_form.datetime[1]).format("YYYY-MM-DD HH:mm:ss")
      const driver_id = this.assign_car_form.driver_id

      let result = await CarService.getBookingDetailsByDriver(driver_id, time_start, time_end);
      result = result.data
      if(result.data == ""){
        this.showModalWarningDriver = false;
      }else{
        this.detailWarningDriver.driver_name = result.driver.driver_name ?? "-"
        this.detailWarningDriver.time_start = dayjs(result.time_start).format("DD/MM/YYYY HH:mm")
        this.detailWarningDriver.time_end = dayjs(result.time_end).format("DD/MM/YYYY HH:mm")
        this.detailWarningDriver.client_name = result.booking.client_name ?? "-"
        this.showModalWarningDriver = true;
      }
    },
    async init_remark(){
      this.remark_detail_all = await BookingService.get_remarks();
      this.remark_types_all = await SystemService.get_all('BookingRemarkType')
      this.remark_types_all = this.remark_types_all.filter((item) => item.flag_check == 'active')
      
      if(this.render_type == "add"){ //เพิ่มข้อมูลใหม่
        this.remark_detail = this.remark_detail_all.filter((item) => item.is_default == 1 && item.remark_type != null)
        this.remark_select = this.remark_detail_all.filter((item) => item.is_default == 0 && item.remark_type != null)
      }else{ //แก้ไขข้อมูล
        if (this.create_booking.list_id_remark) { //มีข้อมูลเดิม
          this.remark_detail = JSON.parse(this.create_booking.list_id_remark);
          this.remark_detail_all = this.remark_detail_all.filter((item) => item.remark_type != null)

          let select = []
          this.remark_detail_all.forEach((e) => {
            const check_if = this.remark_detail.find((item) => item.id == e.id)
            //เพิ่มข้อมูลใน remark_select ที่ไม่มีใน remark_detail
            if(check_if == undefined){
              select.push(e)
            }
          });
          this.remark_select = select
        } else { //ไม่มีข้อมูลเดิม
          this.remark_detail = []
          this.remark_select = this.remark_detail_all.filter((item) =>  item.remark_type != null)
        }
      }
    },
    filter_remark(remark_type_id){
      const result = this.remark_detail.filter((item) => item.remark_type == remark_type_id)
      return result
    },  
    add_remark(id){
      this.remark_detail.push(this.remark_select.find((item) => item.id == id))
      this.remark_select = this.remark_select.filter((item) => item.id != id)
    },
    remove_remark(id){
      this.remark_select.push(this.remark_detail.find((item) => item.id == id))
      this.remark_detail = this.remark_detail.filter((item) => item.id != id)
    },
    move_up(id){
      const index = this.remark_detail.findIndex((item) => item.id == id)
      if(index > 0){
        const temp = this.remark_detail[index]
        this.remark_detail[index] = this.remark_detail[index - 1]
        this.remark_detail[index - 1] = temp
      }
    },
    move_down(id){
      const index = this.remark_detail.findIndex((item) => item.id == id)
      if(index < this.remark_detail.length - 1){
        const temp = this.remark_detail[index]
        this.remark_detail[index] = this.remark_detail[index + 1]
        this.remark_detail[index + 1] = temp
      }
    },
    filterOption(input, option) {
      return (
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        option.description.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    activate_car(record) {
      const { id, booking_id } = record;
      const bookingCar = this.create_booking.booking_cars;
      const currentTimestamp = dayjs().format("YYYY-MM-DD HH:mm:ss");
        if (id) {
            SystemService.update_all("BookingCar", { data: { active_car: true, status: 11, active_car_timestamp: currentTimestamp } }, id).then(
                async (res) => {
                    if (res) {
                        this.refresh_data();
                        Swal.fire("แจ้งงานสำเร็จ!", "ระบบได้แจ้งงานสำเร็จแล้ว", "success");
                        this.activatedCount += 1;
                        if (this.booking_sjinda) {
                          SystemService.update_all("Booking", { data: { progress: 2 } }, booking_id)
                        }
                        SystemService.update_all("Booking", { data: { status: 11 } }, booking_id).then(
                            (res) => {
                                if (res) {
                                    if (this.activatedCount === bookingCar.length) {
                                        SystemService.update_all("Booking", { data: { active_job: true } }, booking_id).then(
                                            (res) => {
                                                if (res) {
                                                    this.refresh_data();
                                                    Swal.fire("แจ้งงานสำเร็จ!", "ระบบได้แจ้งงานสำเร็จแล้ว", "success");
                                                }
                                            }
                                        );
                                    }
                                }
                            }
                        );
                    }
                }
            );
        } else {
            record.active_car = true;
            this.activatedCount += 1;
        }
        
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.onpage = pagination.current;
      this.pagination.perpage = pagination.pageSize;
      this.init_all_bookings();
    },
    cal_total_seats() {
      this.getStatusCarTypes();
      var { car_type_id, car_group_id } = this.booking_car_types;
      if (car_type_id && car_group_id) {
        if (
          !this.car_group_list
            .filter(
              ({ car_type_id }) => car_type_id == this.booking_car_types.car_type_id
            )
            .map(({ id }) => id)
            .includes(car_group_id)
        ) {
          this.booking_car_types.car_group_id = this.car_group_list.filter(
            ({ car_type_id }) => car_type_id == this.booking_car_types.car_type_id
          )[0].id;
          car_group_id = this.booking_car_types.car_group_id;

          const car = this.car_group_list
            .filter((car) => car.car_type_id == car_type_id)
            .filter(({ id }) => id == car_group_id)[0];
          if (car) {
            this.show_total_seats = (car.seats ? car.seats : 0) * 1;
          } else {
            this.show_total_seats = 0;
          }
        } else {
          const car = this.car_group_list
            .filter((car) => car.car_type_id == car_type_id)
            .filter(({ id }) => id == car_group_id)[0];
          if (car) {
            this.show_total_seats = (car.seats ? car.seats : 0) * 1;
          } else {
            this.show_total_seats = 0;
          }
        }
      } else {
        this.show_total_seats = 0;
      }

      if (this.show_total_seats) {
        if (this.booking_car_types.remark.includes("ที่นั่ง")) {
          this.booking_car_types.remark = this.booking_car_types.remark.replace(
            /ค่าขนส่งโดยสารปรับอากาศ\s\d+\sที่นั่ง/,
            `ค่าขนส่งโดยสารปรับอากาศ ${this.show_total_seats} ที่นั่ง`
          );
        } else {
          this.booking_car_types.remark = this.booking_car_types.remark.replace(
            "ค่าขนส่งโดยสารปรับอากาศ",
            `ค่าขนส่งโดยสารปรับอากาศ ${this.show_total_seats} ที่นั่ง`
          );
        }
      }
    },
    calculate_driver_cost() {
        if (
          this.assign_car_form.driver_cost_as_percent &&
          this.assign_car_form.driver_cost_percent
        ) {
          const cost_target = this.driver_costs_list.filter(
            ({ booking_type_id }) => booking_type_id == this.create_booking.booking_type_id
          )[0];
          this.assign_car_form.driver_cost =
            (this.assign_car_form.rental_price * this.assign_car_form.driver_cost_percent) /
            100;
        }
      },
    price_formula() {
      var {
        extras,
        booking_car_types,
        discount,
        booking_cars,
        inc_vat,
        withholding,
        car_company,
      } = this.create_booking;
      if (!discount) discount = 0;
      const sum_car_type = 
      Number(
        booking_car_types.map(({ price, quantity, expenses }) =>
              expenses.map(({ amount, price }) => amount * price).reduce((a, b) => a + b, 0)
              + Number(price * quantity)
          )
          .reduce((a, b) => a + b, 0)
      );
      const revenue =
      Number(
        extras.map(({ amount, price }) => amount * price).reduce((a, b) => a + b , 0)
      ) + sum_car_type;
      const total_discounted = revenue - Number(discount);
      const cost_sum =
        Number(
          booking_cars
            .map(({ driver_cost, rental_price }) => {
              const driverCost = Number(driver_cost);
              const rentalPrice = Number(rental_price);
              return (
                (isNaN(driverCost) ? 0 : driverCost) +
                (isNaN(rentalPrice) ? 0 : rentalPrice)
              );
            })
            .reduce((a, b) => a + b, 0)
        ) 
        // +
        // booking_cars
        //   .map(({ toll_ways, gas_refills, expenses }) => {
        //     return (
        //       toll_ways
        //         .filter(({ granted }) => granted == 1)
        //         .reduce((a, b) => a + (Number(b.amount) || 0), 0) +
        //       gas_refills
        //         .filter(({ granted }) => granted == 1)
        //         .reduce((a, b) => a + (Number(b.amount) || 0), 0) +
        //       expenses
        //         .filter(({ granted }) => granted == 1)
        //         .reduce((a, b) => a + (Number(b.amount) || 0), 0)
        //     );
        //   })
        //   .reduce((a, b) => a + b, 0);
      Number(
        booking_cars
          .map(({ driver_cost, rental_price }) => driver_cost + rental_price)
          .reduce((a, b) => a + b, 0)
      ) +
      booking_cars
        .map(({ toll_ways, gas_refills, expenses }) => {
          return (
            toll_ways
              .filter(({ granted }) => granted == 1)
              .reduce((a, b) => a + (Number(b.amount) || 0), 0) +
            gas_refills
              .filter(({ granted }) => granted == 1)
              .reduce((a, b) => a + (Number(b.amount) || 0), 0) +
            expenses
              .filter(({ granted }) => granted == 1)
              .reduce((a, b) => a + (Number(b.amount) || 0), 0)
          );
        })
        .reduce((a, b) => a + (Number(b.amount) || 0), 0);
      const sum_withholding = withholding
        ? Math.round((sum_car_type * this.withholding_type(car_company)))
        : 0;
      const vat = inc_vat ? Math.round((sum_car_type * 0.07)) : 0;
      const profit = total_discounted - sum_withholding + vat - 
      booking_cars
        .map(({ toll_ways, gas_refills, expenses, driver_cost, rental_price }) => {
          const tollWaysSum = Array.isArray(toll_ways)
            ? toll_ways.reduce((a, b) => a + (Number(b.amount) || 0), 0)
            : 0;
          const gasRefillsSum = Array.isArray(gas_refills)
            ? gas_refills.reduce((a, b) => a + (Number(b.amount) || 0), 0)
            : 0;
          const expensesSum = Array.isArray(expenses)
            ? expenses.reduce((a, b) => a + (Number(b.amount) || 0), 0)
            : 0;
          const driverCost = Number(driver_cost) || 0;
          const rentalPrice = Number(rental_price) || 0;
          return tollWaysSum + gasRefillsSum + expensesSum + driverCost + rentalPrice;
        })
        .reduce((a, b) => a + b, 0)
      return {
        revenue: this.formatPrice(revenue),
        discount: this.formatPrice(discount),
        total_discounted: this.formatPrice(total_discounted),
        cost_sum: this.formatPrice(cost_sum),
        withholding: this.formatPrice(sum_withholding),
        vat: this.formatPrice(vat),
        // profit: this.formatPrice((total_discounted - sum_withholding + vat) - cost_sum),
        profit: this.formatPrice(profit),
        revenue_net: this.formatPrice(total_discounted - sum_withholding + vat),
      };
    },
    selectClientContact(v) {
      this.create_booking.client_contact = this.client_contact_list.find(
        ({ id }) => id == v
      );
      this.create_booking.client_contact_id = v;
    },
    confirm_booking_car(record) {
      const { id } = record;
      if (id) {
        SystemService.update_all("BookingCar", { data: { is_confirm: true } }, id).then(
          async (res) => {
            if (res) {
              this.refresh_data();
              Swal.fire("ยืนยันรถสำเร็จ!", "ระบบได้ยืนยันรถสำเร็จแล้ว", "success");
            }
          }
        );
      } else {
        record.is_confirm = true;
      }
    },
    handleUpload(e, key) {
      const file = e.file.originFileObj;
      const vue = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        vue[key].photo = reader.result;
      };
    },
    formatPrice(price = 0) {
      return Number(price).toLocaleString() + " บาท";
    },
    withholding_type(car_company, type) {
      if (car_company) {
        const { withholding_id } = car_company;
        if (withholding_id == 1) {
          return type == "text" ? `(1%)` : 0.01;
        } else if (withholding_id == 2) {
          return type == "text" ? `(5%)` : 0.05;
        } else {
          return type == "text" ? `(3%)` : 0.03;
        }
      }
    },
    sum_car_price({ cost, expenses, inc_vat, withholding }, type) {
      var total =
        Number(cost) +
        Number(
          expenses.map(({ amount, price }) => amount * price).reduce((a, b) => a + b, 0)
        );
      const vat = inc_vat ? total * 0.07 : 0;
      const withholding_type = this.withholding_type(
        this.create_booking.car_company,
        "number"
      );
      const with_holding = withholding ? total * withholding_type : 0;

      const result = {
        total: total + vat - with_holding,
        vat,
        withholding: with_holding,
      };

      return Math.round(result[type]);
    },
    init_gas_refill() {
      this.add_gas_refill_drawer = true;
      this.create_gas_refill.use_gas_station = this.create_gas_refill.gas_station_id
        ? true
        : false;
      this.gas.bill_photo_show = [];
      this.gas.indexGas = null;
      this.gas.orderPos = null;
      this.model_form.bill_photo_show = null;
      this.bill_photo_show_source = '';
    },
    init_toll_way() {
      this.show_create_toll_way = false; 
      this.create_toll_way = {};
      this.$nextTick(() => {
        this.show_create_toll_way = true;
      });
    },
    init_gas_refill_edit() {
      this.add_gas_refill_drawer = true;
      this.create_gas_refill.use_gas_station = this.create_gas_refill.gas_station_id
        ? true
        : false;
    },
    show_message(text, type = "success") {
      message[type](text);
    },
    filterOption(input, option) {
      return option.label.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    },
    filtered_car(array) {
      const searchTerm = this.search_car;
      var result;
      if (!searchTerm) {
        if (this.assign_car_form && this.assign_car_form.car_id) {
          result = array.filter(({ id }) =>
            id == this.assign_car_form.car_id
              ? true
              : !this.create_booking.booking_cars.map(({ car_id }) => car_id).includes(id)
          );
        } else {
          result = array.filter(
            ({ id }) =>
              !this.create_booking.booking_cars.map(({ car_id }) => car_id).includes(id)
          );
        }
      } else {
        result = array
          .filter((item) => {
            return Object.keys(item).some((key) => {
              if (key == "driver" && item[key]) {
                return (
                  String(item[key].firstname)
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                  String(item[key].lastname)
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                );
              } else {
                return String(item[key]).toLowerCase().includes(searchTerm.toLowerCase());
              }
            });
          })
          .filter(
            ({ id }) =>
              !this.create_booking.booking_cars.map(({ car_id }) => car_id).includes(id)
          );
      }
      
      result = result.map((e) => {
        const { car_group, car_status_id } = e;
        e.class =
        car_group?.car_type_id === this.preselect_car_type_id
        ? car_status_id === 1 || car_status_id === 7
        ? "1"
        : "3"
              : "2";
              // console.log('car_type_id:', car_group?.car_type_id, 'preselect_car_type_id:', this.preselect_car_type_id);
              // console.log('class set to:', e.class);
              return e;
            }).filter(e => e.class === "1" || e.class === "3");
            result = result.sort((a, b) => {
              return a.class - b.class;
            });
      result = result.map((e) => {
        delete e.class;
        return e;
      });
      return result;
    },
    async init_create_booking() {
      this.create_booking = {
        onsite_photo_show: [],
        security_photo_show: [],
        service_photo_show: [],
        cleaning_photo_show: [],
        assist_driver_photo_show: [],

        gas1_show: [],
        gas2_show: [],
        gas3_show: [],
        gas4_show: [],
        gas5_show: [],
        gas6_show: [],

        booking_car_types: [],
        booking_cars: [],
        extras: [],
        booking_files: [],
        program: [],
        map: [],
        booking_services_files: [],
        stops: [],
        start_location_name: null,
        end_location_name: null,
        client_contact_id: null,
        client_contact: {
          firstname: null,
        },
        client_branch_id: null,
        sale_id:
          localStorage.getItem("role") == "Sale"
            ? JSON.parse(localStorage.getItem("user_profile")).id
            : null,
        photo_expenses_1:[],
        photo_expenses_2:[],
        photo_expenses_3:[],
      };
    },
    get_total_seats(index) {
      return `${index + 1} / ${this.create_booking.booking_cars
        .map(({ passenger }) => (passenger ? passenger : 0))
        .reduce((a, b) => a + b, 0)}`;
    },
    get_total_car(index) {
      return `${index + 1} / ${this.create_booking.booking_car_types
        .map(({ quantity }) => (quantity ? quantity : 0))
        .reduce((a, b) => a + b, 0)}`; 
    },
    render_log(log) {
      return log.map(({ model_name, created_at }) => {
        return `${dayjs(created_at, "YYYY-MM-DD HH:mm:ss").format(
          "DD/MM/YYYY HH:mm"
        )}: ${model_name}`;
      });
    },
    upto_log_date(id, amount) {
      SystemService.update_all("BookingCar", { data: { last_update: amount } }, id);
    },
    get_driver_fullname(id) {
      const target = this.driver_list.find(({ id: driver_id }) => driver_id === id);
      return target ? `${target.firstname} ${target.lastname}` : "";
    },
    get_driver_fullname_with_car(id) {
      const target = this.filteredDrivers.find(({ id: driver_id }) => driver_id === id);
      return target ? `${target.firstname} ${target.lastname}` : "";
    },
    get_backup_driver_fullname_with_car(id) {
      const target = this.cars_with_backup_driver.find(({ id: driver_id }) => driver_id === id);
      return target ? `${target.firstname} ${target.lastname}` : "";
    },
    edit_list_in_table(obj, instance, drawer, index) {
      obj.index = index;
      obj.create
      this[instance] = obj;
      this[drawer] = true;
      if(instance == "create_gas_refill"){
        this.create_gas_refill.datetime = dayjs(this.create_gas_refill.created_at)
        this.gas.bill_photo_show = obj.bill_photo_show
        if(obj.bill_photo != null){
          this.bill_photo_show_source = obj.bill_photo
        }else if(obj.bill_photo_show.length){
          this.bill_photo_show_source = obj.bill_photo_show[0].url
        }else{
          this.bill_photo_show_source = ''
        }
        this.gas.indexGas = obj.index
        if(this.create_booking.booking_cars.find(oc=>oc.id == obj.booking_car_id)){
          this.gas.orderPos = this.create_booking.booking_cars.find(oc=>oc.id == obj.booking_car_id).order_pos-1
        }else{
          this.gas.orderPos = 0
        }
      }else if( instance == "create_toll_way"){
        this.create_toll_way.datetime = dayjs(this.create_toll_way.created_at)
      }else if( instance == "create_mileage"){
        this.create_mileage.datetime = dayjs(this.create_mileage.created_at)
      }else if( instance == "create_booking_car_expense"){
        this.edit_expense_index = index
        this.is_edit_expense = true;
      }
    },
    submit_create_pickup_item() {
      const { items } = this.create_pickup_item;
      if (this.create_pickup_item.id) {
        const id = this.create_pickup_item.id;
        SystemService.update_all("PickupItem", { data: { items } }, id);
      } else {
        this.assign_car_form.pickup_items = [
          {
            items,
            booking_id: this.assign_car_form.booking_id,
            booking_car_id: this.assign_car_form.booking_car_id,
          },
        ];
      }
      this.show_create_pickup_item = false;
      this.create_pickup_item = {};
    },
    // submit_create_toll_way() {
    //   try {
    //     const {
    //       amount,
    //       remark,
    //       driver_paid,
    //       booking_status,
    //       photo_1,
    //       created_at,
    //       datetime
    //     } = this.create_toll_way;

    //     if (this.create_toll_way.id) {
    //       const id = this.create_toll_way.id;
    //       // Update existing toll way
          
    //       SystemService.update_all("TollWay", { data: { amount, driver_paid, booking_status, photo_1, created_at : datetime } }, id);
    //       SystemService.update_all("TollWay", { data: { edit_toll_ways_status: 1 } }, id);
    //       this.assign_car_form.toll_ways[this.create_toll_way.index].created_at = datetime;
    //     } else {
    //       if (!Array.isArray(this.assign_car_form.toll_ways)) {
    //         this.assign_car_form.toll_ways = [];
    //       }

    //       if (this.assign_car_form.toll_ways) {
    //         if (this.create_toll_way.index == null || this.create_toll_way.index === undefined) {
    //           // Check if duplicate
    //           const duplicate = this.assign_car_form.toll_ways.find((e) => (
    //             e.amount === amount &&
    //             e.driver_paid === driver_paid &&
    //             e.booking_status === booking_status
    //           ));

    //           if (!duplicate) {
    //             this.assign_car_form.toll_ways.push({
    //               amount,
    //               driver_paid,
    //               booking_status,
    //               created_at: dayjs(datetime),
    //               photo_1: this.model_form.photo_1_show,
    //               booking_car_id: this.assign_car_form.booking_car_id,
    //             });

    //             this.model_form.photo_1_show = null
    //           }
    //         } else {
    //           this.assign_car_form.toll_ways[this.create_toll_way.index].created_at = datetime;
    //         }
    //       }
    //     }

    //     this.show_create_toll_way = false;
    //     this.create_toll_way = {};
    //   } catch (error) {
    //   }
    // },
    async submit_create_gas_refill() {
      this.create_gas_refill.created_at = dayjs(this.create_gas_refill.datetime)
      var {
        pay_method_id,
        refill_amount,
        amount,
        remark,
        gas_station_id,
        station_name,
        gas_type,
        datetime,
        bill_photo,
      } = this.create_gas_refill;
      if (this.create_gas_refill.id) {
        const id = this.create_gas_refill.id;
        if(this.gas.orderPos == null || this.gas.indexGas == null){
          bill_photo = this.model_form.bill_photo_show !== undefined 
                        ? this.model_form.bill_photo_show 
                        :bill_photo
        }
        SystemService.update_all("GasRefill",{data: {pay_method_id, refill_amount, amount, remark, gas_station_id, station_name,gas_type,created_at : datetime, bill_photo},},id);
        SystemService.update_all("GasRefill", { data: { edit_gas_status: 1 }}, id); 
        this.add_gas_refill_drawer = false;
        if(this.create_gas_refill.index){
          this.assign_car_form.gas_refills[this.create_gas_refill.index].created_at = datetime
        }
      } else {
        if (this.assign_car_form.gas_refills) {
          if (!this.create_gas_refill.index && this.create_gas_refill.index != 0) {
            if(this.drawerCreate){
                var formReq  = JSON.parse(JSON.stringify(this.create_gas_refill));
                formReq.booking_car_id = this.selected_booking[0].id
                delete formReq.datetime
                delete formReq.use_gas_station
                this.loading = true;
                formReq.bill_photo = this.model_form.bill_photo_show;
                await SystemService.create_all("GasRefill", { data: [formReq] }).then((result)=>{
                  this.assign_car_form.gas_refills.push(result.data);
                });
                this.loading = false;
                Swal.fire({
                  icon: "success",
                  title: "บันทึกข้อมูลสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }else{
              this.assign_car_form.gas_refills.push({
                pay_method_id,
                refill_amount,
                amount,
                remark,
                gas_station_id,
                station_name,
                gas_type,
                photo_1_show: [],
                photo_2_show: [],
                photo_3_show: [],
                photo_4_show: [],
                photo_5_show: [],
                photo_6_show: [],
                photo_7_show: [],
                photo_8_show: [],
                photo_9_show: [],
                photo_10_show: [],
                bill_photo: this.model_form.bill_photo_show,
                bill_photo_show: [],
                booking_car_id: this.assign_car_form.booking_car_id,
                created_at: dayjs(this.create_gas_refill.datetime),
                datetime:dayjs(this.create_gas_refill.datetime)
              });
            }
          }
        } else {  //ถ้าไม่มีข้อมูลอยู่
          this.assign_car_form.gas_refills = [
            {
              pay_method_id,
              refill_amount,
              amount,
              remark,
              gas_station_id,
              station_name,
              gas_type,
              photo_1_show: [],
              photo_2_show: [],
              photo_3_show: [],
              photo_4_show: [],
              photo_5_show: [],
              photo_6_show: [],
              photo_7_show: [],
              photo_8_show: [],
              photo_9_show: [],
              photo_10_show: [],
              bill_photo_show: [],
              booking_car_id: this.assign_car_form.booking_car_id,
            },
          ];
          this.assign_car_form.gas_refills[this.create_gas_refill.index].created_at = datetime
        }
      }
      this.create_gas_refill = {};
      this.add_gas_refill_drawer = false;
    },
    async remove_list_from_table(index, key, modelname) {
      if (this.assign_car_form[key][index].id) {
        Swal.fire({
          title: "คุณต้องการลบรายการนี้ใช่หรือไม่?",
          text: "คุณจะไม่สามารถกู้คืนรายการนี้ได้หากลบแล้ว!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ใช่, ลบเดี๋ยวนี้!",
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            SystemService.delete_all(modelname, this.assign_car_form[key][index].id).then(
              (res) => {
                if (res) {
                  this.refresh_data();
                  Swal.fire("ลบเรียบร้อย!", "รายการของคุณถูกลบแล้ว", "success");
                  this.assign_car_form[key].splice(index, 1);
                }
              }
            );
          }
        });
      } else {
        this.assign_car_form[key].splice(index, 1);
      }
    },
    async submit_drawer(form_model, array_name, drawer_name, modelname) {
      var formReq  = JSON.parse(JSON.stringify(this[form_model]));
      if (formReq.id) {
        const id = formReq.id;
        if(form_model == "create_booking_car_expense" && delete formReq.index){
          delete formReq.index;
        }
        
        if(form_model == "create_booking_car_expense" && delete formReq.photoList){
          delete formReq.photoList;
        }
        
        if(form_model == "create_booking_car_expense" && delete formReq.plate_no){
          delete formReq.plate_no;
        }
        SystemService.update_all(modelname, { data: formReq }, id);
        SystemService.update_all("BookingCarExpense", { data: {edit_expenses_status: 1 }}, id);
        formReq = {};
        this[form_model] = {};
      } else {
        if (this.assign_car_form[array_name]) {
          if (!formReq.index) {
            if(array_name == "expenses" && this.is_edit_expense){
              this.assign_car_form[array_name][this.edit_expense_index] = formReq
              this.is_edit_expense = false;
            }else{
              if(this.drawerCreate){
                formReq.booking_car_id = this.selected_booking[0].id
                this.loading = true;
                await SystemService.create_all(modelname, { data: [formReq] }).then((result)=>{
                  this.loading = false;
                  this.assign_car_form[array_name].push(result.data);
                });
                Swal.fire({
                  icon: "success",
                  title: "บันทึกข้อมูลสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }else{
                this.assign_car_form[array_name].push(formReq)
              }
            }
          }
        } else {
          this.assign_car_form[array_name] = [formReq];
        }
        formReq = {};
        this[form_model] = {};
      }
      this[drawer_name] = false;
    },
    async update_car_status_id(status_id, car_id) {
      return await SystemService.update_all(
        "Car",
        { data: { car_status_id: status_id } },
        car_id
      );
    },
    async adjust_car_pos(order_pos, car_id, direction) {
      if (!this.from_booking) {
        const new_pos = direction === "up" ? order_pos - 1 : order_pos + 1;
        SystemService.update_all(
          "BookingCar",
          { data: { order_pos: new_pos } },
          car_id
        ).then(async (res) => {
          if (res) {
            this.refresh_data();
            Swal.fire(
              "ปรับเปลี่ยนลำดับสำเร็จ!",
              "ระบบได้ปรับเปลี่ยนลำดับสำเร็จแล้ว",
              "success"
            );
          }
        });
      } else {
        if (direction === "up") {
          this.create_booking.booking_cars = this.moveUp(
            this.create_booking.booking_cars,
            order_pos - 1
          );
        } else if (direction === "down") {
          this.create_booking.booking_cars = this.moveDown(
            this.create_booking.booking_cars,
            order_pos - 1
          );
        }
      }
    },
    moveUp(array, index) {
      console.log("moveUp", array, index);
      if (index <= 0 || index >= array.length) {
        console.warn("Invalid index. Cannot move up.");
        return array;
      }

      [array[index], array[index - 1]] = [array[index - 1], array[index]];
      array[index].order_pos = index + 1;
      array[index].id = index + 1;
      array[index - 1].order_pos = index;
      array[index - 1].id = index;
      return array;
    },
    moveDown(array, index) {
      console.log("moveDown", array, index);
      if (index < 0 || index >= array.length - 1) {
        console.warn("Invalid index. Cannot move down.");
        return array;
      }

      [array[index], array[index + 1]] = [array[index + 1], array[index]];
      array[index].order_pos = index + 1;
      array[index].id = index + 1;
      array[index + 1].order_pos = index + 2;
      array[index + 1].id = +2;
      return array;
    },
    async select_order_pos(order_pos, car_id) {
      if (!this.from_booking) {
        SystemService.update_all("BookingCar", { data: { order_pos } }, car_id).then(
          async (res) => {
            if (res) {
              this.refresh_data();
              Swal.fire(
                "ปรับเปลี่ยนลำดับสำเร็จ!",
                "ระบบได้ปรับเปลี่ยนลำดับสำเร็จแล้ว",
                "success"
              );
            }
          }
        );
      } else {
        function swap(array, i, j) {
          // Swap the main elements
          let temp = array[i];
          array[i] = array[j];
          array[j] = temp;

          // Adjust the order_pos and id properties accordingly
          array[i].order_pos = i + 1;
          array[i].id = i + 1;
          array[j].order_pos = j + 1;
          array[j].id = j + 1;
        }
        swap(this.create_booking.booking_cars, order_pos - 1, car_id - 1);
      }
    },
    async duplicate(obj) {
      const isDuplicateTime = (time_start, time_end) => {
        return this.create_booking.booking_cars.some(
          (bookingCar) =>
            bookingCar.time_start === time_start && bookingCar.time_end === time_end
        );
      }
      const booking_id = this.create_booking.id;
      if (!this.from_booking) {
        this.preselect_car_type_id = obj.car.car_type_id;
        if (!this.car_list.length) {
          this.$message.loading({
            content: "กำลังตรวจสอบรถ",
            duration: 0,
            key: "add_car_to_booking",
          });
          // this.preselect_car_type_id = null;
          this.car_list = await SystemService.get_all("Car");
          this.$message.success({
            // content: `เลือกรถ ${this.filtered_cars[0].plate_no} สำหรับรายการซ้ำ`,
            content: `เลือกรถ ${obj.car.plate_no} สำหรับรายการซ้ำ`,
            // content: `เลือกรถ bypass สำหรับรายการซ้ำ`,
            duration: 1,
            key: "add_car_to_booking",
          });
        }
        const {
          approve_status,
          booking_id,
          car_id,
          driver_cost,
          // driver_id,
          remark,
          rental_price,
          time_start,
          time_end,
          expenses,
          deposit,
          gas_refills,
          guides,
          pickup_items,
          stops,
          toll_ways,
          booking_type_id,
          person_qty,
          flight_pick,
          flight_drop,
          total_days,
          full_day_amount,
          trip_id,
        } = obj;
        
        const bodyBookingCar = {
          approve_status,
          booking_id,
          //ถ้าอยากได้ duplicate แล้วเลขทะเบียน, เลขรถเปลี่ยน ให้ uncomment อันนี้ แล้ว comment carid
          // car_id: this.filtered_cars[0].id,
          car_id,
          driver_cost,
          // driver_id,
          remark,
          rental_price,
          time_start : dayjs(time_start).format("YYYY-MM-DD HH:mm"),
          time_end : dayjs(time_end).format("YYYY-MM-DD HH:mm"),
          deposit,
          booking_type_id,
          person_qty,
          flight_pick : dayjs(flight_pick).format("YYYY-MM-DD HH:mm"),
          flight_drop : dayjs(flight_drop).format("YYYY-MM-DD HH:mm"),
          total_days,
          full_day_amount,
          trip_id,
          stops: stops
            ? stops.map(
                ({ stop_type_id, name, expected_timestamp }) => ({
                  stop_type_id,
                  name,
                  expected_timestamp,
                })
              )
            : [],
          guides: guides
            ? guides.map(
                ({ guide_id, phone_no, price, remark }) => ({
                  guide_id,
                  phone_no,
                  price,
                  remark,
                })
              )
            : [],
          gas_refills: gas_refills
            ? gas_refills.map(
                ({
                  amount,
                  booking_car_id,
                  created_at,
                  gas_station_id,
                  pay_method_id,
                  refill_amount,
                  remark,
                  station_name,
                  gas_type,
                  bill_photo
                }) => ({
                  amount,
                  booking_car_id,
                  created_at,
                  gas_station_id,
                  pay_method_id,
                  refill_amount,
                  remark,
                  station_name,
                  gas_type,
                  bill_photo
                })
              )
            : [],
          toll_ways: toll_ways
            ? toll_ways.map(
                ({ amount, created_at, driver_paid, remark, booking_status }) => ({
                  amount,
                  driver_paid,
                  booking_status,
                  created_at,
                  remark,
                })
              )
            : [],
          expenses: expenses
            ? expenses.map(({ amount, driver_paid, expense_id, pay_method_id, remark }) => ({
                amount,
                driver_paid,
                expense_id,
                pay_method_id,
                remark,
              }))
            : [],
          pickup_items: pickup_items
            ? pickup_items.map(({ items, pickup_status, pickup_at, pickup_photo, remark}) => ({
               items,
                pickup_status,
                pickup_at,
                pickup_photo,
                remark
               }))
            : [],
        };
        if (isDuplicateTime(time_start, time_end)) {
          this.showModalDuplicate = true
        }
        // console.log('789',bodyBookingCar)
        const existingBookingCarTypes = await SystemService.get_all("BookingCarType",  { booking_id });
        const filteredByBookingId = existingBookingCarTypes.filter(existing => existing.booking_id == booking_id);
        const isDuplicate = filteredByBookingId.find(existing => {
          const timeStart = dayjs(existing.time_start).format("YYYY-MM-DD HH:mm");
          const timeEnd = dayjs(existing.time_end).format("YYYY-MM-DD HH:mm");
          const flight_pick = dayjs(existing.flight_pick).format("YYYY-MM-DD HH:mm");
          const flight_drop = dayjs(existing.flight_drop).format("YYYY-MM-DD HH:mm");
          return (
            existing.booking_id == booking_id &&
            existing.booking_type_id == bodyBookingCar.booking_type_id &&
            timeStart == bodyBookingCar.time_start &&
            timeEnd == bodyBookingCar.time_end &&
            flight_pick == bodyBookingCar.flight_pick &&
            flight_drop == bodyBookingCar.flight_drop
            );
          });
        if (!this.from_booking) {
          SystemService.create_all("BookingCar", { data: [bodyBookingCar] }).then(async (res) => {
            if (res) {
              this.refresh_data();
              Swal.fire(
                "สร้างรายการซ้ำสำเร็จ!",
                "ระบบได้สร้างรายการซ้ำสำเร็จแล้ว",
                "success"
              );
            }
          });
          if(this.create_booking.company_id == 39 || this.businessTypeId == 2) {
            if(isDuplicate) {
              await SystemService.update_all("BookingCarType", { data: {quantity: isDuplicate.quantity + 1 }}, isDuplicate.id);
            }
          }
        }
      } else {
        const {
          time_start,
          time_end,
          driver_cost,
          rental_price,
          driver_id,
          car_id,
          stops,
          car,
          guides,
          gas_refills,
          toll_ways,
          expenses,
          remark,
          pickup_items,
          deposit,
          booking_type_id,
          person_qty,
          flight_pick,
          flight_drop,
          total_days,
          full_day_amount,
          trip_id,
        } = obj;
        this.create_booking.booking_cars.push({
          time_start,
          time_end,
          driver_cost,
          rental_price,
          driver_id,
          car_id,
          stops,
          car,
          guides,
          gas_refills,
          toll_ways,
          expenses,
          remark,
          pickup_items,
          deposit,
          booking_type_id,
          person_qty,
          flight_pick,
          flight_drop,
          total_days,
          full_day_amount,
          trip_id,
          order_pos: this.create_booking.booking_cars.length + 1,
          id: this.create_booking.booking_cars.length + 1,
        });
      }
      this.preselect_car_type_id = null;
    },
    compare_timeGreater(time, array) {
      const lastupdate = array.map(({ created_at }) => created_at);
      //if time is greater than any of lastupdate and no any of lastupdate is greater than time
      return array
        ? lastupdate.some((lastupdate) => dayjs(time).isAfter(lastupdate))
        : false;
    },
    compare_time(timeA, timeB, max, unit) {
      const timeA_moment = dayjs(timeA);
      const timeB_moment = dayjs(timeB);
      const diff = timeB_moment.diff(timeA_moment, unit);
      return diff > max;
    },
    async sync_booking_datetime(bookingCar, id) {
      if (!this.from_booking) {
        const [time_start, time_end] = this.create_booking.datetime;
        await SystemService.update_all(
          "Booking",
          { data: { time_start, time_end } },
          this.create_booking.id
        );
        SystemService.update_all(
          "BookingCar",
          { data: { time_start, time_end } },
          bookingCar.id
        ).then(async (res) => {
          if (res) {
            this.refresh_data();
            Swal.fire("แก้ไขเวลาสำเร็จ!", "ระบบได้แก้ไขเวลาสำเร็จแล้ว", "success");
          }
        });
      } else {
        if (this.create_booking.datetime) {
          const [time_start, time_end] = this.create_booking.datetime;
          let index = this.create_booking.booking_cars.findIndex((e) => e.id == id);
          this.create_booking.booking_cars[index].time_start = time_start;
          this.create_booking.booking_cars[index].time_end = time_end;
        } else {
          Swal.fire("แก้ไขเวลาไม่สำเร็จ!", "กรุณาใส่วันเวลารับ-ส่งในการจอง", "error");
        }
      }
    },
    edit_booking_car(obj, id) {
      this.assign_car(obj, { booking_car_id: id });
      this.selected_booking = this.create_booking.booking_cars.filter(car => car.id == id);
      const { time_end, time_start, flight_pick, flight_drop } = obj;

      this.assign_car_form.plate_no = obj.car.plate_no;
      this.assign_car_form.car_id = obj.car.id;
      this.preselect_car_type_id = this.assign_car_form.type_car_id
      this.assign_car_form.type_car_id = null
      let typeIdCar = this.create_booking.booking_cars.find((car)=> car.id == id)
      if(typeIdCar && typeIdCar.car.car_group){
        this.assign_car_form.type_car_id = typeIdCar.car.car_group.car_type?.id ?? null
      }
      this.preselect_car_type_id = this.assign_car_form.type_car_id
      if (time_start != "Invalid date" && time_end != "Invalid date") {
        this.assign_car_form.datetime = [dayjs(time_start), dayjs(time_end)];
        this.assign_car_form.flight_pick = dayjs(flight_pick)
        this.assign_car_form.flight_drop = dayjs(flight_drop)
      } else {
        this.assign_car_form.datetime = this.create_booking.datetime;
        this.assign_car_form.flight_pick = dayjs(this.create_booking.flight_pick)
          this.assign_car_form.flight_drop = dayjs(this.create_booking.flight_drop)
      }
      this.assign_car_form.car_status_id = obj.car.car_status_id;
      this.assign_car_form.id = id;
    },
    edit_booking_expense(index, id) {
      if (id) {
        this.extra = this.create_booking.extras.find((extra) => extra.id === id);
        this.extra.name = this.extra_list.find(
          ({ id }) => id == this.extra.extra_id
        )?.name;
        this.add_booking_expense();
      } else {
        this.extra = this.create_booking.extras[index];
        this.extra.name = this.extra_list.find(
          ({ id }) => id == this.extra.extra_id
        )?.name;
        this.add_booking_expense();
      }
    },
    remove_booking_car_types_expense(index, id) {
      if (id) {
        Swal.fire({
          title: "คุณต้องการลบรายการนี้ใช่หรือไม่?",
          text: "คุณจะไม่สามารถกู้คืนรายการนี้ได้หากลบแล้ว!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ใช่, ลบเดี๋ยวนี้!",
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            SystemService.delete_all("CarTypeExpense", id).then((res) => {
              if (res) {
                Swal.fire("ลบแล้ว!", "รายการของคุณถูกลบแล้ว", "success");
                this.booking_car_types.expenses.splice(index, 1);
                this.refresh_data();
              }
            });
          }
        });
      } else {
        this.booking_car_types.expenses.splice(index, 1);
      }
    },
    show_car_expense_modal(index, record_id) {
      this.show_edit_expense = true;
      const { name, price, amount, expense_id, id } = this.booking_car_types.expenses[index];
      this.edit_expense = {
        name,
        price,
        amount,
        expense_id,
      }
    },
    remove_booking_car(id, record,{ booking_car_type_id, is_last }) {
      if (this.from_booking) {
        Swal.fire({
          title: "คุณต้องการลบรายการนี้ใช่หรือไม่?",
          text: "คุณจะไม่สามารถกู้คืนรายการนี้ได้หากลบแล้ว!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ใช่, ลบเดี๋ยวนี้!",
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            this.create_booking.booking_cars = this.create_booking.booking_cars.filter(
                (e) => e.id != id
              );
              for (let i = 0; i < this.create_booking.booking_cars.length; i++) {
                this.create_booking.booking_cars[i].id = i + 1;
                this.create_booking.booking_cars[i].order_pos = i + 1;
              }
              this.refresh_data();
              Swal.fire("ลบเรียบร้อย!", "รายการของคุณถูกลบแล้ว", "success");
            }
          }
        );
      } else {
        Swal.fire({
          title: "คุณต้องการลบรายการนี้ใช่หรือไม่?",
          text: "คุณจะไม่สามารถกู้คืนรายการนี้ได้หากลบแล้ว!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ใช่, ลบเดี๋ยวนี้!",
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            const idDel = this.create_booking.booking_car_types.find((e)=>e.car_type_id == record.car.car_group.car_type_id && e.booking_id == record.booking_id)
            SystemService.update_all("BookingCarType", { data: {quantity: idDel.quantity - 1 }}, idDel.id);
            SystemService.delete_all("BookingCar", id).then((res) => {
              if (res) {
                this.create_booking.booking_cars = this.create_booking.booking_cars.filter(
                  (e) => e.id != id
                );
                for (let i = 0; i < this.create_booking.booking_cars.length; i++) {
                  this.create_booking.booking_cars[i].id = i + 1;
                  this.create_booking.booking_cars[i].order_pos = i + 1;
                }
                this.refresh_data();
                Swal.fire("ลบเรียบร้อย!", "รายการของคุณถูกลบแล้ว", "success");
              }
            });
          }
        });
      }
    },
    cancel_edit_stop() {
      this.add_stop = {};
      this.on_edit_stop = null;
      document.getElementById("map").value = null;
    },
    save_edit_stop(index) {
      this.assign_car_form.stops[index] = this.add_stop;
      if (this.add_stop.id) {
        SystemService.update_all("Stop", { data: this.add_stop }, this.add_stop.id).then(
          (res) => {
            if (res) {
              this.refresh_data();
              Swal.fire("แก้ไขสำเร็จ!", "ระบบได้แก้ไขข้อมูลสำเร็จแล้ว", "success");
            }
          }
        );
      }
      this.add_stop = {};
      document.getElementById("map").value = null;
      this.on_edit_stop = null;
    },
    edit_stop_from_table(index) {
      this.on_edit_stop = index;
      this.add_stop = this.assign_car_form.stops[index];
      this.add_stop.expected_timestamp = dayjs(this.add_stop.expected_timestamp);
      document.getElementById("map").value = this.add_stop.name;
    },
    remove_stop_from_table(index, id) {
      this.assign_car_form.stops.splice(index, 1);
      if (id) {
        SystemService.delete_all("Stop", id);
      }
    },
    add_stop_to_table(e) {
      if (this.assign_car_form.stops) {
        this.assign_car_form.stops.push(this.add_stop);
      } else {
        this.assign_car_form.stops = [this.add_stop];
      }
      this.add_stop = {};
      document.getElementById("map").value = null;
    },
    async select_stop_name(e, target, is_car) {
      const { latitude, longitude } = e;
      if (target && !is_car) {
        this.create_booking[target] = document.getElementById(target).value.split(",")[0];
      } else if (is_car) {
        this.booking_car_types[target] = document
          .getElementById(target)
          .value.split(",")[0];
      } else {
        this.add_stop.lat = latitude;
        this.add_stop.lng = longitude;
        this.add_stop.name = document.getElementById("map").value.split(",")[0];
      }
    },
    cal_price(e, incVat = false, withhold = false, discount = 0) {
      if (e == 1) {
        let totalExtras = this.create_booking.extras
          .map(({ price, amount }) => price * amount)
          .reduce((a, b) => a + b, 0);

        let totalCarTypes = this.create_booking.booking_car_types
          .map(({ price, quantity, expenses }) => {
            let subtotal = price * quantity;
            if (expenses) {
              subtotal += expenses.map(expense => expense.price * expense.amount).reduce((a, b) => a + b, 0);
            }
            return subtotal;
          })
          .reduce((a, b) => a + b, 0);
          if (incVat) {
            totalCarTypes += Math.round(totalCarTypes * 0.07)
          }
          else if (withhold) {
            totalCarTypes -= Math.round(totalCarTypes * 0.01)
          }
        this.form.total_price = totalCarTypes + totalExtras - discount;
      } else if (e == 2) {
        let totalCarTypes = this.create_booking.booking_car_types
          .map(({ price, quantity, expenses }) => {
            let subtotal = price * quantity;
            if (expenses) {
              subtotal += expenses.map(expense => expense.price * expense.amount).reduce((a, b) => a + b, 0);
            }
            return subtotal;
          })
          .reduce((a, b) => a + b, 0);
        let payAmount = this.form.pay_amount;
        let totalAfterAllocation = 0;
        this.create_booking.booking_car_types.forEach((car, index) => {
          let carCost = car.price * car.quantity;
          if (car.expenses) {
            carCost += car.expenses.map(expense => expense.price * expense.amount).reduce((a, b) => a + b, 0);
          }
          let proportion = carCost / totalCarTypes;
          let allocatedAmount = payAmount * proportion;
          
          totalAfterAllocation += allocatedAmount;
        });
        if (incVat) {
          totalAfterAllocation += Math.round(totalAfterAllocation * 0.07)
        }
        else if (withhold) {
          totalAfterAllocation -= Math.round(totalAfterAllocation * 0.01)
        }
        this.form.total_price = totalAfterAllocation - discount
      }
      else if (e == 3) {
        let totalCarTypes = this.create_booking.booking_car_types
          .map(({ price, quantity, expenses }) => {
            let subtotal = price * quantity;
            if (expenses) {
              subtotal += expenses.map(expense => expense.price * expense.amount).reduce((a, b) => a + b, 0);
            }
            return subtotal;
          })
          .reduce((a, b) => a + b, 0);

        let percentage = this.form.price_percent;
        let totalAfterAllocation = 0;

        this.create_booking.booking_car_types.forEach((car, index) => {
          let carCost = car.price * car.quantity;
          if (car.expenses) {
            carCost += car.expenses.map(expense => expense.price * expense.amount).reduce((a, b) => a + b, 0);
          }
          let proportion = carCost / totalCarTypes;
          let allocatedAmount = totalCarTypes * proportion * percentage;
          totalAfterAllocation += allocatedAmount;
        });
        if (incVat) {
          totalAfterAllocation += Math.round(totalAfterAllocation * 0.07)
        }
        else if (withhold) {
          totalAfterAllocation -= Math.round(totalAfterAllocation * 0.01)
        }
        this.form.total_price = totalAfterAllocation - discount
      }
      // SystemService.update_all("Booking", { data: { net_price: this.form.total_price } }, this.form.id);
    },
    handleClose() {
      this.assign_car_form = {}; // Reset the form
      this.refreshPage();        // Call refreshPage method
    },
    async submit_assign_car() {
      if (!this.from_booking || this.from_booking) {
        this.loading = true;
        const booking_id = this.create_booking.id;
        const billing_status = this.create_booking.billing_status;
        const { 
          create_at, datetime, driver_id, stops, car_id, car_status_id, driver_cost, rental_price, guides, gas_refills, toll_ways, mileages, remark, expenses, pickup_items, deposit, temporary_driver, booking_type_id, person_qty, flight_pick, flight_drop, total_days, full_day_amount, trip_id,
        } = this.assign_car_form;
        const booking_car_id = this.assign_car_form.booking_car_id;
        const body = {
          booking_type_id,
          person_qty,
          flight_pick: flight_pick ? flight_pick.format("YYYY-MM-DD HH:mm") : null,
          flight_drop: flight_drop ? flight_drop.format("YYYY-MM-DD HH:mm") : null,
          total_days,
          full_day_amount,
          trip_id,
          temporary_driver: temporary_driver,
          create_at,
          booking_id,
          time_start: datetime[0].format("YYYY-MM-DD HH:mm"),
          time_end: datetime[1].format("YYYY-MM-DD HH:mm"),
          driver_cost,
          rental_price,
          driver_id,
          car_id,
          stops,
          guides,
          gas_refills: gas_refills
            ? gas_refills.map(
                ({
                  id,
                  amount,
                  booking_car_id,
                  created_at,
                  gas_station_id,
                  pay_method_id,
                  refill_amount,
                  remark,
                  station_name,
                  gas_type,
                  bill_photo
                }) => ({
                  id,
                  amount,
                  booking_car_id,
                  created_at,
                  gas_station_id,
                  pay_method_id,
                  refill_amount,
                  remark,
                  station_name,
                  gas_type,
                  bill_photo
                })
              )
            : [],
          toll_ways: toll_ways
            ? toll_ways.map(
                ({
                  id,
                  amount,
                  driver_paid,
                  booking_status,
                  created_at,
                  photo_1,
                  booking_car_id,
                }) => ({
                  id,
                  amount,
                  driver_paid,
                  booking_status,
                  created_at,
                  photo_1,
                  booking_car_id,
                })
              )
            : [],
          mileages: mileages
            ? mileages.map(({ id, distance, is_start, created_at }) => ({
                id,
                distance,
                is_start,
                created_at,
              }))
            : [],
          expenses: expenses
            ? expenses.map(({ id, amount, driver_paid, expense_id, pay_method_id, remark , photo}) => ({
                id,
                amount,
                driver_paid,
                expense_id,
                pay_method_id,
                remark,
                photo
              }))
            : [],
          remark,
          pickup_items,
          deposit,
        };

        const existingBookingCarTypes = await SystemService.get_all("BookingCarType",  { booking_id });
        const filteredByBookingId = existingBookingCarTypes.filter(existing => existing.booking_id == booking_id);

        const bodyBookingCarType = {
          booking_id, 
          booking_type_id,
          // car_group: null, 
          car_group_id: null,
          car_type_id: this.preselect_car_type_id,
          person_qty,
          // price: this.assign_car_form.rental_price,
          quantity: 1,
          remark,
          total_days,
          time_start: datetime[0].format("YYYY-MM-DD HH:mm"),
          time_end: datetime[1].format("YYYY-MM-DD HH:mm"),
          flight_pick: flight_pick ? flight_pick.format("YYYY-MM-DD HH:mm") : null,
          flight_drop: flight_drop ? flight_drop.format("YYYY-MM-DD HH:mm") : null,
          full_day_amount: this.assign_car_form.full_day_amount,
          trip_id: this.assign_car_form.trip_id,
          on_quotation: 1,
        };

        const item = filteredByBookingId.find(existing => {
          const timeStart = dayjs(existing.time_start).format("YYYY-MM-DD HH:mm");
          const timeEnd = dayjs(existing.time_end).format("YYYY-MM-DD HH:mm");
          return (
            existing.booking_id == booking_id &&
            timeStart == bodyBookingCarType.time_start &&
            timeEnd == bodyBookingCarType.time_end
          );
        });
        let booking_car_type_id;
        let quantity
        let car_group_id
        if (item) {
          booking_car_type_id = item.id;
          quantity = item.quantity;
          car_group_id = item.car_group_id;

          bodyBookingCarType.car_group_id = car_group_id;
        }
        const isDuplicate = filteredByBookingId.find(existing => {
          // console.log('666',existing)
          const timeStart = dayjs(existing.time_start).format("YYYY-MM-DD HH:mm");
          const timeEnd = dayjs(existing.time_end).format("YYYY-MM-DD HH:mm");
          // const flight_pick = dayjs(existing.flight_pick).format("YYYY-MM-DD HH:mm");
          // const flight_drop = dayjs(existing.flight_drop).format("YYYY-MM-DD HH:mm");
          return (
            existing.booking_id == booking_id &&
            existing.booking_type_id == bodyBookingCarType.booking_type_id &&
            existing.car_group_id == bodyBookingCarType.car_group_id &&
            existing.car_type_id == bodyBookingCarType.car_type_id &&
            // existing.person_qty == bodyBookingCarType.person_qty &&
            timeStart == bodyBookingCarType.time_start &&
            timeEnd == bodyBookingCarType.time_end
            // flight_pick == bodyBookingCarType.flight_pick &&
            // flight_drop == bodyBookingCarType.flight_drop &&
            // existing.full_day_amount == bodyBookingCarType.full_day_amount &&
            // existing.trip_id == bodyBookingCarType.trip_id
            );
          });

          if ((body.flight_pick != '' && body.flight_pick != null)  &&
          !(body.flight_pick >= body.time_start && body.flight_pick <= body.time_end)) 
          {
            Swal.fire({
              icon: 'error',  
              title: 'ไฟท์รับ-ไฟท์ส่ง',
              text: 'โปรดเลือกวันที่ที่อยู่ในช่วงวันเวลารับ-ส่ง'
            })
            this.loading = false;
            return true
          }
        
          if ((body.flight_drop != '' && body.flight_drop != null) &&
            !(body.flight_drop <= body.time_end && body.flight_drop >= body.time_start)) 
            {
              Swal.fire({
                icon: 'error',  
                title: 'ไฟท์รับ-ไฟท์ส่ง',
                text: 'โปรดเลือกวันที่ที่อยู่ในช่วงวันเวลารับ-ส่ง'
              })
              this.loading = false;
              return true
            }

          if (this.booking_sjinda && !billing_status == 1) {
            if (booking_car_id) {
              // console.log('5555',filteredByBookingId)
              if (!isDuplicate) {
                await SystemService.update_all("BookingCarType", { data: {quantity: (quantity ?? filteredByBookingId[0].quantity) - 1}}, booking_car_type_id??filteredByBookingId[0].id);
                // await SystemService.update_all("BookingCarType", { data: {on_quotation: 2}}, booking_car_type_id??filteredByBookingId[0].id);
                await SystemService.create_all("BookingCarType", { data: [bodyBookingCarType] });
              } else {
                if (filteredByBookingId.length == 1) {
                  // delete bodyBookingCarType.quantity
                  bodyBookingCarType.quantity = quantity;
                  await SystemService.update_all("BookingCarType", { data: bodyBookingCarType },booking_car_type_id);
                  // console.log('5678', isDuplicate)
                } else if (filteredByBookingId.length >= 2) {
                  await SystemService.update_all("BookingCarType", { data: {quantity: isDuplicate.quantity + 1 }}, isDuplicate.id);
                  await SystemService.delete_all("BookingCarType", filteredByBookingId[1].id);
                }
                
              }
            } else {
              if (isDuplicate) {
                await SystemService.update_all("BookingCarType", { data: {quantity: isDuplicate.quantity + 1 }}, isDuplicate.id);
              } else {
                await SystemService.create_all("BookingCarType", { data: [bodyBookingCarType] });
              }
            }
          }

            var res;
              if (booking_car_id) {
                res = await SystemService.update_all("BookingCar", { data: body },booking_car_id);
              } else {
                res = await SystemService.create_all("BookingCar", { data: [body] });
              }
            if (res) {
            this.assign_car_drawer = false;
            this.assign_car_form = {};
            Swal.fire({
              icon: "success",
              title: "บันทึกข้อมูลสำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            });
            this.refresh_data();
            this.refreshPage();
          }
          this.assign_car_drawer = false;
      } else {
        const {
          datetime,
          driver_id,
          stops,
          car_id,
          car_status_id,
          driver_cost,
          rental_price,
          guides,
          gas_refills,
          toll_ways,
          mileages,
          remark,
          expenses,
          plate_no,
          car_no,
          pickup_items,
          temporary_driver,
          booking_type_id,
          person_qty,
          flight_pick,
          flight_drop,
          total_days,
          full_day_amount,
          trip_id,
          start_point,
          end_point,
        } = this.assign_car_form;
        if (!this.create_booking.booking_cars.find((e) => e.car_id == car_id)) {
          const timeStart = datetime[0].format("YYYY-MM-DD HH:mm")
          const timeEnd = datetime[1].format("YYYY-MM-DD HH:mm")
          const flightPick = flight_pick ? flight_pick.format("YYYY-MM-DD HH:mm") : null;
          if ((flightPick != '' && flightPick != null) &&
            !(flightPick >= timeStart && flightPick <= timeEnd)) 
          {
            Swal.fire({
              icon: 'error',  
              title: 'ไฟท์รับ-ไฟท์ส่ง',
              text: 'โปรดเลือกวันที่ที่อยู่ในช่วงวันเวลารับ-ส่ง'
            })
            this.loading = false;
            return true
          };

          const flightDrop = flight_drop ? flight_drop.format("YYYY-MM-DD HH:mm") : null;
          if ((flightDrop != '' && flightDrop != null) &&
            !(flightDrop <= timeEnd && flightDrop >= timeStart))
          {
            Swal.fire({
              icon: 'error',  
              title: 'ไฟท์รับ-ไฟท์ส่ง',
              text: 'โปรดเลือกวันที่ที่อยู่ในช่วงวันเวลารับ-ส่ง'
            })
            this.loading = false;
            return true
          };
          
            const bookingCar = {
              temporary_driver: temporary_driver,
              time_start: timeStart,
              time_end: timeEnd,
              driver_cost,
              rental_price,
              driver_id,
              car_id,
              stops,
              car: { car_status_id, car_no, plate_no },
              guides,
              gas_refills,
              toll_ways,
              mileages,
              expenses,
              mileages,
              remark,
              pickup_items,
              order_pos: this.create_booking.booking_cars.length + 1,
              id: this.create_booking.booking_cars.length + 1,
              temporary_driver: this.assign_car_form.temporary_driver,
              booking_type_id,
              person_qty,
              flight_pick : flightPick,
              flight_drop : flightDrop,
              total_days,
              full_day_amount,
              trip_id,
              start_point,
              end_point,
            };
            if (flightPick == '') {
              delete bookingCar.flight_pick;
            }
            if (flightDrop == '') {
              delete bookingCar.flight_drop;
            }
            this.create_booking.booking_cars.push(bookingCar);
            this.assign_car_drawer = false;
          this.loading = false;
          // this.create_booking.booking_cars.push({
          //   temporary_driver: temporary_driver_id,
          //   time_start: datetime[0].format("YYYY-MM-DD HH:mm:ss"),
          //   time_end: datetime[1].format("YYYY-MM-DD HH:mm:ss"),
          //   driver_cost,
          //   rental_price,
          //   driver_id,
          //   car_id,
          //   stops,
          //   car: { car_status_id, car_no, plate_no },
          //   guides,
          //   gas_refills,
          //   toll_ways,
          //   mileages,
          //   expenses,
          //   mileages,
          //   remark,
          //   pickup_items,
          //   order_pos: this.create_booking.booking_cars.length + 1,
          //   id: this.create_booking.booking_cars.length + 1,
          //   temporary_driver_id: this.assign_car_form.temporary_driver_id,
          //   booking_type_id,
          //   person_qty,
          //   flight_pick: flight_pick.format("YYYY-MM-DD HH:mm:ss"),
          //   flight_drop: flight_drop.format("YYYY-MM-DD HH:mm:ss"),
          //   total_days,
          //   full_day_amount,
          //   trip_id,
          //   start_point,
          //   end_point,
          // });
        } else {
          const car_data = this.car_list.find((e) => e.id == car_id);

          this.create_booking.booking_cars[this.assign_car_form.id - 1] = {
            time_start: datetime[0].format("YYYY-MM-DD HH:mm"),
            time_end: datetime[1].format("YYYY-MM-DD HH:mm"),
            driver_cost,
            rental_price,
            driver_id,
            car_id,
            stops,
            car: {
              car_no: car_data.car_no,
              plate_no: car_data.plate_no,
              car_status_id: car_data.car_status_id,
            },
            guides,
            gas_refills,
            toll_ways,
            mileages,
            expenses,
            remark,
            pickup_items,
            temporary_driver: this.assign_car_form.temporary_driver,
            booking_type_id,
            person_qty,
            flight_pick,
            flight_drop,
            total_days,
            full_day_amount,
            trip_id,
            start_point,
            end_point,
            id: this.create_booking.booking_cars[this.assign_car_form.id - 1].id,
            order_pos: this.create_booking.booking_cars[this.assign_car_form.id - 1].order_pos,
          };
          this.loading = false;
        }

        // this.assign_car_drawer = false;
        this.assign_car_form = {};
        Swal.fire({
          icon: "success",
          title: "บันทึกข้อมูลสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        });
        this.refreshPage();
      }
      // this.assign_car_drawer = false;
    },
    changeCar(id) {
      const target_carObj = this.car_list.find((car) => id == car.id);
      target_carObj.car_id = target_carObj.id;

      this.assign_car_form.car_id = target_carObj.id;
      this.assign_car_form.plate_no = target_carObj.plate_no;
    },
    async assign_car(booking_car, { status_id, booking_car_id }) {
      const car_id = booking_car_id ? booking_car.car_id : booking_car.id;
      // delete booking_car.id
      this.add_car_drawer = false;
      this.car_list = await SystemService.get_all("Car");
      if (!booking_car.driver_id) booking_car.driver_id = null;
      if (status_id == 1 || status_id == 7) {
        this.assign_car_drawer = true;
        this.assign_car_form = booking_car ;
        this.assign_car_form.car_id = car_id;
      } else if (status_id) {
        message.info("รถไม่สามารถใช้งานได้", 0.5);
      } else {
        this.assign_car_drawer = true;
        this.assign_car_form =  booking_car ;
        this.assign_car_form.car_id = car_id;

        if (booking_car_id) {
          this.assign_car_form.booking_car_id = booking_car_id;
        } else {
          booking_car.gas_refills = [];
        }
      }

      if (!this.assign_car_form.remark) {
        const bookType = this.booking_types.find(
          ({ id }) => id == this.create_booking.booking_type_id
        ).name;
        this.assign_car_form.remark = `ค่าขนส่งโดยสารปรับอากาศ\nเดินทาง\n${bookType}\nวันที่`;
      }

      if (booking_car.car) this.assign_car_form.plate_no = booking_car.car.plate_no;
      if (this.create_booking.datetime?.length) {
        const bookingCar = this.create_booking.booking_cars.find(
          car => car.id == booking_car.id
        );
        if (booking_car_id) {
          const startDate = dayjs(bookingCar.time_start)
          const endDate = dayjs(bookingCar.time_end)
          this.assign_car_form.datetime = [startDate, endDate];
          if (bookingCar.flight_pick) {
            this.assign_car_form.flight_pick = dayjs(bookingCar.flight_pick);
          }
          if (bookingCar.flight_drop) {
            this.assign_car_form.flight_drop = dayjs(bookingCar.flight_drop);
          }
        } if (!booking_car_id) {
          const startDate = dayjs().hour(0).minute(0);
          const endDate = dayjs().hour(0).minute(0);
          this.assign_car_form.datetime = [startDate, endDate];
        }
      }
      if (isNaN(this.assign_car_form.flight_pick)) {
        this.assign_car_form.flight_pick = "";
      }
      if (isNaN(this.assign_car_form.flight_drop)) {
        this.assign_car_form.flight_drop = "";
      }
      if (!this.assign_car_form.driver_cost) this.calculate_driver_cost();
    },
    render_time(datetime) {
      return dayjs(datetime).format("DD/MM/YYYY HH:mm");
    },
    async add_car_to_booking() {
      if (!this.create_booking.id) {
        this.$message.error({
          content: "กรุณากรอกข้อมูลที่ต้องระบุ และบันทึกข้อมูลก่อนกดเพิ่มรถ",
          duration: 5,
          key: "add_car_to_booking",
        });
        return;
      }
      this.$message.loading({
        content: "กำลังตรวจสอบรถ",
        duration: 0,
        key: "add_car_to_booking",
      });
      this.preselect_car_type_id = null;
      this.car_list = await SystemService.get_all("Car");
      this.$message.success({
        content: `พบรถว่าง ${this.car_list.length} คัน`,
        duration: 1,
        key: "add_car_to_booking",
      });
      this.car_list.forEach((car) => {
          const repair = this.repair.filter((repair) => repair.car_id === car.id);
          if (repair && repair.length > 0) {
            repair.sort((a, b) => b.id - a.id);
            car.time_end =
              repair[0]?.time_end !== null && repair[0]?.time_end !== undefined
                ? dayjs(repair[0].time_end).format("DD/MM/YYYY HH:mm")
                : null;
            car.createAt =
              repair[0]?.created_at !== null && repair[0]?.created_at !== undefined
                ? dayjs(repair[0].created_at).format("DD/MM/YYYY HH:mm")
                : null;
          } else {
            car.time_end = null;
            car.createAt = null;
          }
        });
      this.add_car_drawer = true;
      this.preselect_car_type_id = this.create_booking.booking_car_types[0]?.car_type_id;
    },
    filtered_booking_list(array) {
      return array;
    },
    clear_search() {
      this.search_input = null;
      this.search_progress = null;
      this.datetime = null;
      this.search_booking_type_id = null;
      this.preselect_car_type_id = null;
      this.init_all_bookings();
    },
    async booking_progress() { //when click 'Confirm งาน'
      const qt_json = JSON.parse(this.selected_quotation.qt_setting);
      const id = this.selected_quotation.id
      const current_datetime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      const bill_body = {
                booking_id: id,
                billing_type_id: qt_json["pay_type"] == 1 ? 5 : 4,
                sale_id: this.selected_quotation.sale_id,
                issue_date: dayjs(this.selected_quotation.created_at).format('YYYY-MM-DD HH:mm:ss'),
                expire_date: dayjs(this.selected_quotation.created_at).add(30, 'days').format('YYYY-MM-DD HH:mm:ss'),
                total_price: qt_json["total_price"] != 0 ? qt_json["total_price"] : 0,
                is_deposit: false,
            }
      
      await BookingService.booking_progress(this.selected_quotation.id).then(() => {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการเสร็จสิ้น",
          showConfirmButton: false,
          timer: 1500,
        });  
      });
      SystemService.update_all("Booking",{ data: { billing_status: 1, progress_1: 1, progress: 2, confirm_date: current_datetime } },this.selected_quotation.id); 
      // ส่ง bill 
      if (qt_json["total_price"] != 0 && qt_json["pay_type"] != null) {
          await BillingService.create_billing(bill_body)
                .then(res => {
                    if (res.err) {
                        Swal.fire({
                            icon: 'error',
                            title: 'เกิดข้อผิดพลาด',
                            text: res.err
                        })
                    } else {
                        Swal.fire({
                            icon: 'success',
                            title: 'สร้างบิลสำเร็จ',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        SystemService.update_all('Billing',{ data: { billing_status_id: 7 } }, res.id)
                          .then(response => {
                            return response
                          })    
                    }
                  this.refresh_data();
                })
      }
    },
    render_date(datetime) {
      return dayjs(datetime).format("DD/MM/YYYY HH:mm");
    },
    format_time(datetime) {
      if (datetime) {
        return dayjs(datetime).format("DD/MM/YYYY HH:mm");
      } else {
        return ' - '
      }
      
    },
    async open_booking_sjinda() {
      try {
        const res = await SystemService.get_all("CarCompany");
        if(res[0].company_id){
          let result = await CompanyService.getBusinessId(res[0].company_id)
          if(result){
            this.businessTypeId = result.data;
          }
        }
        this.car_company_list = res;
        console.log("check",this.businessTypeId)
        if (res[0].company_id == 39 || this.businessTypeId == 2) {
          this.booking_sjinda = true;
        } else {
          this.booking_sjinda = false;
        }
        console.log("booking_sjinda", this.booking_sjinda);
      } catch (error) {
        console.error("Error fetching CarCompany:", error);
      }
    },
    async validateAndClose() {
      try{
        if([this.create_booking.client_id].filter((e) => e == null).length){
          await this.$refs.bookingForm.validateFields(['client_id']);
        }else if([this.create_booking.booking_type_id, this.create_booking.datetime].filter( (e) => e == null).length){
          await this.$refs.bookingForm.validateFields(['booking_type_id','datetime']);
        }else if([this.create_booking.sale_id].filter((e) => e == null).length){
          await this.$refs.bookingForm.validateFields(['sale_id']);
        }

        this.showModalRequireInput = false;
      }catch{
        this.showModalRequireInput = false;
      }
    },
    async validateAndClose() {
      try{
        if([this.create_booking.client_id].filter((e) => e == null).length){
          await this.$refs.bookingForm.validateFields(['client_id']);
        }else if([this.create_booking.booking_type_id, this.create_booking.datetime].filter( (e) => e == null).length){
          await this.$refs.bookingForm.validateFields(['booking_type_id','datetime']);
        }else if([this.create_booking.sale_id].filter((e) => e == null).length){
          await this.$refs.bookingForm.validateFields(['sale_id']);
        }

        this.showModalRequireInput = false;
      }catch{
        this.showModalRequireInput = false;
      }
    },
    async submit_create_booking() {
      if(this.booking_car_types.index || this.booking_car_types.index == 0){
        delete this.booking_car_types.index
      }
      if([this.create_booking.client_id].filter((e) => e == null).length){
        this.activeKey.push("1");
        this.$nextTick(() => {
          this.$refs.clientItem.$el.scrollIntoView({ behavior: "smooth", block: "start" });
        });
        this.showModalRequireInput = true
      }else if([this.create_booking.booking_type_id, this.create_booking.datetime].filter( (e) => e == null).length){
        this.activeKey.push("2");
        this.$nextTick(() => {
          this.$refs.typeBooking.$el.scrollIntoView({ behavior: "smooth", block: "start" });
        });
        this.showModalRequireInput = true
      } else if ([this.create_booking.booking_car_types[0]?.booking_type_id].filter((e) => e == null).length) {
        this.activeKey.push("3");
        this.$nextTick(() => {
          this.$refs.carType.$el.scrollIntoView({ behavior: "smooth", block: "start" });
        });
        this.showModalRequireInput = true;
      }else if([this.create_booking.sale_id].filter((e) => e == null).length){
      this.activeKey.push("7");
      this.$nextTick(() => {
        this.$refs.clientCS.$el.scrollIntoView({ behavior: "smooth", block: "start" });
      });
      this.showModalRequireInput = true        
      //await this.$refs.bookingForm.validateFields(['sale_id']);
      }
      if([this.create_booking.client_id].filter((e) => e == null).length || 
        [this.create_booking.booking_type_id, this.create_booking.datetime].filter( (e) => e == null).length || 
        [this.create_booking.sale_id].filter((e) => e == null).length ||
        [this.create_booking.booking_car_types[0]?.booking_type_id].filter((e) => e == null).length) {
          return;
      }
      if([this.create_booking.client_id].filter((e) => e == null).length){
        this.activeKey.push("1");
        this.$nextTick(() => {
          this.$refs.clientItem.$el.scrollIntoView({ behavior: "smooth", block: "start" });
        });
        this.showModalRequireInput = true
      }else if([this.create_booking.booking_type_id, this.create_booking.datetime].filter( (e) => e == null).length){
        this.activeKey.push("2");
        this.$nextTick(() => {
          this.$refs.typeBooking.$el.scrollIntoView({ behavior: "smooth", block: "start" });
        });
        this.showModalRequireInput = true
      }else if([this.create_booking.sale_id].filter((e) => e == null).length){
      this.activeKey.push("7");
      this.$nextTick(() => {
        this.$refs.clientCS.$el.scrollIntoView({ behavior: "smooth", block: "start" });
      });
      this.showModalRequireInput = true        
      }
      if([this.create_booking.client_id].filter((e) => e == null).length || 
        [this.create_booking.booking_type_id, this.create_booking.datetime].filter( (e) => e == null).length || 
        [this.create_booking.sale_id].filter((e) => e == null).length){
          return;
      }
      try {
        const hideLoading = message.loading({
          content: "กำลังบันทึกข้อมูล...",
          key: "create_booking",
          duration: 0,  // Keep the loading message until explicitly hidden
      });
      if (!this.create_booking.program && !this.create_booking.map) {
        this.create_booking.program = [];
        this.create_booking.map = [];
      }
      const photo_pool = [
        ...this.create_booking.booking_files,
        ...this.create_booking.program,
        ...this.create_booking.map,
      ];
      if (photo_pool.length > 0) {
        for (var i = 0; i < photo_pool.length; i++) {
          const target = photo_pool[i];
          if(target.originFileObj){
            this.create_booking["photo_" + (i + 1)] = await this.getBase64(
              target.originFileObj
            );
          }
        }
      }
      const { datetime } = this.create_booking;
      this.create_booking.time_start = datetime?.[0].format("YYYY-MM-DD HH:mm:ss");
      this.create_booking.time_end = datetime?.[1].format("YYYY-MM-DD HH:mm:ss");
      this.create_booking.company_id = JSON.parse(localStorage.getItem("companyData")).id;
      // parse json to string
      const remarkDetail =  JSON.stringify(this.remark_detail);
      this.create_booking.booking_car_types.forEach((bookingCarTypeTime, index) => {
        if (bookingCarTypeTime.datetime) {
          this.create_booking.booking_car_types[index].time_start = bookingCarTypeTime.datetime[0].format("YYYY-MM-DD HH:mm:ss");
          this.create_booking.booking_car_types[index].time_end = bookingCarTypeTime.datetime[1].format("YYYY-MM-DD HH:mm:ss");
        }
      });
      const {
        booking_car_types,
        extras,
        photo_1,
        photo_2,
        photo_3,
        photo_4,
        photo_5,
        photo_6,
        photo_7,
        photo_8,
        photo_9,
        photo_10,
        photo_11,
        photo_12,
        client_id,
        client_contact_id,
        company_id,
        time_start,
        time_end,
        discount,
        full_day_amount,
        person_qty,
        booking_type_id,
        sale_id,
        flight_pick,
        flight_drop,
        stops,
        trip_id,
        remark,
        booking_cars,
        inc_vat,
        withholding,
        start_location_name,
        end_location_name,
        car_company_id,
        qt_setting,
        temporary_coordinator,
        temporary_coordinator_phone_no,
        photo_expenses_1,
        photo_expenses_2,
        photo_expenses_3,
        remark_more,
        // progress_1,
        client_branch_id
      } = this.create_booking;
      const booking_body = {
        booking_car_types,
        extras,
        photo_1,
        photo_2,
        photo_3,
        photo_4,
        photo_5,
        photo_6,
        photo_7,
        photo_8,
        photo_9,
        photo_10,
        photo_11,
        photo_12,
        client_id,
        car_company_id,
        client_contact_id,
        company_id,
        time_start,
        time_end,
        discount : this.form.discount,
        full_day_amount,
        person_qty,
        booking_type_id,
        sale_id,
        flight_pick,
        flight_drop,
        stops,
        trip_id,
        remark,
        net_price: this.form.total_price,
        booking_cars: this.from_booking
          ? booking_cars.map((v) => {
              const { id, ...rest } = v;
              return rest;
            })
          : booking_cars,
        progress: this.from_booking ? 2 : null,
        // progress_1,
        client_branch_id,
        inc_vat,
        withholding,
        start_location_name,
        end_location_name,
        qt_setting,
        temporary_coordinator,
        temporary_coordinator_phone_no,
        photo_expenses_1: photo_expenses_1 && photo_expenses_1.length > 0 ? await this.getBase64(photo_expenses_1[0].originFileObj) : undefined,
        photo_expenses_2: photo_expenses_2 && photo_expenses_2.length > 0 ? await this.getBase64(photo_expenses_2[0].originFileObj) : undefined,
        photo_expenses_3: photo_expenses_3 && photo_expenses_3.length > 0 ? await this.getBase64(photo_expenses_3[0].originFileObj) : undefined,
        list_id_remark: remarkDetail,
        remark_more: remark_more,
      };

      if (this.on_booking == false && this.render_type != "add") {
        booking_body.qt_updated_at = dayjs().format("YYYY-MM-DD HH:mm:ss");
      }
      if(this.form.pay_type){
        this.cal_price(this.form.pay_type, this.create_booking.inc_vat, this.create_booking.withholding, this.form.discount)
      }
      if (qt_setting) {
        const new_json = JSON.parse(qt_setting);
        new_json.price_percent = this.form.price_percent;
        new_json.pay_amount = this.form.pay_amount;
        new_json.total_price = this.form.pay_type ? this.form.total_price : 0;
        new_json.pay_type = this.form.pay_type;
        new_json.pay_extend = this.form.pay_extend;
        new_json.discount = this.form.discount;
        new_json.reason_discount = this.form.reason_discount;
        new_json.inc_vat = this.create_booking.inc_vat;
        new_json.withholding = this.create_booking.withholding

        // console.log(new_json);

        booking_body.qt_setting = JSON.stringify(new_json);
        this.cal_price(1, this.create_booking.inc_vat, this.create_booking.withholding, this.form.discount)
        booking_body.net_price = this.form.total_price;
      } else {
        const new_json = {};
        new_json.total_price = this.form.pay_type ? this.form.total_price : 0;
        new_json.pay_extend = this.form.pay_extend;
        new_json.pay_type = this.form.pay_type;
        new_json.price_percent = this.form.price_percent;
        new_json.pay_amount = this.form.pay_amount;
        new_json.discount = this.form.discount;
        new_json.reason_discount = this.form.reason_discount;
        new_json.inc_vat = this.create_booking.inc_vat;
        new_json.withholding = this.create_booking.withholding

        booking_body.qt_setting = JSON.stringify(new_json);
        this.cal_price(1, this.create_booking.inc_vat, this.create_booking.withholding, this.form.discount)
        booking_body.net_price = this.form.total_price;
      }

      if (client_id !== null 
        && client_id !== undefined 
        && client_id !== ""
        && this.create_booking.phone_no !== null
        && this.create_booking.phone_no !== undefined
        && this.create_booking.phone_no !== ""
      ) {
        await ClientService.updatePhoneNo(client_id, this.create_booking.phone_no);
      }

      if (client_contact_id !== null 
        && client_contact_id !== undefined 
        && client_contact_id !== ""
        && this.create_booking.client_contact.phone_no !== null 
        && this.create_booking.client_contact.phone_no !== undefined 
        && this.create_booking.client_contact.phone_no !== ""
      ){
        await ClientContactService.updatePhoneNo(client_contact_id, this.create_booking.client_contact.phone_no);      
      }

      if (this.render_type == "add") {
        if (this.create_booking.company_id == 39 || this.businessTypeId == 2) {
          booking_body.progress = 0;
          // booking_body.progress_1 = 1;
        }
        // console.log("booking_body: ",booking_body)
        await SystemService.create_all("Booking", {data: [booking_body],}).then((res) => {
          console.log("res booking", res);
          if (res) {
            hideLoading();
            message.success({
              content: "บันทึกข้อมูลสำเร็จ",
              key: "create_booking",
            });
            this.refresh_data();
            
            if (this.booking_sjinda && this.on_booking) {
              if (!this.create_booking.booking_cars.length >= 1) {
                SystemService.update_all("Booking",{ data: { progress_1: 1} }, res.data.id);
                const taget_url = `/app/quotation/${res.data.id}?edit=true`;
                this.$router.push(taget_url);
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              } else {
                const taget_url = `/app/${
                  this.from_booking || this.on_booking ? "booking" : "quotation"
                }/all`;
                this.$router.push(taget_url);
              }
            } else {
              const taget_url = `/app/${
                this.from_booking || this.on_booking ? "booking" : "quotation"
              }/all`;
              this.$router.push(taget_url);
            }
            
            // this.$router.go(-1)
          }
        });
      } else {
        // console.log("888888")
        // console.log(this.bookingUpdated["net_price"])
        const date_start = dayjs(booking_body.time_start);
        const date_end = dayjs(booking_body.time_end);
        const dayDifference = date_end.diff(date_start, "days") + 1;
        

        let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
        let sumUpdatedDriverCost = 0
        let sumNetIncome = 0
        let sumExtra = 0
        booking_body.booking_cars.forEach(car => {
          sumUpdatedDriverCost += Number(car.driver_cost);
        });
        booking_body.booking_car_types.forEach(car => {
          sumNetIncome += Number(car.cost);
        });
        this.create_booking.extras.forEach(extracost =>{
          sumExtra += (Number(extracost.amount)*(extracost.price))
        });
        sumNetIncome += sumExtra

        if (booking_body.client_id != this.currentClientId) {
          this.bookingUpdated["client_name"] = currentTime;
        }
        if (booking_body.booking_type_id != this.currentBookingTypeId) {
          this.bookingUpdated["booking_type"] = currentTime;
        }
        if (booking_body.time_start != this.currentTimeStart) {
          this.bookingUpdated["time_start"] = currentTime;
        }
        if (booking_body.time_end != this.currentTimeEnd) {
          this.bookingUpdated["time_end"] = currentTime;
        }
        if (dayDifference != this.currentFullDayAmount) {
          this.bookingUpdated["total_days"] = currentTime;
        }
        if (booking_body.start_location_name != this.currentStartLocationName) {
          this.bookingUpdated["start_location_name"] = currentTime;
        }
        if (booking_body.end_location_name != this.currentEndLocationName) {
          this.bookingUpdated["end_location_name"] = currentTime;
        }
        if (booking_body.person_qty != this.currentPersonQty) {
          this.bookingUpdated["person_qty"] = currentTime;
        }
        if (booking_body.booking_cars.length != this.currentCarQty) {
          this.bookingUpdated["booking_car_types"] = currentTime;
        }
        if (sumUpdatedDriverCost != this.currentDriverCost) {
          this.bookingUpdated["driverCost"] = currentTime;
        }
        if (sumNetIncome != this.currentNetIncome) {
          this.bookingUpdated["net_price"] = currentTime;
        }

        // update photo gas_refills
        for (const car of this.create_booking.booking_cars) {
            if (car.gas_refills) {
                for (const gas of car.gas_refills) {
                  const data =  { 
                    photo_1: gas.photo_1_show.length != 0 ? gas.photo_1 : null,
                    photo_2: gas.photo_2_show.length != 0 ? gas.photo_2 : null,
                    photo_3: gas.photo_3_show.length != 0 ? gas.photo_3 : null,
                    photo_4: gas.photo_4_show.length != 0 ? gas.photo_4 : null,
                    photo_5: gas.photo_5_show.length != 0 ? gas.photo_5 : null,
                    photo_6: gas.photo_6_show.length != 0 ? gas.photo_6 : null,
                    photo_7: gas.photo_7_show.length != 0 ? gas.photo_7 : null,
                    photo_8: gas.photo_8_show.length != 0 ? gas.photo_8 : null,
                    photo_9: gas.photo_9_show.length != 0 ? gas.photo_9 : null,
                    photo_10: gas.photo_10_show.length != 0 ? gas.photo_10 : null,
                  }
                  await SystemService.update_all("GasRefill", { data: data }, gas.id);
              };
          }
        };

        // update photo services
        for (const car of this.create_booking.booking_cars) {
          if (car.service) {
                const data =  { 
                  photo_1: car.service.photo_1_show.length != 0 ? car.service.photo_1 : null,
                  photo_2: car.service.photo_2_show.length != 0 ? car.service.photo_2 : null,
                  photo_3: car.service.photo_3_show.length != 0 ? car.service.photo_3 : null,
                  photo_4: car.service.photo_4_show.length != 0 ? car.service.photo_4 : null,
                  photo_5: car.service.photo_5_show.length != 0 ? car.service.photo_5 : null,
                  photo_6: car.service.photo_6_show.length != 0 ? car.service.photo_6 : null,
                  photo_7: car.service.photo_7_show.length != 0 ? car.service.photo_7 : null,
                  photo_8: car.service.photo_8_show.length != 0 ? car.service.photo_8 : null,
                  photo_9: car.service.photo_9_show.length != 0 ? car.service.photo_9 : null,
                  photo_10: car.service.photo_10_show.length != 0 ? car.service.photo_10 : null,
                  photo_11: car.service.photo_11_show.length != 0 ? car.service.photo_11 : null,
                  photo_12: car.service.photo_12_show.length != 0 ? car.service.photo_12 : null,
                }
                await SystemService.update_all("Service", { data: data }, car.service.id);
            };
        }

        console.log(sumNetIncome,this.currentNetIncome)
        this.bookingUpdated["booking_no"] = this.create_booking.booking_no;
        await BookingService.booking_update_timestamp(this.bookingUpdated)
        await SystemService.update_all(
          "Booking",
          { data: booking_body },
          this.create_booking.id
        ).then(async (res) => {
          if (res) {
            hideLoading();
            // this.refresh_data();
            message.success({
              content: "บันทึกข้อมูลสำเร็จ",
              key: "create_booking",
            });
            this.refresh_data();

            const taget_url = `/app/${
              this.from_booking || this.on_booking ? "booking" : "quotation"
            }/all`;
            this.$router.push(taget_url);

            // this.$router.go(-1)
            this.refresh_data();
          }
        });
      }
      this.refresh_data();

      } catch (error) {
        console.log("Error : ",error);
      }

    },

    methods: {
      async upload_files(e, key, target, id) {
    if (target && !id) {
      await this.getBase64(e.file.originFileObj, key, target);
    } else if (id) {
      await this.getBase64(e.file.originFileObj, key, target, id);
    } else {
      await this.getBase64(e.file.originFileObj, key);
    }

    return `https://mocki.io/v1/a6c8d0ec-4de1-4a61-870c-4e4751fb8fda`;
  },

  async getBase64(file, key, target, id) {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64String = reader.result;

        if (target) {
          target[key] = base64String;
        } else if (id) {
          await SystemService.update_all(id.model, { data: { [key]: base64String } }, id.id);
        } else {
          this.service[key] = base64String;
        }
      };
    } catch (err) {
      console.error("เกิดข้อผิดพลาดในการแปลงไฟล์เป็น Base64:", err);
    }
  },

  async remove_uploaded_file({ file, index, uid }) {
    if (uid) {
      const fileIndex = this.gas.photo_2_show.findIndex(f => f.uid === file.uid);
      if (fileIndex !== -1) {
        this.gas.photo_2_show.splice(fileIndex, 1);
      }
    } else if (index !== undefined) {
      this.gas.photo_1_show.splice(index, 1);
      this.gas.photo_2_show.splice(index, 1);
      this.gas.photo_3_show.splice(index, 1);
      this.gas.photo_4_show.splice(index, 1);
      this.gas.photo_5_show.splice(index, 1);
      this.gas.photo_6_show.splice(index, 1);
      this.gas.photo_7_show.splice(index, 1);
      this.gas.photo_8_show.splice(index, 1);
      this.gas.photo_9_show.splice(index, 1);
      this.gas.photo_10_show.splice(index, 1);
      this.gas.bill_photo_show.splice(index, 1);
    } else {
      const fileIndex = this.gas.photo_2_show.findIndex(f => f.uid === file.uid);
      if (fileIndex !== -1) {
        this.gas.photo_2_show.splice(fileIndex, 1);
      }
    }
  },
  async getBase64(file, key, direct, id) {
  try {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const base64String = reader.result;

      if (this.service[key]) {
        if (Array.isArray(this.service[key]) || typeof this.service[key] === 'string') {
        }
      }
      
      this.service[key] = base64String;
    };
  } catch (err) {
    console.error("Error converting file to Base64:", err);
  }
}

},
    add_booking_expense() {
      this.show_add_booking_extra = true;
    },
    remove_booking_expense(index, id) {
      if (id) {
        Swal.fire({
          title: "คุณต้องการลบรายการนี้ใช่หรือไม่?",
          text: "คุณจะไม่สามารถกู้คืนรายการนี้ได้หากลบแล้ว!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ใช่, ลบเดี๋ยวนี้!",
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            SystemService.delete_all("BookingExtra", id).then((res) => {
              if (res) {
                Swal.fire("ลบแล้ว!", "รายการของคุณถูกลบแล้ว", "success");
                this.create_booking.extras.splice(index, 1);
                this.refresh_data();
              }
            });
          }
        });
      } else {
        this.create_booking.extras.splice(index, 1);
      }
    },
    remove_booking_car_type_id(id) {
      Swal.fire({
        title: "คุณต้องการลบประเภทรถด้วยหรือไม่?",
        text: "รถที่เลือกเป็นรายการสุดท้ายของประเภทรถ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่, ลบเดี๋ยวนี้!",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          SystemService.delete_all("BookingCarType", id).then((res) => {
            if (res) {
              Swal.fire("ลบแล้ว!", "รายการของคุณถูกลบแล้ว", "success");
              this.refresh_data();
            }
          });
        } else {
          this.refresh_data();
        }
      });
    },
    async sync_booking_car_with_quotation(booking_car_type) {
      const [qt_time_start, qt_time_end] = this.create_booking.datetime;
      var time_start = dayjs(qt_time_start).format("YYYY-MM-DD HH:mm:ss");
      var time_end = dayjs(qt_time_end).format("YYYY-MM-DD HH:mm:ss");

      await SystemService.update_all(
        "Booking",
        { data: { time_start, time_end } },
        this.create_booking.id
      );
      SystemService.update_all(
        "BookingCarType",
        {
          data: {
            time_start,
            time_end,
          },
        },
        booking_car_type.id
      ).then(async (res) => {
        if (res) {
          this.refresh_data();
          Swal.fire("แก้ไขเวลาสำเร็จ!", "ระบบได้แก้ไขเวลาสำเร็จแล้ว", "success");
        }
      });
    },
    remove_booking_car_types(index) {
      const { id } = this.create_booking.booking_car_types[index];
      if (id) {
        Swal.fire({
          title: "คุณต้องการลบรายการนี้ใช่หรือไม่?",
          text: "คุณจะไม่สามารถกู้คืนรายการนี้ได้หากลบแล้ว!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ใช่, ลบเดี๋ยวนี้!",
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            SystemService.delete_all("BookingCarType", id).then((res) => {
              if (res) {
                Swal.fire("ลบแล้ว!", "รายการของคุณถูกลบแล้ว", "success");
                this.create_booking.booking_car_types.splice(index, 1);
                this.refresh_data();
              }
            });
          }
        });
      }
      else {
        Swal.fire({
          title: "คุณต้องการลบรายการนี้ใช่หรือไม่?",
          text: "คุณจะไม่สามารถกู้คืนรายการนี้ได้หากลบแล้ว!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ใช่, ลบเดี๋ยวนี้!",
          cancelButtonText: "ยกเลิก",
        }).then(() => {
            this.create_booking.booking_car_types.splice(index, 1);
            this.refresh_data();
        });
      }
    },
    booking_car_re_order(array) {
      const new_order = [];
      const sortable =
        array.filter(({ stops }) => stops.length > 0).length == array.length;
      if (sortable) {
        const sorted = array.sort(
          (a, b) =>
            new Date(
              a.stops[
                a.stops.indexOf(({ stop_type_id }) => stop_type_id == 1)
              ].expected_timestamp
            ) -
            new Date(
              b.stops[
                b.stops.indexOf(({ stop_type_id }) => stop_type_id == 1)
              ].expected_timestamp
            )
        );
        for (let i = 0; i < sorted.length; i++) {
          const { id } = sorted[i];
          SystemService.update_all("BookingCar", { data: { order_pos: i + 1 } }, id).then(
            (res) => {
              if (res) {
                this.$message.success(`รายการที่ ${i + 1} ถูกเรียงลำดับใหม่แล้ว`);
              }
            }
          );
        }
        this.refresh_data();
      } else {
        this.$message.error("ไม่สามารถเรียงลำดับได้ เนื่องจากมีรถบางคันยังไม่มีจุดหมาย");
      }
    },
    submit_add_booking_car_types(e) {
      const { 
        datetime, price, quantity, expenses, id, flight_pick, flight_drop, booking_id, 
        booking_type_id, car_group, car_group_id, car_type, car_type_id, cost, 
        person_qty, remark, time_start, time_end, total_days 
      } = this.booking_car_types;
      const bodyBookingCarType = {
        booking_id, 
        booking_type_id,
        car_group,
        car_group_id,
        car_type,
        car_type_id,
        cost: quantity * price + (expenses ? 
          expenses.map(({ price, amount }) => price * amount).reduce((a, b) => a + b, 0) 
          : 0),
        person_qty,
        price,
        quantity,
        expenses,
        id,
        remark,
        total_days,
        time_start: datetime[0].format ? datetime[0].format("YYYY-MM-DD HH:mm:ss") : time_start,
        time_end: datetime[1].format ? datetime[1].format("YYYY-MM-DD HH:mm:ss") : time_end,
        flight_pick: flight_pick ? flight_pick.format("YYYY-MM-DD HH:mm") : null,
        flight_drop: flight_drop ? flight_drop.format("YYYY-MM-DD HH:mm") : null,
        // on_quotation: 1,
      };
      SystemService.update_all( "Booking",{ data: { on_quotation: 1 } }, this.create_booking.id)

      if ((bodyBookingCarType.flight_pick != '' && bodyBookingCarType.flight_pick != null)  &&
        !(bodyBookingCarType.flight_pick >= bodyBookingCarType.time_start && bodyBookingCarType.flight_pick <= bodyBookingCarType.time_end)) 
      {
        Swal.fire({
          icon: 'error',  
          title: 'ไฟท์รับ-ไฟท์ส่ง',
          text: 'โปรดเลือกวันที่ที่อยู่ในช่วงวันเวลารับ-ส่ง'
        })
        return true
      }

      if ((bodyBookingCarType.flight_drop != ''&& bodyBookingCarType.flight_drop != null) &&
        !(bodyBookingCarType.flight_drop <= bodyBookingCarType.time_end && bodyBookingCarType.flight_drop >= bodyBookingCarType.time_start)) 
      {
        Swal.fire({
          icon: 'error',  
          title: 'ไฟท์รับ-ไฟท์ส่ง',
          text: 'โปรดเลือกวันที่ที่อยู่ในช่วงวันเวลารับ-ส่ง'
        })
        return true
      }

        if (id) {
          Swal.fire({
            title: "คุณต้องการแก้ไขรายการนี้ใช่หรือไม่?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ใช่, แก้ไขเดี๋ยวนี้!",
            cancelButtonText: "ยกเลิก",
          }).then((result) => {
            if (result.isConfirmed) {
              SystemService.update_all(
                "BookingCarType",
                { data: bodyBookingCarType },
                id
              ).then(async (res) => {
                if (res) {
                  for (const expense of bodyBookingCarType.expenses) {
                    const {price, amount, expense_id, id: expenseId} = expense;
                    const body = {
                      price, 
                      amount, 
                      expense_id,
                    };
                    if (expenseId) {
                      await SystemService.update_all("CarTypeExpense", 
                        {data: body},
                        expenseId
                      );
                    }
                  }
                  this.show_add_booking_car_types = false;
                  Swal.fire("แก้ไขแล้ว!", "รายการของคุณถูกแก้ไขแล้ว", "success");
                  this.create_booking.booking_car_types[
                      this.booking_car_types.index
                  ] = bodyBookingCarType;
                  this.booking_car_types = {
                    expenses: [],
                  };
                }
              });
            }
          });
        } else {
          const target = this.create_booking.booking_car_types.find(
            ({ car_group_id, start_point, end_point, price }) => {
              return (
                car_group_id == this.booking_car_types.car_group_id &&
                start_point == this.booking_car_types.start_point &&
                end_point == this.booking_car_types.end_point &&
                price == this.booking_car_types.price
              );
            }
          );
          if (this.booking_car_types.index||this.booking_car_types.index == 0) {
            const expenseCost = this.booking_car_types.expenses.reduce((total,expense) => {
              return total + (Number(expense.price) * Number(expense.amount))
            }, 0)
            this.booking_car_types.cost = this.booking_car_types.price * this.booking_car_types.quantity + expenseCost
            this.create_booking.booking_car_types[
                this.booking_car_types.index
            ] = bodyBookingCarType;
            this.booking_car_types.time_start = datetime[0].format ? datetime[0].format("YYYY-MM-DD HH:mm:ss") : this.booking_car_types.time_start;
            this.booking_car_types.time_end = datetime[1].format ? datetime[1].format("YYYY-MM-DD HH:mm:ss") : this.booking_car_types.time_end;
            delete this.create_booking.booking_car_types[this.booking_car_types.index].index
            this.show_add_booking_car_types = false;
            message.success({
              content: "แก้ไขรายการสำเร็จ",
              key: "create_booking",
            });
          } else {
            this.show_add_booking_car_types = false;
            message.success({
              content: "เพิ่มรายการสำเร็จ",
              key: "create_booking",
            });          
            const expenseCost = this.booking_car_types.expenses.reduce((total,expense) => {
              return total + (Number(expense.price) * Number(expense.amount))
            }, 0)
            this.booking_car_types.cost = this.booking_car_types.price*this.booking_car_types.quantity + expenseCost
            this.booking_car_types.time_start = datetime[0].format ? datetime[0].format("YYYY-MM-DD HH:mm:ss") : null;
            this.booking_car_types.time_end = datetime[1].format ? datetime[1].format("YYYY-MM-DD HH:mm:ss") : null;
            this.booking_car_types.flight_pick = flight_pick ? flight_pick.format("YYYY-MM-DD HH:mm") : null;
            this.booking_car_types.flight_drop = flight_drop ? flight_drop.format("YYYY-MM-DD HH:mm") : null;
            // this.booking_car_types.on_quotation = 1;
            this.create_booking.booking_car_types.push(this.booking_car_types);
            // this.create_booking_cars.push(this.booking_car_types)
            this.booking_car_types = {
              expenses: [],
            };
          }
        }
        this.cal_price(this.form.pay_type ?? 1, this.create_booking.inc_vat, this.create_booking.withholding, this.form.discount)
    },
    submit_extra() {
      this.extra.name = this.extra_list.find(({ id }) => id == this.extra.extra_id)?.name;
      const { id, name, price, amount, extra_id } = this.extra;
      if (this.create_booking.extras) {
        //check if not duplicate
        const target = this.create_booking.extras.find(({ price, amount, extra_id }) => {
          return (
            price == this.extra.price &&
            amount == this.extra.amount &&
            extra_id == this.extra.extra_id
          );
        });
        if (!target) {
          this.create_booking.extras.push({ name, price, amount, extra_id });
        }
        if (id) {
          SystemService.update_all("BookingExtra", { data: this.extra }, id);
        }
      } else {
        this.create_booking.extras = [{ name, price, amount, extra_id }];
      }
      this.extra = {};
      this.show_add_booking_extra = false;
      this.cal_price(this.form.pay_type ?? 1, this.create_booking.inc_vat, this.create_booking.withholding, this.form.discount)
    },
    submit_add_expense() {
      this.add_expense.name = this.expense_list.find(
        ({ id }) => id == this.add_expense.expense_id
      )?.name;
      const { name, price, amount, expense_id } = this.add_expense;
      this.booking_car_types.expenses.push({ name, price, amount, expense_id });
      this.add_expense = {};
      this.cal_price(this.form.pay_type ?? 1, this.create_booking.inc_vat, this.create_booking.withholding, this.form.discount)
    },
    async submit_edit_expense() {
      const expenseNew = JSON.parse(JSON.stringify(this.booking_car_types.expenses));
      this.edit_expense.name = this.expense_list.find(
        ({ id }) => id == this.edit_expense.expense_id
      )?.name;
      const { name, price, amount, expense_id, index, id } = this.edit_expense;
      expenseNew[index].price = price;
      expenseNew[index].amount = amount;
      expenseNew[index].expense_id = expense_id;
      expenseNew[index].name = name;
      this.booking_car_types.expenses = expenseNew;
      
      this.show_edit_expense = false;
    },
    edit_expense_table(obj, instance, drawer, index) {
      const { name, price, amount, expense_id, id } = obj;
      this.edit_expense = { name, price, amount, expense_id, index, id };
      this[drawer] = true;
    },
    add_booking_car_types(car_index) {
      this.show_add_booking_car_types = true;
      if (car_index != null) {
         setTimeout(() => {
          const bookingCarType = {
            ...this.create_booking.booking_car_types[car_index],
            expenses: [...this.create_booking.booking_car_types[car_index].expenses], // if `expenses` is an array
            datetime: this.create_booking.booking_car_types[car_index].datetime ? [...this.create_booking.booking_car_types[car_index].datetime] : null, // if `datetime` is an array
          };
          this.booking_car_types = bookingCarType;

          const { start_point, end_point, flight_pick, flight_drop, time_start, time_end } = this.create_booking.booking_car_types[car_index];
          this.booking_car_types.datetime = this.booking_car_types.datetime? this.booking_car_types.datetime : time_start&&time_end ? [dayjs(time_start), dayjs(time_end)] : [];
          if (flight_pick) {
            this.booking_car_types.flight_pick = dayjs(flight_pick);
          }
          if (flight_drop) {
            this.booking_car_types.flight_drop = dayjs(flight_drop);
          }
          this.booking_car_types.index = car_index;
          if (!this.booking_car_types.remark) {
            const bookType = this.booking_types.find(
              ({ id }) => id == this.create_booking.booking_type_id
            ).name;
            this.booking_car_types.remark = `ค่าขนส่งโดยสารปรับอากาศ\nเดินทาง\n${bookType}\nวันที่ ${this.booking_car_types.datetime
              .map((e) => e.locale("th").format("DD MMMM BBBB HH:mm"))
              .join(" - ")})}`;
          }

          this.$nextTick(() => {
            document.getElementById("start_point").value = '';
            document.getElementById("end_point").value = '';
            if (start_point) {
              document.getElementById("start_point").value = start_point;
            }
            if (end_point) {
              document.getElementById("end_point").value = end_point;
            }
           });
        }, 300);
      } else {
        setTimeout(() => {
          this.booking_car_types = {
            expenses: [],
            price: 0,
          };
          const startDate = dayjs().hour(0).minute(0);
          const endDate = dayjs().hour(0).minute(0);
          this.booking_car_types.datetime = [startDate, endDate];
           // this.booking_car_types.flight_pick = dayjs().hour(0).minute(0);
           // this.booking_car_types.flight_drop = dayjs().hour(0).minute(0);

          if (!this.booking_car_types.remark) {
            this.booking_car_types.remark = `ค่าขนส่งโดยสารปรับอากาศ\nเดินทาง\nวันที่`;
          }

          this.$nextTick(() => {
            document.getElementById("start_point").value = '';
            document.getElementById("end_point").value = '';
          });
        }, 350);
      }
    },
    async remove_booking(id, quotation_no) {
      await Swal.fire({
        icon: "error",
        title: "ลบใบเสนอราคา",
        text: `คุณต้องการลบใบเสนอราคาเลขที่ ${quotation_no} หรือไม่`,
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          await BookingService.delete_booking(id).then((res) => {
            if (res) {
              Swal.fire({
                icon: "success",
                title: "สำเร็จ",
                text: "ลบข้อมูลสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              });
              this.refresh_data();
            }
          });
        }
      });
    },
    async duplicate_booking(id) {
      message.loading({ content: "กำลังโหลด...", key: "loading" });
      let data = await BookingService.duplicate_booking(id);
      SystemService.update_all("Booking", { data: { progress: 0, billing_status: 0 } }, data.client_booking.id).then(
        (res) => {
          if (res) {
            this.refresh_data();
            message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
          }
        }
      )
    },
    edit_quotation(id) {
      this.$router.push(`/app/quotation/${id}`);
      this.init_edit_mode();
    },
    create_export_close() {
      this.booking_detail = null;
      this.show_export_modal = false;
      this.refresh_data();
    },
    open_create_invoice(quotation, alter_title) {
      SystemService.update_all("Booking", { data: { progress: 1 } }, quotation.id);
      this.show_export_modal = true;
      this.booking_detail = quotation;
      this.booking_detail.alter_title = alter_title;
    },
    select_row(selectedRowKeys) {
      this.selected_quotation = selectedRowKeys;
    },
    async init_all_bookings() {
      this.loading = true;

      const timeRange = this.datetime;
      const searchBody = {
        search: this.search_input,
        booking_type_id: this.search_booking_type_id,
      };

      if (timeRange) {
        searchBody.time_start = dayjs(timeRange[0]).format("YYYY-MM-DD HH:mm:ss");
        searchBody.time_end = dayjs(timeRange[1]).format("YYYY-MM-DD HH:mm:ss");
      }

      const datas = await BookingService.get_bookings(
        this.pagination?.onpage,
        this.pagination?.perpage,
        this.search_progress,
        searchBody
      );
      // console.log("get_bookings: ", datas)
      this.pagination = {
        total: datas.total,
        onpage: datas.page,
        perpage: datas.perpaperPagege,
      };
      this.booking_list = datas.data;
      this.loading = false;
    },
    async init_create_booking_options() {
      this.loading = true;
      this.show_create_client = false;
      this.show_create_company = false;
      const [
        client_company_list,
        client_contact_list,
        tour_types_list,
        company_trip_list,
        car_type_list,
        car_group_list,
        expense_list,
        sales_list,
        booking_types,
        driver_list,
        stop_type_list,
        car_status_list,
        guide_list,
        gas_station_list,
        pay_method_list,
        extra_list,
        tour_list,
        driver_costs_list,
        booking_status_list,
        car_company_list,
        remarks_list,
        cars_with_driver,
        repair,
        cars_with_repair,
        cars_with_backup_driver,
        client_branch_list,
      ] = await Promise.all([
        SystemService.get_all("Client"),
        SystemService.get_all("ClientContact"),
        SystemService.get_all("TourType"),
        SystemService.get_all("Trip"),
        SystemService.get_all("CarType"),
        SystemService.get_all("CarGroup"),
        SystemService.get_all("Expense"),
        SystemService.get_all("Sale"),
        SystemService.get_all("BookingType"),
        SystemService.get_all("Driver"),
        SystemService.get_all("StopType"),
        SystemService.get_all("CarStatus"),
        SystemService.get_all("Guide"),
        SystemService.get_all("GasStation"),
        SystemService.get_all("PayMethod"),
        SystemService.get_all("Extra"),
        SystemService.get_all("Tour"),
        SystemService.get_all("DriverCost"),
        SystemService.get_all("BookingStatusText"),
        SystemService.get_all("CarCompany"),
        SystemService.get_all("BookingRemark"),
        SystemService.get_core("cars_with_driver"),
        SystemService.get_core("repair"),
        SystemService.get_core("cars_with_repair"),
        SystemService.get_core("cars_with_backup_driver"),
        SystemService.get_all("ClientBranch"),

      ]);

      this.car_company_list = car_company_list;
      this.client_company_list = client_company_list;
      this.client_contact_list = client_contact_list;
      this.tour_types_list = tour_types_list;
      this.company_trip_list = company_trip_list;
      this.car_type_list = car_type_list;
      this.car_group_list = car_group_list;
      this.expense_list = expense_list;
      this.sales_list = sales_list;
      this.booking_types = booking_types;
      this.driver_list = driver_list;
      this.stop_type_list = stop_type_list.filter(option => {
        return ["จุดรับ","จุดรับย่อย","จุดส่ง","จุดส่งย่อย"].includes(option.name);
      });
      this.stop_type_list = stop_type_list.filter(option => {
        return ["จุดรับ","จุดรับย่อย","จุดส่ง","จุดส่งย่อย"].includes(option.name);
      });
      this.car_status_list = car_status_list;
      this.guide_list = guide_list;
      this.gas_station_list = gas_station_list;
      this.pay_method_list = pay_method_list;
      this.extra_list = extra_list;
      this.tour_list = tour_list;
      this.driver_costs_list = driver_costs_list;
      this.booking_status_list = booking_status_list;
      this.remarks_list = remarks_list;
      this.cars_with_driver = cars_with_driver;
      this.repair = repair;
      this.cars_with_repair = cars_with_repair;
      this.cars_with_backup_driver = cars_with_backup_driver;
      //this.client_branch_list = client_branch_list;

      if (this.create_booking.client_id) {
        this.select_client();
      }
      this.loading = false;
    },
    resetBranch(){
      this.create_booking.client_branch_id = null;
    },
    async select_client() {
      this.loading = true;
      //this.create_booking.client_branch_id = null;
      const target = await SystemService.get_id(
        "Client",
        this.create_booking.client_id
      ).then((res) => {
        this.loading = false;
        return res;
      });
      const {
        address,
        branches,
        contacts,
        tour_type_id,
        tax_id,
        email,
        phone_no,
      } = target;


      this.client_branch_list = branches;
      this.client_contact_list = contacts.filter(
        ({ client_id, client_branch_id}) => this.create_booking.client_branch_id? 
        (client_id == this.create_booking.client_id && client_branch_id == this.create_booking.client_branch_id) 
        : (client_id == this.create_booking.client_id)
      );

      this.create_booking.address = address;
      this.create_booking.tour_type_id = tour_type_id;
      this.create_booking.client_contact_id = this.client_contact_list
        .map(({ id }) => id)
        .includes(this.create_booking.client_contact_id)
        ? this.create_booking.client_contact_id
        : null;
      this.create_booking.tax_id = tax_id;
      this.create_booking.email = email;
      this.create_booking.phone_no = phone_no;
      this.create_booking.tourname;
    },
    async select_branch() {
      this.loading = true;
      const target = await SystemService.get_id(
        "Client",
        this.create_booking.client_id
      ).then((res) => {
        this.loading = false;
        return res;
      });
      const {
        address,
        branches,
        contacts,
        tour_type_id,
        tax_id,
        email,
        phone_no,
      } = target;

      if (this.create_booking.client_branch_id) {
        this.client_contact_list = contacts.filter(
          ({ client_branch_id }) =>
            client_branch_id == this.create_booking.client_branch_id
        );
        this.create_booking.client_contact_id = this.client_contact_list
          .map(({ id }) => id)
          .includes(this.create_booking.client_contact_id)
          ? this.create_booking.client_contact_id
          : null;
      }
    },
    create_company_close() {
      this.show_create_company = false;
    },
    create_client_close() {
      this.show_create_client = false;
    },
    add_company() {
      this.show_create_company = true;
    },
    add_client(id) {
      this.show_create_client = true;
    },
    auto_fill_days() {
      const { datetime } = this.create_booking;
      this.create_booking.total_days = datetime[1].diff(datetime[0], "days") + 1;
    },
    handlePreview(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible = true;
      // window.open(this.previewImage);
    },
    handleIconClick(action, payload, event) {
            event.stopPropagation();
            if (action === 'preview') {
                this.showPreview(event , payload);
            } else if (action === 'delete') {
                // this.model_form[payload] = '';
                this.fileList = [];
            }
        },
        upload_files(e, key , model_name = null , index = null , sub_index = null) {
          if (e.file.status != 'remove') {
              this.getBase64(e.file.originFileObj, key)
              
              if (model_name == 'gas_refills') {
                if (key == 'photo_1_show') {
                  this.create_booking.booking_cars[index].gas_refills[sub_index].photo_1 = this.model_form.photo_1_show
                } else if (key == 'photo_2_show') {
                  this.create_booking.booking_cars[index].gas_refills[sub_index].photo_2 = this.model_form.photo_2_show
                } else if (key == 'photo_3_show') {
                  this.create_booking.booking_cars[index].gas_refills[sub_index].photo_3 = this.model_form.photo_3_show
                } else if (key == 'photo_4_show') {
                  this.create_booking.booking_cars[index].gas_refills[sub_index].photo_4 = this.model_form.photo_4_show
                } else if (key == 'photo_5_show') {
                  this.create_booking.booking_cars[index].gas_refills[sub_index].photo_5 = this.model_form.photo_5_show
                } else if (key == 'photo_6_show') {
                    this.create_booking.booking_cars[index].gas_refills[sub_index].photo_6 = this.model_form.photo_6_show
                } else if (key == 'photo_7_show') {
                    this.create_booking.booking_cars[index].gas_refills[sub_index].photo_7 = this.model_form.photo_7_show
                } else if (key == 'photo_8_show') {
                    this.create_booking.booking_cars[index].gas_refills[sub_index].photo_8 = this.model_form.photo_8_show
                } else if (key == 'photo_9_show') {
                    this.create_booking.booking_cars[index].gas_refills[sub_index].photo_9 = this.model_form.photo_9_show
                } else if (key == 'photo_10_show') {
                    this.create_booking.booking_cars[index].gas_refills[sub_index].photo_10 = this.model_form.photo_10_show
                } else if (key == 'bill_photo_show') {
                  if(index != null && sub_index != null){
                    this.create_booking.booking_cars[index].gas_refills[sub_index].bill_photo = this.model_form.bill_photo_show
                  }
                  this.bill_photo_show_source = this.model_form.bill_photo_show
                }
              }else if (model_name == 'services') {
                if (key == 'photo_1_show') {
                  this.create_booking.booking_cars[index].service.photo_1 = this.model_form.photo_1_show
                } else if (key == 'photo_2_show') {
                  this.create_booking.booking_cars[index].service.photo_2 = this.model_form.photo_2_show
                } else if (key == 'photo_3_show') {
                  this.create_booking.booking_cars[index].service.photo_3 = this.model_form.photo_3_show
                } else if (key == 'photo_4_show') {
                  this.create_booking.booking_cars[index].service.photo_4 = this.model_form.photo_4_show
                } else if (key == 'photo_5_show') {
                  this.create_booking.booking_cars[index].service.photo_5 = this.model_form.photo_5_show
                } else if (key == 'photo_6_show') {
                    this.create_booking.booking_cars[index].service.photo_6 = this.model_form.photo_6_show
                } else if (key == 'photo_7_show') {
                    this.create_booking.booking_cars[index].service.photo_7 = this.model_form.photo_7_show
                } else if (key == 'photo_8_show') {
                    this.create_booking.booking_cars[index].service.photo_8 = this.model_form.photo_8_show
                } else if (key == 'photo_9_show') {
                    this.create_booking.booking_cars[index].service.photo_9 = this.model_form.photo_9_show
                } else if (key == 'photo_10_show') {
                    this.create_booking.booking_cars[index].service.photo_10 = this.model_form.photo_10_show
                } else if (key == 'photo_11_show') {
                    this.create_booking.booking_cars[index].service.photo_11 = this.model_form.photo_11_show
                } else if (key == 'photo_12_show') {
                    this.create_booking.booking_cars[index].service.photo_12 = this.model_form.photo_12_show
                }
              } else if (model_name == 'toll_ways') {
                if (key == 'photo_1_show') {
                  // this.assign_car_form.toll_ways[index].photo_1 = this.model_form.photo_1_show
                  this.create_toll_way.photo_1 = this.model_form.photo_1_show
                }
              }
              return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
          }
        },
        async upload_file_multiple(e, key) {
            if (e.file.status != 'remove') {
                var file = e.file.originFileObj
                if (key) {
                    const vue = this
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                        if(vue.data_raw_files.map(e => e.file_name).includes(file.name)) return
                        vue.data_raw_files.push({
                            file: reader.result,
                            file_name: file.name,
                            name: '',
                        })
                    };
                } else {
                    return await new Promise((resolve, reject) => {
                        if (file) {
                            if (typeof file == 'string') {
                                resolve(file)
                            } else {
                                const reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => resolve(reader.result);
                                reader.onerror = error => reject(error);
                            }
                        }
                    });
                }
                return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
            }
        },
        async getBase64(file, key) {
          console.log('key', key);
          
            if (key) {
                const vue = this
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    vue.model_form[key] = reader.result
                    vue.model_form[`${key}_file_name`] = file.name
                };
            } else {
                return await new Promise((resolve, reject) => {
                    if (file) {
                        if (typeof file == 'string') {
                            resolve(file)
                        } else {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = error => reject(error);
                        }
                    }else{
                      reject('error')
                    }
                });
            }
        },

  getUploadedUrl(file) {
    // ตรวจสอบว่าการอัปโหลดสำเร็จและมี URL หรือไม่
    if (file.response && file.response.url) {
        return file.response.url;
    }
    return file.url || URL.createObjectURL(file.originFileObj);
  },

  async getBase64ImageFromURL(url) {
      const res = await fetch(url , {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
              'Content-Type': 'application/json'
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer'
      });
      const blob = await res.blob();
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
          resolve(reader.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
      });
  },
  async getBase64Image(e, file) {
      e.stopPropagation();
      e.preventDefault();

      if(typeof file === 'string'){
        const result = await this.getBase64ImageFromURL(file);
        let a = document.createElement('a');
        a.href = result;
        a.download = 'image.png';
        a.click();
      }else if(file.url){
        const result = await this.getBase64ImageFromURL(file.url);
  
        // download
        let a = document.createElement('a');
        a.href = result;
        a.download = 'image.png';
        a.click();
      }else{
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => {
          const base64String = reader.result;
          let a = document.createElement('a');
          a.href = base64String;
          a.download = 'image.png';
          a.click();
        };
      }
  },
  showPreview(e , fileUrl) {
    e.stopPropagation();
    this.previewImage = fileUrl;
    this.previewVisible = true;
  },

  handleDownload(file){
    this.previewImage = file.url || file.thumbUrl;
    window.open(this.previewImage);
  },

    // handleDownload(file) {
    //   const link = document.createElement('a');
    //   link.href = file.url || file.thumbUrl;
    //   link.download = file.name;
    //   this.previewImage = file.url || file.thumbUrl;
    //   link.click();
    // },
    preview_close() {
      this.previewVisible = false;
    },
    async init_edit_mode() {
      if (this.render_type != undefined && this.render_type != "all") {
        // await this.init_create_booking_options()
        this.create_booking = await SystemService.get_id("Booking", this.render_type);
        if (this.create_booking.booking_cars) {
          this.create_booking.booking_cars = this.create_booking.booking_cars.sort(
            (a, b) => a.order_pos - b.order_pos
          );
        }

        const {
          time_start,
          time_end,
          booking_car_types,
          booking_cars,
          photo_1,
          photo_2,
          photo_3,
          photo_4,
          photo_5,
          photo_6,
          photo_7,
          photo_8,
          photo_9,
          photo_10,
          photo_11,
          photo_12,
          address,
          phone_no,
          email,
          tax_id,
          client_contact_id,
          person_qty,
          flight_pick,
          flight_drop,
          extras,
          inc_vat,
          withholding,
          start_location_name,
          end_location_name,
          client_contact,
          qt_setting,
          photo_expenses_1,
          photo_expenses_2,
          photo_expenses_3,
          list_id_remark,
          booking_type_id,
          client_id
        } = this.create_booking;

        // if (this.create_booking.list_id_remark) {
        //   this.remark_detail = JSON.parse(list_id_remark);
        //   this.remark_detail_all = this.remark_detail_all.filter((item) => item.remark_type != null)
        //   this.remark_detail_all.forEach((e) => {
        //     if (!this.remark_detail.find((f) => f.id == e.id)) {
        //       this.remark_select.push(e);
        //     }
        //   });
        // }

        if (this.create_booking != "") {
          this.create_booking.extras = extras ? extras : [];
          this.create_booking.datetime = [dayjs(time_start), dayjs(time_end)];
          this.create_booking.address = address;
          // this.create_booking.phone_no = phone_no;
          const target = await SystemService.get_id("Client", this.create_booking.client_id);
          if (phone_no) {
            this.create_booking.phone_no = phone_no;
          } else {
            this.create_booking.phone_no = target.phone_no;
          }
         
          this.create_booking.address = target.address;
          this.create_booking.email = target.email;
          this.create_booking.tax_id = target.tax_id;
          this.create_booking.person_qty = person_qty;
          this.create_booking.booking_car_types = booking_car_types;
          this.create_booking.flight_pick = flight_pick 
          this.create_booking.flight_drop = flight_drop 
          this.create_booking.inc_vat = inc_vat ? true : false;
          this.create_booking.withholding = withholding ? true : false;
          if (start_location_name && document.getElementById("start_location_name")) {
            document.getElementById("start_location_name").value = start_location_name;
            this.create_booking.start_location_name = start_location_name;
          }
          if (end_location_name && document.getElementById("end_location_name")) {
            document.getElementById("end_location_name").value = end_location_name;
            this.create_booking.end_location_name = end_location_name;
          }

          if (!client_contact) this.create_booking.client_contact = {};

          const date_start = dayjs(this.create_booking.datetime?.[0]);
          const date_end = dayjs(this.create_booking.datetime?.[1]);
          this.create_booking.total_days = date_end.diff(date_start, "days") + 1;

          this.create_booking.booking_files = [
            photo_1,
            photo_2,
            photo_3,
            photo_4,
            photo_5,
            photo_6,
            photo_7,
            photo_8,
            photo_9,
            photo_10,
            photo_11,
            photo_12,
          ]
            .filter((e) => e)
            .map((e, i) => {
              return {
                uid: `photo_${i + 1}`,
                name: e.split("/").pop(),
                status: "done",
                url: e,
              };
            });
            this.create_booking.photo_expenses_1 = [photo_expenses_1].filter((e) => e)
            .map((e, i) => {
              return {
                uid: `photo_expenses_1`,
                name: e.split("/").pop(),
                status: "done",
                url: e,
              };
            })
            this.create_booking.photo_expenses_2 = [photo_expenses_2].filter((e) => e)
            .map((e, i) => {
              return {
                uid: `photo_expenses_2`,
                name: e.split("/").pop(),
                status: "done",
                url: e,
              };
            })
            this.create_booking.photo_expenses_3 = [photo_expenses_3].filter((e) => e)
            .map((e, i) => {
              return {
                uid: `photo_expenses_3`,
                name: e.split("/").pop(),
                status: "done",
                url: e,
              };
            })
        }

        if (booking_cars) {
          booking_cars.forEach(async (booking_car, i) => {
            try {
              const { id, service, gas_refills } = booking_car;
              for (var key in service) {
                if (key.includes("photo")) {
                  this.create_booking.booking_cars[i].service[key + "_show"] = service[
                    key
                  ]
                    ? [
                        {
                          uid: key,
                          name: "",
                          status: "done",
                          url: service[key],
                        },
                      ]
                    : [];
                }
              }

              if (!service) {
                const create_service = await SystemService.create_all("Service", {
                  data: [{ booking_car_id: id }],
                });
                console.log(create_service);
              }

              gas_refills.forEach((gas, g) => {
                for (var key in gas) {
                  if (key.includes("photo") && gas[key]) {
                    this.create_booking.booking_cars[i].gas_refills[g][key + "_show"] = [
                      {
                        uid: key,
                        name: "",
                        status: "done",
                        url: gas[key],
                      },
                    ];
                  } else {
                    this.create_booking.booking_cars[i].gas_refills[g][
                      key + "_show"
                    ] = [];
                  }
                }
              });

              return booking_car;
            } catch (err) {
              console.log(err);
              return booking_car;
            }
          });
        }

        if (qt_setting) {
          // this.create_booking.qt_setting = qt_setting
          const setting = JSON.parse(qt_setting);
          const selected_remarks_list = setting.selected_remarks_list;

          // console.log(setting);

          this.create_booking.selected_remarks_list = selected_remarks_list;
          this.price_percent = setting.price_percent ? Number(setting.price_percent) : 0;
          this.form.total_price = setting.total_price;
          if (setting.pay_type) {
            this.form.pay_type = setting.pay_type;
          }
          // this.cal_price(this.form.pay_type);
          if (setting.pay_extend) {
            this.form.pay_extend = setting.pay_extend;
          }
          if (setting.pay_amount) {
            this.form.pay_amount = setting.pay_amount;
          }

          if (setting.price_percent) {
            this.form.price_percent = setting.price_percent;
          }

          if (setting.discount) {
            this.form.discount = setting.discount;
          }

          if (setting.reason_discount) {
            this.form.reason_discount = setting.reason_discount;
          }
        } else {
          this.form.pay_type = 1;
          this.cal_price(this.form.pay_type);
        }       

        await this.initCurrentBookingData();
      }
     
      for (let i = 0; i < this.create_booking.booking_cars.length; i++) {
        this.create_booking.booking_cars[i].order_pos = i + 1;
      }
    },
    async init_mode() {
      this.form = {};
      this.price_percent = 0;
      if (this.render_type != "add" && this.render_type != "all") {
        await this.init_edit_mode();
        await this.init_remark();
      } else {
        this.booking_files = [];
        await this.init_create_booking();
        await this.init_remark();
      }
    },
    async initCurrentBookingData() {
      this.currentClientId = this.create_booking.client_id
      this.currentBookingTypeId = this.create_booking.booking_type_id
      this.currentTimeStart = this.create_booking.time_start
      this.currentTimeEnd = this.create_booking.time_end
      this.currentFullDayAmount = this.create_booking.total_days
      this.currentStartLocationName = this.create_booking.start_location_name
      this.currentEndLocationName = this.create_booking.end_location_name
      this.currentPersonQty = this.create_booking.person_qty
      this.currentCarQty = this.create_booking.booking_cars.length
      this.currentDriverCost = 0 
      this.currentNetIncome = 0
      let extraCost = 0
      this.create_booking.booking_cars.forEach(car => {
      this.currentDriverCost += Number(car.driver_cost);
      });
      this.create_booking.booking_car_types.forEach(car => {
      this.currentNetIncome += Number(car.cost);
      });
      this.create_booking.extras.forEach(extracost =>{
        extraCost += (Number(extracost.amount)*(extracost.price))
      });
      this.currentNetIncome += extraCost
      // console.log("test",this.currentClientId)
      // console.log(this.create_booking.client_id)
    },
    async refresh_data() {
      this.loading = true;
      await this.init_mode();
      await this.init_all_bookings();
      this.loading = false;

      if (this.selected_quotation) {
        this.selected_quotation = this.booking_list.find(
          ({ id }) => id == this.selected_quotation.id
        );
      }
    },
    async getStatusCarTypes() {
      // console.log("this.booking_car_types.car_type_id" , this.booking_car_types.car_type_id);
      // console.log("this.booking_car_types[0]" , this.booking_car_types.datetime[0].format("YYYY-MM-DD HH:mm:ss"));
      // console.log("this.booking_car_types[1]" , this.booking_car_types.datetime[1].format("YYYY-MM-DD HH:mm:ss"));
      if(this.booking_car_types.car_type_id != null){
        const date_start = this.booking_car_types.datetime[0].format("YYYY-MM-DD HH:mm:ss");
        const date_end = this.booking_car_types.datetime[1].format("YYYY-MM-DD HH:mm:ss");
        const car_type_id = this.booking_car_types.car_type_id;
        const body = {
          id : car_type_id,
          date_start : date_start,
          date_end : date_end,
        }
        const result = await CarService.getStatusCarTypes(body);
        this.statusCarTypes.total = result.data.total;
        this.statusCarTypes.has_event = result.data.has_event;
        this.statusCarTypes.no_event = result.data.no_event;

        const startDate = this.booking_car_types.datetime[0];
        const endDate = this.booking_car_types.datetime[1];
        const totalDays = endDate.diff(startDate, 'days') + 1;

        this.booking_car_types.total_days = totalDays;
      }
    },
    async getStatusCar() {
      if (this.assign_car_form.datetime.length === 2) {
        const start = this.assign_car_form.datetime[0];
        const end = this.assign_car_form.datetime[1];
        const totalDays = end.diff(start, 'days') + 1;
        this.assign_car_form.total_days = totalDays;
      }
    },
    handleClickDeletePhoto(e , key , model_name , index = null , sub_index = null) {
      console.log("index" , index);
      console.log("sub_index" , sub_index);
      
      console.log("this.create_booking.booking_cars", this.create_booking.booking_cars);
      
      console.log("this.car.service", this.car.service);
      console.log("this.gas", this.gas);
      
      
      e.stopPropagation(); // ป้องกันการกระทำอื่นๆ ที่เกิดขึ้น
      console.log(e , key , model_name);
      
      if(model_name == 'gas_refills'){
        if(key == 'photo_1_show'){
          this.create_booking.booking_cars[index].gas_refills[sub_index].photo_1_show = [];
        } else if(key == 'photo_2_show'){
          this.create_booking.booking_cars[index].gas_refills[sub_index].photo_2_show = [];
        } else if(key == 'photo_3_show'){
          this.create_booking.booking_cars[index].gas_refills[sub_index].photo_3_show = [];
        } else if(key == 'photo_4_show'){
          this.create_booking.booking_cars[index].gas_refills[sub_index].photo_4_show = [];
        } else if(key == 'photo_5_show'){
          this.create_booking.booking_cars[index].gas_refills[sub_index].photo_5_show = [];
        } else if(key == 'photo_6_show'){
          this.create_booking.booking_cars[index].gas_refills[sub_index].photo_6_show = [];
        } else if(key == 'photo_7_show'){
          this.create_booking.booking_cars[index].gas_refills[sub_index].photo_7_show = [];
        } else if(key == 'photo_8_show'){
          this.create_booking.booking_cars[index].gas_refills[sub_index].photo_8_show = [];
        } else if(key == 'photo_9_show'){
          this.create_booking.booking_cars[index].gas_refills[sub_index].photo_9_show = [];
        } else if(key == 'photo_10_show'){
          this.create_booking.booking_cars[index].gas_refills[sub_index].photo_10_show = [];
        } else if (key == 'bill_photo_show') {          
          this.gas.bill_photo_show = [];
          this.create_booking.booking_cars[index].gas_refills[sub_index].bill_photo = this.gas.bill_photo_show
        }
      }else if(model_name == 'services'){
        if(key == 'photo_1_show'){
          this.create_booking.booking_cars[index].service.photo_1_show = [];
        } else if(key == 'photo_2_show'){
          this.create_booking.booking_cars[index].service.photo_2_show = [];
        } else if(key == 'photo_3_show'){
          this.create_booking.booking_cars[index].service.photo_3_show = [];
        } else if(key == 'photo_4_show'){
          this.create_booking.booking_cars[index].service.photo_4_show = [];
        } else if(key == 'photo_5_show'){
          this.create_booking.booking_cars[index].service.photo_5_show = [];
        } else if(key == 'photo_6_show'){
          this.create_booking.booking_cars[index].service.photo_6_show = [];
        } else if(key == 'photo_7_show'){
          this.create_booking.booking_cars[index].service.photo_7_show = [];
        } else if(key == 'photo_8_show'){
          this.create_booking.booking_cars[index].service.photo_8_show = [];
        } else if(key == 'photo_9_show'){
          this.create_booking.booking_cars[index].service.photo_9_show = [];
        } else if(key == 'photo_10_show'){
          this.create_booking.booking_cars[index].service.photo_10_show = [];
        } else if(key == 'photo_11_show'){
          this.create_booking.booking_cars[index].service.photo_11_show = [];
        } else if(key == 'photo_12_show'){
          this.create_booking.booking_cars[index].service.photo_12_show = [];
        }
      } else if(model_name == 'toll_way'){
        if(key == 'photo_1_show'){
          // this.assign_car_form.toll_ways[index].photo_1 = null
          this.create_toll_way.photo_1 = null
        }
      }
    },
    createBookingExtrasColumns() {
      const baseColumns = [
        {
          title: 'ชื่อ',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'จำนวน',
          dataIndex: 'amount',
          key: 'amount',
          align: 'center',
          width: 150,
        },
        {
          title: 'ราคาต่อหน่วย',
          dataIndex: 'price',
          key: 'price',
          align: 'center',
          width: 150,
        },
        {
          title: 'ราคารวม',
          dataIndex: 'total',
          key: 'total',
          align: 'center',
          width: 150,
        },
      ];
      if (this.from_booking == false && this.on_booking  == false) {
        baseColumns.push({
          title: 'ตัวเลือก',
          dataIndex: 'action',
          key: 'action',
          align: 'center',
          width: 120,
        });
      }

      return baseColumns;
    },
  },
  watch: {
    'create_booking.client_id': function (newVal, oldVal) {
      if (newVal) {
        this.select_client();
      }
    },
    preselect_car_type_id(newVal) {
      this.booking_car_types.car_type_id = newVal;
    },
    'assign_car_form.main_driver_enabled'(newVal) {
      if (newVal) {
        this.assign_car_form.second_driver_enabled = false;
        this.assign_car_form.temporary_driver_enabled = false;
        this.assign_car_form.driver_id = null;
        if (this.filteredDrivers.length > 0) {
          this.assign_car_form.driver_id = this.filteredDrivers[0].id;
        }
      }
    },
    'assign_car_form.second_driver_enabled'(newVal) {
      if (newVal) {
        this.assign_car_form.main_driver_enabled = false;
        this.assign_car_form.temporary_driver_enabled = false;
        this.assign_car_form.driver_id = null;
      }
    },
    'assign_car_form.temporary_driver_enabled'(newVal) {
      if (newVal) {
        this.assign_car_form.main_driver_enabled = false;
        this.assign_car_form.second_driver_enabled = false;
        this.assign_car_form.driver_id = null;
        this.assign_car_form.temporary_driver = null;
      }
    },
    render_type() {
      this.init_mode();
    },
  },
  async mounted() {
    // if (this.render_type != 'all') {
    // 	this.init_create_booking_options();
    // }
    this.get_company();
    this.open_booking_sjinda();
    await this.init_create_booking_options();
    await this.refresh_data();
  },
};
</script>

<style scoped>

::v-deep .custom-label .ant-form-item-label label {
  color: white !important;
}

.ant-row {
  margin: 5px 0;
}

.ant-col-12,
.ant-col-24,
.ant-col-4 {
  padding: 0 4px;
}

.ant-select,
.ant-picker {
  width: 100%;
}

.ant-col {
  width: 100%;
}

.ant-form-item {
  margin-bottom: 0;
}

.quotation-container {
  padding: 15px;
  height: 100vh;
}

.blue-card {
  background-color: #1890ff;
  color: white;
  border-radius: 5px;
  margin: 5px;
}

.yellow-card {
  background-color: #faad14;
  color: white;
  border-radius: 5px;
  margin: 5px;
}

.gray-card {
  background-color: #a2a2a2;
  color: white;
  border-radius: 5px;
  margin: 5px;
}

.create_booking {
    background-color: #e5e4e2;
  }
  
.upload-container-gas {
  position: relative;
  width: 100%;
  height: 100%;
}
.upload-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.uploaded-image {
  width: 100%;
  object-fit: cover;
  transition: filter 0.3s ease;
  cursor: pointer;
}
.uploaded-image-table {
  width: 50px;
  height: 50px;
  transition: filter 0.3s ease;
  cursor: pointer;
}
.upload-container:hover .uploaded-image-table {
  filter: grayscale(100%);
}
.upload-container:hover .uploaded-image {
  filter: grayscale(100%);
}
.icon-container-gas {
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.icon-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.material-symbols-outlined {
  cursor: pointer;
  /* color: white; */
  font-size: 2rem;
  transition: color 0.3s ease;
}
::v-deep .ant-modal-mask {
  background-color: transparent !important;
}
.custom-modal ::v-deep .ant-modal-content {
  box-shadow: none;
  background-color: transparent;
}
.custom-modal ::v-deep .ant-modal-body {
  padding: 0;
}


</style>
