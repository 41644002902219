import httpClient from './httpClient';
const prefix = 'driversChannel'

export default {
    async getAllDriversChannelWithDriver() {
        const response = await httpClient({ requiresAuth: true }).get(`/${prefix}/getAllDriversChannelWithDriver`)
        return response.data.data
    },
    async getAllDriversChannelWithDriverByName(name) {
        const response = await httpClient({ requiresAuth: true }).get(`/${prefix}/getAllDriversChannelWithDriverByName`, {params: {name: name}})
        return response.data.data
    },
    async getDriversChannelWithDriver(driverId) {
        const response = await httpClient({ requiresAuth: true }).get(`/${prefix}/getDriversChannelWithDriver/${driverId}`)
        return response.data.data
    },
}