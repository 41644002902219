
import httpClient from './httpClient';
const prefix = 'stopType'

export default {
  async getStopTypeById( id ) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getStopTypeById/${id}`)

    return data
  },
  async getStopTypeAll() {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getStopTypeAll`)

    return data
  },

}