<template>
  <div style="height: 70vh; overflow-y: auto; max-width: min-content;">
    <div v-if="users.length === 0" class="no-users-found">
      <!-- No users found. -->
    </div>
    <div v-else>
      <div
        v-for="(user, index) in users"
        :key="user.id"
        :class="['user-list-item', { 'selected': selectedUser && selectedUser.id === user.id }]"
        @click="selectUser(user)"
      >
        <a-badge v-if="user.latestMessage && user.latestMessage.sentBy.role == 'Driver' && !user.latestMessage.isRead" :dot="true" placement="start" status="error" :offset="[-40, 2.5]">
          <a-avatar v-if="user.avatar" :src="user.avatar" shape="square" :size="36"/>
          <a-avatar v-else shape="square" :size="36">
            <UserOutlined />
          </a-avatar>
        </a-badge>
        <a-badge v-else :dot="false" status="default">
          <a-avatar v-if="user.avatar" :src="user.avatar" shape="square" :size="36"/>
          <a-avatar v-else shape="square" :size="36">
            <UserOutlined />
          </a-avatar>
        </a-badge>
        <div class="user-details">
          <div class="user-name">{{ user.firstname + " " + user.lastname }}</div>
          <div class="user-last-message" v-if="user.latestMessage && user.latestMessage.content">
            <a-typography-text :ellipsis="true" type="secondary">{{ (user.latestMessage.sentBy.role != 'Driver' ? 'คุณ : ' : '') + user.latestMessage.content}}</a-typography-text>
          </div>

          <div class="user-last-message" v-else-if="user.latestMessage && user.latestMessage.type == 'image'">
            <a-typography-text type="secondary">{{ (user.latestMessage.sentBy.role != 'Driver' ? 'คุณ : ' : '') + 'ส่งรูปภาพแล้ว'}} </a-typography-text>
          </div>

          <div class="user-last-message" v-else-if="user.latestMessage && user.latestMessage.type == 'document'">
            <a-typography-text type="secondary">{{ (user.latestMessage.sentBy.role != 'Driver' ? 'คุณ : ' : '') + 'ส่งเอกสารแล้ว'}} </a-typography-text>
          </div>
          <!-- <div v-if="!user.isRead" class="user-unread-indicator">Unread</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UserOutlined } from '@ant-design/icons-vue';

export default {
  name: 'ChatList',
  components:{UserOutlined},
  props: {
    users: {
      type: Array,
      required: true,
    },
    searchQuery: {
      type: String,
      required: false,
      default: '', 
    },
  },
  data() {
    return {
      selectedUser: null,
    };
  },
  computed: {
  },
  methods: {
    selectUser(user) {
      this.$emit('userSelected', user);
      this.selectedUser = user;
    },
  },
};
</script>

<style scoped>
.user-list-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 35rem;
  border-bottom: 1px solid #ddd;
  padding: 0.25rem;
}

.user-list-item:hover {
  background-color: #f0f0f0;
}

.user-list-item.selected {
  background-color: #d3d3d3;
}

.user-avatar {
  margin-right: 10px;
}

.user-details {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start;
  align-items: flex-start; */
  flex: 1;
}

.user-name {
  font-weight: bold;
  font-size: 1.5rem;
}

.user-last-message {
  display:flex;
  font-size: 1.25rem;
  color: #888;
  width: 25rem;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
   border-radius: 30px;
  /* background-color: rgba(0,0,0,.5); */
  background: #D9D9D9;

  /* color: #00FF01; */
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
</style>
