import httpClient from './httpClient';
const prefix = 'chat'

export default {
    async getAllMessageByChannelId(channelId) {
        const response = await httpClient({ requiresAuth: true }).get(`/${prefix}/getAllMessageByChannelId/${channelId}`)
        return response.data
    },
    async uploadFile(body) {
        const response = await httpClient({ requiresAuth: true }).post(`/${prefix}/uploadFile`, body)
        return response
    },
}