
import httpClient from './httpClient';
const prefix = 'core'

export default {
  async export_invoice(body) {
    const { data } = await httpClient({ requiresAuth: true }).post(`/${prefix}/report/export-invoice`, { data: body });

    return data;
  },
  async export_receipt(body) {
    const { data } = await httpClient({ requiresAuth: true }).post(`/${prefix}/report/export-receipt`, { data: body });

    return data;
  },
  async export_delivery_note(body) {
    const { data } = await httpClient({ requiresAuth: true }).post(`/${prefix}/report/export-delivery-note`, { data: body });

    return data;
  },
  async export_invoice_and_delivery_note(body) {
    const { data } = await httpClient({ requiresAuth: true }).post(`/${prefix}/report/export-invoice-and-delivery-note`, { data: body });

    return data;
  },
  async export_qt(body) {
    const { data } = await httpClient({ requiresAuth: true }).post(`/${prefix}/report/export-qt`, { data: body });

    return data;
  },
  async booking_gas_report (plate_no , time_start, time_end) {
    const { data } = await httpClient({ requiresAuth: true }).get(`/${prefix}/report/booking-gas-report?time_start=${time_start}&time_end=${time_end}&plate_no=${plate_no}`);
    
    return data;
  },
  async booking_report (plate_no , time_start, time_end, type_report) {
    const { data } = await httpClient({ requiresAuth: true }).get(`/${prefix}/report/booking-report?timeStart=${time_start}&timeEnd=${time_end}&plateNo=${plate_no}&typeReport=${type_report}`);
    
    return data;
  },
  async month_report (fullName, status, time_start, time_end) {
    const { data } = await httpClient({ requiresAuth: true }).get(`/${prefix}/report/month-report?fullName=${fullName}&status=${status}&timeStart=${time_start}&timeEnd=${time_end}`);
    
    return data;
  },
  async stops () {
    const { data } = await httpClient({ requiresAuth: true }).get(`/${prefix}/report/stops`);

    return data;
  },
  async car_used () {
    const { data } = await httpClient({ requiresAuth: true }).get(`/${prefix}/report/car_used`);

    return data;
  },
  async vehicle_usage () {
    const { data } = await httpClient({ requiresAuth: true }).get(`/${prefix}/report/vehicle-usage`);

    return data;
  },
  async vehicle_use_report () {
    const { data } = await httpClient({ requiresAuth: true }).get(`/${prefix}/report/vehicle-use-report`);

    return data;
  },
  async park_report () {
    const { data } = await httpClient({ requiresAuth: true }).get(`/${prefix}/report/park-report`);

    return data;
  },
  async park_booking () {
    const { data } = await httpClient({ requiresAuth: true }).get(`/${prefix}/report/park-booking`);

    return data;
  },


  async import_client (body) {
    const { data } = await httpClient({ requiresAuth: true }).post(`/import/clients`, { data: body });

    return data;
  },
  async import_outside_client (body) {
    const { data } = await httpClient({ requiresAuth: true }).post(`/import/outside-clients`, { data: body });

    return data;
  },
  async import_car (body) {
    const { data } = await httpClient({ requiresAuth: true }).post(`/import/car`, { data: body });

    return data;
  },
  async import_driver (body) {
    const { data } = await httpClient({ requiresAuth: true }).post(`/import/driver`, { data: body });

    return data;
  }
}