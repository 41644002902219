<template>
    <div style="padding: 15px;height: 100vh">
        <div v-if="render_type == 'add' || render_type == 'detail' " class="app-content">
            <a-card :title="is_render_update?'แก้ไขปั๊มน้ำมัน':'เพิ่มปั๊มน้ำมัน'">
                <template #extra>
                    <a-button style="display: flex" @click="$router.go(-1)" size="large">
                        <template #icon>
                            <span class="material-symbols-outlined">arrow_back_ios</span>
                        </template>
                        ย้อนกลับ
                    </a-button>
                </template>

                <a-row type="flex">
                    <a-col :span="8"> 
                        <a-card title="รูปภาพ" style="width: 98%; text-align: center">
                                <a-card>
                                    <template #cover>
                                        <a-upload 
                                        v-model:file-list="fileList"
                                        name="photo"
                                        class="avatar-uploader"
                                        method="GET" accept="image/png, image/jpeg, image/jpg"
                                        style="max-height: 200px"
                                        :action="onChooseImage"
                                        :beforeUpload="beforeUpload"
                                        :showUploadList="false">
                                            <img v-if="detail.photo" :src="detail.photo" style="max-height: 200px" alt="photo"/>
                                            <div v-else style="padding: 10px">
                                                <p class="ant-upload-drag-icon">
                                                        <span class="material-symbols-outlined" style="font-size: 5rem">upload_file</span>
                                                </p>
                                                คลิก หรือ ลากไฟล์มาที่นี่ เพื่ออัพโหลดรูปภาพ
                                            </div>
                             
                                        </a-upload>
                                            <a-col v-if="detail.photo">
                                                <a @click="onRemoveImage"><span
                                                    style="font-size: 20px; color: red" class="material-symbols-outlined">delete</span></a>
                                            </a-col>
                                    </template>
                                </a-card>
                        </a-card>
                    </a-col>

                    <a-col :span="16">
                        <a-card title="ข้อมูล" >
                            <a-form
                                layout="vertical"
                                :model="detail" name="detail-form" 
                                autocomplete="off"
                                @finish="onManageGasStation">
                                    <a-form-item label="ชื่อปั๊มน้ำมัน" name="name"
                                        :rules="[{ required: true, message: 'กรุณากรอกชื่อปั๊มน้ำมัน' }]">
                                        <a-input v-model:value="detail.name" placeholder="ชื่อปั๊มน้ำมัน"></a-input>
                                    </a-form-item>

                                    <a-row type="flex">
                                        <a-col :span="12">
                                            <a-form-item label="ผู้ติดต่อ" name="contact_name" style="width: 98%;"
                                            :rules="[{ required: true, message: 'กรุณากรอกผู้ติดต่อ' }]">
                                                <a-input v-model:value="detail.contact_name" placeholder="ผู้ติดต่อ"></a-input>
                                            </a-form-item>
                                        </a-col>

                                        <a-col :span="12">
                                            <a-form-item label="เบอร์โทร" name="phone_no"
                                            :rules="[{ required: true, message: 'กรุณากรอกเบอร์โทร' }]">
                                                <a-input v-model:value="detail.phone_no" placeholder="เบอร์โทร"></a-input>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>

                                    <a-form-item label="ที่อยู่" name="address"
                                        :rules="[{ required: true, message: 'กรุณากรอกที่อยู่' }]">
                                        <a-textarea v-model:value="detail.address" placeholder="ที่อยู่" :rows="4"></a-textarea>
                                    </a-form-item>

                                    <a-row justify="end">
                                        <a-col>
                                            <a-button type="primary" html-type="submit" size="large"
                                                style="margin: 4px;text-align: right">
                                                    <span class="material-symbols-outlined" style="font-size: 16px;margin-right: 4px">Edit</span>
                                                    {{is_render_update? 'บันทึกข้อมูล': 'เพิ่มข้อมูล'}}
                                            </a-button>
                                        </a-col>
                                    </a-row>
                            </a-form>
                        </a-card>
                    </a-col>
                </a-row>
            </a-card>
        </div>

        <div v-else class="app-content">
            <a-card :title="title">
                <template v-if="!sjinda" #extra>
                    <a-space>
                          <router-link :to="`/app/gas-station/add`" v-if="editable">
                            <a-button v-if="button_title" style="display: flex"
                                type="primary" size="large" @click="to_create()">
                                <template #icon><span class="material-symbols-outlined">Add</span></template>
                                {{ button_title }}
                            </a-button>
                          </router-link>
                    </a-space>
                </template>
                <a-row v-if="sjinda" style="display: flex; justify-content: flex-end;">
                    <a-space>
                          <router-link :to="`/app/gas-station/add`" v-if="editable">
                            <a-button v-if="button_title" style="display: flex"
                                type="primary" size="large" @click="to_create()">
                                <template #icon><span class="material-symbols-outlined">Add</span></template>
                                {{ button_title }}
                            </a-button>
                          </router-link>
                    </a-space>
                </a-row>
                <br>

                <a-row>
                    <a-space>
                        <a-input-search v-if="input" v-model:value="search_input"
                            :placeholder="`ค้นหา ${input}`" enter-button="ค้นหา" />
                    </a-space>

                </a-row>

                <br>
                <a-row>
                    <a-table :columns="columns" :data-source="filtered_list(show_table_list)" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'id'">
                                <div>
                                    <a @click="edit_gas_station(record.id)"><span style="font-size: 18px" class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                    <a-divider type="vertical"></a-divider>
                                    <a @click="remove_gas_station(record.id, record)"><span style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
                                </div>
                            </template>
                        </template>

                    </a-table>
                </a-row>
            </a-card>
        </div>
    </div>
</template>

<script>
import Utility from '../../utility'
import Swal from 'sweetalert2'
import SystemService from '../../api/SystemService'
import UserService from '../../api/UserService';
import CompanyService from '../../api/CompanyService';
export default {
    name: 'gas-station',
    computed: {
        render_type() {
            return this.$route.name
        },
        is_render_update(){
            this.init_data()
            return this.$route.name == 'detail'
        }
    },
    data() {
        return {
            sjinda: false,
            editable: Utility.get_permission_editable_with_key('gas_station'),
            img_placeholder: require('@/assets/images/place-holder/placeholder.jpg'),
            show_table_list: [],
            fileList: [],
            title: 'จัดการปั๊มน้ำมัน',
            button_title: 'เพิ่มปั๊มน้ำมัน',
            input: 'ชื่อปั๊มน้ำมัน',
            search_input: null,
            columns: [
                {
                    title: 'ชื่อปั๊มน้ำมัน',
                    dataIndex: 'name',
                    key: 'name',
                    sorter: true,
                    width: 200
                }, {
                    title: 'ผู้ติดต่อ',
                    dataIndex: 'contact_name',
                    key: 'contact_name',
                    sorter: true,
                    width: 200
                }, {
                    title: 'เบอร์โทร',
                    dataIndex: 'phone_no',
                    key: 'phone_no',
                    width: 125
                }, {
                    title: 'ที่อยู่',
                    dataIndex: 'address',
                    key: 'address',
                    width: 120
                }, {
                    title: 'ตัวเลือก',
                    dataIndex: 'id',
                    key: 'id',
                    width: 100,
                    fixed: 'right'
                }
            ],
            detail: {
                id: null,
                name: null,
                contact_name: null,
                phone_no: null,
                address: null,
                photo: null,
                company_id: null
            }
        }
    },
    methods: {
        async get_company() {
			const company = await UserService.get_user_profile();
            const businessType = await CompanyService.getBusinessId(company.company_id)
            if (company.company_id === 39 || businessType.data === 2)
            {
                this.sjinda = true
            }
        },
        to_create() {
            this.detail = {
                id: null,
                name: null,
                contact_name: null,
                phone_no: null,
                address: null,
                photo: null,
                company_id: null
            }
        },
        init_data(){
            if(this.$route.name == 'detail'){
                this.get_gas_station_detail()
            }
        },
        async get_gas_station_detail(){
            this.detail = await SystemService.get_id('GasStation', this.$route.params.id)
        },
        filtered_list(array) {
            return array.filter(({ name }) => {
                if(this.search_input){
                    return name.toLowerCase().includes(this.search_input.toLowerCase())
                } else {
                    return true
                }
            })
        },
        async init_stations () {
            this.show_table_list = await SystemService.get_all('GasStation')
        },
        async onManageGasStation() {
             if(this.is_render_update){
                await SystemService.update_all('GasStation', { data: this.detail }, this.detail.id)
                Swal.fire(
                            'แก้ไขผู้น้ำมันสำเร็จ!',
                            'แก้ไขน้ำมันสำเร็จแล้ว',
                            'success'
                )
             }else{
                this.detail.company_id = JSON.parse(localStorage.getItem('companyData')).id
                await SystemService.create_all('GasStation', { data: [this.detail] })
                Swal.fire(
                    'เพิ่มปั๊มน้ำมันสำเร็จ!',
                    'เพิ่มผปัีมน้ำมันสำเร็จแล้ว',
                    'success'
                )
             }
            this.onResetData()
            this.$router.go(-1)
            this.onRefresh()
        },
        getBase64(file) {
            return new Promise((resolve, reject) =>{
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result)
                reader.onerror = error => reject(error)
            })
        },
        async onChooseImage(e) {
            var base64 = await this.getBase64(e)
            this.detail.photo = base64
            return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
        },
        beforeUpload (file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJpgOrPng) {
                Swal.fire(
                    'อัพโหลดไฟล์',
                    'ประเภทไฟล์ไม่ถูกต้อง อัพโหลดใหม่อีกครั้ง`',
                    'error'
                )
            }
            return isJpgOrPng;
        },
        onRemoveImage() {
            this.detail.photo = null
        },
        onResetData(){
             this.detail =  {
                id: null,
                name: null,
                contact_name: null,
                phone_no: null,
                address: null,
                photo: null,
                company_id: null
            }
        },
        onRefresh() {
             this.init_stations()
        },
        edit_gas_station(id){
            this.$router.push(`/app/gas-station/detail/${id}`)
        },
        remove_gas_station(id, detail){
            Swal.fire({
                title: 'ลบปั๊มน้ำมัน?',
                text: `คุณต้องการลบปั๊มน้ำมัน ${detail.name} นี้หรือไม่`,
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก'
                }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all('GasStation', id)
                    this.onRefresh()
                }
            })
        }
    },
    mounted() {
        this.get_company()
        this.init_stations()
    }
}
</script>