import { layouts } from "chart.js";

let car_fields = {
    title: {
        create: 'เพิ่มรถ',
        edit: 'แก้ไขรถ'
    },
    inputs: {
        divider_1: {
            input_type: 'divider',
            label: 'ข้อมูลรถ',
            col_span: 23
        },
        car_company_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'CarCompany',
            label_key: 'fullname',
            placeholder: 'บริษัทรถ',
            // required: true,
            required: false,
            col_span: 23
        },
        car_type_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'CarType',
            label_key: 'name',
            placeholder: 'ชนิดรถ',
            // required: true,
            required: false,
            col_span: 11
        },
        car_group_id: {
            input_type: 'sub_select',
            placeholder: 'ประเภทที่นั่งรถ',
            modelname: 'CarGroup',
            label_key: 'name',
            parent: {
                list: 'CarType',
                sub_list_name: 'car_groups',
                key: 'car_type_id'
            },
            // required: true,
            required: false,
            col_span: 11,
        },
        car_no: {
            input_type: 'text',
            placeholder: 'เบอร์รถ',
            // required: true,
            required: false,
            col_span: 11
        },
        plate_no: {
            input_type: 'text',
            placeholder: 'เลขทะเบียนรถ',
            // required: true,
            required: false,
            col_span: 11
        },
        driver_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'Driver',
            label_key: 'firstname',
            label_function: (driver) => {
                return `${driver.firstname} ${driver.lastname}`},
            placeholder: 'คนขับรถ',
            search_select: true,
            // required: true,
            required: false,
            col_span: 11
        },
        car_brand: {
            input_type: 'text',
            placeholder: 'ยี่ห้อรถ',
            // required: true,
            required: false,
            col_span: 11
        },
        car_year: {
            input_type: 'text',
            placeholder: 'ปีรถ',
            // required: true,
            required: false,
            col_span: 11
        },
        car_model: {
            input_type: 'text',
            placeholder: 'รุ่นรถ',
            // required: true,
            required: false,
            col_span: 11
        },
        parking_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'Parking',
            label_key: 'name',
            placeholder: 'ที่จอดรถ',
            // required: true,
            required: false,
            col_span: 11
        },
        car_source_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'CarSource',
            label_key: 'name',
            placeholder: 'ที่มารถ',
            required: false,
            col_span: 11
        },
        remark: {
            input_type: 'textarea',
            placeholder: 'รายละเอียดรถเพิ่มเติม',
            required: false,
            col_span: 23
        },
        divider_2: {
            input_type: 'divider',
            label: 'ข้อมูลด้านประกันภัย',
            col_span: 23
        },
        tax_label: {
            input_type: 'label',
            label: 'พ.ร.บ.',
            col_span: 23,
        },
        tax_provider_name: {
            input_type: 'text',
            placeholder: 'บริษัทประกันภัย',
            // required: true,
            required: false,
            col_span: 11
        },
        car_tax_no: {
            input_type: 'text',
            placeholder: 'เลขที่กรมธรรม์',
            // required: true,
            required: false,
            col_span: 11
        },
        car_tax_start: {
            input_type: 'date',
            placeholder: 'วันเริ่ม',
            // required: true,
            required: false,
            col_span: 11
        },
        car_tax_expire: {
            input_type: 'date',
            placeholder: 'วันสิ้นสุด',
            // required: true,
            required: false,
            col_span: 11    
            },
        car_tax_premium_net: {
            input_type: 'number',
            placeholder: 'เบี้ยประกันภัยสุทธิ',
            // required: true,
            required: false,
            col_span: 11
                },
        car_tax_premium_total_added: {
            input_type: 'number',
            placeholder: 'เบี้ยประกันภัยสุทธิรวมภาษีอากร',
            // required: true,
            required: false,
            col_span: 11     
           },
        car_tax_stamps: {
            input_type: 'number',
            placeholder: 'อากรแสตมป์',
            // required: true,
            required: false,
            col_span: 11     
           },
        car_tax_vat: {
            input_type: 'number',
            placeholder: 'ภาษีมูลค่าเพิ่ม',
            // required: true,
            required: false,
            col_span: 11
        },
        car_tax_total: {
            input_type: 'number',
            placeholder: 'ยอดสุทธิ',
            // required: true,
            required: false,
            col_span: 11
        },
        insure_label: {
            input_type: 'label',
            label: 'ภาคสมัครใจ',
            col_span: 23,
        },
        insure_provider_name: {
            input_type: 'text',
            placeholder: 'บริษัทประกันภัย',
            // required: true,
            required: false,
            col_span: 11
                },
        insure_no: {
            input_type: 'text',
            placeholder: 'เลขที่กรมธรรม์',
            // required: true,
            required: false,
            col_span: 11
        },
        insure_start: {
            input_type: 'date',
            placeholder: 'วันเริ่ม',
            format: 'DD/MM/YYYY',
            // required: true,
            required: false,
            col_span: 11
        },
        insure_expire: {
            input_type: 'date',
            placeholder: 'วันสิ้นสุด',
            required: false,
            col_span: 11
        },
        insure_premium_net: {
            input_type: 'number',
            placeholder: 'เบี้ยประกันภัยสุทธิ',
            // required: true,
            required: false,
            col_span: 11
        },
        insure_premium_total_added: {
            input_type: 'number',
            placeholder: 'เบี้ยประกันภัยสุทธิรวมภาษีอากร',
            // required: true,
            required: false,
            col_span: 11
        },
        insure_stamps: {
            input_type: 'number',
            placeholder: 'อากรแสตมป์',
            // required: true,
            required: false,
            col_span: 11
        },
        insure_vat: {
            input_type: 'number',
            placeholder: 'ภาษีมูลค่าเพิ่ม',
            // required: true,
            required: false,
            col_span: 11
        },
        maintenance_expire: {
            input_type: 'date',
            placeholder: 'วันตรวจสภาพรถประจำปี',
            // required: true,
            required: false,
            col_span: 11
        },
        repair_target: {
            input_type: 'number',
            placeholder: 'เลขไมล์ซ่อมถัดไป',
            required: false,
            col_span: 11
        },
        insure_total: {
            input_type: 'number',
            placeholder: 'ยอดสุทธิ',
            // required: true,
            required: false,
            col_span: 11
        },
        divider_3: {
            input_type: 'divider',
            label: 'ข้อมูลด้านทะเบียน / ชำระภาษี',
            col_span: 23
        },
        car_register_date: {
            input_type: 'date', 
            placeholder: 'วันที่จดทะเบียน',
            // required: true,
            required: false,
            col_span: 7
        },
        car_tax_rate: {
            input_type: 'number',
            placeholder: 'อัตราภาษี',
            // required: true,
            required: false,
            col_span: 7
        },
        car_appearance: {
            input_type: 'text',
            placeholder: 'ลักษณะ/มาตรฐานรถ',
            // required: true,
            required: false,
            col_span: 7
        },
        car_inspect_1: {
            input_type: 'month',
            placeholder: 'รอบตรวจสภาพ 1',
            // required: true,
            required: false,
            col_span: 10
        },
        car_inspect_2: {
            input_type: 'month',
            placeholder: 'รอบตรวจสภาพ 2',
            // required: true,
            required: false,
            col_span: 10
        },
        sub_divider_3_0: {
            input_type: 'divider',
            label: 'Fleet oil & Sim GPS',
            col_span: 23
        },
        oil_1_1: {
            input_type: 'text',
            placeholder: 'ประเภทปั๊ม',
            col_span: 7
        },
        oil_1_2: {
            input_type: 'text',
            placeholder: 'Fleetcard',
            col_span: 7
        },
        oil_1_3: {
            input_type: 'text',
            placeholder: 'GPS simcard',
            col_span: 7
        },


        documents: {
            input_type: 'sub-divider',
            label: 'เอกสารทะเบียนครบชุด',
            col_span: 23
        },
        doc_1: {
            input_type: 'file',
            label: 'ทะเบียนรถ/การต่อภาษี',
            col_span: 10,
            layout: 'vertical'
        },
        doc_2: {
            input_type: 'file',
            label: 'พ.ร.บ.',
            col_span: 10,
            layout: 'vertical'
        },
        doc_3: {
            input_type: 'file',
            label: 'ประกันภัยประเภท 3',
            col_span: 10,
            layout: 'vertical',
        },
        doc_4: {
            input_type: 'file',
            label: 'หนังสือรับรอง GPS',
            col_span: 10,
            layout: 'vertical'
        },
        doc_5: {
            input_type: 'file',
            label: 'ใบขับขี่',
            col_span: 10,
            layout: 'vertical'
        },
        doc_6: {
            input_type: 'file',
            label: 'ครบชุด pdf',
            col_span: 10,
            layout: 'vertical'
        },
        divider_4: {
            input_type: 'divider',
            label: 'เอกสารสำคัญต่างๆ',
            col_span: 23
        },
        file_asset: {
            input_type: 'table_files',
            placeholder: '',
            col_span: 23
        },
        divider_5: {
            input_type: 'divider',
            label: 'ข้อมูลออฟชั่นรถ',
            col_span: 23
        },
        create_options: {
            input_type: 'checkbox',
            placeholder: 'ออฟชั่นรถ',
            required: false,
            modelname: 'Option',
            label_key: 'name',
            col_span: 23
        },
        divider_6: {
            input_type: 'divider',
            label: 'รูปพรรณ',
            col_span: 23
        },
        photo_1: {
            input_type: 'photo',
            label: 'ด้านหน้า',
            col_span: 10,
            layout: 'vertical'
        },
        photo_2: {
            input_type: 'photo',
            label: 'ด้านหลัง',
            col_span: 10,
            layout: 'vertical'
        },
        photo_3: {
            input_type: 'photo',
            label: 'ด้านซ้าย',
            col_span: 10,
        },
        photo_4: {
            input_type: 'photo',
            label: 'ด้านขวา',
            col_span: 10,
            layout: 'vertical'
        },
        photo_5: {
            input_type: 'photo',
            label: 'ชั้นบน',
            col_span: 10,
            layout: 'vertical'
        },
        photo_6: {
            input_type: 'photo',
            label: 'ชั้นล่าง',
            col_span: 10,
            layout: 'vertical'
        },
        photo_7: {
            input_type: 'photo',
            label: 'แผ่นเพลทตัวรถ',
            col_span: 10,
            layout: 'vertical'
        },
        photo_8: {
            input_type: 'photo',
            label: 'แผ่นเพลทหมายเลขตัวถัง',
            col_span: 10,
            layout: 'vertical'
        },

        sub_divider_3_1: {
            input_type: 'divider',
            label: 'เครื่องยนต์',
            col_span: 23
        },
        engin_label_1: {
            input_type: 'sub-divider',
            label: 'คัทซี',
            col_span: 23
        },
        chassis_name: {
            input_type: 'text',
            placeholder: 'ชื่อ',
            col_span: 11
        },
        chassis_model: {
            input_type: 'text',
            placeholder: 'รุ่น',
            col_span: 11
        },
        chassis_inner_tube: {
            input_type: 'text',
            placeholder: 'ถุงลมใน',
            col_span: 11
        },
        chassis_nut: {
            input_type: 'text',
            placeholder: 'น็อต',
            col_span: 11
        },
        chassis_break: {
            input_type: 'text',
            placeholder: 'เบรค',
            col_span: 11
        },
        chassis_assembly_workshop: {
            input_type: 'text',
            placeholder: 'อู่ประกอบคัสซี',
            col_span: 11
        },
        engin_label_2: {
            input_type: 'sub-divider',
            label: 'เครื่องยนต์',
            col_span: 23
        },
        engine_name: {
            input_type: 'text',
            placeholder: 'ชื่อ',
            col_span: 11
        },
        engine_small_air_compressor: {
            input_type: 'text',
            placeholder: 'ปั๊มลมเล็ก',
            col_span: 11
        },
        engine_pump: {
            input_type: 'text',
            placeholder: 'สูบ',
            col_span: 11
        },
        engine_hp: {
            input_type: 'text',
            placeholder: 'แรงม้า คอมมอลเรล',
            col_span: 11
        },
        engin_label_3: {
            input_type: 'sub-divider',
            label: 'เกียร์',
            col_span: 23
        },
        gear_bus: {
            input_type: 'text',
            placeholder: 'bus',
            col_span: 7
        },
        gear_gear_reverse: {
            input_type: 'text',
            placeholder: 'เกียร์+ถอย',
            col_span: 7
        },
        gear_gear: {
            input_type: 'text',
            placeholder: 'เกียร์',
            col_span: 7
        },
        engin_label_4: {
            input_type: 'sub-divider',
            label: 'แผ่นคลัส',
            col_span: 23
        },
        clutch_plate_inch: {
            input_type: 'text',
            placeholder: 'นิ้ว',
            col_span: 11
        },
        clutch_plate_friction_plate: {
            input_type: 'text',
            placeholder: 'แผ่น เฟืองละเอียด',
            col_span: 11
        },
        engin_label_5: {
            input_type: 'sub-divider',
            label: 'จานกดคลัส',
            col_span: 23
        },
        clutch_pressure_brand: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
            col_span: 11
        },
        clutch_pressure_model: {
            input_type: 'text',
            placeholder: 'รุ่น',
            col_span: 11
        },
        engin_label_6: {
            input_type: 'sub-divider',
            label: 'ลูกปืนคลัส',
            col_span: 23
        },
        clutch_bearing_brand: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
            col_span: 11
        },
        clutch_bearing_model: {
            input_type: 'text',
            placeholder: 'รุ่น',
            col_span: 11
        },
        engin_label_7: {
            input_type: 'sub-divider',
            label: 'เฟืองท้าย',
            col_span: 23
        },
        differential_brand: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
            col_span: 11
        },
        differential_model: {
            input_type: 'text',
            placeholder: 'รุ่น',
            col_span: 11
        },
        engin_label_8: {
            input_type: 'sub-divider',
            label: 'ถุงลม',
            col_span: 23
        },
        airbag_front: {
            input_type: 'text',
            placeholder: 'ด้านหน้า',
            col_span: 7
        },
        airbag_drive_wheel: {
            input_type: 'text',
            placeholder: 'ล้อจักร',
            col_span: 7
        },
        airbag_trailer_wheel: {
            input_type: 'text',
            placeholder: 'ล้อพ่วง',
            col_span: 7
        },
        engin_label_9: {
            input_type: 'sub-divider',
            label: 'วาวล์ยกถุงลม',
            col_span: 23
        },
        airbag_lift_valve: {
            input_type: 'text',
            placeholder: 'วาวล์ยกถุงลม',
            col_span: 23
        },
        engin_label_10: {
            input_type: 'sub-divider',
            label: 'ผ้าเบรค',
            col_span: 23
        },
        brake_pads_front: {
            input_type: 'text',
            placeholder: 'ด้านหน้า',
            col_span: 7
        },
        brake_pads_back: {
            input_type: 'text',
            placeholder: 'ด้านหลัง',
            col_span: 7
        },
        brake_pads_trailer_wheel: {
            input_type: 'text',
            placeholder: 'ล้อพ่วง',
            col_span: 7
        },
        engin_label_11: {
            input_type: 'sub-divider',
            label: 'กันโคลง',
            col_span: 23
        },
        sway_bar_front_and_back: {
            input_type: 'text',
            placeholder: 'ด้านหน้า-ด้านหลัง',
            col_span: 23
        },
        engin_label_12: {
            input_type: 'sub-divider',
            label: 'โช้คอัพ',
            col_span: 23
        },
        shock_absorber_front: {
            input_type: 'text',
            placeholder: 'ด้านหน้า',
            col_span: 23
        },
        shock_absorber_back: {
            input_type: 'text',
            placeholder: 'ด้านหลัง',
            col_span: 23
        },
        shock_absorber_trailer_wheel: {
            input_type: 'text',
            placeholder: 'ล้อพ่วง',
            col_span: 23
        },
        engin_label_13: {
            input_type: 'sub-divider',
            label: 'สายพาน',
            col_span: 23
        },
        drive_belt_serpentine_belt: {
            input_type: 'text',
            placeholder: 'สายพาน',
            col_span: 7
        },
        drive_belt_radiator: {
            input_type: 'text',
            placeholder: 'หม้อน้ำ',
            col_span: 7
        },
        drive_belt_alternator: {
            input_type: 'text',
            placeholder: 'ไดชาร์ทเครื่อง',
            col_span: 7
        },
        drive_belt_long_belt: {
            input_type: 'text',
            placeholder: 'สายยาว',
            col_span: 7
        },
        drive_belt_a_c_alternator: {
            input_type: 'text',
            placeholder: 'ไดชาร์ทแอร์',
            col_span: 7 
        },
        drive_belt_audio_system: {
            input_type: 'text',
            placeholder: 'เครื่องเสียง',
            col_span: 7
        },
        drive_belt_a_c_compressor: {
            input_type: 'text',
            placeholder: 'คอมแอร์',
            col_span: 23
        },
        engin_label_14: {
            input_type: 'sub-divider',
            label: 'ไดชาร์ทเครื่อง',
            col_span: 23
        },
        alternator_engine: {
            input_type: 'text',
            placeholder: ' ไดชาร์ทเครื่อง',
            col_span: 23
        },
        engin_label_15: {
            input_type: 'sub-divider',
            label: 'ไดชาร์ท',
            col_span: 23
        },
        alternator_a_c: {
            input_type: 'text',
            placeholder: 'ไดชาร์ทแอร์',
            col_span: 23
        },
        engin_label_16: {
            input_type: 'sub-divider',
            label: 'ไดชาร์ทเครื่องเสียง',
            col_span: 23
        },
        alternator_audio_brand: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
            col_span: 11
        },
        alternator_audio_model: {
            input_type: 'text',
            placeholder: 'รุ่น',
            col_span: 11
        },
        engin_label_17: {
            input_type: 'sub-divider',
            label: 'ถังน้ำมัน',
            col_span: 23
        },
        fuel_quantity: {
            input_type: 'text',
            placeholder: 'จำนวน',
            col_span: 11
        },
        fuel_liter: {
            input_type: 'text',
            placeholder: 'ลิตร',
            col_span: 11
        },
        engin_label_18: {
            input_type: 'sub-divider',
            label: 'หม้อน้ำ',
            col_span: 23
        },
        radiator_1: {
            input_type: 'text',
            placeholder: 'หม้อน้ำ1',
            col_span: 11
        },
        radiator_2: {
            input_type: 'text',
            placeholder: 'หม้อน้ำ2',
            col_span: 11
        },
        engin_label_19: {
            input_type: 'sub-divider',
            label: 'ใบพักหม้อน้ำ',
            col_span: 23
        },
        radiator_fins_brand: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
            col_span: 23
        },
        engin_label_20: {
            input_type: 'sub-divider',
            label: 'ท่อพักไอเสีย',
            col_span: 23
        },
        exhaust_pipe_size: {
            input_type: 'text',
            placeholder: 'ขนาด',
            col_span: 11
        },
        exhaust_pipe_quantity: {
            input_type: 'text',
            placeholder: 'ใบ',
            col_span: 11
        },
        engin_label_21: {
            input_type: 'sub-divider',
            label: 'ข้ออ่อนท่อไอเสีย',
            col_span: 23
        },
        exhaust_flex_pipe_size: {
            input_type: 'text',
            placeholder: 'ขนาด',
            col_span: 11
        },
        exhaust_flex_pipe_quantity: {
            input_type: 'text',
            placeholder: 'อัน',
            col_span: 11
        },
        engin_label_22: {
            input_type: 'sub-divider',
            label: 'ยางแท่นเครื่อง',
            col_span: 23
        },
        motor_mount_front: {
            input_type: 'text',
            placeholder: 'ด้านหน้า (สูบ)',
            col_span: 11
        },
        motor_mount_back: {
            input_type: 'text',
            placeholder: 'ด้านหลัง (สูบ)',
            col_span: 11
        },
        engin_label_23: {
            input_type: 'sub-divider',
            label: 'กระปุกพวงมาลัย',
            col_span: 23
        },
        steering_box: {
            input_type: 'text',
            placeholder: 'กระปุกพวงมาลัย',
            col_span: 23
        },
        engin_label_24: {
            input_type: 'sub-divider',
            label: 'กรองอากาศลูกนอก',
            col_span: 23
        },
        air_filter_element: {
            input_type: 'text',
            placeholder: 'กรองอากาศลูกนอก',
            col_span: 23
        },
        engin_label_25: {
            input_type: 'sub-divider',
            label: 'กรองอากาศลูกใน',
            col_span: 23
        },
        air_filter_internal: {
            input_type: 'text',
            placeholder: 'กรองอากาศลูกใน',
            col_span: 23
        },
        engin_label_26: {
            input_type: 'sub-divider',
            label: 'กรองโซล่า',
            col_span: 23
        },
        fuel_filter_gasket_1: {
            input_type: 'text',
            placeholder: 'ปะเก็น1',
            col_span: 11
        },
        fuel_filter_gasket_2: {
            input_type: 'text',
            placeholder: 'ปะเก็น2',
            col_span: 11
        },
        engin_label_27: {
            input_type: 'sub-divider',
            label: 'กรองดักน้ำ',
            col_span: 23
        },
        filter_water: {
            input_type: 'text',
            placeholder: 'กรองดักน้ำ',
            col_span: 23
        },
        engin_label_28: {
            input_type: 'sub-divider',
            label: 'กรองเกียร์',
            col_span: 23
        },
        filter_gear: {
            input_type: 'text',
            placeholder: 'กรองเกียร์',
            col_span: 23
        },
        engin_label_29: {
            input_type: 'sub-divider',
            label: 'กรองแอร์ไดเออร์',
            col_span: 23
        },
        filter_a_c: {
            input_type: 'text',
            placeholder: 'กรองแอร์ไดเออร์',
            col_span: 23
        },
        engin_label_30: {
            input_type: 'sub-divider',
            label: 'พาวเวอร์ชิพ',
            col_span: 23
        },
        power_chip_brand: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
            col_span: 11
        },
        power_chip_model: {
            input_type: 'text',
            placeholder: 'รุ่น',
            col_span: 11
        },
        engin_label_31: {
            input_type: 'sub-divider',
            label: 'ปั๊มน้ำ',
            col_span: 23
        },
        pump_water_brand: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
            col_span: 11
        },
        pump_water_model: {
            input_type: 'text',
            placeholder: 'รุ่น',
            col_span: 11
        },
        engin_label_32: {
            input_type: 'sub-divider',
            label: 'ปั๊มลม',
            col_span: 23
        },
        pump_air_compressor_brand: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
            col_span: 11
        },
        pump_air_compressor_model: {
            input_type: 'text',
            placeholder: 'รุ่น',
            col_span: 11
        },
        engin_label_33: {
            input_type: 'sub-divider',
            label: 'ปั๊มโซล่า',
            col_span: 23
        },
        pump_fuel_brand: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
            col_span: 11
        },
        pump_fuel_model: {
            input_type: 'text',
            placeholder: 'รุ่น',
            col_span: 11
        },
        engin_label_34: {
            input_type: 'sub-divider',
            label: 'มู่เล่',
            col_span: 23
        },
        flywheel_engine: {
            input_type: 'text',
            placeholder: 'เครื่อง',
        },
        flywheel_starter_motor: {
            input_type: 'text',
            placeholder: 'ไดร์เครื่อง',
        },
        flywheel_starter_a_c: {
            input_type: 'text',
            placeholder: 'ไดร์แอร์',
        },
        flywheel_starter: {
            input_type: 'text',
            placeholder: 'ไดร์',
        },
        flywheel_audio: {
            input_type: 'text',
            placeholder: 'เครื่องเสียง',
        },
        sub_divider_3_2: {
            input_type: 'sub-divider',
            label: 'ตัวถัง',
            col_span: 23
        },
        // body_label_1: {
        //     input_type: 'sub-divider',
        //     label: 'ตัวถัง',
        //     col_span: 23
        // },
        vehicle_body: {
            input_type: 'text',
            placeholder: 'ทรง',
            col_span: 23
        },
        vehicle_assembly_workshop: {
            input_type: 'sub-divider',
            label: 'อู่ประกอบ',
            col_span: 23
        },
        vehicle_assembly_workshop_name: {
            input_type: 'text',
            placeholder: 'ชื่ออู่',
            col_span: 7
        },
        vehicle_assembly_workshop_date: {
            input_type: 'date',
            placeholder: 'วันที่ผลิต',
            col_span: 7
        },
        vehicle_assembly_workshop_address: {
            input_type: 'textarea',
            placeholder: 'ที่อยู่',
            col_span: 7
        },
        body_label_3: {
            input_type: 'sub-divider',
            label: 'สี',
            col_span: 23
        },
        vehicle_color: {
            input_type: 'text',
            placeholder: 'สี',
            col_span: 23,
        },
        body_label_4: {
            input_type: 'sub-divider',
            label: 'กระจก',
            col_span: 23
        },
        mirror_bottom: {
            input_type: 'text',
            placeholder: 'หน้าล่าง รุ่น/ขนาด/สี',
            col_span: 11,
        },
        mirror_top: {
            input_type: 'text',
            placeholder: 'หน้าบน รุ่น/ขนาด/สี',
            col_span: 11,
        },
        mirror_side: {
            input_type: 'text',
            placeholder: 'ข้าง Lt/Rt สีฟ้าชา',
            col_span: 11,
        },
        mirror_back: {
            input_type: 'text',
            placeholder: 'ท้ายสี',
            col_span: 11,
        },
        body_label_5: {
            input_type: 'sub-divider',
            label: 'กระจกงวงช้าง',
            col_span: 23
        },
        mirror_fender_brand: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
            col_span: 11,
        },
        mirror_fender_model: {
            input_type: 'text',
            placeholder: 'รุ่น',
            col_span: 11,
        },
        body_label_6: {
            input_type: 'sub-divider',
            label: 'ไฟหน้า',
            col_span: 23
        },
        light_front_brand: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
            col_span: 11,
        },
        light_front_model: {
            input_type: 'text',
            placeholder: 'รุ่น',
            col_span: 11,
        },
        body_label_7: {
            input_type: 'sub-divider',
            label: 'ไฟท้าย หลังคา',
            col_span: 23
        },
        light_back_brand: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
            col_span: 11,
        },
        light_back_model: {
            input_type: 'text',
            placeholder: 'รุ่น',
            col_span: 11,
        },
        body_7_3: {
            input_type: 'text',
            placeholder: 'เล็ก',
            col_span: 11,
        },
        body_7_4: {
            input_type: 'text',
            placeholder: 'ใหญ่',
            col_span: 11,
        },
        body_label_8: {
            input_type: 'sub-divider',
            label: 'ภายใน',
            col_span: 23
        },
        inside_ceiling: {
            input_type: 'text',
            placeholder: 'เพดาน สี',
            col_span: 11,
        },
        inside_curtain: {
            input_type: 'text',
            placeholder: 'ม่านสี',
            col_span: 11,
        },
        inside_headrest: {
            input_type: 'text',
            placeholder: 'หัวเบาะ ลายสี',
            col_span: 11,
        },
        inside_more_detail: {
            input_type: 'text',
            placeholder: 'เพิ่มเติม',
            col_span: 11,
        },
        body_label_9: {
            input_type: 'sub-divider',
            label: 'แอร์',
            col_span: 23
        },
        air_brand: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
            col_span: 7,
        },
        air_model: {
            input_type: 'text',
            placeholder: 'รุ่น',
            col_span: 7,
        },
        air_hot_coil: {
            input_type: 'text',
            placeholder: 'ระบบคอยร้อน',
            col_span: 7,
        },
        air_refrigerant: {
            input_type: 'text',
            placeholder: 'น้ำยาแอร์ รุ่น',
            col_span: 7,
        },
        air_cooling_coil: {
            input_type: 'text',
            placeholder: 'ระบบคอยเย็น',
            col_span: 7,
        },
        air_warranty_end_date: {
            input_type: 'date',
            placeholder: 'รับประกัน ถึงวันที่',
            col_span: 7,
        },
        air_get_car_date: {
            input_type: 'date',
            placeholder: 'วันที่รับรถจริง ',
            col_span: 23,
        },
        air_pdi: {
            input_type: 'date',
            placeholder: 'วันที่ PDI รถใหม่',
            col_span: 23,
        },
        air_warranty_start_date: {
            input_type: 'date',
            placeholder: 'วันเริ่มรับประกัน',
            col_span: 23,
        },
    }
};

export default car_fields