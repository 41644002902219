<template>
    <a-range-picker v-model:value="datetime" @change="onChange" :placeholder="placeholder" />
</template>

<script>

export default {
    props: {
        time_start: Object,
        time_end: Object,
        placeholder: String
    },
    data () {
        return {
            datetime: null
        }
    },
    methods: {
        onChange() {
            this.$emit('onChange', this.datetime.map(d => d.format('DD/MM/YYYY')))
        }
    },
    mounted() {
        this.datetime = [this.time_start, this.time_end]
    }
};
</script>