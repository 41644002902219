<template>
    <div>
      <div v-for="index in ((create_paper_original_and_copy) ? 2 : 1)" :key="index">
        <div v-if="show_pdf" :class="'page-' + index" style="height: 1754px; width: 1240px; display: flex; padding: 4em; flex-direction: column; justify-content: space-between">
          <div style="height: 95%; width: 100%; display: flex; flex-direction: column; border-bottom: 2px solid black;">
            <p style="margin-left: 90%;">{{ paper_type(index) }}</p>
            <div>
              <div style="display:flex;justify-content: space-between;align-items:end;border-bottom: 2px solid black;">
                <div>
                  <span style="font-size: 18px;">
                      <div style="display: flex;flex-direction: row;">
                        <img crossorigin="anonymous" style="height: 90px;object-fit: cover;" :src="car_companies.logoSc" />
                        <img crossorigin="anonymous" style="height: 90px;width: 180px;object-fit: cover;" :src="car_companies.logoIso" /><br/>
                      </div>
                      {{ selected_billing.company?.fullname }} <br>
                      เลขประจำตัวผู้เสียภาษี {{ selected_billing.company?.tax_id }} <br>
                      โทร. {{ selected_billing.company?.phone_no }} โทรสาร. {{ selected_billing.company?.fax_no }}<br>
                      {{ selected_billing?.company?.address }} <br>
                  </span>
                  </div>
                    <img crossorigin="anonymous" style="height: 170px ;object-fit: cover;" :src="car_companies.logo" />
                </div>
              <br>
              <h1 style="text-align: center">ใบส่งมอบงาน
              </h1>
              <div style="display: flex;width: 100%;justify-content: end;">
                <div style="margin-right: 2rem;text-align: end;">
                  <p v-if="selected_billing.billing_no">เลขที่:</p>
                  <p>วันที่:</p>
                  <!-- <p>Quotaion No.:</p> -->
                  <p>QTC:</p>
                </div>
                <div style="width: 25%">
                  <p v-if="selected_billing.billing_no">{{ selected_billing.billing_no }}</p>
                  <p>{{ render_time(new Date(), 'DD MMMM BBBB') }}</p>
                  <!-- <p>{{ selected_billing.booking.booking_no }}</p> -->
                  <p>{{ selected_billing.booking.booking_no }}</p>
                </div>
              </div>
              <div>
                <p>ชื่อลูกค้า: {{ selected_billing?.booking?.client?.fullname }}</p>
                <p>ที่อยู่: {{ selected_billing.booking.client.address }}</p>
                <p>เลขประจำตัวผู้เสียภาษี: {{ selected_billing.booking.client.tax_id }}</p>
                <!--<p>รายละเอียด: {{ render_booking_detail(selected_billing.booking) }}</p> -->
              </div>
              <br>
              <table style="width: 100%;border-collapse: collapse;">
                <tr style="text-align: center;">
                  <td style="width: 10%">ลำดับ</td>
                  <td style="width: 40%">{{ selected_billing.is_guarantee ? 'ค่าหลักประกันสัญญางาน' : 'รายการ' }}</td>
                  <td style="width: 20%">ราคา(บาท)</td>
                  <td style="width: 10%">จำนวน</td>
                  <td style="width: 20%">ราคารวม(บาท)</td>
                </tr>
                <tr style="text-align:center" v-for="{ quantity, cost, expenses, remark }, i in selected_billing.booking.booking_car_types" :key="i">
                  <td>{{ (i + 1) }}</td>
                  <td style="text-align:start">
                    <span v-if="remark">
                      <span v-for="line in remark.split('\n')">
                        {{ line }}<br>
                      </span>
                      <span v-if="expenses.length">(ค่าใช้จ่ายเพิ่มเติม {{ expenses.reduce((a, b) => a + (b.amount * b.price), 0)}} บาท)</span>
                    </span>
                  </td>
                  <td style="text-align: end">{{ (cost / quantity).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</td>
                  <td>{{ quantity }}</td>
                  <td style="text-align: end">{{ (cost).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</td>
                </tr>
                <tr style="text-align:center;" v-for="{ price, extra: { name }, amount }, i in selected_billing.booking.extras" :key="i">
                  <td>{{ (selected_billing.booking.booking_car_types.length + i + 1) }}</td>
                  <td :colspan="1" style="text-align:start;word-break: break-all;">
                    {{ name }}
                  </td>
                  <td style="text-align: end">{{ price.toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</td>
                  <td>{{ amount }}</td>
                  <td style="text-align: end">{{ (price * amount).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</td>
                </tr>
                <tr>
                  <td :colspan="4">
                    <div style="display: flex;align-items: end;width: 100%;justify-content: space-between;">
                      <p style="padding-left: 20em">({{ price_to_word(selected_billing.total_price) }})</p>
                      <div style="text-align: end;">
                        <!-- <p v-if="selected_billing.extra_sum">ค่าใช้จ่ายเพิ่มเติม</p> -->
                        <p>ส่วนลด</p>
                        <!-- <p>เงินมัดจำ</p> -->
                        <p v-if="selected_billing.inc_vat">ภาษีมูลค่าเพิ่ม</p>
                        <!-- <p v-if="selected_billing.withholding">หัก ณ ที่จ่าย</p> -->
                        <p>จำนวนเงินรวมทั้งสิ้น</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div style="text-align: end;">
                      <!-- <p v-if="selected_billing.extra_sum">{{selected_billing.extra_sum? selected_billing.extra_sum:0}}</p> -->
                      <p>{{ selected_billing.booking.discount ? selected_billing.booking.discount.toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }):'0.00'}}</p>
                      <!-- <p>0.00</p> -->
                      <!-- <p>{{ billed_amount.toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</p> -->
                      <p v-if="selected_billing.inc_vat">{{ selected_billing.inc_vat.toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</p>
                      <!-- <p v-if="selected_billing.withholding">{{ selected_billing.withholding.toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</p> -->
                      <p>{{ (selected_billing.total_price).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td :colspan="4" style="text-align: end;">
                    <p v-if="selected_billing.withholding">สามารถหักภาษี ณ ที่จ่าย (1%)(ค่าขนส่ง)</p>
                  </td>
                  <td style="text-align: end;">
                    <p v-if="selected_billing.withholding">{{ selected_billing.withholding.toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</p>
                  </td>
                </tr>
              </table>
            </div>
            <br>
            <div>
              <!-- เงินมัดจำ {{ billed_amount.toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} บาท -->
              <p>กำหนดชำระเงิน: ชำระเงินภายในวันที่ {{ render_time(selected_billing.expire_date, 'DD MMMM YYYY') }} ตามเอกสารอ้างอิง</p>
              <p>วิธีการชำระเงิน ดังต่อไปนี้</p>
              <div>
                <p>[ ] ชำระเงินด้วยเงินสด</p>
                <p>[ ] ชำระเงินด้วยเช็ค หรือ ดร๊าฟ โดยสั่งจ่ายในนาม "{{ selected_billing?.booking?.car_company?.fullname }}" และ "A/C PAYEE ONLY" เท่านั้น</p>
                <p>[ ] ชำระเงินด้วยโอนเงินโดยโอนเข้าบัญชี {{ selected_billing?.booking?.car_company?.bank_code }}
                  สาขา {{ selected_billing?.booking?.car_company?.bank_branch }}
                  ในนาม {{ selected_billing?.booking?.car_company?.fullname }}
                  บัญชีออมทรัพย์เลขที่ {{ selected_billing?.booking?.car_company?.bank_account_no }}
                </p>
              </div>
              <br>
              <div style="display:flex;">
                <p style="margin-right: 2rem">หมายเหตุ: </p>
                <div>
                  <p>- โปรดตรวจสอบรายการข้างต้น หากมีข้อผิดพลาดและต้องการแก้ไข กรุณาติดต่อกลับมาภายใน 3 วัน
                    นับจากวันที่รับวางบิล มิฉะนั้นทางบริษัทจะถือว่าท่านได้รับเอกสารถูกต้อง</p>
                  <p>- ในกรณีโอนเงินเข้าบัญชีธนาคาร กรุณาส่ง หลักฐานการชำระเงิน มาทาง โทรสาร. {{
                    selected_billing?.booking?.car_company?.fax_no }} หรือ อีเมล์ {{
                      selected_billing?.booking?.car_company?.email }}</p>
                </div>
              </div>
              <div style="display: flex;justify-content: space-between;text-align: center;line-height: 2; align-items: center;">
                <div style="margin-top: 6.5rem;">
                  <div v-if="selected_billing.booking.sale?.signature" style="height: 60px;"></div>
                  <p>ลงชื่อ..........................................................................ผู้รับมอบงาน
                  </p>
                  <p>(.........................................................................................)
                  </p>
                  <p>{{ selected_billing?.booking?.client?.fullname }}</p>
                  <!-- <p>วันที่ ........................... / ........................... / ...........................</p> -->
                </div>
                <div style="margin: 0.5rem;width: 105px;height: 105px;border: 1px dotted black;display: flex;align-items: center;justify-content: center;" v-if="!no_stamp">
                  <p>ประทับตรา </p>
                </div>
                <div style="position: relative;">
                  <br>
                  <div v-if="selected_billing?.booking?.sale?.signature">
                    <canvas :id="'signature-canvas-' + index" width="320" height="55" style="position: absolute; left: 5rem; top: 70px;"></canvas>
                    <p style="padding-top: 9rem;">
                      ลงชื่อ..........................................................................ผู้ส่งมอบงาน
                    </p>
                  </div>
                  <div v-else>
                    <p style="padding-top: 3rem;">
                      ลงชื่อ..........................................................................ผู้ส่งมอบงาน
                    </p>
                  </div>
                  <!-- <p>({{ selected_billing.booking.sale?.firstname || null }} {{ selected_billing.booking.sale?.lastname || null }})</p> -->
                  <p>{{ display_fullname(selected_billing.booking.sale?.firstname, selected_billing.booking.sale?.lastname) }}</p>
                  <!-- <p>{{ selected_billing?.booking?.car_company?.fullname }}</p> -->
                  <!-- <p> วันที่ {{ auto_current_date(true) }}</p> -->
                  <template v-if="auto_date">
                    <p>วันที่ {{ render_time(new Date(), 'DD MMMM BBBB') }}</p>
                  </template>
                  <template v-else>
                    <p>วันที่ ........................... / ........................... /...........................</p>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex;justify-content: space-between;">
            <span>{{ car_companies.fullname_en }}</span>
            <span v-if="(car_companies.company_id != 39 && businessTypeId == null) || businessTypeId == 1">F-CS-0037 ฉบับแก้ไขครั้งที่ 2 วันที่อนุมัติใช้ 1 มิถุนายน 2560</span>
            <span v-else></span>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { jsPDF } from 'jspdf'
  import html2canvas from 'html2canvas'
  import dayjs from 'dayjs'
  import buddhistEra from 'dayjs/plugin/buddhistEra'
  dayjs.extend(buddhistEra);
  import 'dayjs/locale/th';
  import CompanyService from "../../api/CompanyService";
  import { message } from 'ant-design-vue';
  import { ArabicNumberToText } from '@/components/helpers.js'
  import { cr } from '@fullcalendar/core/internal-common';
  
  export default {
    props: {
      selected_billing: Object,
      billed_amount: {
        type: Number,
        default: 0
      },
      billing_payments: Array,
      user_name: String,
      no_stamp: Boolean,
      auto_date: Boolean,
      create_paper_copy: Boolean,
      create_paper_original: Boolean,
      create_paper_original_and_copy: Boolean,
      car_companies: Object,
    },
    name: 'Export-Modal',
    data() {
      return {
        show_pdf: false,
        logo_url: require('@/assets/logo.jpg'),
        car_company_list: [],
        client_list: [],
        client_contact_list: [],
        remarks_list: [],
        bookingType: {
          1: { txt: 'เที่ยวเดียว (ขาเดียว)', color: 'pink' },
          2: { txt: 'ไป-กลับ', color: 'red' },
          3: { txt: 'รายวัน (เต็มวัน)', color: 'orange' },
          4: { txt: 'รายวัน (ครึ่งวัน)', color: 'green' },
          5: { txt: 'เฉพาะวัน (ส่ง-รับ วันกลางไม่ใช้รถ)', color: 'cyan' },
          6: { txt: 'เฉพาะวัน (ระบุวัน)', color: 'blue' },
          7: { txt: 'ใช้รถทุกวัน', color: 'purple' },
          8: { txt: 'รายเดือน', color: 'green' },
        },
        businessTypeId:null
      }
    },
    computed: {
      time_now() {
        return dayjs().format('DD-MM-YYYY HH:mm')
      }
    },
    methods: {
      paper_type(index) {
        if (this.create_paper_original_and_copy && index === 1) {
          return 'ต้นฉบับ';
        } else if (this.create_paper_original_and_copy && index === 2) {
          return 'สำเนา';
        }
        if (this.create_paper_copy && index === 1) {
          return 'สำเนา';
        }
        if (this.create_paper_original && index === 1) {
          return 'ต้นฉบับ';
        }
      },
  
      render_booking_detail(booking) {
        const { booking_type, person_qty, flight_pick, flight_drop,
          time_start, time_end, full_day_amount, start_location_name, end_location_name
        } = booking
  
        return `[${booking_type}] ${person_qty} ท่าน 
              ${flight_pick ? `ไฟล์ท ${flight_pick}` : ''} ${flight_drop ? `ไฟล์ท ${flight_drop}` : ''} 
              ${time_start ? `เวลา ${this.render_time(time_start, 'DD MMMM YYYY HH:mm')} น.` : ''} ${time_end ? `ถึงเวลา ${this.render_time(time_end, 'DD MMMM YYYY HH:mm')} น.` : ''} 
              ${full_day_amount ? `จำนวนวัน ${full_day_amount} วัน` : ''} 
              ${start_location_name ? `จุดเริ่มต้น ${start_location_name}` : ''} ${end_location_name ? `จุดสิ้นสุด ${end_location_name}` : ''}`
      },
      price_to_word(value) {
        return ArabicNumberToText(value)
      },
      get_stops(array) {
        const stops = []
        array.booking_cars.map(({ stops }) => (stops)).forEach((stop) => {
          stop.forEach((s) => {
            stops.push(s.name)
          })
        })
  
        return stops.join(' - ')
      },
      render_time(datetime, format) {
        return dayjs(datetime).locale('th').format(format)
      },
  
      display_fullname(firstname, lastname) {
        const firstNamePart = firstname ? firstname : '';
        const lastNamePart = lastname ? lastname : '';
        var fullName = `${firstNamePart} ${lastNamePart}`.trim()
        if (fullName !== null && fullName.trim() !== "") {
          return `(${fullName})`
        } else {
          return '(.........................................................................................)'
        }
      },
  
      export_pdf() {
        this.show_pdf = true;
        message.loading({
          content: 'กำลังสร้าง PDF...',
          key: 'export_pdf',
          duration: 0,
        });
  
        const totalPages = this.create_paper_original_and_copy ? 2 : 1;
  
        setTimeout(() => {
          const promises = [];
          for (let i = 1; i <= totalPages; i++) {
            promises.push(this.initSignature(this.selected_billing.booking.sale?.signature, i));
          }
  
          Promise.all(promises)
            .then(() => {
              const capturePromises = [];
              for (let i = 1; i <= totalPages; i++) {
                const pageElement = document.querySelector(`.page-${i}`);
                if (pageElement) {
                  capturePromises.push(html2canvas(pageElement, {
                    dpi: 72,
                    scale: 2,
                  }));
                } else {
                  console.error(`Element .page-${i} not found`);
                }
              }
  
              return Promise.all(capturePromises);
            })
            .then((canvases) => {
              if (canvases.length === 0) {
                throw new Error('No canvases were created.');
              }
  
              const doc = new jsPDF('p', 'pt', 'a4', true);
              canvases.forEach((canvas, index) => {
                if (index > 0) doc.addPage();
                const imgData = canvas.toDataURL('image/png');
                const width = doc.internal.pageSize.getWidth();
                const height = doc.internal.pageSize.getHeight();
                doc.addImage(imgData, 'PNG', 4, 4, width - 8, height - 8);
              });
              doc.save(`ใบส่งมอบงาน-${this.selected_billing.booking.booking_no}.pdf`);
              this.show_pdf = false;
              message.success({
                content: 'สร้าง PDF เรียบร้อย',
                key: 'export_pdf',
                duration: 1.5,
              });
              this.$parent.get_fake_delivery_note = false;
              this.$emit('close');
            })
            .catch((error) => {
              console.error('Error generating PDF:', error);
              message.error({
                content: 'เกิดข้อผิดพลาดในการสร้าง PDF',
                key: 'export_pdf',
                duration: 1.5,
              });
              this.show_pdf = false;
            });
        }, 1000); // Increased timeout to 1000ms to ensure all elements are rendered
      },
      export_docx() {
        this.show_pdf = true
        message.loading({
          content: 'กำลังสร้าง Doc...',
          key: 'export_doc',
          duration: 0
        });
  
        setTimeout(() => {
          var blob = new Blob(['\ufeff', document.getElementById('content').innerHTML], {
            type: 'application/msword'
          });
  
          saveAs(blob, `ใบส่งมอบงาน-${this.selected_billing.booking.booking_no}.docx`);
          this.show_pdf = false
          message.success({
            content: 'สร้าง Doc เรียบร้อย',
            key: 'export_doc',
            duration: 1.5
          });
          this.$parent.get_fake_delivery_note = false
          this.$emit('close')
        }, 100)
      },
      initSignature(signature, index) {
        return new Promise((resolve, reject) => {
          if (!signature) return resolve()
          setTimeout(() => {
            const canvas = document.getElementById(`signature-canvas-${index}`);
            const ctx = canvas.getContext("2d");
            const imageUrl = signature + '?no';
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.src = imageUrl;
            img.onload = function () {
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
              resolve()
            }
            img.onerror = function (err) {
              console.log(err);
              reject()
            }
          }, 100)
        })
      },
      async convertImgToBase64(url) {
            const response = await fetch(url);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        },
    },
    async mounted() {
      this.export_pdf()
      const logo = await this.convertImgToBase64(this.car_companies.logo);
      const logoIso = await this.convertImgToBase64(this.car_companies.logoIso);
      const logoSc = await this.convertImgToBase64(this.car_companies.logoSc);
      this.car_companies.logo = logo;
      this.car_companies.logoIso = logoIso;
      this.car_companies.logoSc = logoSc;
      console.log("car_companies", this.car_companies);
      let resultBid = await CompanyService.getBusinessId(this.car_companies.company_id);
      if(resultBid){
          this.businessTypeId = resultBid.data
      }
      // this.export_docx()
    }
  }
  </script>
  
  <style scoped>
  #content {
    line-height: 2;
  }
  
  td {
    border: 1px solid black;
  }
  
  .job-box {
    margin: 2rem 0;
    padding: 2rem;
    border: 3px solid black;
    border-radius: 15px;
  }
  
  p {
    margin: 0;
    color: black !important;
    font-size: 18px
  }
  
  h1 {
    font-size: 32px;
    margin: 5px 0;
  }
  
  h2 {
    font-size: 24px;
    margin: 5px 0;
  }
  
  .doc-table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
    font-size: 20px;
  }
  
  tr,
  td {
    padding: 0px 5px;
  }
  
  .showing-for-dev {
    display: block;
    background: white;
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
    border: 2px solid black;
    height: 100vh;
    overflow-y: scroll;
  }
  
  .ant-row {
    margin-bottom: 8px;
  }
  
  p {
    color: grey
  }
  
  .ant-col-12 {
    min-width: 300px;
    padding: 4px
  }
  
  .ant-select,
  .ant-picker {
    width: 100%
  }
  
  .ant-col {
    width: 100%
  }
  
  .ant-form-item {
    margin-bottom: 0;
  }
  
  .quotation-container {
    padding: 15px;
    height: 100vh;
  }
  </style>
  