import httpClient from './httpClient';
const prefix = 'admin'

export default {
  
    async createCompanyAdmin(body) {
      const { data } = await httpClient({ requiresAuth: true })
        .post(`${prefix}/createCompanyAdmin`, {
          body
        })
  
      return data
    },
  
    async createAdmin(body) {
      const { data } = await httpClient({ requiresAuth: true })
        .post(`${prefix}/createAdmin`, {
          body
        })
  
      return data
    },
  
    async createPermissionAdmin(body) {
      const { data } = await httpClient({ requiresAuth: true })
        .post(`${prefix}/createPermissionAdmin`, {
          body
        })
  
      return data
    }
}