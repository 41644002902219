
import httpClient from './httpClient';

export default {
  async get_calendar(query) {
    const { data } = await httpClient({ requiresAuth: true }).get(`/calendar${query? `?${query}` : ''}`);
    
    return data;
  },
  async get_schedule(car_company_id, { startDate, endDate }) {
    const { data } = await httpClient({ requiresAuth: true }).get(`/schedule${car_company_id? 
        `?car_company_id=${car_company_id}${(startDate && endDate)? `&timeStart=${startDate}&timeEnd=${endDate}`:''}`:''}`);
    
    return data;
  }
}