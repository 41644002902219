<template>
  <div class="login-container">
    <div class="form-card-img" :style="`background-image: url('${cover_img}')`"></div>
    <div class="login-form">
      <div class="flex-column">
        <img :src="logo_img" alt="Logo" style="width: 160px" />
        <span class="welcome-text">รีเซ็ตรหัสผ่าน</span>
        <div class="flex-column form-inputs">
          <input_primary
            style="margin: 0.5rem 0"
            type="password"
            icon="lock"
            placeholder="รหัสผ่านใหม่"
            @input_model="newPassword = $event"
          />
          <input_primary
            style="margin: 0.5rem 0"
            type="password"
            icon="lock"
            placeholder="ยืนยันรหัสผ่านใหม่"
            @input_model="confirmPassword = $event"
          />
          <a-button 
            type="primary" 
            @click="resetPassword()" 
            class="primary-button"
          >
            รีเซ็ตรหัสผ่าน
          </a-button>
        </div>
        <p style="color: red;" v-if="message">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
import input_primary from '../components/input_primary.vue';
import UserService from '../api/UserService.js'
import Swal from 'sweetalert2';
import { useRouter, useRoute } from 'vue-router';

export default defineComponent({
  components: { input_primary },
  name: 'ResetPassword',
  setup() {
    const router = useRouter();
    const route = useRoute();
    
    const email = route.query.email;
    const otp = route.query.otp;
    const username = route.query.username;

    const data = reactive({
      cover_img: require('@/assets/images/fleetex-home-page.png'),
      logo_img: require('@/assets/images/fleetex-log-in-logo.png'),
      newPassword: '',
      confirmPassword: '',
      message: ''
    });

    const resetPassword = async () => {
      if (data.newPassword !== data.confirmPassword) {
        data.message = 'รหัสผ่านไม่ตรงกัน';
        return;
      }

      try {
        const res = await UserService.resetPassword({
          email, otp,
          newPassword: data.newPassword,
          username
        });

        if (res.status === 200) {
          Swal.fire({
            title: 'รีเซ็ตรหัสผ่านสำเร็จ',
            icon: 'success',
            confirmButtonText: 'ตกลง'
          });
          router.push({ name: 'Login' });
        } else {
          data.message = res.message;
        }
      } catch (error) {
        data.message = 'Failed to reset password';
      }
    };

    return {
      ...toRefs(data),
      resetPassword
    };
  }
});
</script>

<style lang="scss">
.login-container {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
}
.login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (min-width: 992px) {
    max-width: 50%;
    flex: 0 0 50%;
  }
}
.form-card-img {
  width: 100%;
  min-height: 300px;
  background-size: cover;
  background-position: 50% 50%;

  @media (min-width: 992px) {
    max-width: 50%;
    flex: 0 0 50%;
  }
}
.welcome-text {
  margin-top: 1rem;
  font-size: 19px;
  font-weight: 800;
  color: RGB(38, 49, 107);
}
.detail-text {
  margin-bottom: 1.5rem;
  font-size: 14px;
}
.form-inputs {
  width: 100%;
  align-items: initial;
}
.primary-button {
  margin: 0.5rem 0;
}
.navigation-links {
  display: flex;
  justify-content: space-between;
}
.nav-link {
  font-size: 12px;
  text-align: center;
  margin: 1rem 0;
}
</style>