<template>
    <div>   
        <a-col>                      
            <a-table :columns="[{
                title: `ประเภทหมายเหตุ: ${remark_type.remark_name}`,
                dataIndex: 'remark',
                key: 'remark',
                width: 150,
            }
            ]" :data-source="remark_detail"
                bordered size="small">
                <template #bodyCell="{ column, record}">
                    <a-row justyfy="space-between">
                        <a-col>
                            <a style="font-size: 14px;" @click="move_up(record.id)" 
                                class="material-symbols-outlined">arrow_upward</a>

                            <a style="font-size: 14px;color: red" @click="remove(record.id)"
                                class="material-symbols-outlined">delete</a> 

                            <a style="font-size: 14px;"
                                @click="move_down(record.id)"
                                class="material-symbols-outlined">arrow_downward</a>
                                
                            {{ record.remark }}
                        </a-col>
                    </a-row>
                </template>
            </a-table>
        </a-col>
    </div>
</template>

<script>
export default {
    name: "remark_group_new",
    props: {
        remark_type: Object,
        remark_detail: Array
    },
    data() {
        return {
            
        }
    },
    methods: {
        remove(id){
            this.$emit('remove_remark',id);
        },
        move_up(id){
            this.$emit('move_up',id);
        },
        move_down(id){
            this.$emit('move_down',id);
        }

            // sortRemarkList(index){
            //     const result = remark_all.sort((a,b)=>{
            //         return a.index - b.index
            //     })
            //     return result
            // },
            // async add_remark() {
            //     let get_type = this.remark_all.find(remark => remark.id === this.selected_remark).remark_type
            //     let find_index = this.remark_types.findIndex((item) => item.id == get_type)

            //     this.remark_list_by_type[find_index].push(this.remark_all.find(remark => remark.id === this.selected_remark))
            //     this.remark_all = this.remark_all.filter(remark => remark.id !== this.selected_remark)
                
            //     this.selected_remark = null
            // },
            // sort_up(id,type) {
            //     let index = this.remark_list_by_type[type].findIndex((item) => item.id == id)
            //     if (index != 0){
            //         let old_index = this.remark_list_by_type[type][index].index
            //         let new_index = this.remark_list_by_type[type][index - 1].index
            //         this.remark_list_by_type[type][index].index = new_index
            //         this.remark_list_by_type[type][index - 1].index = old_index
            //     }
            // },
            // sort_down(id,type) {
            //     let length = this.remark_list_by_type[type].length
            //     let index = this.remark_list_by_type[type].findIndex((item) => item.id == id)
            //     if (index != length - 1){
            //         let old_index = this.remark_list_by_type[type][index].index
            //         let new_index = this.remark_list_by_type[type][index + 1].index
            //         this.remark_list_by_type[type][index].index = new_index
            //         this.remark_list_by_type[type][index + 1].index = old_index
            //     }
            // },
            // async remove_remark(id,type) {
            //     this.remark_all.push(this.remark_list_by_type[type].find(remark => remark.id === id))
            //     this.remark_list_by_type[type] = this.remark_list_by_type[type].filter(remark => remark.id !== id)
                
            // },
    },
    async mounted() {
    }
}
</script>