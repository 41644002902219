<template>
    <div v-if="isVisible" class="modal-overlay" @click.self="close">
      <div v-if="booking_updated" class="modal-content">
        <div class="modal-header">
          <strong>แก้ไข</strong>
          <span class="close" @click="close">&times;</span>
        </div>
        <div class="booking-details">
          <div v-if="booking_updated.time_start" class="detail-item">
            <strong>วันที่ - เวลา รับ</strong>
            <span>{{ formatDate(booking_updated.time_start) }}</span>
          </div>
          <div v-if="booking_updated.time_end" class="detail-item">
            <strong>วันที่ - เวลา ส่ง</strong>
            <span>{{ formatDate(booking_updated.time_end) }}</span>
          </div>
          <div v-if="booking_updated.total_days" class="detail-item">
            <strong>จำนวนวัน</strong>
            <span>{{ formatDate(booking_updated.total_days) }}</span>
          </div>
          <div v-if="booking_updated.start_location_name" class="detail-item">
            <strong>จุดรับ</strong>
            <span>{{ formatDate(booking_updated.start_location_name) }}</span>
          </div>
          <div v-if="booking_updated.end_location_name" class="detail-item">
            <strong>จุดส่ง</strong>
            <span>{{ formatDate(booking_updated.end_location_name) }}</span>
          </div>
          <div v-if="booking_updated.booking_type" class="detail-item">
            <strong>ประเภท</strong>
            <span>{{ formatDate(booking_updated.booking_type) }}</span>
          </div>
          <div v-if="booking_updated.status" class="detail-item">
            <strong>สถานะ</strong>
            <span>{{ formatDate(booking_updated.status) }}</span>
          </div>
          <div v-if="booking_updated.booking_cars_status" class="detail-item">
            <strong>สถานะการจัดรถ</strong>
            <span>{{ formatDate(booking_updated.booking_cars_status) }}</span>
          </div>
          <div v-if="booking_updated.booking_car_types" class="detail-item">
            <strong>จำนวนรถ</strong>
            <span>{{ formatDate(booking_updated.booking_car_types) }}</span>
          </div>
          <div v-if="booking_updated.car_late_amount" class="detail-item">
            <strong>จำนวนรถล่าช้า</strong>
            <span>{{ formatDate(booking_updated.car_late_amount) }}</span>
          </div>
          <div v-if="booking_updated.billing_status" class="detail-item">
            <strong>จำนวนการออกบิล</strong>
            <span>{{ formatDate(booking_updated.billing_status) }}</span>
          </div>
          <div v-if="booking_updated.client_name" class="detail-item">
            <strong>ลูกค้า/บริษัท</strong>
            <span>{{ formatDate(booking_updated.client_name) }}</span>
          </div>
          <div v-if="booking_updated.person_qty" class="detail-item">
            <strong>ผู้โดยสาร</strong>
            <span>{{ formatDate(booking_updated.person_qty) }}</span>
          </div>
          <div v-if="booking_updated.net_price" class="detail-item">
            <strong>รายได้</strong>
            <span>{{ formatDate(booking_updated.net_price) }}</span>
          </div>
          <div v-if="booking_updated.total_cars_expense" class="detail-item">
            <strong>รายจ่าย</strong>
            <span>{{ formatDate(booking_updated.total_cars_expense) }}</span>
          </div>
          <div v-if="booking_updated.profit" class="detail-item">
            <strong>กำไรขั้นต้น</strong>
            <span>{{ formatDate(booking_updated.profit) }}</span>
          </div>
          <div v-if="booking_updated.driverCost" class="detail-item">
            <strong>เบี้ยเลี้ยง</strong>
            <span>{{ formatDate(booking_updated.driverCost) }}</span>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import dayjs from "dayjs";
    export default {
        props: {
        isVisible: {
            type: Boolean,
            required: true
        },
        booking_updated: {
            type: Object,
            required: true
        }
        },
    name: 'Export-Modal',
    methods: {
      close() {
        this.$emit('close');
      },
      formatDate(date) {
      return dayjs(date).format("YYYY-MM-DD HH:mm:ss")
    }
    }
  }
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
  background: white;
  border-radius: 4px;
  max-width: 400px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1001;
  font-size: 16px; /* Base font size for modal content */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 10px; 
}

.modal-header strong {
  text-align: left;
  font-weight: bold;
  margin: 0;
  font-size: 20px;
  flex-grow: 1; 
}

.modal-header .close {
  font-size: 40px;
  cursor: pointer;
  margin-left: auto; 
}

.booking-details {
  display: grid;
  grid-template-columns: 180px 1fr; /* Adjust as needed */
  gap: 10px;
}

.detail-item {
  display: contents; /* Use contents to make grid items align in grid without extra styling */
}

.detail-item strong {
  text-align: left;
  margin-right: 5px;
}

.detail-item span {
  text-align: left;
  justify-self: end; /* Align value to the right side of its grid cell */
  margin-left: 5px;
}
  </style>