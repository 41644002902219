<template>
    <div class="app-content">
        <a-card :title="title">
            <template #extra>
                <a-button style="display: flex" @click="clear_model_data()" size="large">
                    <template #icon>
                        <span class="material-symbols-outlined">arrow_back_ios</span>
                    </template>
                     ย้อนกลับ
                </a-button>
            </template>
            <a-tabs v-if="model_type === 'Driver'" default-active-key="1">
                <a-tab-pane key="1" tab="เพิ่มพนักงานขับรถ">
                    <!-- ข้อมูลทั่วไป -->
                    <a-form :model="model_form" :layout="form_layout" :onFinish="submit_model_data">
                        <a-row justify="center">
                            <a-col v-if="default_fields[model_type].photos" :span="8" style="margin: 1rem">
                                <a-card title="รูปภาพ" style="text-align: center; margin-top: 20px;">
                                    <a-row justify="center" :gutter="16">
                                        <a-col :span="12">
                                            <a-row justify="center"
                                                v-for="{ key, label, height, width, description } in default_fields[model_type].photos"
                                                :gutter="16">
                                                    <div style="display: flex;flex-direction: column;height: fit-content; margin-bottom: 1rem;">
                                                    <p style="margin-top: 10;">{{ label }}</p>
                                                    <a-upload v-model:file-list="fileList" list-type="picture-card"
                                                            class="avatar-uploader" :show-upload-list="false"
                                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                            @change="upload_files($event, key)" method="PUT"
                                                            :before-upload="setFileType"
                                                            accept="image/*"
                                                            >
                                                        <img v-if="model_form[key]" :src="model_form[key]"
                                                            :style="`height: ${height}px;width: ${width}px;object-fit: cover`"
                                                            alt="avatar" />
                                                        <div v-else>
                                                            <p class="ant-upload-drag-icon">
                                                                <span class="material-symbols-outlined" style="font-size: 5rem">upload_file</span>
                                                            </p>
                                                            คลิกเพื่ออัพโหลดรูปภาพ
                                                        </div>
                                                    </a-upload>
                                                    <a-button danger type="primary" @click="(model_form[key] = '')"
                                                        v-if="model_form[key]">
                                                        <span class="material-symbols-outlined">remove</span>
                                                        ลบ
                                                    </a-button>
                                                    <p style="color: gray;">{{ description }}</p>
                                                </div> 
                                            </a-row>
                                        </a-col>
                                        <a-col :span="12" v-show="model_type == 'CarCompany'">
                                            <a-row justify="center" v-for="{ key, label, height, width, description } in default_fields[model_type].photos_payment" :gutter="16">
                                                <div style="display: flex;flex-direction: column;height: fit-content; margin-bottom: 1rem;">
                                                    <p>{{ label }}</p>
                                                    <a-upload v-model:file-list="fileList" list-type="picture-card" 
                                                            class="avatar-uploader" :show-upload-list="false"
                                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6" 
                                                            @change="upload_files($event, key)" method="GET"
                                                            :before-upload="setFileType"
                                                            accept="image/*"
                                                            >
                                                        <img v-if="model_form[key]" :src="model_form[key]" :style="`height: ${height}px;width: ${width}px;object-fit: cover`" alt="avatar" />
                                                        <div v-else>
                                                            <p class="ant-upload-drag-icon">
                                                                <span class="material-symbols-outlined" style="font-size: 5rem">upload_file</span>
                                                            </p>
                                                            คลิกเพื่ออัพโหลดรูปภาพ
                                                        </div>
                                                    </a-upload>
                                                    <a-button danger type="primary" @click="(model_form[key] = '')" v-if="model_form[key]">
                                                        <span class="material-symbols-outlined">remove</span>
                                                        ลบ
                                                    </a-button>
                                                    <p style="color: gray;">{{ description }}</p>
                                                </div>
                                            </a-row>
                                        </a-col>
                                    </a-row>
                                </a-card>

                                <a-card  v-if="model_type === 'CarCompany'"  title="รูปภาพที่เกี่ยวข้อง" style="text-align: center; margin-top: 20px;">
                                    <a-row justify="center" :gutter="16">
                                        <a-col :span="12">
                                            <a-row justify="center" v-for="{ key, label, height, width, description } in default_fields[model_type].photos_social" style="margin: 1rem 0">
                                                <div style="display: flex;flex-direction: column;height: fit-content; margin-bottom: 1rem;">
                                                    <p>{{ label }}</p>
                                                    <a-upload v-model:file-list="fileList" list-type="picture-card" 
                                                            class="avatar-uploader" :show-upload-list="false"
                                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6" 
                                                            @change="upload_files($event, key)" method="GET"
                                                            :before-upload="setFileType"
                                                            accept="image/*"
                                                            >
                                                        <img v-if="model_form[key]" :src="model_form[key]" :style="`height: ${height}px;width: ${width}px;object-fit: cover`" alt="avatar" />
                                                        <div v-else>
                                                            <p class="ant-upload-drag-icon">
                                                                <span class="material-symbols-outlined" style="font-size: 5rem">upload_file</span>
                                                            </p>
                                                            คลิกเพื่ออัพโหลดรูปภาพ
                                                        </div>
                                                    </a-upload>
                                                    <a-button danger type="primary" @click="(model_form[key] = '')" v-if="model_form[key]">
                                                        <span class="material-symbols-outlined">remove</span>
                                                        ลบ
                                                    </a-button>
                                                    <p style="color: gray;">{{ description }}</p>
                                                </div>
                                            </a-row>
                                        </a-col>
                                        <a-col :span="12">
                                            <a-row justify="center" v-for="{ key, label, height, width, description } in default_fields[model_type].photos_iso" style="margin: 1rem 0">
                                                <div style="display: flex;flex-direction: column;height: fit-content; margin-bottom: 1rem;">
                                                    <p>{{ label }}</p>
                                                    <a-upload v-model:file-list="fileList" list-type="picture-card" 
                                                            class="avatar-uploader" :show-upload-list="false"
                                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6" 
                                                            @change="upload_files($event, key)" method="GET"
                                                            :before-upload="setFileType"
                                                            accept="image/*"
                                                            >
                                                        <img v-if="model_form[key]" :src="model_form[key]" :style="`height: ${height}px;width: ${width}px;object-fit: cover`" alt="avatar" />
                                                        <div v-else>
                                                            <p class="ant-upload-drag-icon">
                                                                <span class="material-symbols-outlined" style="font-size: 5rem">upload_file</span>
                                                            </p>
                                                            คลิกเพื่ออัพโหลดรูปภาพ
                                                        </div>
                                                    </a-upload>
                                                    <a-button danger type="primary" @click="(model_form[key] = '')" v-if="model_form[key]">
                                                        <span class="material-symbols-outlined">remove</span>
                                                        ลบ
                                                    </a-button>
                                                    <p style="color: gray;">{{ description }}</p>
                                                </div>
                                            </a-row>
                                        </a-col>
                                    </a-row>
                                </a-card>
                            </a-col>

                            <a-col :span="15" style="margin: 1rem; width: 100%;">
                                <a-card title="ข้อมูล">
                                    <a-row>
                                        <a-col style="margin: 1rem" :span="col_span" v-for="
                                                {
                                                    col_span, non_editable, input_type, search_select, modelname, label_key, label, placeholder, required,
                                                    target_key, target_modelname, drawer_title, table_columns, drawer_fields, drawer_button_text, drawer_button_text_edit, parent, null_on_change, check_duplicate, check_rule,
                                                    on_change
                                                }, key in default_fields[model_type].inputs">
                                            <div v-if="input_type== 'label'">{{ label }}</div>
                                            <a-form-item v-else :name="key" :label="placeholder" :has-feedback="check_duplicate"
                                                :rules="check_duplicate? check_rule: (non_editable ? null : [{ required, message: `โปรดระบุ ${placeholder}` }])">
                                                <a-select v-if="key === 'work_status_id'" v-model:value="model_form[key]" @change="handleChange(null_on_change , on_change)">
                                                    <a-select-option v-for="option in selectables['DriverStatus']" :key="option.id" :value="option.id">
                                                        {{ option.name }} 
                                                    </a-select-option>
                                                </a-select>
                                                <a-select v-else-if="key === 'sex'" v-model:value="model_form[key]" @change="handleChange(null_on_change , on_change)">
                                                    <a-select-option v-for="(item, i) in sex_types" :key="i" :value="item.name">
                                                        {{ item.name }}
                                                    </a-select-option>
                                                </a-select>
                                                <a-input v-else-if="key === 'age'" :value="model_form[key] ? model_form[key] : calculatedAge" @input="model_form[key] = $event.target.value" :placeholder="placeholder" :type="input_type"/>
                                                <!-- to change back to the original driving_license_type_id uncomment this add delete -->
                                                <!-- model_form.driving_license_type_id watcher filteredDrivingLicenseTypes compute and handleChangeDrivingLicenseType method -->
                                                <!-- <a-select v-else-if="key === 'driving_license_type_id'" v-model:value="model_form[key]" @change="handleChange(null_on_change , on_change)">
                                                    <a-select-option v-for="option in selectables['DrivingLicenseType']" :key="option.id" :value="option.id">
                                                        {{ option.name }}
                                                    </a-select-option> -->
                                                <a-select v-else-if="key === 'driving_license_type_id'" v-model:value="model_form[key]" @change="handleChangeDrivingLicenseType">
                                                    <a-select-option v-for="option in filteredDrivingLicenseTypes" :key="option.id" :value="option.id">
                                                        {{ option.name }}
                                                    </a-select-option>
                                                </a-select>
                                                <a-select v-else-if="key === 'withholding_id'" v-model:value="model_form[key]" @change="handleChange(null_on_change , on_change)">
                                                    <a-select-option v-for="option in selectables['Withholding']" :key="option.id" :value="option.id">
                                                        {{ option.name }}
                                                    </a-select-option>
                                                </a-select>
                                                <a-select v-else-if="key === 'bank_id'" v-model:value="model_form[key]" @change="handleChange(null_on_change , on_change)">
                                                    <a-select-option v-for="option in selectables['Bank']" :key="option.id" :value="option.id">
                                                        {{ option.name }}
                                                    </a-select-option>
                                                </a-select>
                                                <a-select v-else-if="key === 'car_type_id'" v-model:value="model_form[key]" @change="handleChange(null_on_change , on_change)">
                                                    <a-select-option v-for="option in selectables['CarType']" :key="option.id" :value="option.id">
                                                        {{ option.name }}
                                                    </a-select-option>
                                                </a-select>
                                                <a-select v-else-if="key === 'client_id'" 
                                                    show-search v-model:value="model_form[key]" 
                                                    @change="handleChange(null_on_change , on_change)" 
                                                    :options="selectables['Client'].map((e) => ({ value: e.id, label: e.fullname }))"
                                                    :filter-option="filterOption">
                                                    <a-select-option v-for="option in selectables['Client']" :key="option.id" :value="option.id">
                                                        {{ option.fullname }}
                                                    </a-select-option>
                                                </a-select>
                                                <a-select
                                                    v-else-if="input_type == 'select' && search_select && selectables[modelname]"
                                                    v-model:value="model_form[key]"
                                                    show-search
                                                    :placeholder="placeholder"
                                                    :options="selectables[modelname].map((e) => ({ value: e.id, label: e[label_key] }))"
                                                    :filter-option="filterOption"
                                                    @change="handleChange(null_on_change)"
                                                ></a-select>
                                                <a-select v-else-if="input_type == 'sub_select'"
                                                    v-model:value="model_form[key]" :disabled="!model_form[parent.key]">
                                                    <a-select-option 
                                                        v-for="{id,name} in selectables[modelname]?.filter(e => e[parent.key] == model_form[parent.key])"
                                                        :value="id" 
                                                        selected
                                                    >
                                                        {{ name }}
                                                    </a-select-option>
                                                </a-select>
                                                <a-checkbox-group v-else-if="input_type == 'checkbox'"
                                                    v-model:value="model_form[key]"
                                                    :options="selectables[modelname]?.map(option => { return { label: option[label_key], value: option.id } })" />
                                                <a-divider v-else-if="input_type == 'divider'" orientation="left">{{ label
                                                }}</a-divider>
                                                <a-divider v-else-if="input_type == 'sub-divider'" orientation="left">
                                                    <h5>{{ label }}</h5>
                                                </a-divider>
                                                <TableCreateDrawer v-else-if="input_type == 'table'" :divider="drawer_title"
                                                    :table_columns="table_columns" :target_object="model_form"
                                                    :target_key="target_key" :drawer_title="drawer_title"
                                                    :drawer_fields="drawer_fields.inputs"
                                                    :drawer_button_text="drawer_button_text"
                                                    :drawer_button_text_edit="drawer_button_text_edit"
                                                    :target_modelname="target_modelname" />
                                                <a-date-picker v-else-if="input_type == 'date'" v-model:value="model_form[key]"
                                                    :placeholder="placeholder"
                                                    :disabled="action_type == 'create' ? false : non_editable" />
                                                <a-date-picker v-else-if="input_type == 'datetime'" v-model:value="model_form[key]"
                                                    :placeholder="placeholder"
                                                    :show-time="{ format: 'HH:mm' }"
                                                    format="DD/MM/YYYY HH:mm"
                                                    :disabled="action_type == 'create' ? false : non_editable" />
                                                <a-date-picker v-else-if="input_type == 'month'" v-model:value="model_form[key]"
                                                    :placeholder="placeholder"
                                                    picker="month"
                                                    format="MMMM"
                                                    :disabled="action_type == 'create' ? false : non_editable" />
                                                <a-switch v-else-if="input_type == 'switch'" 
                                                    v-model:checked="model_form[key]"
                                                    :placeholder="placeholder"
                                                    :disabled="action_type == 'create' ? false : non_editable" />
                                                <a-textarea v-else-if="input_type == 'textarea'" v-model:value="model_form[key]"
                                                    :placeholder="placeholder"
                                                    :disabled="action_type == 'create' ? false : non_editable" />
                                                <div v-else-if="input_type == 'photos'" class="clearfix">
                                                    <a-upload v-model:file-list="model_form[target_key]"
                                                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                        @change="upload_files($event, key)" method="GET"
                                                        @remove="remove_uploaded_file($event)"
                                                        list-type="picture-card">
                                                        <div v-if="model_form[target_key]?.length < 5"
                                                            style="height: 104px; width: 104px; padding: 2rem">
                                                            <span style="font-size: 20px"
                                                                class="material-symbols-outlined">add</span>
                                                            <div style="margin-top: 8px">Upload</div>
                                                        </div>
                                                    </a-upload>
                                                </div>
                                                <div v-else-if="input_type == 'photo'" style="display: flex; flex-direction: column; align-items: center; gap:1rem">
                                                    <div>{{ label }}</div>
                                                    <a-upload 
                                                        v-model:file-list="fileList"
                                                        :show-upload-list="false"
                                                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                        @change="upload_files($event, key)" 
                                                        method="GET"
                                                        :before-upload="setFileType"
                                                        accept="image/*"
                                                    >
                                                        <div v-if="model_form[key]" class="upload-container">
                                                            <img :src="model_form[key]" class="uploaded-image" alt="avatar" @click="showPreview(model_form[key])"/>
                                                            <div class="icon-container">
                                                                <span
                                                                    class="material-symbols-outlined preview-icon"
                                                                    @click="handleIconClick('preview', model_form[key], $event)"
                                                                >
                                                                    visibility
                                                                </span>
                                                                <a :href="model_form[key]" download @click.stop>
                                                                    <span class="material-symbols-outlined download-icon"
                                                                >
                                                                        download
                                                                    </span>
                                                                </a>
                                                                <span
                                                                    class="material-symbols-outlined delete-icon"
                                                                    @click="handleIconClick('delete', key, $event)"
                                                                >
                                                                    delete
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                                            <p class="ant-upload-drag-icon">
                                                                <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                                            </p>
                                                            คลิกเพื่ออัพโหลดรูปภาพ
                                                        </div>
                                                    </a-upload>
                                                    <a-modal v-model:visible="previewVisible" :footer="null" :mask="false" @cancel="previewVisible = false" class="custom-modal" style="background-color: none !important" >
                                                        <img :src="previewImage" alt="Preview" style="width: 100%;" />
                                                    </a-modal>
                                                </div>
                                                <!-- upload_file -->
                                                <div v-else-if="input_type == 'file'" style="display: flex; flex-direction: column; align-items: center; gap:1rem">
                                                    <div>{{ label }}</div>
                                                    <a-upload 
                                                        v-model:file-list="fileList" 
                                                        v-if="!model_form[key]"
                                                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                        method="GET"
                                                        :show-upload-list="false"
                                                        @change="upload_files($event, key)"
                                                    >
                                                        <img v-if="model_form[key]" :src="model_form[key]"
                                                            style="width: 100%; height: 100px;"
                                                            alt="avatar"
                                                        />
                                                        <div style="display: flex; flex-direction: column; align-items: center;">
                                                            <p class="ant-upload-drag-icon">
                                                                <span class="material-symbols-outlined" 
                                                                    style="font-size: 5rem;">upload_file</span>
                                                            </p>
                                                            อัพโหลดไฟล์
                                                        </div>
                                                    </a-upload>
                                                    <a :href="model_form[key]" v-else="model_form[key]"
                                                        target="_blank" rel="doc"
                                                    >
                                                        {{ model_form[`${key}_file_name`] }}
                                                    </a>
                                                    <a-button danger type="text" @click="(model_form[key] = '')" style="padding: 0; height: 18px;"
                                                        v-if="model_form[key]">
                                                        <span class="material-symbols-outlined">close</span>
                                                    </a-button>
                                                </div>
                                                <div  v-else-if="input_type == 'table_files'" >
                                                    <a-table
                                                        :columns="[
                                                            {
                                                                title: 'ชื่อเอกสาร',
                                                                dataIndex: 'name',
                                                                key: 'name',
                                                                width: 400,
                                                            },
                                                            {
                                                                title: 'ไฟล์เอกสาร',
                                                                dataIndex: 'file',
                                                                key: 'file',
                                                            },
                                                            {
                                                                title: 'ลบ',
                                                                dataIndex: 'id',
                                                                key: 'id',
                                                                width: 100,
                                                                align: 'center',
                                                            }
                                                        ]"
                                                        :dataSource="data_raw_files"
                                                        :pagination="false"
                                                        :scroll="{ y: 240 }"
                                                    >
                                                        <template #bodyCell="{ column, record, index}" >
                                                            <template v-if="column.key === 'name'">
                                                                <a-form-item>
                                                                    <a-input v-model:value="record.name"
                                                                    />
                                                                </a-form-item>
                                                            </template>
                                                            <template v-if="column.key === 'file'">
                                                                <a-form-item>
                                                                <a :href="record.file" target="_blank" rel="doc">{{ record.file_name }}</a>
                                                                </a-form-item>
                                                            </template>
                                                            <template v-if="column.key === 'id'">
                                                                <a-form-item>
                                                                    <a-button danger type="text" @click="(data_raw_files.splice(index, 1))">
                                                                        <span class="material-symbols-outlined">delete</span>
                                                                    </a-button>
                                                                </a-form-item>
                                                            </template>
                                                        </template>
                                                    </a-table>
                                                    <a-upload v-model:file-list="model_form[key]"
                                                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                        method="GET"
                                                        @change="upload_file_multiple($event, key)"
                                                        :show-upload-list="false"
                                                        >
                                                        <a-button style="margin-top: 10px;">
                                                            <span class="material-symbols-outlined">add</span>
                                                            เพิ่มไฟล์
                                                        </a-button>
                                                    </a-upload>
                                                </div>
                                                <div v-else-if="input_type== 'password'">
                                                    <a-input-password v-if="action_type== 'create'" v-model:value="model_form[key]" 
                                                        :placeholder="placeholder" 
                                                        :type="input_type"
                                                        :disabled="action_type == 'create' ? false : non_editable"
                                                        autoComplete="new-password"
                                                    />
                                                    <a-input-password v-else v-model:value="model_form[key]" 
                                                        :placeholder="placeholder" 
                                                        :type="input_type"
                                                        :disabled="action_type == 'create' ? false : non_editable"
                                                        autoComplete="new-password"
                                                    />
                                                </div>
                                                <a-input v-else v-model:value="model_form[key]" :placeholder="placeholder"
                                                    :type="input_type"
                                                    :disabled="action_type == 'create' ? false : non_editable"
                                                />
                                            </a-form-item>
                                        </a-col>
                                    </a-row>

                                    <a-row justify="end">
                                        <a-form-item>
                                            <a-button type="primary" size="large" html-type="submit">
                                                <template #icon>
                                                    <span style="font-size: 20px; color: white" class="material-symbols-outlined">save</span>
                                                </template>
                                                บันทึกข้อมูล
                                            </a-button>
                                        </a-form-item>
                                    </a-row>
                                </a-card>
                            </a-col>
                        </a-row>
                    </a-form>
                </a-tab-pane>          
                <a-tab-pane key="2" tab="ทะเบียนประวัติ">
                    <a-form :model="model_form" :layout="form_layout" :onFinish="submit_model_data">
                        <a-row justify="center">
                            <a-col :span="15" style="margin: 1rem; width: 100%;">
                                <a-card title="ข้อมูลทั่วไป">
                                    <a-row>
                                        <a-col v-for="(field, key) in default_fields_driver[model_type].inputs" 
                                            :key="key" 
                                            :span="field.col_span" 
                                            style="margin: 1rem">
                                            <div v-if="field.input_type == 'label'">{{ field.label }}</div>
                                            <a-form-item v-else :name="key" :label="field.placeholder" :rules="[{ required: field.required, message: `โปรดระบุ ${field.placeholder}` }]">
                                                <a-input v-if="field.input_type == 'text'" v-model:value="model_form[key]" :placeholder="field.placeholder" :disabled="field.non_editable" />
                                                <div v-else-if="field.input_type == 'file'" style="display: flex; flex-direction: column; align-items: center; gap:1rem">
                                                    <div>{{ label }}</div>
                                                    <a-upload 
                                                        v-model:file-list="fileList" 
                                                        v-if="!model_form[key]"
                                                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                        method="GET"
                                                        :show-upload-list="false"
                                                        :accept="field.accept ? field.accept : '*/*'"
                                                        @change="upload_files($event, key)"
                                                    >
                                                        <img v-if="model_form[key]" :src="model_form[key]"
                                                            style="width: 100%; height: 100px;"
                                                            alt="avatar"
                                                        />
                                                        <div style="display: flex; flex-direction: column; align-items: center;">
                                                            <p class="ant-upload-drag-icon">
                                                                <span class="material-symbols-outlined" 
                                                                    style="font-size: 5rem;">upload_file</span>
                                                            </p>
                                                            อัพโหลดไฟล์
                                                        </div>
                                                    </a-upload>
                                                    <a :href="model_form[key]" v-else="model_form[key]"
                                                        target="_blank" rel="doc"
                                                    >
                                                        {{ model_form[`${key}_file_name`] }}
                                                    </a>
                                                    <a-button danger type="text" @click="(model_form[key] = '')" style="padding: 0; height: 18px;"
                                                        v-if="model_form[key]">
                                                        <span class="material-symbols-outlined">close</span>
                                                    </a-button>
                                                </div>
                                                <a-date-picker v-else-if="field.input_type == 'date'" v-model:value="model_form[key]" :placeholder="field.placeholder" style="display: flex;" />
                                                <a-input v-else-if="key === 'age'" :value="model_form[key] ? model_form[key] : calculatedAge" @input="model_form[key] = $event.target.value" :placeholder="placeholder" :type="input_type"/>
                                                <a-checkbox-group v-else-if="field.input_type == 'checkbox'" v-model:value="model_form[key]" :placeholder="field.placeholder" style="display: flex;">
                                                    <a-checkbox
                                                        :disabled="end"
                                                        @change="handleChange('start', $event)"
                                                        v-model="start"
                                                        value="ทำงาน">ทำงาน</a-checkbox>
                                                    <a-checkbox
                                                        :disabled="start"
                                                        @change="handleChange('end', $event)"
                                                        v-model="end"
                                                        value="สิ้นสุดการเป็นพนักงานตั้งแต่วันที่">สิ้นสุดการเป็นพนักงานตั้งแต่วันที่</a-checkbox>
                                                </a-checkbox-group>

                                                
                                                <a-date-picker v-if="key == 'driver_stoped_employment_date'"
                                                    v-show="end"
                                                    v-model:value="model_form.driver_stoped_employment_date"
                                                    :placeholder="field.placeholder"
                                                    :disabled="start"
                                                    style="display: flex;" />

                                                <a-date-picker v-else-if="field.input_type == 'register_date'"
                                                    v-model:value="model_form.register_date"
                                                    :placeholder="field.placeholder"
                                                    style="display: flex;" />

                                                <a-input v-else-if="field.input_type == 'driver_age_of_work'"
                                                    v-model:value="model_form.driver_age_of_work"
                                                    :placeholder="field.placeholder"
                                                    :disabled="register_date"
                                                    readonly />

                                                <a-divider v-else-if="field.input_type == 'bold'" orientation="left">
                                                    <p> {{ field.label }} </p>
                                                </a-divider>

                                                <a-select v-else-if="field.input_type == 'select_driver_work_ranks'" v-model:value="model_form[key]" :placeholder="field.placeholder">
                                                    <a-select-option value="พนักงานขับรถ (D)">พนักงานขับรถ (D)</a-select-option>
                                                    <a-select-option value="พนักงานขับรถชั่วคราว (DPT)">พนักงานขับรถชั่วคราว (DPT)</a-select-option>
                                                    <a-select-option value="พนักงานบริการประจำรถ (BA)">พนักงานบริการประจำรถ (BA)</a-select-option>
                                                    <a-select-option value="พนักงานบริการประจำรถชั่วคราว (BAPT)">พนักงานบริการประจำรถชั่วคราว (BAPT)</a-select-option>
                                                    <a-select-option value="พนักงานขับรถอาวุโส (SD)">พนักงานขับรถอาวุโส (SD)</a-select-option>
                                                    <a-select-option value="พนักงานบริการประจำรถอาวุโส (SBA)">พนักงานบริการประจำรถอาวุโส (SBA)</a-select-option>
                                                </a-select>
                                                <a-select v-else-if="field.input_type == 'select_driver_prefix_name'" v-model:value="model_form[key]" :placeholder="field.placeholder">
                                                    <a-select-option value="นาย">นาย</a-select-option>
                                                    <a-select-option value="นาง">นาง</a-select-option>
                                                    <a-select-option value="นางสาว">นางสาว</a-select-option>
                                                </a-select>
                                                <a-select v-else-if="field.input_type == 'select_driver_religions'" v-model:value="model_form[key]" :placeholder="field.placeholder">
                                                    <a-select-option value="พุทธ">พุทธ</a-select-option>
                                                    <a-select-option value="คริสต์">คริสต์</a-select-option>
                                                    <a-select-option value="อิสลาม">อิสลาม</a-select-option>
                                                    <a-select-option value="ไม่มีศาสนา">ไม่มีศาสนา</a-select-option>
                                                    <a-select-option value="อื่นๆ">อื่นๆ</a-select-option>
                                                </a-select>
                                                <a-select v-else-if="field.input_type == 'select_driver_educations'" v-model:value="model_form[key]" :placeholder="field.placeholder" @change="(value) => handleEducationChange(value, key)">
                                                    <a-select-option value="ประถมศึกษา">ประถมศึกษา</a-select-option>
                                                    <a-select-option value="มัธยมศึกษา">มัธยมศึกษา</a-select-option>
                                                    <a-select-option value="อาชีวศึกษา">อาชีวศึกษา</a-select-option>
                                                    <a-select-option value="ปริญญาบัณฑิต">ปริญญาบัณฑิต</a-select-option>
                                                    <a-select-option value="บัณฑิตศึกษา">บัณฑิตศึกษา</a-select-option>
                                                    <a-select-option value="อื่นๆ">อื่นๆ</a-select-option>
                                                </a-select>
                                                <a-select v-else-if="field.input_type == 'select_driver_license_type'" v-model:value="model_form[key]" :placeholder="field.placeholder">
                                                    <a-select-option value="ส่วนบุคคล (บ.)">ส่วนบุคคล (บ.)</a-select-option>
                                                    <a-select-option value="ทุกประเภท (ท.)">ทุกประเภท (ท.)</a-select-option>
                                                </a-select>
                                                <a-checkbox-group 
                                                    v-if="field.input_type === 'checkbox_result'"
                                                    v-model:value="model_form[key]" 
                                                    :placeholder="field.placeholder"
                                                    @change="handleCheckboxChange"
                                                >
                                                    <a-checkbox value="ผ่าน">ผ่าน</a-checkbox>
                                                    <a-checkbox value="ไม่ผ่าน">ไม่ผ่าน</a-checkbox>
                                                </a-checkbox-group>
                                                <a-select
                                                    v-else-if="field.input_type == 'select' && selectables[field.modelname]"
                                                    v-model:value="model_form[key]"
                                                    show-search
                                                    :placeholder="field.placeholder"
                                                    :options="selectables[field.modelname].map((e) => ({ value: e.id, label: e[field.label_key] }))"
                                                    :filter-option="filterOption"
                                                    @change="handleChange(null_on_change)"
                                                    :disabled="field.non_editable ? true : false"
                                                ></a-select>

                                                <a-select v-else-if="field.input_type == 'select_driver_bank'" v-model:value="model_form[key]" :placeholder="field.placeholder">
                                                    <a-select-option value="กรุงเทพ">กรุงเทพ</a-select-option>
                                                    <a-select-option value="กสิกรไทย">กสิกรไทย</a-select-option>
                                                    <a-select-option value="กรุงไทย">กรุงไทย</a-select-option>
                                                    <a-select-option value="ไทยพาณิชย์">ไทยพาณิชย์</a-select-option>
                                                    <a-select-option value="ทหารไทย">ทหารไทย</a-select-option>
                                                    <a-select-option value="กรุงศรีอยุธยา">กรุงศรีอยุธยา</a-select-option>
                                                    <a-select-option value="เกียรตินาคิน">เกียรตินาคิน</a-select-option>
                                                    <a-select-option value="อื่นๆ">อื่นๆ</a-select-option>
                                                </a-select>
                                                <TableCreateDrawer 
                                                    v-else-if="field.input_type == 'table'" 
                                                    :divider="field.drawer_title"
                                                    :table_columns="field.table_columns" 
                                                    :target_object="model_form"
                                                    :target_key="key" 
                                                    :drawer_title="field.drawer_title"
                                                    :drawer_fields="field.drawer_fields.inputs"
                                                    :drawer_button_text="field.drawer_button_text"
                                                    :drawer_button_text_edit="field.drawer_button_text_edit"
                                                    :target_modelname="field.target_modelname" 
                                                    class="left-aligned-button">
                                                </TableCreateDrawer>
                                                <template>
                                                  <table_create_drawer @update:data="updateDrawerData"/>
                                                </template>
                                                <a-modal v-model:visible="showOtherEducationModal" title="ระบุระดับการศึกษาอื่นๆ" @ok="handleModalOk" @cancel="handleModalCancel">
                                                    <a-input v-model:value="otherEducationInput" placeholder="ระบุระดับการศึกษาอื่นๆ" />
                                                </a-modal>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                    <a-row justify="end">
                                        <a-form-item>
                                            <a-button type="primary" size="large" html-type="submit">
                                                <template #icon>
                                                    <span style="font-size: 20px" class="material-symbols-outlined">save</span>
                                                </template>
                                                บันทึกข้อมูล
                                            </a-button>
                                        </a-form-item>
                                    </a-row>
                                </a-card>
                            </a-col>
                        </a-row>
                    </a-form>
                </a-tab-pane>
            </a-tabs>
            <a-form v-else :model="model_form" :layout="form_layout" :onFinish="submit_model_data">
                        <a-row justify="center">
                            <a-col v-if="default_fields[model_type].photos" :span="8" style="margin: 1rem">
                                <a-card title="รูปภาพ" style="text-align: center; margin-top: 20px;">
                                    <a-row justify="center" :gutter="16">
                                        <a-col :span="12">
                                            <a-row justify="center"
                                                v-for="{ key, label, height, width, description } in default_fields[model_type].photos"
                                                :gutter="16">
                                                    <div style="display: flex;flex-direction: column;height: fit-content; margin-bottom: 1rem;">
                                                    <p style="margin-top: 10;">{{ label }}</p>
                                                    <a-upload v-model:file-list="fileList" list-type="picture-card"
                                                        class="avatar-uploader" :show-upload-list="false"
                                                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                        @change="upload_files($event, key)" method="PUT"
                                                        :before-upload="setFileType"
                                                        accept="image/*"
                                                        >
                                                        <img v-if="model_form[key]" :src="model_form[key]"
                                                            :style="`height: ${height}px;width: ${width}px;object-fit: cover`"
                                                            alt="avatar" />
                                                        <div v-else>
                                                            <p class="ant-upload-drag-icon">
                                                                <span class="material-symbols-outlined"
                                                                    style="font-size: 5rem">upload_file</span>
                                                            </p>
                                                            คลิกเพื่ออัพโหลดรูปภาพ
                                                        </div>
                                                    </a-upload>
                                                    <a-button danger type="primary" @click="(model_form[key] = '')"
                                                        v-if="model_form[key]">
                                                        <span class="material-symbols-outlined">remove</span>
                                                        ลบ
                                                    </a-button>
                                                    <p style="color: gray;">{{ description }}</p>
                                                </div> 
                                            </a-row>
                                        </a-col>
                                        <a-col :span="12" v-show="model_type == 'CarCompany'">
                                            <a-row justify="center" v-for="{ key, label, height, width, description } in default_fields[model_type].photos_payment" :gutter="16">
                                                <div style="display: flex;flex-direction: column;height: fit-content; margin-bottom: 1rem;">
                                                    <p>{{ label }}</p>
                                                    <a-upload v-model:file-list="fileList" list-type="picture-card" 
                                                        class="avatar-uploader" :show-upload-list="false"
                                                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6" 
                                                        @change="upload_files($event, key)" method="GET"
                                                        :before-upload="setFileType"
                                                        accept="image/*"
                                                        >
                                                        <img v-if="model_form[key]" :src="model_form[key]" :style="`height: ${height}px;width: ${width}px;object-fit: cover`" alt="avatar" />
                                                        <div v-else>
                                                            <p class="ant-upload-drag-icon">
                                                                <span class="material-symbols-outlined" style="font-size: 5rem">upload_file</span>
                                                            </p>
                                                            คลิกเพื่ออัพโหลดรูปภาพ
                                                        </div>
                                                    </a-upload>
                                                    <a-button danger type="primary" @click="(model_form[key] = '')" v-if="model_form[key]">
                                                        <span class="material-symbols-outlined">remove</span>
                                                        ลบ
                                                    </a-button>
                                                    <p style="color: gray;">{{ description }}</p>
                                                </div>
                                            </a-row>
                                        </a-col>
                                    </a-row>
                                </a-card>

                                <a-card  v-if="model_type === 'CarCompany'"  title="รูปภาพที่เกี่ยวข้อง" style="text-align: center; margin-top: 20px;">
                                    <a-row justify="center" :gutter="16">
                                        <a-col :span="12">
                                            <a-row justify="center" v-for="{ key, label, height, width, description } in default_fields[model_type].photos_social" style="margin: 1rem 0">
                                                <div style="display: flex;flex-direction: column;height: fit-content; margin-bottom: 1rem;">
                                                    <p>{{ label }}</p>
                                                    <a-upload v-model:file-list="fileList" list-type="picture-card" 
                                                        class="avatar-uploader" :show-upload-list="false"
                                                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6" 
                                                        @change="upload_files($event, key)" method="GET"
                                                        :before-upload="setFileType"
                                                        accept="image/*"
                                                        >
                                                        <img v-if="model_form[key]" :src="model_form[key]" :style="`height: ${height}px;width: ${width}px;object-fit: cover`" alt="avatar" />
                                                        <div v-else>
                                                            <p class="ant-upload-drag-icon">
                                                                <span class="material-symbols-outlined" style="font-size: 5rem">upload_file</span>
                                                            </p>
                                                            คลิกเพื่ออัพโหลดรูปภาพ
                                                        </div>
                                                    </a-upload>
                                                    <a-button danger type="primary" @click="(model_form[key] = '')" v-if="model_form[key]">
                                                        <span class="material-symbols-outlined">remove</span>
                                                        ลบ
                                                    </a-button>
                                                    <p style="color: gray;">{{ description }}</p>
                                                </div>
                                            </a-row>
                                        </a-col>
                                        <a-col :span="12">
                                            <a-row justify="center" v-for="{ key, label, height, width, description } in default_fields[model_type].photos_iso" style="margin: 1rem 0">
                                                <div style="display: flex;flex-direction: column;height: fit-content; margin-bottom: 1rem;">
                                                    <p>{{ label }}</p>
                                                    <a-upload v-model:file-list="fileList" list-type="picture-card" 
                                                        class="avatar-uploader" :show-upload-list="false"
                                                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6" 
                                                        @change="upload_files($event, key)" method="GET"
                                                        :before-upload="setFileType"
                                                        accept="image/*"
                                                        >
                                                        <img v-if="model_form[key]" :src="model_form[key]" :style="`height: ${height}px;width: ${width}px;object-fit: cover`" alt="avatar" />
                                                        <div v-else>
                                                            <p class="ant-upload-drag-icon">
                                                                <span class="material-symbols-outlined" style="font-size: 5rem">upload_file</span>
                                                            </p>
                                                            คลิกเพื่ออัพโหลดรูปภาพ
                                                        </div>
                                                    </a-upload>
                                                    <a-button danger type="primary" @click="(model_form[key] = '')" v-if="model_form[key]">
                                                        <span class="material-symbols-outlined">remove</span>
                                                        ลบ
                                                    </a-button>
                                                    <p style="color: gray;">{{ description }}</p>
                                                </div>
                                            </a-row>
                                        </a-col>
                                    </a-row>
                                </a-card>
                            </a-col>

                            <a-col :span="15" style="margin: 1rem; width: 100%;">
                                <a-card title="ข้อมูล">
                                    <a-row>
                                        <a-col style="margin: 1rem" :span="col_span" v-for="
                                                {
                                                    col_span, non_editable, input_type, search_select, modelname, label_key, label, placeholder, required,
                                                    target_key, target_modelname, drawer_title, label_function, table_columns, drawer_fields, drawer_button_text, drawer_button_text_edit, parent, null_on_change, check_duplicate, check_rule,
                                                    on_change
                                                }, key in default_fields[model_type].inputs">
                                            <div v-if="input_type== 'label'">{{ label }}</div>
                                            <a-form-item v-else :name="key" :label="placeholder" :has-feedback="check_duplicate"
                                                :rules="check_duplicate? check_rule: (non_editable ? null : [{ required, message: `โปรดระบุ ${placeholder}` }])">
                                                <a-select v-if="key === 'work_status_id'" v-model:value="model_form[key]" @change="handleChange(null_on_change , on_change)">
                                                    <a-select-option v-for="option in selectables['DriverStatus']" :key="option.id" :value="option.id">
                                                        {{ option.name }} 
                                                    </a-select-option>
                                                </a-select>
                                                <a-select v-else-if="key === 'sex'" v-model:value="model_form[key]" @change="handleChange(null_on_change , on_change)">
                                                    <a-select-option v-for="(item, i) in sex_types" :key="i" :value="item.name">
                                                        {{ item.name }}
                                                    </a-select-option>
                                                </a-select>
                                                <a-select v-else-if="key === 'driving_license_type_id'" v-model:value="model_form[key]" @change="handleChange(null_on_change , on_change)">
                                                    <a-select-option v-for="option in selectables['DrivingLicenseType']" :key="option.id" :value="option.id">
                                                        {{ option.name }}
                                                    </a-select-option>
                                                </a-select>
                                                <a-select v-else-if="key === 'withholding_id'" v-model:value="model_form[key]" @change="handleChange(null_on_change , on_change)">
                                                    <a-select-option v-for="option in selectables['Withholding']" :key="option.id" :value="option.id">
                                                        {{ option.name }}
                                                    </a-select-option>
                                                </a-select>
                                                <a-select v-else-if="key === 'bank_id'" v-model:value="model_form[key]" @change="handleChange(null_on_change , on_change)">
                                                    <a-select-option v-for="option in selectables['Bank']" :key="option.id" :value="option.id">
                                                        {{ option.name }}
                                                    </a-select-option>
                                                </a-select>
                                                <a-select v-else-if="key === 'car_type_id'" v-model:value="model_form[key]" @change="handleChange(null_on_change , on_change)">
                                                    <a-select-option v-for="option in selectables['CarType']" :key="option.id" :value="option.id">
                                                        {{ option.name }}
                                                    </a-select-option>
                                                </a-select>
                                                <a-select v-else-if="key === 'client_id'" 
                                                    show-search v-model:value="model_form[key]" 
                                                    @change="handleChange(null_on_change , on_change)" 
                                                    :options="selectables['Client'].map((e) => ({ value: e.id, label: e.fullname }))"
                                                    :filter-option="filterOption">
                                                    <a-select-option v-for="option in selectables['Client']" :key="option.id" :value="option.id">
                                                        {{ option.fullname }}
                                                    </a-select-option>
                                                </a-select>
                                                <a-select
                                                    v-else-if="input_type == 'select' && search_select && selectables[modelname]"
                                                    v-model:value="model_form[key]"
                                                    show-search
                                                    :placeholder="placeholder"
                                                    :options="modelname == 'Driver' ? selectables[modelname]
                                                        .filter(driver => driver.car_id === null || driver.id === model_form[key])
                                                        .map(e => ({ value: e.id, label: label_function ? label_function(e) : e[label_key] })) :
                                                        selectables[modelname].map((e) => ({ value: e.id, label: label_function ? label_function(e) : e[label_key] }))"
                                                    :filter-option="filterOption"
                                                    @change="handleChange(null_on_change)"
                                                ></a-select>
                                                <a-select v-else-if="input_type == 'sub_select'"
                                                    v-model:value="model_form[key]" :disabled="!model_form[parent.key]">
                                                    <a-select-option 
                                                        v-for="{id,name} in selectables[modelname]?.filter(e => e[parent.key] == model_form[parent.key])"
                                                        :value="id" 
                                                        selected
                                                    >
                                                        {{ name }}
                                                    </a-select-option>
                                                </a-select>
                                                <a-checkbox-group v-else-if="input_type == 'checkbox'"
                                                    v-model:value="model_form[key]"
                                                    :options="selectables[modelname]?.map(option => { return { label: option[label_key], value: option.id } })" />
                                                <a-divider v-else-if="input_type == 'divider'" orientation="left">{{ label
                                                }}</a-divider>
                                                <a-divider v-else-if="input_type == 'sub-divider'" orientation="left">
                                                    <h5>{{ label }}</h5>
                                                </a-divider>
                                                <TableCreateDrawer v-else-if="input_type == 'table'" :divider="drawer_title"
                                                    :table_columns="table_columns" :target_object="model_form"
                                                    :target_key="target_key" :drawer_title="drawer_title"
                                                    :drawer_fields="drawer_fields.inputs"
                                                    :drawer_button_text="drawer_button_text"
                                                    :drawer_button_text_edit="drawer_button_text_edit"
                                                    :target_modelname="target_modelname" />
                                                <a-date-picker v-else-if="input_type == 'date'" v-model:value="model_form[key]"
                                                    :placeholder="placeholder" style="display: flex;"
                                                    :disabled="action_type == 'create' ? false : non_editable" />
                                                <a-date-picker v-else-if="input_type == 'datetime'" v-model:value="model_form[key]"
                                                    :placeholder="placeholder"
                                                    :show-time="{ format: 'HH:mm' }"
                                                    format="DD/MM/YYYY HH:mm"
                                                    :disabled="action_type == 'create' ? false : non_editable" />
                                                <a-date-picker v-else-if="input_type == 'month'" v-model:value="model_form[key]"
                                                    :placeholder="placeholder" style="display: flex;"
                                                    picker="month"
                                                    format="MMMM"
                                                    :disabled="action_type == 'create' ? false : non_editable" />
                                                <a-switch v-else-if="input_type == 'switch'" 
                                                    v-model:checked="model_form[key]"
                                                    :placeholder="placeholder"
                                                    :disabled="action_type == 'create' ? false : non_editable" />
                                                <a-textarea v-else-if="input_type == 'textarea'" v-model:value="model_form[key]"
                                                    :placeholder="placeholder"
                                                    :disabled="action_type == 'create' ? false : non_editable" />
                                                <div v-else-if="input_type == 'photos'" class="clearfix">
                                                    <a-upload v-model:file-list="model_form[target_key]"
                                                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                        @change="upload_files($event, key)" method="GET"
                                                        @remove="remove_uploaded_file($event)"
                                                        list-type="picture-card">
                                                        <div v-if="model_form[target_key]?.length < 5"
                                                            style="height: 104px; width: 104px; padding: 2rem">
                                                            <span style="font-size: 20px"
                                                                class="material-symbols-outlined">add</span>
                                                            <div style="margin-top: 8px">Upload</div>
                                                        </div>
                                                    </a-upload>
                                                </div>
                                                <div v-else-if="input_type == 'photo'" style="display: flex; flex-direction: column; align-items: center; gap:1rem">
                                                    <div>{{ label }}</div>
                                                    <a-upload 
                                                        v-model:file-list="fileList"
                                                        :show-upload-list="false"
                                                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                        @change="upload_files($event, key)" 
                                                        method="GET"
                                                        :before-upload="setFileType"
                                                        accept="image/*"
                                                    >
                                                        <div v-if="model_form[key]" class="upload-container">
                                                            <img :src="model_form[key]" class="uploaded-image" alt="avatar" @click="showPreview(model_form[key])"/>
                                                            <div class="icon-container">
                                                                <span
                                                                    class="material-symbols-outlined preview-icon"
                                                                    @click="handleIconClick('preview', model_form[key], $event)"
                                                                >
                                                                    visibility
                                                                </span>
                                                                <a :href="model_form[key]" download @click.stop>
                                                                    <span class="material-symbols-outlined download-icon"
                                                                >
                                                                        download
                                                                    </span>
                                                                </a>
                                                                <span
                                                                    class="material-symbols-outlined delete-icon"
                                                                    @click="handleIconClick('delete', key, $event)"
                                                                >
                                                                    delete
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                                            <p class="ant-upload-drag-icon">
                                                                <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                                            </p>
                                                            คลิกเพื่ออัพโหลดรูปภาพ
                                                        </div>
                                                    </a-upload>
                                                    <a-modal v-model:visible="previewVisible" :footer="null" :mask="false" @cancel="previewVisible = false" class="custom-modal" style="background-color: none !important" >
                                                        <img :src="previewImage" alt="Preview" style="width: 100%;" />
                                                    </a-modal>
                                                </div>
                                                <!-- upload_file -->
                                                <div v-else-if="input_type == 'file'" style="display: flex; flex-direction: column; align-items: center; gap:1rem">
                                                    <div>{{ label }}</div>
                                                    <a-upload 
                                                        v-model:file-list="fileList" 
                                                        v-if="!model_form[key]"
                                                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                        method="GET"
                                                        :show-upload-list="false"
                                                        @change="upload_files($event, key)"
                                                    >
                                                        <img v-if="model_form[key]" :src="model_form[key]"
                                                            style="width: 100%; height: 100px;"
                                                            alt="avatar"
                                                        />
                                                        <div style="display: flex; flex-direction: column; align-items: center;">
                                                            <p class="ant-upload-drag-icon">
                                                                <span class="material-symbols-outlined" 
                                                                    style="font-size: 5rem;">upload_file</span>
                                                            </p>
                                                            อัพโหลดไฟล์
                                                        </div>
                                                    </a-upload>
                                                    <a :href="model_form[key]" v-else="model_form[key]"
                                                        target="_blank" rel="doc"
                                                    >
                                                        {{ model_form[`${key}_file_name`] }}
                                                    </a>
                                                    <a-button danger type="text" @click="(model_form[key] = '')" style="padding: 0; height: 18px;"
                                                        v-if="model_form[key]">
                                                        <span class="material-symbols-outlined">close</span>
                                                    </a-button>
                                                </div>
                                                <div  v-else-if="input_type == 'table_files'" >
                                                    <a-table
                                                        :columns="[
                                                            {
                                                                title: 'ชื่อเอกสาร',
                                                                dataIndex: 'name',
                                                                key: 'name',
                                                                width: 400,
                                                            },
                                                            {
                                                                title: 'ไฟล์เอกสาร',
                                                                dataIndex: 'file',
                                                                key: 'file',
                                                            },
                                                            {
                                                                title: 'ลบ',
                                                                dataIndex: 'id',
                                                                key: 'id',
                                                                width: 100,
                                                                align: 'center',
                                                            }
                                                        ]"
                                                        :dataSource="data_raw_files"
                                                        :pagination="false"
                                                        :scroll="{ y: 240 }"
                                                    >
                                                        <template #bodyCell="{ column, record, index}" >
                                                            <template v-if="column.key === 'name'">
                                                                <a-form-item>
                                                                    <a-input v-model:value="record.name"
                                                                    />
                                                                </a-form-item>
                                                            </template>
                                                            <template v-if="column.key === 'file'">
                                                                <a-form-item>
                                                                <a :href="record.file" target="_blank" rel="doc">{{ record.file_name }}</a>
                                                                </a-form-item>
                                                            </template>
                                                            <template v-if="column.key === 'id'">
                                                                <a-form-item>
                                                                    <a-button danger type="text" @click="(data_raw_files.splice(index, 1))">
                                                                        <span class="material-symbols-outlined">delete</span>
                                                                    </a-button>
                                                                </a-form-item>
                                                            </template>
                                                        </template>
                                                    </a-table>
                                                    <a-upload v-model:file-list="model_form[key]"
                                                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                        method="GET"
                                                        @change="upload_file_multiple($event, key)"
                                                        :show-upload-list="false"
                                                        >
                                                        <a-button style="margin-top: 10px;">
                                                            <span class="material-symbols-outlined">add</span>
                                                            เพิ่มไฟล์
                                                        </a-button>
                                                    </a-upload>
                                                </div>
                                                <div v-else-if="input_type== 'password'">
                                                    <a-input-password v-if="action_type== 'create'" v-model:value="model_form[key]" 
                                                        :placeholder="placeholder" 
                                                        :type="input_type"
                                                        :disabled="action_type == 'create' ? false : non_editable"
                                                        autoComplete="new-password"
                                                    />
                                                    <a-input-password v-else v-model:value="model_form[key]" 
                                                        :placeholder="placeholder" 
                                                        :type="input_type"
                                                        :disabled="action_type == 'create' ? false : non_editable"
                                                        autoComplete="new-password"
                                                    />
                                                </div>
                                                <a-input v-else v-model:value="model_form[key]" :placeholder="placeholder"
                                                    :type="input_type"
                                                    :disabled="action_type == 'create' ? false : non_editable"
                                                />
                                            </a-form-item>
                                        </a-col>
                                    </a-row>

                                    <a-row justify="end">
                                        <a-form-item>
                                            <a-button type="primary" size="large" html-type="submit">
                                                <template #icon>
                                                    <span style="font-size: 20px; color: white" class="material-symbols-outlined">save</span>
                                                </template>
                                                บันทึกข้อมูล
                                            </a-button>
                                        </a-form-item>
                                    </a-row>
                                </a-card>
                            </a-col>
                        </a-row>
             </a-form>
        </a-card>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import SystemService from '../../api/SystemService'
import TableCreateDrawer from '@/components/table_create_drawer.vue'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra);
import CarField from '../../field_config/car_fields'
import DriverService from '../../api/DriverService.js'
import WithholdingService from '../../api/WithholdingService.js'
import BankService from '../../api/BankService.js'
import DriverInfo from '../../api/DriverInfo'
import CarService from '../../api/CarService'

export default {
    components: { TableCreateDrawer },
    props: {
        create: Boolean,
        model: String,
        model_data: Object,
    },
    name: 'update_or_create',
    computed: {
        isStartSelected() {
            return this.start;
        },
        isEndSelected() {
            return this.end;
        },
        model_type() {
            return this.$route.params.model
        },
        action_type() {
            return this.$route.params.id
        },
        title() {
            return this.default_fields[this.$route.params.model].title[this.$route.params.id] ?
                this.default_fields[this.$route.params.model].title[this.$route.params.id] :
                `${this.default_fields[this.$route.params.model].title['edit']}`
        },
        form_layout() {
            const model_horizontal_form = ['Car']
            if(model_horizontal_form.includes(this.model_type)) {
                return 'horizontal'
            }else{
                return 'vertical'
            }
        },
        calculatedAge() {
            const birthDate = this.model_form.date_of_birth;
            if (!birthDate) return '';
            const today = dayjs();
            const birth = dayjs(birthDate);
            let age = today.year() - birth.year();
            const m = today.month() - birth.month();
            if (m < 0 || (m === 0 && today.date() < birth.date())) {
                age--;
            }
            return age;
        },
        filteredDrivingLicenseTypes() {
            return this.selectables['DrivingLicenseType'].filter(option => [1, 2, 3].includes(option.id));
        }
    },
    watch: {
            'model_form.register_date': function(newDate) {
            if (newDate) {
                this.updateWorkAge();
            } else {
                this.model_form.driver_age_of_work = '';
            }
        },
        start(newVal) {
            if (newVal) {
                this.end = false;
            }
        },
        end(newVal) {
            if (newVal) {
                this.start = false;
            }
        }
    },
    data() {
        
        return {
            isLoading: false,
            start: false,
            end: false,
            register_date: null,
            driver_age_of_work: null,
            model_form: {
                driver_stoped_employment_date: null
            },
            today: new Date(),
            company_id: JSON.parse(localStorage.getItem('companyData')).id,
            previewVisible: false,
            previewImage: '',
            fileList: [],
            data_raw_files: [],
            default_rules: [],
            myArray: [],
            default_fields: {
                Sale: {
                    title: {
                        create: 'เพิ่มข้อมูลพนักงานขาย',
                        edit: 'แก้ไขข้อมูลพนักงานขาย'
                    },
                    photos: [
                        {
                            key: 'avatar',
                            label: 'ภาพประจำตัว',
                            height: 275,
                            width: 275
                        }, {
                            key: 'signature',
                            label: 'ลายเซ็นพนักงานขาย',
                            height: 60,
                            width: 300,
                            description: 'ขนาด 400 x 60 px'
                        }
                    ],
                    inputs: {
                        username: {
                            input_type: 'text',
                            placeholder: 'ชื่อผู้ใช้',
                            check_duplicate: true,
                            check_rule: [{
                                required: false,
                                validator: this.validate_duplicate,
                                trigger: 'change',
                            }],
                            required: true,
                            non_editable: true,
                            col_span: 11
                        },
                        password: {
                            input_type: 'password',
                            placeholder: 'รหัสผ่าน',
                            required: true,
                            non_editable: true,
                            col_span: 11
                        },
                        email: {
                            input_type: 'email',
                            placeholder: 'อีเมล',
                            required: false,
                            col_span: 11
                        },
                        firstname: {
                            input_type: 'text',
                            placeholder: 'ชื่อพนักงาน',
                            required: true,
                            col_span: 11
                        },
                        lastname: {
                            input_type: 'text',
                            placeholder: 'นามสกุลพนักงานขาย',
                            required: true,
                            col_span: 11
                        },
                        nickname: {
                            input_type: 'text',
                            placeholder: 'ชื่อเล่น',
                            required: true,
                            col_span: 11
                        },
                        phone_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทร',
                            required: true,
                            col_span: 11
                        },
                        phone_backup: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทรสำรอง',
                            required: false,
                            col_span: 11
                        },
                        commission: {
                            input_type: 'number',
                            placeholder: 'คอมมิชชั่น',
                            required: true,
                            col_span: 11
                        },
                    }
                },
                CarRepairType: {
                    title: {
                        create: 'เพิ่มประเภทการซ่อม',
                        edit: 'แก้ไขประเภทการซ่อม'
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อประเภท',
                            required: true,
                            col_span: 24
                        }
                    }
                },
                Client: {
                    title: {
                        create: 'เพิ่มข้อมูลลูกค้า',
                        edit: 'แก้ไขข้อมูลลูกค้า'
                    },
                    photos: [
                        {
                            key: 'logo',
                            label: 'ภาพประจำตัว',
                            height: 275,
                            width: 275
                        }
                    ],
                    inputs: {
                        fullname: {
                            input_type: 'text',
                            placeholder: 'ชื่อบริษัท',
                            required: true,
                            col_span: 11
                        },
                        car_company_id: {
                            input_type: 'select',
                            modelname: 'CarCompany',
                            label_key: 'fullname',
                            placeholder: 'บริษัทรถ',
                            required: false,
                            col_span: 11
                        },
                        create_branch: {
                            col_span: 23,
                            input_type: 'table',
                            target_key: 'branches',
                            target_modelname: 'ClientBranch',
                            drawer_title: 'สาขาของบริษัท',
                            drawer_button_text: 'เพิ่มสาขา',
                            drawer_button_text_edit: 'แก้ไขสาขา',
                            table_columns: [
                                {
                                    title: 'ชื่อสาขา',
                                    dataIndex: 'name',
                                    key: 'name',
                                },
                                {
                                    title: 'ที่อยู่',
                                    dataIndex: 'address',
                                    key: 'address',
                                },
                                {
                                    title: 'เบอร์โทร',
                                    dataIndex: 'phone_no',
                                    key: 'phone_no',
                                },
                                {
                                    title: 'ตัวเลือก',
                                    dataIndex: 'id',
                                    key: 'id',
                                    width: 100,
                                    align: 'center',
                                },
                            ],
                            drawer_fields: {
                                title: 'เพิ่มสาขาของบริษัท',
                                show: false,
                                type: 'drawer',
                                instance: {},
                                inputs: {
                                    name: {
                                        input_type: 'text',
                                        placeholder: 'ชื่อสาขา',
                                        required: true,
                                    },
                                    address: {
                                        input_type: 'text',
                                        placeholder: 'ที่อยู่',
                                        required: false,
                                    },
                                    phone_no: {
                                        input_type: 'text',
                                        placeholder: 'เบอร์โทร',
                                        required: false,
                                    }
                                }
                            }
                        },
                        email: {
                            input_type: 'email',
                            placeholder: 'อีเมล',
                            required: false,
                            col_span: 11
                        },
                        tour_type_id: {
                            input_type: 'select',
                            modelname: 'TourType',
                            label_key: 'name',
                            placeholder: 'ประเภทกลุ่มลูกค้า',
                            on_change: this.client_no_on_change,
                            required: true,
                            col_span: 11
                        },
                        client_no: {
                            input_type: 'text',
                            placeholder: 'รหัสลูกค้า',
                            required: false,
                            col_span: 11
                        },
                        tax_id: {
                            input_type: 'text',
                            placeholder: 'เลขประจำตัวผู้เสียภาษี',
                            required: false,
                            col_span: 11
                        },
                        email: {
                            input_type: 'email',
                            placeholder: 'อีเมลองค์กร',
                            required: false,
                            col_span: 11
                        },
                        phone_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทร',
                            required: true,
                            col_span: 11
                        },
                        phone_backup: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทรสำรอง',
                            required: false,
                            col_span: 11
                        },
                        fax_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์แฟกซ์',
                            required: false,
                            col_span: 11
                        },
                        address: {
                            input_type: 'textarea',
                            placeholder: 'ที่อยู่',
                            required: false,
                            col_span: 23
                        },
                        remark: {
                            input_type: 'textarea',
                            placeholder: 'หมายเหตุ',
                            required: false,
                            col_span: 23
                        }
                    }
                },
                CarCompany: {
                    title: {
                        create: 'เพิ่มข้อมูลบริษัทรถ',
                        edit: 'แก้ไขข้อมูลบริษัทรถ'
                    },
                    photos: [
                        {
                            key: 'logo',
                            label: 'Logo บริษัท',
                            height: 275,
                            width: 275
                        }
                    ],
                    photos_social: [
                        {
                            key: 'logoSc',
                            label: 'ช่องทางติดต่อ social',
                            height: 275,
                            width: 275
                        }
                    ],
                    photos_iso: [
                        {
                            key: 'logoIso',
                            label: 'มาตรฐาน ISO',
                            height: 275,
                            width: 275
                        }
                    ],
                    photos_payment: [
                        {
                            key: 'image_payment',
                            label: 'ช่องทางการจ่ายเงิน',
                            height: 275,
                            width: 275
                        }
                    ],
                    inputs: {
                        withholding_id: {
                            input_type: 'select',
                            placeholder: 'ประเภทบริษัท',
                            modelname: 'Withholding',
                            label_key: 'name',
                            required: false,
                            col_span: 19
                        },
                        is_3rd_party: {
                            input_type: 'switch',
                            placeholder: 'บริษัทภายนอก',
                            required: false,
                            col_span: 3
                        },
                        fullname: {
                            input_type: 'text',
                            placeholder: 'ชื่อบริษัทรถ',
                            required: true,
                            col_span: 23
                        },
                        // car_company_type_id: {
                        //     input_type: 'select',
                        //     modelname: 'CarCompanyType',
                        //     label_key: 'name',
                        //     placeholder: 'ประเภทบริษัท',
                        //     required: false,
                        //     col_span: 11
                        // },
                        email: {
                            input_type: 'email',
                            placeholder: 'อีเมล',
                            required: false,
                            col_span: 11
                        },
                        tax_id: {
                            input_type: 'text',
                            placeholder: 'เลขประจำตัวผู้เสียภาษี',
                            required: false,
                            col_span: 11
                        },
                        phone_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทร',
                            required: true,
                            col_span: 11
                        },
                        fax_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์แฟกซ์',
                            required: false,
                            col_span: 11
                        },
                        facebook_url: {
                            input_type: 'text',
                            placeholder: 'เฟสบุ๊ค',
                            required: false,
                            col_span: 11
                        },
                        line_id: {
                            input_type: 'text',
                            placeholder: 'ไลน์',
                            required: false,
                            col_span: 11
                        },
                        website: {
                            input_type: 'text',
                            placeholder: 'เว็บไซต์',
                            required: false,
                            col_span: 11
                        },
                        address: {
                            input_type: 'textarea',
                            placeholder: 'ที่อยู่',
                            required: false,
                            col_span: 23
                        },
                        billing_address: {
                            input_type: 'textarea',
                            placeholder: 'ที่อยู่สำหรับออกเอกสาร',
                            required: false,
                            col_span: 23
                        },
                        divider_1: {
                            input_type: 'divider',
                            label: 'ภาษาอื่น ๆ',
                            col_span: 23
                        },
                        fullname_en: {
                            input_type: 'text',
                            placeholder: 'ชื่อบริษัท (ภาษาอื่นๆ)',
                            required: false,
                            col_span: 23
                        },
                        address_en: {
                            input_type: 'textarea',
                            placeholder: 'ที่อยู่ (ภาษาอื่นๆ)',
                            required: false,
                            col_span: 23
                        },
                        billing_address_en: {
                            input_type: 'textarea',
                            placeholder: 'ที่อยู่สำหรับออกเอกสาร (ภาษาอื่นๆ)',
                            required: false,
                            col_span: 23
                        },
                        divider_2: {
                            input_type: 'divider',
                            label: 'รายละเอียดบัญชีธนาคาร',
                            col_span: 23
                        },
                        bank_id: {
                            input_type: 'select',
                            modelname: 'Bank',
                            label_key: 'name',
                            placeholder: 'ชื่อธนาคาร',
                            required: true,
                            col_span: 11
                        },
                        bank_branch: {
                            input_type: 'text',
                            placeholder: 'สาขา',
                            required: true,
                            col_span: 11
                        },
                        bank_account_name: {
                            input_type: 'text',
                            placeholder: 'ชื่อบัญชี',
                            required: true,
                            col_span: 11
                        },
                        bank_account_no: {
                            input_type: 'text',
                            placeholder: 'เลขที่บัญชี',
                            required: true,
                            col_span: 11
                        },
                    }
                },
                CarType: {
                    title: {
                        create: 'เพิ่มชนิดรถ',
                        edit: 'แก้ไขชนิดรถ'
                    },
                    photos: [
                        {
                            key: 'avatar',
                            label: 'รูปชนิดรถ',
                            height: 275,
                            width: 275
                        }
                    ],
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อชนิดรถ',
                            required: true,
                            col_span: 23
                        }
                    }
                },
                DriverCost: {
                    title: {
                        create: 'เพิ่มเบี้ยเลี้ยง',
                        edit: 'แก้ไขเบี้ยเลี้ยง'
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อเบี้ยเลี้ยง',
                            required: true,
                            col_span: 24
                        },
                        booking_type_id: {
                            input_type: 'select',
                            modelname: 'BookingType',
                            label_key: 'name',
                            placeholder: 'ชื่อประเภทการจอง',
                            required: true,
                            col_span: 24
                        },
                        cost_per_day: {
                            input_type: 'number',
                            placeholder: 'ราคาต่อวัน',
                            required: true,
                            col_span: 24
                        }
                    }
                },
                CarGroup: {
                    title: {
                        create: 'เพิ่มประเภทรถ',
                        edit: 'แก้ไขประเภทรถ'
                    },
                    photos: [
                        {
                            key: 'avatar',
                            label: 'รูปประเภทรถ',
                            height: 275,
                            width: 275
                        }
                    ],
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อประเภทรถ',
                            required: true,
                            col_span: 11
                        },
                        car_type_id: {
                            input_type: 'select',
                            modelname: 'CarType',
                            label_key: 'name',
                            placeholder: 'ชื่อชนิดรถ',
                            required: true,
                            col_span: 11
                        },
                        seats: {
                            input_type: 'number',
                            placeholder: 'จำนวนที่นั่ง',
                            required: true,
                            col_span: 11
                        },
                        price: {
                            input_type: 'number',
                            placeholder: 'ราคาต่อวัน',
                            required: true,
                            col_span: 11
                        },
                    }
                },
                Driver: {
                    title: {
                        create: 'เพิ่มพนักงานขับรถ',
                        edit: 'แก้ไขพนักงานขับรถ'
                    },
                    photos: [
                        {
                            key: 'avatar',
                            label: 'รูปพนักงานขับรถ',
                            height: 275,
                            width: 275
                        },
                        {
                            key: 'driving_license_photo',
                            label: 'รูปใบขับขี่',
                            height: 275,
                            width: 275
                        },
                        {
                            key: 'id_no_photo',
                            label: 'รูปบัตรประชาชน',
                            height: 275,
                            width: 275
                        }
                    ],
                    inputs: {
                        username: {
                            input_type: 'text',
                            placeholder: 'ชื่อผู้ใช้',
                            check_duplicate: true,
                            check_rule: [{
                                required: true,
                                validator: this.validate_duplicate,
                                trigger: 'change',
                            }],
                            required: true,
                            non_editable: true,
                            col_span: 11
                        },
                        password: {
                            input_type: 'password',
                            placeholder: this.action_type == 'create' ? 'รหัสผ่าน' : 'รหัสผ่านใหม่',
                            // required: true,
                            // non_editable: true,
                            col_span: 11,
                        },
                        // email: {
                        //     input_type: 'text',
                        //     placeholder: 'อีเมล',
                        //     required: true,
                        //     col_span: 11
                        // },
                        driver_no: {
                            input_type: 'text',
                            check_duplicate: true,
                            check_rule: [{
                                required: true,
                                validator: this.validate_duplicate,
                                trigger: 'change',
                            }],
                            placeholder: 'Driver No.',
                            required: true,
                            col_span: 11
                        },
                        id_no: {
                            input_type: 'text',
                            placeholder: 'เลขบัตรประชาชน',
                            required: true,
                            col_span: 11
                        },
                        firstname: {
                            input_type: 'text',
                            placeholder: 'ชื่อ',
                            required: true,
                            col_span: 11
                        },
                        lastname: {
                            input_type: 'text',
                            placeholder: 'นามสกุล',
                            required: true,
                            col_span: 11
                        },
                        bank_accno: {
                            input_type: 'text',
                            placeholder: 'เลขบัญชีธนาคาร',
                            required: false,
                            col_span: 11
                        },
                        salary: {
                            input_type: 'number',
                            placeholder: 'เงินเดือน',
                            required: false,
                            col_span: 11
                        },
                        phone_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทรศัพท์',
                            required: true,
                            col_span: 11
                        },
                        work_status_id: {
                            input_type: 'select',
                            modelname: 'DriverStatus',
                            label_key: 'name',
                            placeholder: 'สถานะพนักงาน',
                            required: false,
                            col_span: 11
                        },
                        register_date: {
                            input_type: 'date',
                            placeholder: 'วันเริ่มงาน',
                            required: false,
                            col_span: 11
                        },
                        date_of_birth: {
                            input_type: 'date',
                            placeholder: 'วันเดือนปีเกิด',
                            required: false,
                            col_span: 11
                        },
                        age: {
                            input_type: 'number',
                            placeholder: 'อายุ',
                            required: false,
                            col_span: 11
                        },
                        sex: {
                            input_type: 'select',
                            modelname: 'SexType',
                            label_key: 'name',
                            placeholder: 'เพศ',
                            required: false,
                            col_span: 11
                        },
                        divider: {
                            input_type: 'divider',
                            label: 'ใบขับขี่',
                            col_span: 24
                        },
                        driver_license_no: {
                            input_type: 'text',
                            placeholder: 'เลขที่ใบขับขี่',
                            required: false,
                            col_span: 11
                        },
                        driving_license_exp: {
                            input_type: 'date',
                            placeholder: 'วันหมดอายุใบขับขี่',
                            required: false,
                            col_span: 11
                        },
                        driving_license_type_id: {
                            input_type: 'select',
                            modelname: 'DrivingLicenseType',
                            label_key: 'name',
                            placeholder: 'ประเภทใบขับขี่',
                            required: false,
                            col_span: 11

                        },
                        remark: {
                            input_type: 'textarea',
                            placeholder: 'หมายเหตุ',
                            required: false,
                            col_span: 23
                        },
                    }
                },
                Guide: {
                    title: {
                        create: 'เพิ่มไกด์/ผู้ประสานงาน',
                        edit: 'แก้ไขไกด์/ผู้ประสานงาน'
                    },
                    photos: [
                        {
                            key: 'avatar',
                            label: 'รูปไกด์/ผู้ประสานงาน',
                            height: 275,
                            width: 275
                        }
                    ],
                    inputs: {
                        firstname: {
                            input_type: 'text',
                            placeholder: 'ชื่อ',
                            required: true,
                            col_span: 11
                        },
                        lastname: {
                            input_type: 'text',
                            placeholder: 'นามสกุล',
                            required: true,
                            col_span: 11
                        },
                        nickname: {
                            input_type: 'text',
                            placeholder: 'ชื่อเล่น',
                            required: true,
                            col_span: 11
                        },
                        email: {
                            input_type: 'text',
                            placeholder: 'อีเมล',
                            required: true,
                            col_span: 11
                        },
                        phone_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทรศัพท์',
                            required: true,
                            col_span: 11
                        },
                        phone_backup: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทรศัพท์สำรอง',
                            required: false,
                            col_span: 11
                        },
                        license: {
                            input_type: 'text',
                            placeholder: 'เลขที่ใบอนุญาต',
                            required: false,
                            col_span: 11
                        },
                        license_expire: {
                            input_type: 'date',
                            placeholder: 'วันหมดอายุใบอนุญาต',
                            required: false,
                            col_span: 11
                        },
                        remark: {
                            input_type: 'textarea',
                            placeholder: 'หมายเหตุ',
                            required: false,
                            col_span: 23
                        },
                    }
                },
                ClientContact: {
                    title: {
                        create: 'เพิ่มผู้ประสานงาน',
                        edit: 'แก้ไขผู้ประสานงาน'
                    },
                    photos: [
                        {
                            key: 'avatar',
                            label: 'รูปผู้ประสานงาน',
                            height: 275,
                            width: 275
                        }
                    ],
                    inputs: {
                        client_id: {
                            input_type: 'select',
                            search_select: true,
                            placeholder: 'เลือกบริษัท',
                            modelname: 'Client',
                            label_key: 'fullname',
                            null_on_change: 'client_branch_id',
                            required: true,
                            col_span: 11,
                        },
                        client_branch_id: {
                            input_type: 'sub_select',
                            placeholder: 'เลือกสาขา',
                            modelname: 'ClientBranch',
                            label_key: 'name',
                            parent: {
                                list: 'Client',
                                sub_list_name: 'branches',
                                key: 'client_id'
                            },
                            required: false,
                            col_span: 11,
                        },
                        firstname: {
                            input_type: 'text',
                            placeholder: 'ชื่อ',
                            required: false,
                            col_span: 11
                        },
                        lastname: {
                            input_type: 'text',
                            placeholder: 'นามสกุล',
                            required: false,
                            col_span: 11
                        },
                        email: {
                            input_type: 'text',
                            placeholder: 'อีเมล',
                            required: true,
                            col_span: 11
                        },
                        phone_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทรศัพท์',
                            required: true,
                            col_span: 11
                        },
                    }
                },
                Car: CarField,
                Parking: {
                    title: {
                        create: 'เพิ่มที่จอดยานพาหนะ',
                        edit: 'แก้ไขที่จอดยานพาหนะ',
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อที่จอดยานพาหนะ',
                            required: true,
                            col_span: 23
                        },
                        max_lots: {
                            input_type: 'number',
                            placeholder: 'จำนวนที่จอด',
                            required: true,
                            col_span: 23
                        },
                        lat: {
                            input_type: 'number',
                            placeholder: 'ละติจูด',
                            required: true,
                            col_span: 11
                        },
                        lng: {
                            input_type: 'number',
                            placeholder: 'ลองติจูด',
                            required: true,
                            col_span: 11
                        },
                    }
                },
                Expense: {
                    title: {
                        create: 'เพิ่มประเภทค่าใช้จ่าย',
                        edit: 'แก้ไขประเภทค่าใช้จ่าย',
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อประเภทค่าใช้จ่าย',
                            required: true,
                            col_span: 23
                        }
                    }
                },
                Extra: {
                    title: {
                        create: 'เพิ่มค่าใช้จ่ายส่วนเกิน',
                        edit: 'แก้ไขค่าใช้จ่ายส่วนเกิน',
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อค่าใช้จ่าย',
                            required: true,
                            col_span: 23
                        },
                        price: {
                            input_type: 'number',
                            placeholder: 'ราคา',
                            required: true,
                            col_span: 23
                        },
                    }
                },
                Trip: {
                    title: {
                        create: 'เพิ่มจุดประสงค์การเดินทาง',
                        edit: 'แก้ไขจุดประสงค์การเดินทาง',
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อจุดประสงค์การเดินทาง',
                            required: true,
                            col_span: 23
                        }
                    }
                },
                Tour: {
                    title: {
                        create: 'เพิ่มทัวร์',
                        edit: 'แก้ไขทัวร์',
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อทัวร์',
                            required: false,
                            col_span: 11
                        },
                        tour_type_id: {
                            input_type: 'select',
                            modelname: 'TourType',
                            label_key: 'name',
                            placeholder: 'ประเภทกลุ่มลูกค้า',
                            required: true,
                            col_span: 11
                        },
                        client_id: {
                            input_type: 'select',
                            modelname: 'Client',
                            label_key: 'fullname',
                            placeholder: 'ลูกค้า/บริษัท',
                            required: true,
                            col_span: 11
                        },
                        description: {
                            input_type: 'text',
                            placeholder: 'สถานที่เที่ยว',
                            required: false,
                            col_span: 11
                        }
                    }
                },
                TourType: {
                    title: {
                        create: 'เพิ่มประเภทกลุ่มลูกค้า',
                        edit: 'แก้ไขประเภทกลุ่มลูกค้า',
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อประเภทกลุ่มลูกค้า',
                            required: true,
                            col_span: 11
                        },
                        color_id: {
                            input_type: 'select',
                            modelname: 'Color',
                            label_key: 'name',
                            placeholder: 'สีประเภทกลุ่มลูกค้า',
                            required: false,
                            col_span: 11
                        }
                    }
                },
                PartType: {
                    title: {
                        create: 'เพิ่มประเภทอะไหล่',
                        edit: 'แก้ไขประเภทอะไหล่'
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อประเภทอะไหล่',
                            required: true,
                            col_span: 23
                        },
                    }
                },
                PartUnit: {
                    title: {
                        create: 'เพิ่มหน่วยอะไหล่',
                        edit: 'แก้ไขหน่วยอะไหล่'
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อหน่วยอะไหล่',
                            required: true,
                            col_span: 23
                        },
                    }
                },
                Vendor: {
                    title: {
                        create: 'เพิ่มผู้ขาย',
                        edit: 'แก้ไขผู้ขาย'
                    },
                    photos: [
                        {
                            key: 'avatar',
                            label: '',
                            height: 275,
                            width: 275
                        },
                    ],
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อผู้ขาย',
                            required: true,
                            col_span: 11
                        },
                        contact_name: {
                            input_type: 'text',
                            placeholder: 'ชื่อผู้ติดต่อ',
                            required: true,
                            col_span: 11
                        },
                        phone_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทร',
                            required: true,
                            col_span: 11
                        },
                        fax_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์แฟกซ์',
                            required: true,
                            col_span: 11
                        },
                        address: {
                            input_type: 'textarea',
                            placeholder: 'ที่อยู่',
                            required: true,
                            col_span: 23
                        },
                    }
                },
                Technician: {
                    title: {
                        create: 'เพิ่มช่าง (Technician)',
                        edit: 'แก้ไขช่าง'
                    },
                    photos: [
                        {
                            key: 'avatar',
                            label: '',
                            height: 275,
                            width: 275
                        },
                    ],
                    inputs: {
                        car_company_id: {
                            input_type: 'select',
                            modelname: 'CarCompany',
                            label_key: 'fullname',
                            placeholder: 'บริษัท',
                            required: true,
                            col_span: 23
                        },
                        firstname: {
                            input_type: 'text',
                            placeholder: 'ชื่อช่าง',
                            required: true,
                            col_span: 11
                        },
                        lastname: {
                            input_type: 'text',
                            placeholder: 'นามสกุล',
                            required: true,
                            col_span: 11
                        },
                        nickname: {
                            input_type: 'text',
                            placeholder: 'ชื่อเล่น',
                            required: true,
                            col_span: 11
                        },
                        phone_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทร',
                            required: true,
                            col_span: 11
                        },
                        phone_backup: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทรสำรอง',
                            col_span: 11
                        },
                        email: {
                            input_type: 'text',
                            placeholder: 'อีเมล',
                            col_span: 11
                        },
                    }
                },
                Part: {
                    title: {
                        create: 'เพิ่มอะไหล่',
                        edit: 'แก้ไขอะไหล่'
                    },
                    photos: [
                        {
                            key: 'photo',
                            label: '',
                            height: 275,
                            width: 275
                        },
                    ],
                    inputs: {
                        barcode: {
                            input_type: 'text',
                            placeholder: 'รหัส',
                            required: true,
                            col_span: 11
                        },
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่ออะไหล่',
                            required: true,
                            col_span: 11
                        },
                        amount: {
                            input_type: 'text',
                            placeholder: 'จำนวน',
                            required: true,
                            col_span: 5
                        },
                        part_unit_id: {
                            input_type: 'select',
                            modelname: 'PartUnit',
                            label_key: 'name',
                            placeholder: 'เลือกหน่วย',
                            required: true,
                            col_span: 5
                        },
                        price: {
                            input_type: 'text',
                            placeholder: 'ราคา',
                            required: true,
                            col_span: 5
                        },
                        part_type_id: {
                            input_type: 'select',
                            modelname: 'PartType',
                            label_key: 'name',
                            placeholder: 'เลือกประเภทอะไหล่',
                            required: true,
                            col_span: 5
                        },
                        vendor_id: {
                            input_type: 'select',
                            modelname: 'Vendor',
                            label_key: 'name',
                            placeholder: 'เลือกผู้ขาย',
                            required: true,
                            col_span: 11
                        },
                        remark: {
                            input_type: 'text',
                            placeholder: 'อื่นๆ',
                            col_span: 11
                        },
                    }
                },
                StockHistory: {
                    title: {
                        create: 'เพิ่มรายการ',
                        edit: 'แก้ไขรายการ'
                    },
                    inputs: {
                        withdraw_type_id: {
                            input_type: 'select',
                            modelname: 'WithdrawType',
                            label_key: 'name',
                            placeholder: 'ประเภทการเบิก',
                            required: true,
                            col_span: 6
                        },
                        withdrawed_at: {
                            input_type: 'date',
                            placeholder: 'ระบุวันที่เบิก',
                            required: true,
                        },
                        remark: {
                            input_type: 'textarea',
                            placeholder: 'หมายเหตุ',
                            col_span: 23
                        },
                        create_part_withdraw: {
                            col_span: 23,
                            input_type: 'table',
                            drawer_title: 'รายการอะไหล่',
                            drawer_button_text: 'เพิ่มอะไหล่',
                            target_key: 'part_histories',
                            target_object: [],
                            table_columns: [
                                {
                                    title: 'ลำดับ',
                                    dataIndex: 'index',
                                    key: 'index',
                                },
                                {
                                    title: 'ชื่ออะไหล่',
                                    dataIndex: 'part_id',
                                    key: 'part_id',
                                },
                                {
                                    title: 'จำนวน',
                                    dataIndex: 'amount',
                                    key: 'amount',
                                },
                                {
                                    title: 'หมายเหตุ',
                                    dataIndex: 'remark',
                                    key: 'remark',
                                },
                                {
                                    title: 'ตัวเลือก',
                                    dataIndex: 'id',
                                    key: 'id',
                                    width: 100,
                                    align: 'center',
                                },
                            ],
                            drawer_fields: {
                                title: 'เพิ่มอะไหล่',
                                show: false,
                                type: 'drawer',
                                inputs: {
                                    part_id: {
                                        input_type: 'select',
                                        modelname: 'Part',
                                        label_key: 'name',
                                        placeholder: 'ชื่ออะไหล่',
                                        required: true,
                                    },
                                    amount: {
                                        input_type: 'number',
                                        placeholder: 'จำนวน',
                                        required: true,
                                    },
                                    remark: {
                                        input_type: 'textarea',
                                        placeholder: 'หมายเหตุ',
                                        required: false,
                                    }
                                }
                            }
                        },
                    },
                },
            },
            selectables: {
                Client: [],
                ClientBranch: [],
                DriverStatus: [],
                SexType: [],
                DrivingLicenseType: [],
                Withholding: [],
                Bank: [],
                CarType: [],
                CarGroup: [],
            },
            default_fields_driver: {        
                Driver: {
                    inputs: {
                        driver_no: {
                            input_type: 'text',
                            placeholder: 'รหัสประจำตัวพนักงาน',
                            check_duplicate: true,
                            required: false,
                            // non_editable: true,
                            col_span: 11
                        },
                        driver_job_position: {
                            input_type: 'select_driver_work_ranks',
                            placeholder: 'ตำแหน่งหน้าที่',
                            required: false,
                            col_span: 11
                        },
                        driver_prefix_name: {
                            input_type: 'select_driver_prefix_name',
                            placeholder: 'คำนำหน้านาม',
                            required: false,
                            col_span: 6
                        },
                        firstname: {
                            input_type: 'text',
                            placeholder: 'ชื่อ',
                            required: false,
                            col_span: 8
                        },
                        lastname: {
                            input_type: 'text',
                            placeholder: 'นามสกุล',
                            required: false,
                            col_span: 8
                        },
                        phone_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทรศัพท์',
                            required: false,
                            col_span: 6
                        },
                        register_date: {
                            input_type: 'register_date',
                            placeholder: 'วันที่เริ่มงาน',
                            required: false,
                            col_span: 8
                        },
                        driver_age_of_work: {
                            input_type: 'driver_age_of_work',
                            placeholder: 'อายุงาน',
                            required: false,
                            col_span: 8
                        },
                        driver_work_status: {
                            input_type: 'checkbox',
                            label_key: 'name',
                            placeholder: 'สถานะทำงาน',
                            required: false,
                            col_span: 8,
                        },
                        driver_stoped_employment_date: {
                            input_type: 'date_end',
                            placeholder: ' ',
                            required: false,
                            col_span: 7
                        },
                        driver_current_car_no: {
                            input_type: 'text',
                            placeholder: 'ปัจจุบันประจำเบอร์รถ',
                            required: false,
                            col_span: 7
                        },
                        date_of_birth: {
                            input_type: 'date',
                            placeholder: 'วันเดือนปีเกิด',
                            required: false,
                            col_span: 11,
                        },
                        age: {
                            input_type: 'number',
                            placeholder: 'อายุ',
                            required: false,
                            col_span: 11
                        },
                        driver_religions: {
                            input_type: 'select_driver_religions',
                            placeholder: 'ศาสนา',
                            required: false,
                            col_span: 11
                        },
                        driver_educations: {
                            input_type: 'select_driver_educations',
                            placeholder: 'ระดับการศึกษา',
                            showOtherEducationModal: false, // ตัวแปรสำหรับควบคุมการแสดงผลของ Modal
                            otherEducationInput: '', // ตัวแปรสำหรับเก็บค่าจาก Modal
                            currentFieldKey: '',
                            required: false,
                            col_span: 11
                        },
                        id_no: {
                            input_type: 'text',
                            placeholder: 'หมายเลขบัตรประจำตัวประชาชน',
                            required: false,
                            col_span: 23
                        },
                        driver_address_no: {
                            input_type: 'text',
                            placeholder: 'เลขที่',
                            required: false,
                            col_span: 11
                        },
                        driver_address_village: {
                            input_type: 'text',
                            placeholder: 'อาคาร/หมู่บ้าน',
                            required: false,
                            col_span: 11
                        },
                        driver_address_sub_district: {
                            input_type: 'text',
                            placeholder: 'หมู่ที่',
                            required: false,
                            col_span: 6
                        },
                        driver_address_alley: {
                            input_type: 'text',
                            placeholder: 'ตรอก/ซอย',
                            required: false,
                            col_span: 8
                        },
                        driver_address_road: {
                            input_type: 'text',
                            placeholder: 'ถนน',
                            required: false,
                            col_span: 8
                        },
                        driver_address_district: {
                            input_type: 'text',
                            placeholder: 'แขวง/ตำบล',
                            required: false,
                            col_span: 11
                        },
                        driver_address_city: {
                            input_type: 'text',
                            placeholder: 'เขต/อำเภอ',
                            required: false,
                            col_span: 11
                        },
                        driver_address_province: {
                            input_type: 'text',
                            placeholder: 'จังหวัด',
                            required: false,
                            col_span: 11
                        },
                        driver_address_postal_code: {
                            input_type: 'text',
                            placeholder: 'รหัสไปรษณีย์',
                            required: false,
                            col_span: 11
                        },
                        driver_license_no: {
                            input_type: 'text',
                            placeholder: 'หมายเลขใบอนุญาตขับรถ',
                            required: false,
                            col_span: 11
                        },
                        driving_license_type_id: {
                            input_type: 'select',
                            modelname: 'DrivingLicenseType',
                            label_key: 'name',
                            placeholder: 'ประเภทใบขับขี่',
                            required: false,
                            col_span: 11,
                            non_editable: true,

                        },
                        // driver_license_type: {
                        //     input_type: 'select_driver_license_type',
                        //     placeholder: 'ประเภทใบอนุญาต',
                        //     required: false,
                        //     col_span: 11
                        // },
                        driver_bank: {
                            input_type: 'select_driver_bank',
                            placeholder: 'ธนาคาร',
                            required: false,
                            col_span: 11
                        },
                        bank_accno: {
                            input_type: 'text',
                            placeholder: 'หมายเลยบัญชีธนาคาร',
                            required: false,
                            col_span: 11
                        },
                        salary: {
                            input_type: 'text',
                            placeholder: 'อัตราเงินเดือน',
                            required: false,
                            col_span: 23
                        },
                        driver_tax_rate_service: {
                            input_type: 'text',
                            placeholder: 'อัตราดอกเบี้ยบริการ',
                            required: false,
                            col_span: 23
                        },
                        topic_important: {
                            input_type: 'bold',
                            col_span: 23,
                            label: 'เอกสารสำคัญ',
                        },
                        important_doc1: {
                            input_type: 'file',
                            required: false,
                            col_span: 4,
                            accept: '.pdf'
                        },
                        important_doc2: {
                            input_type: 'file',
                            required: false,
                            col_span: 4,
                            accept: '.pdf'
                        },
                        important_doc3: {
                            input_type: 'file',
                            required: false,
                            col_span: 4,
                            accept: '.pdf'
                        },
                        important_doc4: {
                            input_type: 'file',
                            required: false,
                            col_span: 4,
                            accept: '.pdf'
                        },
                        important_doc5: {
                            input_type: 'file',
                            required: false,
                            col_span: 4,
                            accept: '.pdf'
                        },
                        topic_other: {
                            input_type: 'bold',
                            col_span: 23,
                            label: 'เอกสารต่างๆ',
                        },
                        other_doc1: {
                            input_type: 'file',
                            required: false,
                            col_span: 4,
                            accept: '.pdf'
                        },
                        other_doc2: {
                            input_type: 'file',
                            required: false,
                            col_span: 4,
                            accept: '.pdf'
                        },
                        other_doc3: {
                            input_type: 'file',
                            required: false,
                            col_span: 4,
                            accept: '.pdf'
                        },
                        other_doc4: {
                            input_type: 'file',
                            required: false,
                            col_span: 4,
                            accept: '.pdf'
                        },
                        other_doc5: {
                            input_type: 'file',
                            required: false,
                            col_span: 4,
                            accept: '.pdf'
                        },
                        document_field_3: {
                        input_type: 'table',
                        required: false,
                        col_span: 23,
                        drawer_title: 'ผลคะแนนการทดสอบ',
                        table_columns: [
                            { title: 'วัน/เดือน/ปี', dataIndex: 'driver_document_date', key: 'driver_document_date' },
                            { title: 'รายการทดสอบ', dataIndex: 'driver_document_list', key: 'driver_document_list' },
                            { title: 'คะแนนเต็ม', dataIndex: 'driver_document_maxscore', key: 'driver_document_maxscore' },
                            { title: 'คะแนนที่ได้', dataIndex: 'driver_document_getscore', key: 'driver_document_getscore' },
                            { title: 'ผลการทดสอบ', dataIndex: 'driver_document_result', key: 'driver_document_result' },
                            { title: 'เอกสารแนบ', dataIndex: 'file_name', key: 'file_name' },
                            {
                                    title: 'ตัวเลือก',
                                    dataIndex: 'id',
                                    key: 'id',
                                    width: 100,
                                    align: 'center',
                            },
                        ],
                        drawer_fields: {
                            inputs: {
                                driver_document_date: {
                                    input_type: 'date',
                                    placeholder: 'วัน/เดือน/ปี',
                                    required: false,
                                    col_span: 23
                                },
                                driver_document_list: {
                                    input_type: 'select',
                                    placeholder: 'รายการทดสอบ',
                                    required: false,
                                    col_span: 23,
                                    options: [
                                          { label: 'ประเมินขวัญกำลังใจ', value: 'ประเมินขวัญกำลังใจ' },
                                          { label: 'การคิดจินตนาการ', value: 'การคิดจินตนาการ' },
                                          { label: 'การรับรู้รูปแบบ', value: 'การรับรู้รูปแบบ' },
                                          { label: 'การควบคุมอารมณ์ขณะขับ', value: 'การควบคุมอารมณ์ขณะขับ' },
                                          { label: 'ความสามารถของพนักงานขับรถ', value: 'ความสามารถของพนักงานขับรถ' },
                                          { label: 'ทดสอบขับรถ (ก่อนรับเข้าปฏิบัติงาน)', value: 'ทดสอบขับรถ (ก่อนรับเข้าปฏิบัติงาน)' },
                                          { label: 'อื่นๆ', value: 'OTHER' }
                                    ]
                                },
                                driver_document_maxscore: {
                                    input_type: 'text',
                                    placeholder: 'คะแนนเต็ม',
                                    required: false,
                                    col_span: 23
                                },
                                driver_document_getscore: {
                                    input_type: 'text',
                                    placeholder: 'คะแนนที่ได้',
                                    required: false,
                                    col_span: 23
                                },
                                driver_document_result: {
                                    input_type: 'select',
                                    placeholder: 'ผลการทดสอบ',
                                    required: false,
                                    col_span: 23,
                                    options: [
                                        { label: 'ผ่าน', value: 'ผ่าน' },
                                        { label: 'ไม่ผ่าน', value: 'ไม่ผ่าน' }
                                    ]
                                },
                                file: {
                                    input_type: 'file',
                                    placeholder: 'เอกสารแนบ',
                                    required: false,
                                    col_span: 23,
                                    accept: '.pdf'
                                },
                                file_name:{
                                    input_type: 'none',
                                },
                            }
                        },
                        drawer_button_text: 'เพิ่ม',
                        drawer_button_text_edit: 'แก้ไขไฟล์'
                        },
                        document_field_4: {
                        input_type: 'table',
                        required: false,
                        col_span: 23,
                        drawer_title: 'ประวัติการโยกย้ายประจำรถ',
                        table_columns: [
                            { title: 'วัน/เดือน/ปี', dataIndex: 'driver_history_movecar_date', key: 'driver_history_movecar_date' },
                            { title: 'ประจำเบอร์รถ', dataIndex: 'driver_main_car', key: 'driver_main_car' },
                            { title: 'บันทึกการตรวจรับรถ', dataIndex: 'driver_check_car', key: 'driver_check_car' },
                            { title: 'หมายเหตุ', dataIndex: 'driver_history_movecar_description', key: 'driver_history_movecar_description' },
                            { title: 'เอกสารแนบ', dataIndex: 'file_name', key: 'file_name' },
                            {
                                title: 'ตัวเลือก', dataIndex: 'id', key: 'id', width: 100, align: 'center'
                            },
                        ],
                        drawer_fields: {
                            inputs: {
                                driver_history_movecar_date: {
                                    input_type: 'date',
                                    placeholder: 'วัน/เดือน/ปี',
                                    required: false,
                                    col_span: 23
                                },
                                driver_main_car: {
                                    input_type: 'select',
                                    placeholder: 'ประจำเบอร์รถ',
                                    required: false,
                                    col_span: 23,
                                    options:  [],       
                                },
                                driver_check_car: {
                                    input_type: 'text',
                                    placeholder: 'บันทึกการตรวจรับรถ',
                                    required: false,
                                    col_span: 23
                                },
                                driver_history_movecar_description: {
                                    input_type: 'text',
                                    placeholder: 'หมายเหตุ',
                                    required: false,
                                    col_span: 23
                                },
                                file: {
                                    input_type: 'file',
                                    placeholder: 'แนบหลักฐาน(PDF)',
                                    required: false,
                                    col_span: 23,
                                    accept: '.pdf'
                                },
                                file_name:{
                                    input_type: 'none',
                                },
                            }
                        },
                        drawer_button_text: 'เพิ่ม',
                        drawer_button_text_edit: 'แก้ไขไฟล์'
                        },
                        document_field_5: {
                        input_type: 'table',
                        required: false,
                        col_span: 23,
                        drawer_title: 'ประวัติการลางาน',
                        table_columns: [
                            { title: 'วัน/เดือน/ปี', dataIndex: 'driver_leave_date', key: 'driver_leave_date' },
                            { title: 'ประเภทการลา', dataIndex: 'driver_leave_date_type', key: 'driver_leave_date_type' },
                            { title: 'จำนวนวัน', dataIndex: 'driver_leave_date_day', key: 'driver_leave_date_day' },
                            { title: 'แนบเอกสาร', dataIndex: 'file_name', key: 'file_name' },
                            { title: 'หมายเหตุ', dataIndex: 'driver_leave_date_description', key: 'driver_leave_date_description' },
                            {
                                    title: 'ตัวเลือก',
                                    dataIndex: 'id',
                                    key: 'id',
                                    width: 100,
                                    align: 'center',
                            },
                        ],
                        drawer_fields: {
                            inputs: {
                                driver_leave_date: {
                                    input_type: 'date',
                                    placeholder: 'วัน/เดือน/ปี',
                                    required: false,
                                    col_span: 23
                                },
                                driver_leave_date_type: {
                                    input_type: 'select',
                                    placeholder: 'ประเภทการลา',
                                    required: false,
                                    col_span: 23,
                                    options: [
                                        { label: 'ลากิจ', value: 'ลากิจ' },
                                        { label: 'ลาป่วย', value: 'ลาป่วย' }
                                    ]
                                },
                                driver_leave_date_day: {
                                    input_type: 'text',
                                    placeholder: 'จำนวนวัน',
                                    required: false,
                                    col_span: 23
                                },
                                file: {
                                    input_type: 'file',
                                    placeholder: 'แนบเอกสาร',
                                    required: false,
                                    col_span: 23,
                                    accept: '.pdf'
                                },
                                file_name:{
                                    input_type: 'none',
                                },
                                driver_leave_date_description: {
                                    input_type: 'text',
                                    placeholder: 'หมายเหตุ',
                                    required: false,
                                    col_span: 23
                                },
                            }
                        },
                        drawer_button_text: 'เพิ่ม',
                        drawer_button_text_edit: 'แก้ไขไฟล์'
                        },
                        document_field_6: {
                        input_type: 'table',
                        required: false,
                        col_span: 23,
                        drawer_title: 'รายละเอียดประวัติการฝึกอบรม',
                        table_columns: [
                            { title: 'วัน/เดือน/ปี', dataIndex: 'driver_train_date', key: 'driver_train_date' },
                            { title: 'ชื่อหลักสูตรการอบรม', dataIndex: 'driver_train_subject', key: 'driver_train_subject' },
                            { title: 'ประเภท', dataIndex: 'driver_train_type', key: 'driver_train_type' },
                            { title: 'สถานที่อบรม', dataIndex: 'driver_train_location', key: 'driver_train_location' },
                            { title: 'เอกสาร/ใบประกาศ (ถ้ามี)', dataIndex: 'file_name', key: 'file_name' },
                            { title: 'หมายเหตุ', dataIndex: 'driver_train_description', key: 'driver_train_description' },
                            {
                                    title: 'ตัวเลือก',
                                    dataIndex: 'id',
                                    key: 'id',
                                    width: 100,
                                    align: 'center',
                            },
                        ],
                        drawer_fields: {
                            inputs: {
                                driver_train_date: {
                                    input_type: 'date',
                                    placeholder: 'วัน/เดือน/ปี',
                                    required: false,
                                    col_span: 23
                                },
                                driver_train_subject: {
                                    input_type: 'text',
                                    placeholder: 'ชื่อหลักสูตรการอบรม',
                                    required: false,
                                    col_span: 23
                                },
                                driver_train_type: {
                                    input_type: 'select',
                                    placeholder: 'ประเภท',
                                    required: false,
                                    col_span: 23,
                                    options: [
                                        { label: 'KM', value: 'KM' },
                                        { label: 'อบรมภายใน', value: 'อบรมภายใน' },
                                        { label: 'อบรมภายนอก', value: 'อบรมภายนอก' },
                                        { label: 'OJT พนักงานใหม่ - กลุ่ม1', value: 'OJT พนักงานใหม่ - กลุ่ม1' },
                                        { label: 'OJT การทำงานฝ่าย', value: 'OJT การทำงานฝ่าย' },
                                        { label: 'อื่นๆ', value: 'OTHER' }
                                    ],
                                    
                                },
                                driver_train_location: {
                                    input_type: 'text',
                                    placeholder: 'สถานที่อบรม',
                                    required: false,
                                    col_span: 23
                                },
                                file: {
                                    input_type: 'file',
                                    placeholder: 'เอกสาร/ใบประกาศ (ถ้ามี)',
                                    required: false,
                                    col_span: 23,
                                    accept: '.pdf'
                                },
                                file_name:{
                                    input_type: 'none',
                                },
                                driver_train_description: {
                                    input_type: 'text',
                                    placeholder: 'หมายเหตุ',
                                    required: false,
                                    col_span: 23
                                },
                            }
                        },
                        drawer_button_text: 'เพิ่ม',
                        drawer_button_text_edit: 'แก้ไขไฟล์'
                        },
                        document_field_7: {
                        input_type: 'table',
                        required: false,
                        col_span: 23,
                        drawer_title: 'ประวัติการทำงานบกพร่อง',
                        table_columns: [
                            { title: 'วัน/เดือน/ปี', dataIndex: 'driver_work_defect_date', key: 'driver_work_defect_date' },
                            { title: 'เบอร์รถตอนเกิดเหตุ', dataIndex: 'driver_work_defect_car', key: 'driver_work_defect_car' },
                            { title: 'ประเภทข้อบกพร่อง', dataIndex: 'driver_work_defect_type', key: 'driver_work_defect_type' },
                            { title: 'รายละเอียด', dataIndex: 'driver_work_defect_detail', key: 'driver_work_defect_detail' },
                            { title: 'การลงโทษ', dataIndex: 'driver_work_defect_punishment', key: 'driver_work_defect_punishment' },
                            { title: 'แนบเอกสาร', dataIndex: 'file_name', key: 'file_name' },
                            {
                                    title: 'ตัวเลือก',
                                    dataIndex: 'id',
                                    key: 'id',
                                    width: 100,
                                    align: 'center',
                            },
                        ],
                        drawer_fields: {
                            inputs: {
                                driver_work_defect_date: {
                                    input_type: 'date',
                                    placeholder: 'วัน/เดือน/ปี',
                                    required: false,
                                    col_span: 23
                                },
                                driver_work_defect_car: {
                                    input_type: 'select',
                                    placeholder: 'เบอร์รถตอนเกิดเหตุ',
                                    required: false,
                                    col_span: 23,
                                    options: []
                                },
                                driver_work_defect_type: {
                                    input_type: 'select',
                                    placeholder: 'ประเภทข้อบกพร่อง',
                                    required: false,
                                    col_span: 23,
                                    options: [
                                        { label: 'ข้อร้องเรียนจากลูกค้า', value: 'ข้อร้องเรียนจากลูกค้า' },
                                        { label: 'ข้อร้องเรียนผู้ร่วมทาง', value: 'ข้อร้องเรียนผู้ร่วมทาง' },
                                        { label: 'ข้อบกพร่องการทำงาน (ระบุ)', value: 'ข้อบกพร่องการทำงาน (ระบุ)' },
                                        { label: 'ประพฤติผิดวินัย (ระบุ)', value: 'ประพฤติผิดวินัย (ระบุ)' },
                                        { label: 'อื่นๆ', value: 'OTHER' }
                                    ],
                                },
                                driver_work_defect_detail: {
                                    input_type: 'text',
                                    placeholder: 'รายละเอียด',
                                    required: false,
                                    col_span: 23
                                },
                                driver_work_defect_punishment: {
                                    input_type: 'text',
                                    placeholder: 'การลงโทษ',
                                    required: false,
                                    col_span: 23
                                },
                                file: {
                                    input_type: 'file',
                                    placeholder: 'เอกสาร/ใบประกาศ (ถ้ามี)',
                                    required: false,
                                    col_span: 23,
                                    accept: '.pdf'
                                },
                                file_name:{
                                    input_type: 'none',
                                },
                            }
                        },
                        drawer_button_text: 'เพิ่ม',
                        drawer_button_text_edit: 'แก้ไขไฟล์'
                        },
                        document_field_8: {
                        options: [],
                        input_type: 'table',
                        required: false,
                        col_span: 23,
                        drawer_title: 'ประวัติการเกิดอุบัติเหตุ',
                        table_columns: [
                            { title: 'วัน/เดือน/ปี', dataIndex: 'driver_accident_date', key: 'driver_accident_date' },
                            { title: 'เบอร์รถตอนเกิดเหตุ', dataIndex: 'driver_accident_car', key: 'driver_accident_car' },
                            { title: 'ผิด/ถูก', dataIndex: 'driver_accident_result', key: 'driver_accident_result' },
                            { title: 'รายละเอียด', dataIndex: 'driver_accident_detail', key: 'driver_accident_detail' },
                            { title: 'ค่าเสียหาย', dataIndex: 'driver_accident_total', key: 'driver_accident_total' },
                            { title: 'หมายเหตุ', dataIndex: 'driver_accident_description', key: 'driver_accident_description' },
                            {
                                    title: 'ตัวเลือก',
                                    dataIndex: 'id',
                                    key: 'id',
                                    width: 100,
                                    align: 'center',
                            },
                        ],
                        drawer_fields: {
                            inputs: {
                                driver_accident_date: {
                                    input_type: 'date',
                                    placeholder: 'วัน/เดือน/ปี',
                                    required: false,
                                    col_span: 23
                                },
                                driver_accident_car: {
                                    input_type: 'select',
                                    placeholder: 'เบอร์รถตอนเกิดเหตุ',
                                    required: false,
                                    col_span: 23,
                                    options:  [],       
                                },
                                driver_accident_result: {
                                    input_type: 'select',
                                    placeholder: 'ฝ่าย(ผิด/ถูก)',
                                    required: false,
                                    col_span: 23,
                                    options: [
                                        { label: 'ฝ่ายผิด', value: 'ฝ่ายผิด' },
                                        { label: 'ฝ่ายถูก', value: 'ฝ่ายถูก' }
                                    ]
                                },
                                driver_accident_detail: {
                                    input_type: 'text',
                                    placeholder: 'รายละเอียด',
                                    required: false,
                                    col_span: 23
                                },
                                driver_accident_total: {
                                    input_type: 'text',
                                    placeholder: 'ค่าเสียหาย',
                                    required: false,
                                    col_span: 23
                                },
                                driver_accident_description: {
                                    input_type: 'text',
                                    placeholder: 'หมายเหตุ',
                                    required: false,
                                    col_span: 23
                                },
                            }
                        },
                        drawer_button_text: 'เพิ่ม',
                        drawer_button_text_edit: 'แก้ไขไฟล์'
                        }
                    }
                }
            },
            model_form: {
                key: null // Initialize with your appropriate key
            },
            sex_types: [],
            check_keys_to_modelname: {
                driver_no: 'Driver',
                username: 'ClientContact',
                plate_no: 'Car'
            },
            delayed: null,
            oldPassword: null
        }
    },
    methods: {
        updateWorkAge() {
            if (!this.model_form.register_date) {
                this.model_form.driver_age_of_work = '';
                return;
            }

            const startDate = new Date(this.model_form.register_date);
            if (isNaN(startDate)) {
                this.model_form.driver_age_of_work = '';
                return;
            }

            const today = new Date(this.today);
            const diffTime = Math.abs(today - startDate);
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
            this.model_form.driver_age_of_work = `${diffDays} วัน`;
        },
        handleChange(type, event) {
            if (type === 'start') {
                this.start = event.target.checked;
                if (this.start) {
                    this.end = false;
                    this.model_form.driver_stoped_employment_date = null;
                }
            } else if (type === 'end') {
                this.end = event.target.checked;
                if (this.end) {
                    this.start = false;
                }
            }

            this.showEmploymentEndDate = !this.start;
        },
        setFileType(file) {
            const fileType = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif' 
            || file.type === 'image/raw' || file.type === 'image/webp' || file.type === 'image/tif' || file.type === 'image/psd' || file.type === 'image/svg';
                if (!fileType) {
                    this.$message.error('คุณสามารถอัปโหลดได้เฉพาะไฟล์รูปภาพเท่านั้น!');
                }
            return fileType
        },
        handleChangeDrivingLicenseType(value) {
            if (![1, 2, 3].includes(value)) {
                this.model_form.driving_license_type_id = null;
            } else {
                this.model_form.driving_license_type_id = value;
            }
        },
        handleEducationChange(value, key) {
            if (value === 'อื่นๆ') {
                this.currentFieldKey = key;
                this.showOtherEducationModal = true;
            }
        },
        handleModalOk() {
            this.model_form[this.currentFieldKey] = this.otherEducationInput;
            this.showOtherEducationModal = false;
            this.otherEducationInput = '';
        },
        handleModalCancel() {
            this.showOtherEducationModal = false;
            this.otherEducationInput = '';
        },
        handleIconClick(action, payload, event) {
            event.stopPropagation();
            if (action === 'preview') {
                this.showPreview(payload);
            } else if (action === 'delete') {
                this.model_form[payload] = '';
                this.fileList = [];
            }
        },
        showPreview(imageUrl) {
            this.previewImage = imageUrl;
            this.previewVisible = true;
        },
        // upload_files(event, key) {
            // Your upload file logic here
        // },
        async client_no_on_change() {
            this.model_form.client_no = (this.model_form.client_type_id? this.model_form.client_type_id:0).toString().padStart(2, '0') + await SystemService.get_client_no()
        },
        async validate_duplicate(_rule, value) {
            return new Promise((resolve, reject) => {
                if(_rule.required && !value) {
                        reject('โปรดระบุ');
                }
                setTimeout(() => {
                    this.$route.params.id == 'create' ? 
                    SystemService.check_duplicate(
                        this.model_type,
                        // this.check_keys_to_modelname[_rule.field],
                        `field=${_rule.field}&value=${value}&id=${this.action_type}`
                    ).then((res) => {
                        if (res) {
                            reject("มีข้อมูลนี้อยู่แล้ว");
                        } else {
                            resolve();
                        }
                    })
                    : resolve();
                }, 0);
            });
        },
        filterOption (input, option) {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
        async remove_uploaded_file({ uid }) {
            // console.log(uid);
            this.model_form[uid] = ''
        },
        show_sub_selectables(parent) {
            try {
                return this.selectables[parent.list].find(({ id }) => id == this.model_form[parent.key])[parent.sub_list_name]
            } catch (er) {
                return []
            }
        },
        async go_remove(id, type) {
            Swal.fire({
                title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่? [${type}ID: ${id}]`,
                text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all(type, id)
                    Swal.fire(
                        'ลบข้อมูลเรียบร้อย!',
                        'ข้อมูลของคุณถูกลบแล้ว',
                        'success'
                    )
                }
            })
        },
        upload_files(e, key) {
            if (e.file.status != 'remove') {
                this.getBase64(e.file.originFileObj, key)
                if (e.file.status == 'uploading' && !this.isLoading) {
                    this.$message.loading('กำลังอัปโหลดไฟล์...');
                    this.isLoading = true;
                }
                if (e.file.status == 'error') {
                    this.$message.success('อัปโหลดไฟล์สำเร็จ')
                    this.isLoading = false;
                }
                return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
            }
        },
        async upload_file_multiple(e, key) {
            if (e.file.status != 'remove') {
                var file = e.file.originFileObj
                if (key) {
                    const vue = this
                    if (!vue.data_raw_files) {
                        vue.data_raw_files = [];
                    }
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                        if(vue.data_raw_files.map(e => e.file_name).includes(file.name)) return
                        vue.data_raw_files.push({
                            file: reader.result,
                            file_name: file.name,
                            name: '',
                        })
                    };
                } else {
                    return await new Promise((resolve, reject) => {
                        if (file) {
                            if (typeof file == 'string') {
                                resolve(file)
                            } else {
                                const reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => resolve(reader.result);
                                reader.onerror = error => reject(error);
                            }
                        }
                    });
                }
                return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
            }
        },
        async getBase64(file, key) {
            if (key) {
                const vue = this
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    vue.model_form[key] = reader.result
                    vue.model_form[`${key}_file_name`] = file.name
                };
            } else {
                return await new Promise((resolve, reject) => {
                    if (file) {
                        if (typeof file == 'string') {
                            resolve(file)
                        } else {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = error => reject(error);
                        }
                    }
                });
            }
        },
        async validate_model_form() {
            try {
                if (this.data_raw_files && this.data_raw_files.length) {
                    this.model_form.data_raw_files = this.data_raw_files
                }
                
                if (this.model_form.create_options) {
                    this.model_form.car_options = this.model_form.create_options.map((id) => {
                        return {
                            option_id: id
                        }
                    })
                    delete this.model_form.create_options
                }

                if (this.model_form.photos) {
                    for (var i = 0; i < this.model_form.photos.length; i++) {
                        this.model_form[`photo_${i + 1}`] = await this.getBase64(this.model_form.photos[i].originFileObj)
                    }
                    delete this.model_form.photos
                    delete this.model_form.create_photos
                }
                this.model_form.company_id = this.company_id
                
            } catch (err) {
                console.log(err);
            }
        },
        async submit_model_data() {
            const modelType = this.model_type
            const type = this.$route.params.id == 'create' ? 'create_all' : 'update_all';
            const data = JSON.parse(JSON.stringify(this.model_form));
            const { password, ...newData } = data;
            const lastestData = (password === this.oldPassword || password === null) ? newData : data
            const result = { ...lastestData, 
                document_field_3: (this.model_form.document_field_3 !== null) ? JSON.stringify(this.model_form.document_field_3) : null,
                document_field_4: (this.model_form.document_field_4 !== null) ? JSON.stringify(this.model_form.document_field_4) : null,
                document_field_5: (this.model_form.document_field_5 !== null) ? JSON.stringify(this.model_form.document_field_5) : null,
                document_field_6: (this.model_form.document_field_6 !== null) ? JSON.stringify(this.model_form.document_field_6) : null,
                document_field_7: (this.model_form.document_field_7 !== null) ? JSON.stringify(this.model_form.document_field_7) : null,
                document_field_8: (this.model_form.document_field_8 !== null) ? JSON.stringify(this.model_form.document_field_8) : null,
             }
             try {
                Swal.fire({
                    title: 'กรุณารอสักครู่',
                    text: 'กำลังบันทึกข้อมูล',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    willOpen: () => {
                        Swal.showLoading();
                    }
                });
                this.validate_model_form();
                if (this.model_type === 'Driver') {
                    if (type === 'create_all') {
                        // await DriverService.createDriver({ data: [data] });
                        await DriverService.createDriver({ data: [result] });
                    } else {
                        await DriverService.updateDriver({ data: [result] }, this.model_form.id);
                    }
                } else {
                    if (this.model_form.withholding) {
                        this.model_form.withholding = this.model_form.withholding.id;
                    }
                    if (this.model_form.driver_id) {
                        const existingCars = await CarService.getCarByDriver(this.model_form.driver_id);
                        const isExistingCar = await existingCars.some(car => car.id === this.model_form.id);
                        if (existingCars.length > 0 && !isExistingCar) {
                            Swal.close();
                            const confirmation = await Swal.fire({
                                title: 'คนขับมีรถประจำอยู่แล้ว',
                                text: 'คุณต้องการดำเนินการต่อหรือไม่?',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'ดำเนินการต่อ',
                                cancelButtonText: 'ยกเลิก'
                            });
                            if (!confirmation.isConfirmed) {
                                Swal.fire({
                                    title: 'ยกเลิกการดำเนินการ',
                                    text: 'การดำเนินการถูกยกเลิก',
                                    icon: 'info',
                                    timer: 1500,
                                    showConfirmButton: false
                                });
                                return;
                            }
                            Swal.fire({
                                title: 'กรุณารอสักครู่',
                                text: 'กำลังดำเนินการต่อ',
                                allowOutsideClick: false,
                                showConfirmButton: false,
                                willOpen: () => {
                                    Swal.showLoading();
                                }
                            });
                            //TODO(Fix more behavior)
                            for (const car of existingCars) {
                                await SystemService[type](
                                    'Driver',
                                    { data: { car_id: null, driver_type: 2 } },
                                    car.driver_id
                                );
                                await SystemService[type](
                                    modelType,
                                    { data: { driver_id: null } },
                                    car.id
                                );
                            }
                        }
                        if (this.model_form.driver) {
                            await SystemService[type](
                                'Driver',
                                { data: { car_id: null, driver_type: 2 } },
                                this.model_form.driver.id
                            );
                        }
                        await SystemService[type](
                            'Driver',
                            { data: { car_id: this.model_form.id, driver_type: 1 } },
                            this.model_form.driver_id
                        );
                    }
                    // original //
                    await SystemService[type](
                        modelType,
                        type === 'create_all' ? { data: [this.model_form] } : { data: this.model_form },
                        this.model_form.id
                    );
                    // original //
                }
                Swal.close();
                Swal.fire({
                    title: 'บันทึกข้อมูลสำเร็จ',
                    icon: 'success',
                    timer: 2500,
                    timerProgressBar: false,
                    showConfirmButton: false,
                });
            } catch (error) {
                Swal.close();
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'ไม่สามารถบันทึกข้อมูลได้',
                    icon: 'error',
                    confirmButtonText: 'ตกลง',
                });
                console.log(error);
            } finally {
                this.clear_model_data();
            }
        },
        async init_create_edit() {
            try {
                const carOptions = await this.getDropdownCars();
                let driver = this.default_fields_driver.Driver.inputs
                driver.document_field_4.drawer_fields.inputs.driver_main_car.options = carOptions;
                driver.document_field_7.drawer_fields.inputs.driver_work_defect_car.options = carOptions;
                driver.document_field_8.drawer_fields.inputs.driver_accident_car.options = carOptions;
                if (this.$route.params.id != 'create') {
                    let data = await SystemService.get_id(this.model_type, this.$route.params.id)
                    if (data) {
                        if (this.model_type === "Driver") {
                            data.document_field_3 = this.safeParseJSON(data.document_field_3);
                            data.document_field_4 = this.safeParseJSON(data.document_field_4);
                            data.document_field_5 = this.safeParseJSON(data.document_field_5);
                            data.document_field_6 = this.safeParseJSON(data.document_field_6);
                            data.document_field_7 = this.safeParseJSON(data.document_field_7);
                            data.document_field_8 = this.safeParseJSON(data.document_field_8);
                        }
                        this.model_form = this.parse_update_data(data)
                    }
                } else {
                    this.model_form = this.model_data
                }
            } catch (error) {

            }
        },
        clear_model_data() {
            this.$emit('apply_model_data', {})
            this.$router.go(-1)
        },
        async get_default_fields() {
            for (var key in this.default_fields[this.model_type].inputs) {
                const { input_type, modelname, target_key } = this.default_fields[this.model_type].inputs[key]
                if ((input_type == 'select' || input_type == 'checkbox') && modelname) {
                    let res;
                    if (modelname == 'DriverStatus') {
                        res = await DriverService.getDriverWorkStatusAll()
                    } else if (modelname == 'DrivingLicenseType') {
                        res = await DriverService.getDrivingLicenseTypeAll()
                    } else if (modelname == 'Withholding') {
                        res = await WithholdingService.getAll()
                    } else if (modelname == 'Bank') {
                        res = await BankService.getAll()
                    } else if (modelname == 'Client') {
                        const res1 = await SystemService.get_all("ClientBranch")
                        this.selectables.ClientBranch = res1
                        res = await SystemService.get_all(modelname)
                    } else {
                        res = await SystemService.get_all(modelname)
                    }
                    this.selectables[modelname] = res.data ? res.data : res
                }
                if (input_type == 'photos') {
                    if (!this.model_form[target_key]) {
                        this.model_form[target_key] = []
                    }
                }
                if (key == 'client_no') {
                    this.model_form[key] = (this.model_form.client_type_id ? this.model_form.client_type_id : 0).toString().padStart(2, '0') + await SystemService.get_client_no()
                }
            }
            this.selectables['CarGroup'] = await SystemService.get_all('CarGroup')
        },
        async get_default_fields_driver() {
            for (var key in this.default_fields_driver[this.model_type]?.inputs) {
                const { input_type, modelname, target_key } = this.default_fields_driver[this.model_type]?.inputs[key]
                if ((input_type == 'select' || input_type == 'checkbox') && modelname) {
                    if (modelname == 'DriverStatus') {
                        const res = await DriverService.getDriverWorkStatusAll()
                        this.selectables[modelname] = res.data
                    } else if (modelname == 'DrivingLicenseType') {
                        const res = await DriverService.getDrivingLicenseTypeAll()
                        this.selectables[modelname] = res.data
                    } else if (modelname == 'Withholding') {
                        const res = await WithholdingService.getAll()
                        this.selectables[modelname] = res.data
                    }  else if (modelname == 'Bank') {
                        const res = await BankService.getAll()
                        this.selectables[modelname] = res.data
                        // console.log("res", res);
                    } else {
                        const res = await SystemService.get_all(modelname)
                        this.selectables[modelname] = res
                    }
                }
                if (input_type == 'photos') {
                    if (!this.model_form[target_key]) {
                        this.model_form[target_key] = []
                    }
                }
                if (key == 'client_no') {
                    this.model_form[key] = (this.model_form.client_type_id? this.model_form.client_type_id:0).toString().padStart(2, '0') + await SystemService.get_client_no()
                }
            }
            this.selectables['CarGroup'] = await SystemService.get_all('CarGroup')
        },
        clear_drawer_data(key) {
            this.default_fields[this.model_type].inputs[key].create.show = false
            this.default_fields_driver[this.model_type].inputs[key].create.show = false
        },
        parse_update_data(data) {
            try {
                for (var key in data) {
                    if (!data[key]) continue
                    if(key == "password"){
                        this.oldPassword = data[key] ?? null
                        data[key] = null
                        // console.log("oldPassword: ",this.oldPassword)
                        // console.log("password: ",data[key])
                    }
                    if(isNaN(data[key]) == false){
                        data[key] = data[key]
                    }else if(dayjs(data[key]).isValid() && key != 'car_no' && key != 'fullname' && key != 'remark') {
                        data[key] = dayjs(data[key])
                    }
                }

                const array_fields = ['options']
                for (var i = 0; i < array_fields.length; i++) {
                    const key = array_fields[i]
                    if (key in data) {
                        data[`create_${key}`] = data[key].map((item) => {
                            return item.id
                        })
                    }
                }

                const photos_fields = ['photo_1', 'photo_2', 'photo_3', 'photo_4', 'photo_5', 'photo_6', 'photo_7', 'photo_8']
                for (var i = 0; i < photos_fields.length; i++) {
                    const key = photos_fields[i]
                    if (key in data) {
                        if (data[key] != null) {
                            if (data.photos) {
                                data.photos.push({ 
                                    uid: key,
                                    name: data[key].split('/').pop(),
                                    status: 'done',
                                    url: data[key]
                                })
                            } else {
                                data.photos = [{ 
                                    uid: key,
                                    name: data[key].split('/').pop(),
                                    status: 'done',
                                    url: data[key]
                                }]
                            }
                        }
                    }
                }

                const boolean_fields = ['is_3rd_party','is_logis']
                for (var i = 0; i < boolean_fields.length; i++) {
                    const key = boolean_fields[i]
                    if (key in data) {
                        if (data[key] != null) {
                            data[key] = data[key] == 1 ? true : false
                        }
                    }
                }
                this.data_raw_files = data.data_raw_files
                this.modelname = this.model_type
                return data
            } catch (err) {
                console.log(err)
            }
        },
        init_sex_type() {
            this.sex_types = [
                { name: 'ชาย' },
                { name: 'หญิง' },
            ]
        },
        safeParseJSON(jsonString) {
            try {
                return jsonString ? JSON.parse(jsonString) : null;
            } catch (error) {
                console.error('Error parsing JSON:', jsonString, error);
                return null;
            }
        },
        async getDropdownCars() {
           const cars = await SystemService.get_core("getDropdownCars");
            return cars
        }
    },
    async mounted() {
        this.init_sex_type()
        await this.init_create_edit()
        await this.get_default_fields()
        await this.get_default_fields_driver()
    }
}
</script>

<style scoped>
.upload-container {
    position: relative;
    width: 100%;
    height: 100px;
}

.uploaded-image {
    width: 100%;
    height: 100%;
    transition: filter 0.3s ease;
    cursor: pointer;
}

.upload-container:hover .uploaded-image {
    filter: grayscale(100%);
}

.icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.upload-container:hover .material-symbols-outlined {
    color: white;
}

</style>