import httpClient from './httpClient';
const prefix = 'rollcall'

export default {
    async createRollcall(body) {
        const response = await httpClient({ requiresAuth: true }).post(`/${prefix}/createRollcall`, body)
        return response
    },
    async getRollcallByTimeStartAndTimeEnd(time_start , time_end) {
        const response = await httpClient({ requiresAuth: true }).get(`/${prefix}/getByTimeStartAndTimeEnd?time_start=${time_start}&time_end=${time_end}`)
        return response.data
    },
    async getCarRollcallByTimeStartAndTimeEnd(time_start , time_end) {
        const response = await httpClient({ requiresAuth: true }).get(`/${prefix}/getByCarTimeStartAndTimeEnd?time_start=${time_start}&time_end=${time_end}`)
        return response.data
    },
    async getDriverRollcallByTimeStartAndTimeEnd(time_start , time_end) {
        const response = await httpClient({ requiresAuth: true }).get(`/${prefix}/getByDriverTimeStartAndTimeEnd?time_start=${time_start}&time_end=${time_end}`)
        return response.data
    }
}