<template>
    <div style="padding: 15px;height: 100vh">
        <div class="app-content">
            <a-card :title="idUser? 'ข้อมูลผู้ใช้งาน (Admin)' :'เพิ่มผู้ใช้งาน (Admin)'">
                <template #extra>
                    <a-button style="display: flex" @click="rollbackPage()" size="large">
                        <template #icon>
                            <span class="material-symbols-outlined">arrow_back_ios</span>
                        </template>
                        ย้อนกลับ
                    </a-button>
                </template>
                <div style="display: flex; justify-content: center;">
                    <div class="login-form flex-column">
                        <a-form 
                            :model="formState" name="register-form" 
                            :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }"
                            autocomplete="off" @finish="onFinish" @finishFailed="onFinishFailed">
                            <a-divider orientation="left">Company Type</a-divider>
                            <a-form-item 
                            label="ประเภทธุรกิจ" 
                            name="business_type_id" 
                            :rules="[{ required: true, message: 'โปรดเลือกประเภทธุรกิจ' }]">
                                <a-radio-group v-if="!idUser" v-model:value="formState.business_type_id">
                                    <a-radio value="2">Logistics</a-radio>
                                    <a-radio value="1">Car Rental</a-radio>
                                </a-radio-group>
                                <span v-else> {{businessTypeChooseName}} </span>
                            </a-form-item>
                            <a-form-item 
                            label="ประเภทบริษัท"
                            name="tax_id"
                            :rules="[{ required: true, message: 'โปรดเลือกประเภทบริษัท' }]">
                                <a-radio-group v-if="!idUser" v-model:value="formState.tax_id">
                                    <a-radio value="1">Tour</a-radio>
                                    <a-radio value="2">Garage</a-radio>
                                    <a-radio value="3">Non-Commercial</a-radio>
                                    <a-radio value="4">Commercial</a-radio>
                                </a-radio-group>
                                <span v-else> {{companyTypeChooseName}}</span>
                            </a-form-item>

                            <a-form-item label="ชื่อบริษัท/ลูกค้า" name="company_name"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.company_name" :disabled="isDisabled"/>
                            </a-form-item>

                            <a-form-item label="เลขที่ผู้เสียภาษี" name="company_tax_id"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.company_tax_id" :disabled="isDisabled"/>
                            </a-form-item>

                            <a-form-item 
                            label="โลโก้บริษัท"
                            :rules="[{ required: true, message: 'โปรดอัพโหลดโลโก้บริษัทของคุณ' }]">
                                <a-form-item name="company_logo" no-style>
                                    <a-upload v-model:file-list="fileList" list-type="picture-card"
                                            :disabled="isDisabled"
                                            class="avatar-uploader"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            @change="upload_files($event, 'company_logo')" method="GET"
                                            :before-upload="setFileType"
                                            accept="image/*"
                                            @remove="remove_uploaded_file($event)">
                                            <img v-if="formState['company_logo']" :src="formState['company_logo']"
                                                :style="`width: 100%;object-fit: cover`"
                                                alt="avatar" />
                                            <div v-else>
                                                <p class="ant-upload-drag-icon">
                                                    <span class="material-symbols-outlined"
                                                        style="font-size: 5rem">upload_file</span>
                                                </p>
                                                คลิกเพื่ออัพโหลดรูปภาพ
                                            </div>
                                    </a-upload>
                                </a-form-item>
                            </a-form-item>

                            <a-form-item label="ที่อยู่บริษัท" name="company_address"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-textarea v-model:value="formState.company_address" :disabled="isDisabled"/>
                            </a-form-item>

                            <a-form-item label="ที่อยู่เรียกเก็บเงินของบริษัท" name="company_billing_address"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-textarea v-model:value="formState.company_billing_address" :disabled="isDisabled"/>
                            </a-form-item>

                            <a-form-item label="เบอร์โทรศัพท์ของบริษัท" name="company_phone_no"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.company_phone_no" :disabled="isDisabled"/>
                            </a-form-item>

                            <a-form-item label="ชื่อธนาคาร" name="company_bank"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-select v-model:value="formState.company_bank" :disabled="isDisabled">
                                    <a-select-option v-for="option in formState.banks" :key="option.id" :value="option.id">
                                        {{ option.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>

                            <a-form-item label="ชื่อสาขาของธนาคาร" name="company_bank_branch"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.company_bank_branch" :disabled="isDisabled"/>
                            </a-form-item>

                            <a-form-item label="ชื่อบัญชี" name="company_bank_acc_name"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.company_bank_acc_name" :disabled="isDisabled"/>
                            </a-form-item>

                            <a-form-item label="เลขที่บัญชี" name="company_bank_acc_no"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.company_bank_acc_no" :disabled="isDisabled"/>    
                            </a-form-item>

                            <a-divider orientation="left">Company owner account (ADMIN)</a-divider>

                            <a-form-item label="ชื่อ" name="admin_firstname"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.admin_firstname" :disabled="isDisabled"/>
                            </a-form-item>

                            <a-form-item label="นามสกุล" name="admin_lastname"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.admin_lastname" :disabled="isDisabled"/>
                            </a-form-item>

                            <a-form-item label="อีเมล" name="admin_email"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.admin_email" :disabled="isDisabled"/>
                            </a-form-item>

                            <a-form-item label="เบอร์โทรศัพท์" name="admin_phone_no"
                                :rules="[{ required: true, message: 'โปรดกรอกข้อมูลของคุณ' }]">
                                <a-input v-model:value="formState.admin_phone_no" :disabled="isDisabled"/>
                            </a-form-item>

                            <a-tooltip placement="top">
                                <template #title>
                                    <span>ใช้สำหรับเข้าสู่ระบบ</span>
                                </template>
                                <a-form-item label="ชื่อบัญชีผู้ใช้" name="admin_username"
                                :rules="[
                                          {
                                            required: true,
                                            message: 'โปรดกรอกข้อมูลของคุณ',
                                          },
                                          {
                                            validator: validate_duplicate,
                                            trigger: 'change',
                                          },
                                        ]"

                                 >
                                  <a-input v-model:value="formState.admin_username" :disabled="isDisabled" />
                                </a-form-item>
                            </a-tooltip>

                            <a-form-item v-if="!idUser" :wrapper-col="{ offset: 8, span: 16 }">
                                <a-button type="primary" html-type="submit">สมัครสมาชิก</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                </div>
            </a-card>
        </div>
    </div>
</template>

<script>
import input_primary from '../components/input_primary.vue'
import UserService from '../api/UserService.js'
import BankService from '../api/BankService.js'
import { defineComponent, reactive } from 'vue';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons-vue';
import SystemService from '../api/SystemService';
import BusinessTypeService from '../api/BusinessTypeService';
import CompanyService from '../api/CompanyService';
import CompanyTypeService from '../api/CompanyTypeService';
import CarCompanyService from '../api/CarCompanyService';


export default defineComponent({
    components: {
        input_primary,
        UploadOutlined,
        InboxOutlined
    },
    name: 'Login',
    data() {
        return {
            cover_img: require('@/assets/images/fleetex-home-page.png'),
            logo_img: require('@/assets/images/fleetex-log-in-logo.png'),
            idUser: null,
            businessType : [],
            companyTypeData : [],
            companyData : [],
            businessTypeChooseName : null,
            companyTypeChooseName : null,
            companyCarData : [],
        }
    },
    computed: {
        render_type() {
            return this.$route.params?.type;
        },
        isDisabled() {
            return (this.idUser !== null && this.idUser !== undefined);
        },
    },
    watch: {
        // Watch for changes in the route
        $route(newPath, oldPath) {
            this.idUser = this.render_type
            // Reset formState when navigating to a different route
            this.resetFormState();
        }
    },
    setup() {
        const fileList = []

        const formState = reactive({
            company_name: null,
            company_type: null,
            company_tax_id: null,
            company_logo: null,
            company_address: null,
            company_billing_address: null,
            company_phone_no: null,
            company_bank: null,
            company_bank_branch: null,
            company_bank_acc_name: null,
            company_bank_acc_no: null,
            banks: [],
            business_type_id: null,
            //Admin
            admin_firstname: null,
            admin_lastname: null,
            admin_email: null,
            admin_phone: null,
            admin_username: null,
            admin_password: null,
            admin_confirm_password: null
        });
        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const get_company_bank = async () => {
            try {
                const res = await BankService.getAll();
                formState.banks = res.data;
            } catch (error) {
                console.error("Error fetching banks:", error);
            }
        };

        return {
            formState,
            onFinishFailed,
            fileList,
            get_company_bank
        };
    },
    methods: {
        async rollbackPage(){
            this.$router.push(`/app/admin`)
        },
        async get_company_bank() {
            for (var key in this.formState.company_bank.inputs) {
                const { input_type, modelname} = this.formState.company_bank.inputs[key]
                if ((input_type == 'select') && modelname) {
                    if (modelname == 'Bank') {
                        const res = await BankService.getAll()
                        this.formState.company_bank[modelname] = res.data
                        console.log("res", res);
                    } 
                }
            }
        },
        resetFormState() {
            this.formState = {
                company_name: null,
                company_type: null,
                company_tax_id: null,
                company_logo: null,
                company_address: null,
                company_billing_address: null,
                company_phone_no: null,
                company_bank: null,
                company_bank_branch: null,
                company_bank_acc_name: null,
                company_bank_acc_no: null,
                banks: [],
                business_type_id: null,
                admin_firstname: null,
                admin_lastname: null,
                admin_email: null,
                admin_phone: null,
                admin_username: null,
                admin_password: null,
                admin_confirm_password: null
            };
        },
        async remove_uploaded_file({ uid }) {
            this.formState[uid] = ''
        },
        setFileType(file) {
            const fileType = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif' 
            || file.type === 'image/raw' || file.type === 'image/webp' || file.type === 'image/tif' || file.type === 'image/psd' || file.type === 'image/svg';
            if (!fileType) {
                this.$message.error('คุณสามารถอัปโหลดได้เฉพาะไฟล์รูปภาพเท่านั้น!');
            }
            return fileType
        },
        upload_files(event, field) {
            const vue = this
            const file = event.file.originFileObj
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                vue.formState[field] = reader.result
            }

            return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
        },
        onFinish(values) {
            this.$emit('register_value', values)
        },
        async sign_in() {
            const data = await UserService.login({ username: this.username, password: this.password })
            if (data.token) {
                localStorage.setItem('token', data.token)

                location.reload()
            }
        },
      async validate_duplicate(_rule, value) {
        return new Promise((resolve, reject) => {
          if (_rule.required && !value) {
            reject("โปรดระบุ");
          }
          if (!value) {
            reject();
          }
          setTimeout(() => {
            SystemService.check_duplicate(
              "Admin",
              `field=username&value=${value}&id=create`
            ).then((res) => {
              if (res) {
                reject("มีข้อมูลนี้อยู่แล้ว");
              } else {
                resolve();
              }
            });
          }, 0);
        });
      },
    },
    async mounted() {
        this.get_company_bank()
        this.idUser = this.render_type
        let checkInt = parseInt(this.idUser)||null
        if(!checkInt){
            this.idUser = null
        }
        let res = await BusinessTypeService.getAll()
        if(res){
            this.businessType = res.data
        }
        let result = await CompanyTypeService.getAll()
        if(result){
            this.companyTypeData = result.data
        }
        if(this.idUser){
            let res3 = await CarCompanyService.getByCompanyId(this.idUser);
            let admin = await SystemService.get_core('getEmailAdminByCompanyId',`company_id=${this.idUser}`);
            let res2 = await CompanyService.getCompaniesData(this.idUser);
            if (res2) {
                this.companyData = res2.data[0]
                this.companyCarData = res3[0]
                // Map res2 data to formState
                let bType = this.companyData.companyBusinessType;
                let cType = this.companyData.companyType;
                if(bType){
                    if(this.businessType.find((bs)=>bs.id == bType)){
                        bType = this.businessType.find((bs)=>bs.id == bType).name
                    }
                }
                if(cType){
                    if(this.companyTypeData.find((bs)=>bs.id == cType)){
                        cType = this.companyTypeData.find((bs)=>bs.id == cType).name
                    }
                }
                this.businessTypeChooseName = bType|| null;
                this.companyTypeChooseName = cType || null;
                this.formState.company_name = this.companyCarData.fullname || null;
                this.formState.company_tax_id = this.companyCarData.tax_id || null;
                this.formState.company_logo = this.companyCarData.logo || null;
                this.formState.company_address = this.companyCarData.address || null;
                this.formState.company_billing_address = this.companyCarData.billing_address || null;
                this.formState.company_phone_no = this.companyCarData.phone_no || null;
                this.formState.company_bank = this.companyCarData.bank_id || null;
                this.formState.company_bank_branch = this.companyCarData.bank_branch || null;
                this.formState.company_bank_acc_name = this.companyCarData.bank_account_name || null;
                this.formState.company_bank_acc_no = this.companyCarData.bank_account_no || null;

                // Admin information
                this.formState.admin_firstname = this.companyData.adminFirstName || null;
                this.formState.admin_lastname = this.companyData.adminLastName || null;
                this.formState.admin_email = this.companyCarData.email?this.companyCarData.email:admin?admin:null;
                this.formState.admin_username = this.companyData.adminUsername || null;
                this.formState.admin_phone_no = this.companyData.adminPhoneNo || null;
                
            }
        }
    }
})
</script>

<style lang="scss">
label,
h2 {
    font-weight: 400 !important;
}

.login-container {
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
}

.login-form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (min-width: 992px) {
        max-width: 50%;
        flex: 0 0 50%;
    }
}

.form-card-img {
    width: 100%;
    min-height: 300px;
    background-size: cover;
    background-position: 50% 50%;

    @media (min-width: 992px) {
        max-width: 50%;
        flex: 0 0 50%;
    }
}
</style>
