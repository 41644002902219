<template>
    <div style="padding: 15px;height: 100vh">
        <div class="app-content">
            <a-card :title="title">
                <template #extra>
                    <a-space v-if="editable">
                        <a-button v-if="button_title" style="display: flex" type="primary" size="large"
                            @click="go_create(render_type)">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            {{ button_title }}
                        </a-button>

                        <a-dropdown v-if="allow_import">
                            <template #overlay>
                                <a-menu @click="import_export_dropdown">
                                    <a-menu-item key="1">
                                        <a-upload
                                            style="display: flex"
                                            v-model:file-list="import_fileList"
                                            :show-upload-list="false"
                                            name="file"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            method="GET"
                                            @change="import_change"
                                        >
                                            <a-button ref="import_file">
                                                <span class="material-symbols-outlined">publish</span>Import
                                            </a-button>
                                        </a-upload>
                                    </a-menu-item>
                                    <a-menu-item key="2">
                                        <a-button ref="import_file">
                                            <span class="material-symbols-outlined">download</span>Export
                                        </a-button>
                                    </a-menu-item>
                                </a-menu>
                            </template>
                            <a-button style="display: flex;">
                                <span class="material-symbols-outlined">Settings</span>
                                ตัวเลือก
                                <span class="material-symbols-outlined">expand_more</span>
                            </a-button>
                        </a-dropdown>
                    </a-space>
                </template>
                <a-row>
                    <a-table
                        :columns="columns.filter((c) => !editable >= 1 ? !c.key.includes('id') : true)"
                        :data-source="show_table_list" bordered size="small"
                        :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'id'">
                                <div>
                                    <a @click="go_edit(record)"><span style="font-size: 14px"
                                            class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                    <a-divider type="vertical"></a-divider>
                                    <a @click="go_remove(record.id, record.modelname)"><span
                                            style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
                                </div>
                            </template>
                            <template v-else-if="column.key == 'isAir'">
                                <a-switch v-model:checked="record.isAir" />
                            </template>
                        </template>

                    </a-table>
                </a-row>
            </a-card>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import SystemService from '../../api/SystemService'
import Utility from '../../utility'
export default {
    name: 'sales',
    data() {
        return {
            editable: Utility.get_permission_editable_with_key('manage_sales'),
            show_table_list: [],
            title: 'จัดการพนักงานบริการลูกค้า CS',
            button_title: 'เพิ่มคนขาย',
            input: 'รหัส / ชื่อคนขาย',
            allow_import: false,
            columns: [
                {
                    title: 'ชื่อคนขาย',
                    dataIndex: 'firstname',
                    key: 'firstname',
                    sorter: true,
                    width: 200
                }, {
                    title: 'ชื่อเล่น',
                    dataIndex: 'nickname',
                    key: 'nickname',
                    sorter: true,
                    width: 200
                }, {
                    title: 'เบอร์โทร',
                    dataIndex: 'phone_no',
                    key: 'phone_no',
                    width: 125
                }, {
                    title: 'ค่า Commission',
                    dataIndex: 'commission',
                    key: 'commission',
                    width: 120
                }, {
                    title: 'ตัวเลือก',
                    dataIndex: 'id',
                    key: 'id',
                    width: 100,
                    fixed: 'right'
                }
            ],
        }
    },
    methods: {
        import_export_dropdown(e) {
            const { key } = e
            if (key == '1') {
                
            } else if (key == '2') {
                this.export_file()
            }
        },
        async go_remove(id, type) {
            Swal.fire({
                title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่? [${type}ID: ${id}]`,
                text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all(type, id)
                    this.init_sales()
                    Swal.fire(
                        'ลบข้อมูลเรียบร้อย!',
                        'ข้อมูลของคุณถูกลบแล้ว',
                        'success'
                    )
                }
            })
        },
        go_edit(model_data) {
            this.$emit('apply_model_data', model_data)
            this.$router.push(`/app/Sale/${model_data.id}`)
        },
        go_create() {
            this.$router.push('/app/Sale/create')
        },
        async init_sales() {
            this.show_table_list = await SystemService.get_all('Sale')
        }
    },
    mounted() {
        this.init_sales()
    }
}
</script>