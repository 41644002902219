import httpClient from './httpClient';

const prefix = 'core'

export default {
    async getLeaveDriver(type){
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/leave/${type}`)
        return data
    }
}
