import httpClient from './httpClient';

const prefix = 'tsm'

export default {
    async getFromIdWithRelationById (id) {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getFromIdWithRelationById?id=${id}`)
        return data
    },

    async createTSM (body) {
        const { data } = await httpClient({ requiresAuth: true }).post(`${prefix}/createTSM`, body)
        return data
    },

    async updateTSMPermissionById (body, id) {
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateTSMPermissionById/${id}`, body)
        return data
    },

    async updateTSM (body, id) {
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateTSM/${id}`, body)
        return data
    },

    async updateTSMIsActiveById (body, id) {
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateTSMIsActiveById/${id}`, body)
        return data
    },

    async deleteTSM (id) {
        const { data } = await httpClient({ requiresAuth: true }).delete(`${prefix}/deleteTSM/${id}`)
        return data
    }
}