import httpClient from './httpClient';
const prefix = 'driverInfo'

export default {
    async createDriverInfo(body) {
        const response = await httpClient({ requiresAuth: true }).post(`/${prefix}/createDriverInfo`, body)
        return response
    },
    async getDriverInfo(body) {
        const response = await httpClient({ requiresAuth: true }).get(`/${prefix}/getDriverInfo`, body)
        return response
    },
    // async getDriverInfoByDriverNo(driver_employee_id) {
    //     const data = await httpClient({ requiresAuth: true }).get(`${prefix}/getDriverInfoByDriverNo/${driver_employee_id}`);
    //     return data;
    // },
}