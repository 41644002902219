
import httpClient from './httpClient';

const prefix = 'core'

export default {
  async get_billable_bookings(page, perPage) {
    const {data} = await httpClient({ requiresAuth: true }).get(`${prefix}/billings?page=${page}&perPage=${perPage}`);
    
    return data;
  },
  async get_billings(page, perPage) {
    const {data} = await httpClient({ requiresAuth: true }).get(`${prefix}/billings/all?page=${page}&perPage=${perPage}`);
    
    return data;
  },
  async create_billing(billing) {
    const {data} = await httpClient({ requiresAuth: true }).post(`${prefix}/billings`, billing);
    
    return data;
  },
  async create_billing_list(billings) {
    const {data} = await httpClient({ requiresAuth: true }).post(`${prefix}/multi_billings`, billings);
    
    return data;
  }
}