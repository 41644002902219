<template>
    <div>
        <div v-if="show_pdf" id="content" style="height: 1754px;width: 1240px;display: flex;">
            <div style="height: 100%;width: 100%">
                <div style="display:flex;justify-content: space-between;align-items:center">
                    <img crossorigin="anonymous" style="height: 120px" :src="booking_detail.company.logo" />
                    <h1 style="margin: 0;">Job Order / ใบงาน <span v-if="selected_driver_id">[#{{ booking_detail.booking_cars[0].order_pos }}]</span></h1>
                </div>
                <h2 style="text-align: end">เลขที่ {{ booking_detail.booking_no }}</h2>
                <h2>{{ booking_detail.company.fullname }} เลขประจำตัวผู้เสียภาษี : {{ booking_detail.company.tax_id }}</h2>
                <h2>{{ booking_detail.company.address }} โทรศัพท์ : {{ booking_detail.company.phone_no }}</h2>
                <div class="job-box">
                    <h1>ข้อมูลลูกค้า</h1>
                    <br>
                    <div style="display: flex;justify-content: space-between">
                        <h2>ชื่อบริษัท : {{ booking_detail.client.fullname }}</h2>
                        <div style="display: flex; flex-direction: column">
                            <h2>ชื่อผู้ประสานงาน : {{ booking_detail.client_contact?.firstname }} {{ booking_detail.client_contact?.lastname }}</h2>
                            <h2>เบอร์โทรผู้ติดต่อ/ผู้ประสานงาน : {{ booking_detail.client_contact?.phone_no }}</h2>
                            <h2>ชื่อผู้ขาย : {{ booking_detail.sale?.firstname }} {{ booking_detail.sale?.lastname }}</h2>
                            <h2>เบอร์โทรผู้ติดต่อ/ผู้ขาย : {{ booking_detail.sale?.phone_no }}</h2>
                        </div>
                    </div>
                </div>
                <h1>วันที่รับ : {{ render_time(booking_detail.time_start, 'DD MMMM YYYY HH:mm') }} - วันที่ส่ง :
                    {{ render_time(booking_detail.time_end, 'DD MMMM YYYY HH:mm') }}</h1>
                <h2>จุดประสงค์การเดินทาง (รูปแบบงาน) : {{ booking_detail.trip?.name }}</h2>
                <h2>ผู้ร่วมงาน : {{ booking_detail.cars.map(({ name }) => name).toString() }}</h2>
                <div class="job-box">
                    <table style="width: 100%;font-size: 20px;">
                        <thead>
                            <tr style="border-bottom: 4px solid gray">
                                <th style="text-align: center;width: 100px">รายการที่</th>
                                <th style="border-left: 4px solid gray;text-align: center">PARTICULARS / รายละเอียดยานพาหนะ</th>
                                <th style="border-left: 4px solid gray;text-align: center;width: 320px">Price Per Unit / ราคาต่อคัน</th>
                            </tr>
                        </thead>
                        <tbody style="font-size: 18px;line-height: 2.5" >
                            <tr v-for="{ id, car, car_type, cost, car_group },i in booking_detail.booking_car_types" :key="id">
                                <td style="text-align: center">{{ i + 1 }}</td>
                                <td>{{ car?.name }} {{car_group?.name}} ({{car_group? car_group.seats:''}})</td>
                                <td style="text-align: end">{{ cost.toLocaleString() }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="job-box">
                    <table style="width: 100%;font-size: 20px;">
                        <thead>
                            <tr style="border-bottom: 4px solid gray">
                                <th style="text-align: center;width: 100px">รายการที่</th>
                                <th style="border-left: 4px solid gray;text-align: center">
                                    Stop Point / จุดแวะ</th>
                                <th style="border-left: 4px solid gray;text-align: center;width: 320px">
                                    Datetime / วัน-เวลา</th>
                            </tr>
                        </thead>
                        <tbody style="font-size: 18px;line-height: 2.5" >
                            <tr v-for="{ id, name, expected_timestamp},i in booking_detail.booking_cars.map(({ stops }) => (stops)).flat()" :key="id">
                                <td style="text-align: center">{{ i + 1 }}</td>
                                <td>{{name}}</td>
                                <td style="text-align: center">{{ render_time(expected_timestamp, 'DD MMMM YYYY HH:mm') }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="job-box">
                    <table style="width: 100%;font-size: 20px;">
                        <thead>
                            <tr>
                                <th>สำหรับฝ่ายบัญชี</th>
                                <th style="width: 35%"></th>
                                <th>ภาษีหัก ณ ที่จ่ายค่าขนส่ง 1%</th>
                            </tr>
                        </thead>
                        <tbody style="font-size: 18px;line-height: 2.5" >
                            <tr>
                                <td><input type="checkbox"> เงินสด จำนวน</td>
                                <td><div style="display: flex;width: 80%">= <div style="width: 100%;border-bottom:2px dotted black"></div> บาท</div></td>
                                <td><input type="checkbox"> ไม่หักภาษี ณ ที่จ่าย</td>
                            </tr>

                            <tr>
                                <td><input type="checkbox"> เช็คธนาคาร
                                    <div style="display: flex;width: 80%"><span style="white-space: nowrap;line-height: 1">ชื่อธนาคาร</span><div style="width: 100%;border-bottom:2px dotted black"></div></div>
                                </td>
                                <td><div style="display: flex;width: 80%">= <div style="width: 100%;border-bottom:2px dotted black"></div> บาท</div></td>
                                <td><input type="checkbox"> ไม่เก็บเงิน วางบิลโดน</td>
                            </tr>

                            <tr>
                                <td><input type="checkbox"> เงินสด จำนวน</td>
                                <td><div style="display: flex;width: 80%">= <div style="width: 100%;border-bottom:2px dotted black"></div> บาท</div></td>
                                <td><input type="checkbox"> ไม่หักภาษี ณ ที่จ่าย</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="job-box">
                    <table style="width: 100%;font-size: 20px;">
                        <thead>
                            <tr>
                                <th style="text-align: start;width: 15%">การเงิน (หลังจบงาน)</th>
                                <th style="width: 60rem"></th>
                                <th style="width: 15%"></th>
                                <th style="width: 60rem"></th>
                            </tr>
                        </thead>
                        <tbody style="font-size: 18px;line-height: 2.5" class="bi-column" >
                            <tr>
                                <td>ค่า พชร.</td>
                                <td><div style="display: flex;width: 80%">= <div style="width: 100%;border-bottom:2px dotted black"></div> บาท</div></td>
                                <td>เงินสำรอง</td>
                                <td><div style="display: flex;width: 80%">= <div style="width: 100%;border-bottom:2px dotted black"></div> บาท</div></td>
                            </tr>
                            <tr>
                                <td>ค่า ผู้ช่วย</td>
                                <td><div style="display: flex;width: 80%">= <div style="width: 100%;border-bottom:2px dotted black"></div> บาท</div></td>
                                <td>เก็บเงิน</td>
                                <td><div style="display: flex;width: 80%">= <div style="width: 100%;border-bottom:2px dotted black"></div> บาท</div></td>
                            </tr>
                            <tr>
                                <td>ค่า ทางด่วน</td>
                                <td><div style="display: flex;width: 80%">= <div style="width: 100%;border-bottom:2px dotted black"></div> บาท</div></td>
                                <td>จ่ายคนขับ</td>
                                <td><div style="display: flex;width: 80%">= <div style="width: 100%;border-bottom:2px dotted black"></div> บาท</div></td>
                            </tr>
                            <tr>
                                <td>ค่า น้ำแข็ง</td>
                                <td><div style="display: flex;width: 80%">= <div style="width: 100%;border-bottom:2px dotted black"></div> บาท</div></td>
                                <td>คนขับ รับ/ส่ง</td>
                                <td><div style="display: flex;width: 80%">= <div style="width: 100%;border-bottom:2px dotted black"></div> บาท</div></td>
                            </tr>
                            <tr>
                                <td>ค่า น้ำมัน</td>
                                <td><div style="display: flex;width: 80%">= <div style="width: 100%;border-bottom:2px dotted black"></div> บาท</div></td>
                            </tr>
                            <tr>
                                <td>ค่า อื่นๆ</td>
                                <td><div style="display: flex;width: 80%">= <div style="width: 100%;border-bottom:2px dotted black"></div> บาท</div></td>
                            </tr>
                            <tr>
                                <td>ค่า พขร.</td>
                                <td><div style="display: flex;width: 80%">= <div style="width: 100%;border-bottom:2px dotted black"></div> บาท</div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra);
import 'dayjs/locale/th';
import { message } from 'ant-design-vue';

export default {
    props: {
        booking_detail: Object,
        user_name: String,
        selected_driver_id: Number,
    },
    name: 'Export-Modal',
    data() {
        return {
            show_pdf: false,
            car_company_list: [],
            client_list: [],
            client_contact_list: [],
            remarks_list: [],
        }
    },
    computed: {
        time_now() {
            return dayjs().format('DD-MM-YYYY HH:mm')
        }
    },
    methods: {
        render_time(datetime, format) {
            return dayjs(datetime).locale('th').format(format)
        },
        export_pdf() {
            this.show_pdf = true
            message.loading({
                content: 'กำลังสร้าง PDF...',
                key: 'export_pdf',
                duration: 0
            });

            setTimeout(() => {
                html2canvas(document.getElementById('content'), {
                    dpi: 72,
                    scale: 2
                }).then((canvas) => {
                    var imgData = canvas.toDataURL('image/png');
                    var doc = new jsPDF('p', 'pt', 'a4', true);
                    var width = doc.internal.pageSize.getWidth();
                    var height = doc.internal.pageSize.getHeight();

                    doc.addImage(imgData, 'PNG', 4, 4, width - 8, height - 8)
                    doc.save(`JobOrder-${this.booking_detail.booking_no}.pdf`);
                    this.show_pdf = false
                    message.success({
                        content: 'สร้าง PDF เรียบร้อย',
                        key: 'export_pdf',
                        duration: 1.5
                    });
                    this.$emit('close')
                });
            }, 100)
        }
    },
    mounted() {
        this.export_pdf()
    }
}
</script>

<style scoped>
.job-box {
    margin: 2rem 0;
    padding: 2rem;
    border: 3px solid black;
    border-radius: 15px;
}

p {
    margin: 0;
    color: black !important;
}

h1 {
    font-size: 24px;
    margin: 5px 0;
}

h2 {
    font-size: 18px;
    margin: 5px 0;
}

.doc-table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
    font-size: 20px;
}

tr,
td {
    padding: 0px 5px;
}

.showing-for-dev {
    display: block;
    background: white;
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
    border: 2px solid black;
    height: 100vh;
    overflow-y: scroll;
}

.ant-row {
    margin-bottom: 8px;
}

p {
    color: grey
}

.ant-col-12 {
    min-width: 300px;
    padding: 4px
}

.ant-select,
.ant-picker {
    width: 100%
}

.ant-col {
    width: 100%
}

.ant-form-item {
    margin-bottom: 0;
}

.quotation-container {
    padding: 15px;
    height: 100vh;
}
</style>