import httpClient from './httpClient';

const prefix = 'tm'

export default {
    async getFromIdWithRelationById (id) {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getFromIdWithRelationById?id=${id}`)
        return data
    },

    async createTM (body) {
        const { data } = await httpClient({ requiresAuth: true }).post(`${prefix}/createTM`, body)
        return data
    },

    async updateTMPermissionById (body, id) {
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateTMPermissionById/${id}`, body)
        return data
    },

    async updateTM (body, id) {
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateTM/${id}`, body)
        return data
    },

    async updateTMIsActiveById (body, id) {
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateTMIsActiveById/${id}`, body)
        return data
    },

    async deleteTM (id) {
        const { data } = await httpClient({ requiresAuth: true }).delete(`${prefix}/deleteTM/${id}`)
        return data
    }
}