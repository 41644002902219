<template>
    <div style="padding: 15px;height: 100vh">
        <div class="app-content">
            <a-card title="การแจ้งลา">
                <template #extra>
                    <a-space>
                        <a-button style="display: flex" type="primary" size="large" @click="goLeaveHistory()">
                            ประวัติการแจ้งลา
                        </a-button>
                    </a-space>
                </template>
            </a-card>

            <a-space style="width: 100%; margin-top: 20px">
                <a-input-search v-model:value="search_input" placeholder="ค้นหาชื่อผู้ขับ" enter-button="Search"
                    style="width: 350px;" />

                <a-range-picker v-model:value="datetime" />

                <a-button @click="clear_search()" type="dashed">CLEAR</a-button>
            </a-space>
            <div style="display: flex; margin-top: 20px;">
                <a-table :columns="[
                            {
                                title: 'ชื่อ-นามสกุลผู้ขับ',
                                dataIndex: 'name',
                                key: 'name',
                                align: 'center',
                            },
                            {
                                title: 'วันที่ลา',
                                dataIndex: 'date',
                                key: 'date',
                                width: 250,
                            },
                            {
                                title: 'จำนวนวัน',
                                dataIndex: 'days',
                                key: 'days',
                                width: 125,
                            },
                            {
                                title: 'ประเภทการลา',
                                dataIndex: 'leave_type',
                                key: 'leave_type',
                                width: 125,
                            },
                            {
                                title: 'การอนุมัติ',
                                dataIndex: 'approve',
                                key: 'approve',
                                width: 250,
                                align: 'center',
                            },
                            {
                                title: 'ตัวเลือก',
                                key: 'action',
                                align: 'center',
                            },
                        ]" :dataSource="filtered_table(data)" bordered size="small"
                    :scroll="{ x: 'calc(100px + 50%)', y: '500px' }">
                    <template #bodyCell="{ column, record }">

                        <template v-if="column.key === 'approve'">
                            <a-select style="width: 120px;" placeholder="การอนุมัติ"
                                @change="(value) => handleChange(record.id, value)">
                                <a-select-option value=1>อนุมัติ</a-select-option>
                                <a-select-option value=2>ไม่อนุมัติ</a-select-option>
                            </a-select>
                        </template>

                        <template v-if="column.key === 'action'">
                            <div>
                                <a-tooltip>
                                    <template #title>ส่งการแจ้งเตือนอนุมัติ</template>
                                    <a @click="send_approve(record.id)"><span style="font-size: 14px"
                                            class="material-symbols-outlined">send</span></a>
                                </a-tooltip>
                                <a-tooltip>
                                    <a-divider type="vertical"></a-divider>
                                    <template #title>แก้ไข</template>
                                    <a @click="show_modal(record.id)"><span style="font-size: 14px"
                                            class="material-symbols-outlined">visibility</span></a>

                                </a-tooltip>
                                <a-divider type="vertical"></a-divider>
                                <a-tooltip>
                                    <template #title>ยกเลิก</template>
                                    <a @click="remove_leave(record.id)"><span style="font-size: 18px"
                                            class="material-symbols-outlined">delete</span></a>
                                </a-tooltip>
                            </div>

                        </template>
                    </template>
                </a-table>
            </div>
        </div>
    </div>
    <a-modal v-model:visible="visible" :title="แก้ไขการแจ้งลา" :closable="false" footer>
        <a-form :model="modal_form">
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">ชื่อ-นามสกุลผู้ขับ:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <p class="value-text">{{ modal_form.name }}</p>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">วันที่ลาเริ่มต้น:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <p class="value-text">{{ modal_form.time_start }}</p>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">วันที่ลาสิ้นสุด:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <p class="value-text">{{ modal_form.time_end }}</p>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">จำนวนวันลา:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <p class="value-text">{{ modal_form.days }}</p>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">ประเภทการลา:</p>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="18">
                    <div class="gutter-box value-container">
                        <p class="value-text">{{ modal_form.leave_type }}</p>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">ใบลา:</p>
                    </div>
                </a-col>
            </a-row>
            <div v-if="modal_form.photo != null && modal_form.photo != ''" class="image-container">
                <img :src="modal_form.photo" class="uploaded-image-table" alt="uploaded image" />
                <div class="icon-container">
                    <span class="material-symbols-outlined preview-icon" @click="showPreview($event, modal_form.photo)">
                        visibility
                    </span>
                    <span class="material-symbols-outlined download-icon"
                        @click="getBase64Image($event, modal_form.photo)">
                        download
                    </span>
                </div>
            </div>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="6">
                    <div class="gutter-box label-container">
                        <p class="label-text">หมายเหตุ:</p>
                    </div>
                </a-col>
            </a-row>
            <a-row class="row-spacing">
                <a-col class="gutter-row" :span="24">
                    <div class="gutter-box value-container">
                        <p class="textarea-like">{{ modal_form.remark }}</p>
                    </div>
                </a-col>
            </a-row>

            <div style="display: flex; width: 100%; justify-content: end; gap: 5px;">
                <a-form-item>
                    <a-button @click="close_modal(modal_form.id)" type="">ปิด</a-button>
                </a-form-item>
            </div>
            <a-modal v-model:visible="previewVisible" :footer="null" :mask="false" @cancel="previewVisible = false"
                class="custom-modal" style="background-color: none !important">
                <img :src="previewImage" alt="Preview" style="width: 100%;" />
            </a-modal>
        </a-form>
    </a-modal>

</template>

<script>
import Swal from 'sweetalert2';
import dayjs from "dayjs";
const duration = require('dayjs/plugin/duration')
dayjs.extend(duration)
import LeaveService from "../../api/LeaveService";
import SystemService from "../../api/SystemService";
export default ({

    data() {
        return {
            search_input: null,
            datetime: null,
            data: [],
            leave_response: [],
            approve: null,
            visible: false,
            modal_form: {},
            previewImage: "",
            previewVisible: false,
        };
    },
    methods: {
        show_modal(id) {
            const data = this.data.find((item) => item.id === id);
            // console.info("show_modal", data)
            this.modal_form = {
                id: data.id,
                name: data.name,
                days: data.days,
                date: [dayjs(data.time_start), dayjs(data.time_end)],
                leave_type: data.leave_type,
                time_start: data.time_start,
                time_end: data.time_end,
                approve: data.approve,
                photo: data.photo,
                remark: data.remark
            }
            this.visible = true;
        },
        showPreview(e, fileUrl) {
            e.stopPropagation();
            this.previewImage = fileUrl;
            this.previewVisible = true;
        },
        async getBase64Image(e, file) {
            e.stopPropagation();
            e.preventDefault();

            if (typeof file === 'string') {
                const result = await this.getBase64ImageFromURL(file);
                let a = document.createElement('a');
                a.href = result;
                a.download = 'image.png';
                a.click();
            } else if (file.url) {
                const result = await this.getBase64ImageFromURL(file.url);

                // download
                let a = document.createElement('a');
                a.href = result;
                a.download = 'image.png';
                a.click();
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => {
                    const base64String = reader.result;
                    let a = document.createElement('a');
                    a.href = base64String;
                    a.download = 'image.png';
                    a.click();
                };
            }
        },
        async getBase64ImageFromURL(url) {
            const res = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            });
            const blob = await res.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        },
        close_modal(id) {
            this.visible = false;
        },
        goLeaveHistory() {
            this.$router.push(`/app/leave/leaveHistory`);
        },
        dateCheck(id, value) {
            const driver_id = this.data.find((item) => item.id === id).driver_id;
            const driver = this.leave_response.filter((item) => item.driver_id === driver_id && item.id !== id && item.approve === 1);

            if (driver.length >= 1) {
                var count = 0;
                var date = {};
                driver.forEach((item) => {
                    const start = dayjs(item.time_start).startOf('day').unix();
                    const end = dayjs(item.time_end).startOf('day').unix();
                    const [time_start, time_end] = value.map(item => dayjs(item).startOf('day').unix());
                    if ((time_start >= start && time_start <= end) || (time_end >= start && time_end <= end)) {
                        count++;
                        date = { time_start: dayjs(item.time_start).format("YYYY/MM/DD"), time_end: dayjs(item.time_end).format("YYYY/MM/DD") }
                        return;
                    }
                });
                if (count > 0) {
                    Swal.fire({
                        title: `วันที่ลาซ้ำกับวันที่ลาไปแล้ว!`,
                        text: `วันที่ลาไปแล้ว ${date.time_start} - ${date.time_end}`,
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: `ตกลง`,
                    });
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        },
        async on_submit(id) {
            this.visible = false;
            if (this.modal_form.leave_type == "ลากิจ") this.modal_form.leave_type = 4
            else if (this.modal_form.leave_type == "ลาป่วย") this.modal_form.leave_type = 5
            const data = {
                time_start: this.modal_form.date[0],
                time_end: this.modal_form.date[1],
                work_status_id: parseInt(this.modal_form.leave_type),
            }
            await SystemService.update_all('Leave', { data: data }, id).then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'แก้ไขการแจ้งลาสำเร็จ',
                    showConfirmButton: false,
                    timer: 1000
                });
                this.get_leaves();
            })
        },
        handleChange(id, value) {
            const index = this.data.findIndex((item) => item.id === id);
            this.data[index].approve = parseInt(value);
            // console.log(this.data[index]);
        },
        async send_approve(id) {
            const index = this.data.findIndex((item) => item.id === id);
            if (this.data[index].approve === 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'กรุณาเลือกการอนุมัติ',
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                const driver = this.data.find((item) => item.id === id)
                if (this.data[index].approve === 2) { // กรณีไม่อนุมัติ
                    await SystemService.update_all('Leave', { data: { approve: this.data[index].approve } }, id).then(() => {
                        Swal.fire({
                            icon: 'success',
                            title: 'ส่งแจ้งเตือนการอนุมัติสำเร็จ',
                            showConfirmButton: false,
                            timer: 1500
                        });
                    });
                    this.get_leaves();
                } else { // กรณีอนุมัติ
                    const check = this.dateCheck(id, [driver.time_start, driver.time_end])
                    if (check) {
                        const now = dayjs()
                        if (now > dayjs(driver.time_start) && this.data[index].leave_type != "ลาป่วย") {
                            Swal.fire({
                                icon: 'warning',
                                iconColor: "#ff4d4f",
                                title: 'อนุมัติไม่สำเร็จ !',
                                text: "ระบบไม่สามารถอนุมัติแจ้งลากิจย้อนหลังได้",
                                showConfirmButton: true,
                                confirmButtonText: "OK"
                            });
                        } else {
                            await SystemService.update_all('Leave', { data: { approve: this.data[index].approve, is_valid: 1 } }, id)
                            // await SystemService.update_all('Driver', { data: { work_status_id: this.leave_response[index].work_status_id,
                            //     leave_status: this.leave_response[index].work_status_id, leave_date: this.data[index].time_start,}},
                            //     this.data[index].driver_id);
                            Swal.fire({
                                icon: 'success',
                                title: 'ส่งแจ้งเตือนการอนุมัติสำเร็จ',
                                showConfirmButton: false,
                                timer: 1500
                            });
                            this.get_leaves();
                        }
                    }
                }
            }
        },
        remove_leave(id) {
            Swal.fire({
                title: `คุณต้องการลบใช่หรือไม่?`,
                text: `คุณจะไม่สามารถกู้คืนได้`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `ใช่`,
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all('Leave', id).then(() => {
                        Swal.fire('ลบข้อมูลสำเร็จ', '', 'success');
                        this.get_leaves();
                    });
                } else if (result.isDenied) {
                    Swal.fire('ข้อมูลไม่ถูกลบ', '', 'info');
                }
            });
        },
        edit_leave_type(value) {
            this.modal_form.work_status_id = parseInt(value)
        },
        clear_search() {
            this.search_input = null;
            this.datetime = null;
            this.data = [];
            this.get_leaves();
        },
        filtered_table(array) {
            return array.filter((item) => {
                if (this.search_input) {
                    return item.name.includes(this.search_input);
                }
                return true;
            })?.filter((item) => {
                if (this.datetime) {
                    const [time_start, time_end] = this.datetime.map(item => dayjs(item).startOf('day').unix())
                    const start = dayjs(item.time_start).startOf('day').unix();
                    const end = dayjs(item.time_end).startOf('day').unix();
                    return start >= time_start && end <= time_end;
                }
                return true;
            });
        },
        async get_leaves() {
            this.data = [];
            this.leave_response = await LeaveService.getLeaveDriver('WAITING');
            const response = this.leave_response.filter((item) => item.approve == 0);
            // console.log(this.leave_response);
            function calDays(date1, date2) {
                const startDate = dayjs(date1).startOf('day')
                const endDate = dayjs(date2).startOf('day')
                const diffInDays = endDate.diff(startDate, 'day')
                return diffInDays == 0 ? 1 : diffInDays + 1;
            }
            for (let i = 0; i < response.length; i++) {
                this.data.push({
                    id: response[i].id,
                    driver_id: response[i].driver_id,
                    name: response[i].driver.firstname + ' ' + response[i].driver.lastname,
                    time_start: dayjs(response[i].time_start).format("YYYY/MM/DD"),
                    time_end: dayjs(response[i].time_end).format("YYYY/MM/DD"),
                    date: dayjs(response[i].time_start).format("YYYY/MM/DD") + ' - ' + dayjs(response[i].time_end).format("YYYY/MM/DD"),
                    days: (typeof response[i].count_leave === 'number') && response[i].count_leave >= 0 ? response[i].count_leave : calDays(dayjs(response[i].time_start), dayjs(response[i].time_end)),
                    leave_type: response[i].work_status.name,
                    approve: response[i].approve,
                    photo: response[i].photo_1,
                    remark: response[i].remark_leave
                });
            }
            // console.log(this.data);
        },
    },
    mounted() {
        this.get_leaves();
    },
});
</script>
<style scoped>
.label-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}

.label-text {
    margin: 0;
    padding-right: 1.5rem;
}

.row-spacing {
    margin-bottom: 16px;
}

.value-container {
    display: flex;
    align-items: center;
    height: 100%;
}

.value-text {
    /* background-color: #f5f5f5; */
    border: 1px solid #d9d9d9;
    color: #000000;
    padding: 4px 12px;
    border-radius: 16px;
    box-sizing: border-box;
    flex: 1;
    text-align: center;
    margin: 0;
}

.image-container {
    position: relative;
    display: inline-block;
    margin-bottom: 16px
}

.uploaded-image-table {
    display: block;
    width: 10rem;
    height: auto;
}

.icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    padding: 5px;
}

.preview-icon,
.download-icon {
    cursor: pointer;
    font-size: 20px;
    color: black;
}

.custom-modal ::v-deep .ant-modal-content {
    box-shadow: none;
    background-color: transparent;
}

.custom-modal ::v-deep .ant-modal-body {
    padding: 0;
}

.textarea-like {
    /* background-color: #f5f5f5; */
    border: 1px solid #d9d9d9;
    color: #000000;
    padding: 8px 12px;
    border-radius: 4px;
    min-height: 90px;
    line-height: 1.5;
    white-space: pre-wrap;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
}
</style>