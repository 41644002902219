<template>
  <div class="input-group flex-row">
    <a-input 
      v-model:value="name" 
      :placeholder="placeholder" 
      @input="$emit('input_model', $event.target.value)"
      :type="type"
      @keyup.enter="enter_press"
    >
      <template #prefix>
        <label style="font-size: 22px;user-select: none" class="material-symbols-outlined">{{icon}}</label>
      </template>
    </a-input>
  </div>
</template>

<script>
export default {
  name: 'input-primary',
  props: {
    name: String,
    type: String,
    icon: String, //https://fonts.google.com/icons?icon.set=Material+Symbols
    placeholder: String,
    enter_press: Function
  }
}
</script>

<style lang="scss" scoped>
  input:focus {
    outline: none;
  }
  input::placeholder {
    color: #b4b4b4
  }
</style>