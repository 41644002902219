
import httpClient from './httpClient';
const prefix = 'company'

export default {
  async getManageUsers(page = 1, perPage = 20) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getManageUsers?page=${page}&perPage=${perPage}`)

    return data
  },
  async getCompaniesData(company_id){
    const {data} = await httpClient({requiresAuth:true}).get(`${prefix}/getCompaniesData?company_id=${company_id}`)
    return data
  },
  async getBusinessId(company_id){
    const {data} = await httpClient({requiresAuth:true}).get(`${prefix}/getBusinessId?company_id=${company_id}`)
    return data
  }
}