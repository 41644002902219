import httpClient from './httpClient';

const prefix = 'sale'

export default {
    async getSaleAll () {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getSaleAll`)
        return data
    },
    async getSaleByCompany () {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getSaleByCompany`)
        return data
    },
    async getSaleByCompanyAndId (id) {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getSaleByCompanyAndId/${id}`)
        return data
    },

    async getFromIdWithRelationById (id) {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getFromIdWithRelationById?id=${id}`)
        return data
    },

    async createSale (body) {
        const { data } = await httpClient({ requiresAuth: true }).post(`${prefix}/createSale`, body)
        return data
    },

    async updateSalePermissionById (body, id) {
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateSalePermissionById/${id}`, body)
        return data
    },

    async updateSale (body, id) {
        let payload = {
            data: body.data[0]
        }
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateSale/${id}`,payload)
        return data
    },

    async updateSaleIsActiveById (body, id) {
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateSaleIsActiveById/${id}`, body)
        return data
    },

    async deleteSale (id) {
        const { data } = await httpClient({ requiresAuth: true }).delete(`${prefix}/deleteSale/${id}`)
        return data
    }
}