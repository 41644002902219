<template>
    <div style="padding: 15px;height: 100vh">
        <div class="app-content">
            <a-spin :spinning="loading">
                <a-card :title="title">
                    <template #extra>
                        <a-space>
                            <router-link :to="`/app/admin/register`">
                                <a-button v-if="button_title" style="display: flex;"
                                    type="primary" size="large">
                                    <template #icon><span class="material-symbols-outlined">Add</span></template>
                                    {{ button_title }}
                                </a-button>
                            </router-link>
                        </a-space>
                    </template>

                    <a-row>
                        <a-space>
                            <a-input-search v-if="input" v-model:value="search_input"
                                :placeholder="`ค้นหา ${input}`" enter-button="ค้นหา" style="width: 260px;"/>
                        </a-space>

                            <a-select
                                v-model:value="search_business_type"
                                label-in-value
                                style="width: 135px; margin-left: 1rem"
                                placeholder="ประเภทธุรกิจ"
                            >
                                <a-select-option :value="1">Car Rental</a-select-option>
                                <a-select-option :value="2">Logistics</a-select-option>
                            </a-select>

                        <a-button @click="clear_search" type="dashed" style="margin-left: 1rem">CLEAR</a-button>

                    </a-row>

                    <br>
                    <a-row>
                        <a-table :columns="columns" :data-source="filtered_list(show_table_list)" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                            <template #bodyCell="{ column, record }">
                                <template v-if="column.key === 'rights'">
                                    <div> ADMIN </div>
                                </template>
                                <template v-if="column.key === 'fullname'">
                                    <div> {{record.admin_fullname}} </div>
                                </template>
                                <template v-if="column.key === 'business_type_id'">
                                    <div> {{record.business_type_name}} </div>
                                </template>
                                <template v-if="column.key === 'phone_no'">
                                    <div> {{record.admin_phone_no}} </div>
                                </template>
                                <template v-if="column.key === 'company_type_id'">
                                    <div> {{record.company_type_name}} </div>
                                </template>
                                <template v-if="column.key === 'email'">
                                    <div> {{record.admin_email}} </div>
                                </template>
                                <template v-if="column.key == 'use_user'" >
                                    <a-row style="display: flex; justify-content: center;">
                                        <a-button 
                                            :style="buttonCancelStyle(record.use_user)"
                                            @click="closeUseUser(record.admin_id)">
                                            <strong>OFF</strong>
                                        </a-button>
                                        <a-button 
                                            :style="buttonOpenStyle(record.use_user)"
                                            @click="openUseUser(record.admin_id)">
                                            <strong style="display: flex; justify-content: center;">ON</strong>
                                        </a-button>
                                    </a-row>
                                </template>
                                <template v-if="column.key === 'admin_id'">
                                    <div>
                                        <a @click="viewUser(record.company_id)"><span style="font-size: 18px" class="material-symbols-outlined">Visibility</span></a>
                                    </div>
                                </template>
                            </template>

                        </a-table>
                    </a-row>
                </a-card>
            </a-spin>
        </div>
    </div>
</template>

<script>
import Utility from '../../utility'
import Swal from 'sweetalert2'
import SystemService from '../../api/SystemService'
export default {
    name: 'gas-station',
    computed: {
        buttonOpenStyle() {
            return (useUser) => {
                let style = {
                    borderTopRightRadius: '50px',
                    borderBottomRightRadius: '50px',
                    fontSize: '10px',
                    height: '25px',
                    width: '35px'
                };
                if (useUser == 1) {
                    style.backgroundColor = '#1890FF';
                    style.color = 'white';
                }
                return style;
            };
        },
        buttonCancelStyle() {
            return (useUser) => {
                let style = {
                    borderTopLeftRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    fontSize: '10px',
                    height: '25px',
                    width: '35px'
                };
                if (useUser == 0) {
                    style.backgroundColor = '#9f9f9f';
                    style.color = 'white';
                }
                return style;
            };
        }
    },
    data() {
        return {
            loading: false,
            search_business_type: null,

            img_placeholder: require('@/assets/images/place-holder/placeholder.jpg'),
            show_table_list: [],
            fileList: [],
            title: 'จัดการผู้ใช้ (Admin)',
            button_title: 'เพิ่มผู้ใช้งาน',
            input: 'Username / ชื่อบริษัท',
            search_input: null,
            columns: [
                {
                    title: 'Username',
                    dataIndex: 'username',
                    key: 'username',
                    width: 200,
                    sorter: {
                        compare: (a, b) => a.username.localeCompare(b.username),
                        multiple: 1,
                    },
                }, 
                {
                    title: 'ชื่อบริษัท',
                    dataIndex: 'fullname',
                    key: 'fullname',
                    width: 250,
                    sorter: {
                        compare: (a, b) => {
                            if (a.fullname && b.fullname) {
                                return a.fullname.localeCompare(b.fullname);
                            }
                            return 0;
                        },
                        multiple: 2,
                    },
                }, 
                {
                    title: 'ประเภทธุรกิจ',
                    dataIndex: 'business_type_id',
                    key: 'business_type_id',
                    width: 120
                }, 
                {
                    title: 'ประเภทบริษัท',
                    dataIndex: 'company_type_id',
                    key: 'company_type_id',
                    width: 120
                }, 
                {
                    title: 'เบอร์โทรศัพท์',
                    dataIndex: 'phone_no',
                    key: 'phone_no',
                    width: 120
                }, 
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                    width: 200
                }, 
                {
                    title: 'สิทธิ์',
                    dataIndex: 'rights',
                    key: 'rights',
                    width: 70
                }, 
                {
                    title: 'สถานะ',
                    dataIndex: 'use_user',
                    key: 'use_user',
                    width: 130,
                    align: 'center',
                }, 
                {
                    title: 'ตัวเลือก',
                    dataIndex: 'admin_id',
                    key: 'admin_id',
                    width: 70,
                    align: 'center',
                    fixed: 'right'
                }
            ],
            detail: {
                id: null,
                name: null,
                contact_name: null,
                phone_no: null,
                address: null,
                photo: null,
                company_id: null
            }
        }
    },
    methods: {
        async openUseUser(id) {
            this.loading = true;
            await SystemService.update_all("Admin", { data: { use_user: true } }, id)
            await this.init_admin()
            this.loading = false;
        },
        async closeUseUser(id) {
            this.loading = true;
            await SystemService.update_all("Admin", { data: { use_user: false } }, id)
            await this.init_admin()
            this.loading = false;
        },
        clear_search() {
            this.search_input = null
            this.search_business_type = null
        },
        filtered_list(array) {
            return array.filter(({ username, fullname, business_type_id }) => {
                const searchInputLower = this.search_input ? this.search_input.toLowerCase() : "";
                const searchInput = !this.search_input || 
                    (username && username.toLowerCase().includes(searchInputLower)) ||
                    (fullname && fullname.toLowerCase().includes(searchInputLower));

                const searchBusinessType = !this.search_business_type || !this.search_business_type.value || 
                    business_type_id == this.search_business_type.value;

                return searchInput && searchBusinessType;
            });
        },
        async init_admin () {
            this.loading = true;
            this.show_table_list = await SystemService.get_core('getDataCompanyByAdmin')
            this.loading = false;
        },
        viewUser(id){
            this.$router.push(`/app/admin/${id}`)
        },
    },
    mounted() {
        this.init_admin()
    }
}
</script>